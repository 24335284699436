export default class RecurrenceRule {
  frequency!: string | null;
  interval!: number | null;
  count!: number | null;
  until!: string | null;
  bySecond!: number[] | null;
  byMinute!: number[] | null;
  byHour!: number[] | null;
  byMonthDay!: number[] | null;
  byYearDay!: number[] | null;
  byWeekNumber!: number[] | null;
  byMonth!: number[] | null;
  bySetPosition!: number[] | null;
  byDay!: any | null;
  workweekStart!: number | null;

  constructor() {
    this.frequency = null;
    this.interval = null;
    this.count = null;
    this.until = null;
    this.bySecond = null;
    this.byMinute = null;
    this.byHour = null;
    this.byMonthDay = null;
    this.byYearDay = null;
    this.byWeekNumber = null;
    this.byMonth = null;
    this.bySetPosition = null;
    this.byDay = null;
    this.workweekStart = null;
  }
}
