export default class Permission {
  mode!: string | null;
  userName!: string | null;
  groupName!: string | null;

  constructor() {
    this.mode = null;
    this.userName = null;
    this.groupName = null;
  }
}
