
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import RpcError from "@/api/RpcError"
import LoadingButton from "@/components/common/LoadingButton.vue"
import useToast from "@/util/toasts"
import ResourceShareUserInput from "@/components/common/resourceShare/ResourceShareUserInput.vue"
import ResourceShareGroupInput from "@/components/common/resourceShare/ResourceShareGroupInput.vue"
import ResourceShare from "@/model/common/ResourceShare"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import {resourceShareServiceApi} from "@/api/ResourceShareServiceApi"
import i18n from "@/util/i18n"
import ProgressSpinner from "primevue/progressspinner"
import Avatar from "@/components/common/Avatar.vue"

@Options({
  //@ts-ignore
  props: {
    title: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: i18n.$gettext("Resource permissions changed")
    },
    errorMessage: {
      type: String,
      default: i18n.$gettext("Could not change resource permissions")
    },
    resource: String,
    type: String
  },
  components: {
    Dialog, Button, UserSharingInput, LoadingButton, TabView, TabPanel,
    ResourceShareUserInput, ResourceShareGroupInput, ProgressSpinner, Avatar
  },
  emits: [ 'hide' ]
})
export default class ResourceShareDialog extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  title: string = this.i18n.$gettext('Share resource')
  resource!: string
  type!: string
  successMessage!: string
  errorMessage!: string
  loading: boolean = false
  showSharingDialog = false
  step: number = 0
  calculatingPermissions: boolean = false
  calculatedPermissions: ResourceShare[] = []

  permissionNames: any = {
    'READ': this.i18n.$gettext('Read Only'),
    'WRITE': this.i18n.$gettext('Read & Write'),
    'OWNER': this.i18n.$gettext('Owner')
  }
  typeNames: any = {
    'USER': this.i18n.$pgettext('share-type', 'Direct'),
    'GROUP': this.i18n.$pgettext('share-type', 'Group')
  }

  get resourceShares(): ResourceShare[] {
    if (this.resource && this.type) {
      return resourceShareServiceApi.getSharesFor(this.resource, this.type).data || []
    }
    return []
  }

  get sharedToUsers(): ResourceShare[] {
    if (this.resource && this.type) {
      return this.resourceShares.filter((s: ResourceShare) => {
        return s.resource == this.resource && s.type == this.type && s.targetType === 'USER'
      }) || []
    }
    return []
  }

  get sharedToGroups(): ResourceShare[] {
    if (this.resource && this.type) {
      return this.resourceShares.filter((s: ResourceShare) => {
        return s.resource == this.resource && s.type == this.type && s.targetType === 'GROUP'
      }) || []
    }
    return []
  }

  @Watch('resource')
  watchResource(resource: string) {
    if (resource && this.type) {
      this.showSharingDialog = true
    } else {
      this.hide()
    }
  }

  calculatePermissions() {
    this.step = 1
    this.calculatingPermissions = true
    resourceShareServiceApi._calculatePermissions(this.resource, this.type, this.sharedToUsers.concat(this.sharedToGroups)).then((calculatedPermissions: ResourceShare[]) => {
      this.calculatedPermissions = calculatedPermissions
    }).catch((err: RpcError) => {
      this.toast.error(this.errorMessage, err.message)
      this.step = 0
    }).finally(() => {
      this.calculatingPermissions = false
    })
  }

  saveSharesRecursively(): Promise<void> {
    return this.save(true)
  }

  saveShares(): Promise<void> {
    return this.save(false)
  }

  save(recursive: boolean): Promise<void> {
    if (this.resource && this.type) {
      return resourceShareServiceApi._setShares(this.resource, this.type, this.sharedToUsers.concat(this.sharedToGroups), recursive).then(() => {
        this.toast.success(this.successMessage)
        this.hide()
      }).catch((err: RpcError) => {
        this.toast.error(this.errorMessage, err.message)
        //Refresh data
        this.refreshData()
      })
    } else {
      return Promise.resolve() //TODO: Display error?
    }
  }

  hide() {
    this.step = 0
    this.calculatedPermissions = []
    this.calculatingPermissions = false
    this.showSharingDialog = false
    this.$emit('hide')
  }

  refreshData() {
    if (this.resource && this.type) {
      resourceShareServiceApi.getSharesFor(this.resource, this.type, true)
    }
  }

  updated() {
    this.refreshData()
  }

  mounted() {
    this.watchResource(this.resource)
  }
}
