import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Contact from '@/model/entry/Contact';
import Page from '@/model/Page';
import Query from '@/model/common/Query';
import { contactStore } from '@/store/ContactStore';

export default class GeneratedContactServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getAllContacts(originalParentId: string): Promise<Contact[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getAllContacts', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const contacts: Contact[] = data.map(contact => Object.assign(new Contact(), contact))
                contactStore.addOrReplaceContacts(contacts)
                return contacts
            } else return Promise.reject()
        })
    }


    _queryContacts(query: Query): Promise<Contact[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryContacts', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const contacts: Contact[] = data.map(contact => Object.assign(new Contact(), contact))
                contactStore.addOrReplaceContacts(contacts)
                return contacts
            } else return Promise.reject()
        })
    }


    _addContact(toAdd: Contact): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addContact', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Contact(), data)
            contactStore.addOrReplaceContact(model)
            return model.originalId
        })
    }

    _importContacts(addressBookOriginalId: string, fileToken: string): Promise<Contact[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('importContacts', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const contacts: Contact[] = data.map(contact => Object.assign(new Contact(), contact))
                contactStore.addOrReplaceContacts(contacts)
                return contacts
            } else return Promise.reject()
        })
    }


    _updateContact(toUpload: Contact): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateContact', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Contact(), data)
            contactStore.removeContact(toUpload.originalId)
            contactStore.addOrReplaceContact(model)
            return model.originalId
        })
    }

    _moveContact(originalId: string, targetId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('moveContact', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Contact(), data)
            contactStore.removeContact(originalId)
            contactStore.addOrReplaceContact(model)
            return model.originalId
        })
    }

    _deleteContact(originalId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteContact', rpcParams, false).then(() => {
            contactStore.removeContact(originalId)
        })
    }

    getAllContacts(originalParentId: string, refresh?: boolean | number, sortBy?: string[] | string): SWR<Contact[], string[]> {
        //@ts-ignore
        const result: SWR<Contact[], string[]> = reactive(new SWR<Contact[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getAllContacts' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getAllContacts[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const contacts: Contact[] = []
                for (const id of data) {
                    const contact: Contact | undefined = contactStore.state.contacts.get(id)
                    if (contact) {
                        contacts.push(contact)
                    }
                }
                result.data = contacts
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getAllContacts(originalParentId).then((data: Contact[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(contact => contact.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let contacts: Contact[] = [...contactStore.state.contacts.values()]
        if (originalParentId) contacts = SortAndFilterUtil.filter(contacts, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(contacts, sortBy)
        
        result.data = contacts
        return result
    }

    queryContacts(query: Query, refresh?: boolean | number): SWR<Contact[], string[]> {
        //@ts-ignore
        const result: SWR<Contact[], string[]> = reactive(new SWR<Contact[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_queryContacts' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_queryContacts[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const contacts: Contact[] = []
                for (const id of data) {
                    const contact: Contact | undefined = contactStore.state.contacts.get(id)
                    if (contact) {
                        contacts.push(contact)
                    }
                }
                result.data = contacts
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._queryContacts(query).then((data: Contact[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(contact => contact.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const contacts: Contact[] = []
            for (const id of cached.data) {
                const contact: Contact | undefined = contactStore.state.contacts.get(id)
                if (contact) {
                    contacts.push(contact)
                }
            }
            result.data = contacts
        }
        return result
    }

    importContacts(addressBookOriginalId: string, fileToken: string, refresh?: boolean | number): SWR<Contact[], string[]> {
        //@ts-ignore
        const result: SWR<Contact[], string[]> = reactive(new SWR<Contact[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_importContacts' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_importContacts[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const contacts: Contact[] = []
                for (const id of data) {
                    const contact: Contact | undefined = contactStore.state.contacts.get(id)
                    if (contact) {
                        contacts.push(contact)
                    }
                }
                result.data = contacts
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._importContacts(addressBookOriginalId, fileToken).then((data: Contact[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(contact => contact.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const contacts: Contact[] = []
            for (const id of cached.data) {
                const contact: Contact | undefined = contactStore.state.contacts.get(id)
                if (contact) {
                    contacts.push(contact)
                }
            }
            result.data = contacts
        }
        return result
    }

    getContactsFilterByOriginalParentId(originalParentId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<Contact[], string[]> {
        //@ts-ignore
        const result: SWR<Contact[], string[]> = reactive(new SWR<Contact[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getAllContacts' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getAllContacts[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const contacts: Contact[] = []
                for (const id of data) {
                    const contact: Contact | undefined = contactStore.state.contacts.get(id)
                    if (contact) {
                        contacts.push(contact)
                    }
                }
                result.data = contacts
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getAllContacts(originalParentId).then((data: Contact[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(contact => contact.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let contacts: Contact[] = [...contactStore.state.contacts.values()]
        contacts = SortAndFilterUtil.filter(contacts, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(contacts, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            contacts = contacts.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = contacts
        return result
    }

    getContactsFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Contact[] {
        let contacts: Contact[] = [...contactStore.state.contacts.values()]
        contacts = SortAndFilterUtil.filter(contacts, { queryId: queryId })
        SortAndFilterUtil.sort(contacts, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            contacts = contacts.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return contacts
    }

    getContacts(sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Contact[] {
        let contacts: Contact[] = [...contactStore.state.contacts.values()]
        
        SortAndFilterUtil.sort(contacts, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            contacts = contacts.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return contacts
    }

    getContact(originalId: string): Contact | undefined {
        return contactStore.state.contacts.get(originalId)
    }
}
