export default class OutOfOffice {
  id!: number | null;
  start!: string | null;
  end!: string | null;
  subject!: string | null;
  htmlBody!: string | null;
  textBody!: string | null;

  constructor() {
    this.id = null;
    this.start = null;
    this.end = null;
    this.subject = null;
    this.htmlBody = null;
    this.textBody = null;
  }
}
