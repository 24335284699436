import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import INode from '@/model/entry/INode';


export default class GeneratedINodeStore {

    state = reactive({
        iNodes: new Map<string, INode>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.iNodes) this.state.iNodes.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceINode(newINode: INode) {
        if (newINode.path) {
            this.state.iNodes.set(newINode.path, newINode)
            
        }
    }

    addOrReplaceINodes(newINodes: INode[]) {
        for (const newINode of newINodes) {
            this.addOrReplaceINode(newINode)
        }
    }

    replaceINodes(iNodes: INode[]) {
        const newINodes = new Map<string, INode>()
        for (const newINode of iNodes) {
            if (newINode.path) {
                newINodes.set(newINode.path, newINode)
                
            }
        }
        this.state.iNodes = newINodes
    }

    removeINode(path: string | undefined | null) {
        if (path !== undefined && path !== null) {
            this.state.iNodes.delete(path)
        }
    }

    removeINodes(paths: (string | null)[] | undefined | null) {
        if (paths !== undefined && paths !== null) {
            for (const path of paths) {
                this.removeINode(path)
            }
        }
    }
}