export default class CalendarSettings {
  days!: number | null;
  showAll!: boolean | null;
  showIfOrganizer!: boolean | null;
  showIfAccepted!: boolean | null;
  showIfInvited!: boolean | null;
  filterCalendars!: boolean | null;
  calendars!: string[] | null;

  constructor() {
    this.days = null;
    this.showAll = null;
    this.showIfOrganizer = null;
    this.showIfAccepted = null;
    this.showIfInvited = null;
    this.filterCalendars = null;
    this.calendars = null;
  }
}
