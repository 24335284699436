<template>
  <Dialog
    v-model:visible="showSharingDialog"
    :header="title"
    style="min-width: 50vw; height: 40rem; min-height: 70vh"
    @hide="hide"
  >
    <template #header>
      <h6 class="m-0"><translate>Change folder Permissions</translate></h6>
    </template>
    <TabView v-if="step === 0">
      <TabPanel :header="i18n.$gettext('Users')">
        <ResourceShareUserInput
          v-model="sharedToUsers"
          only-allow-suggestions
          :resource="resource"
          :type="type"
        >
          <template #header>
            <p class="mt-4">
              <translate>Select the users you want to share this folder with</translate>.
            </p>
          </template>
        </ResourceShareUserInput>
      </TabPanel>
      <TabPanel :header="i18n.$gettext('Groups')">
        <ResourceShareGroupInput
          v-model="sharedToGroups"
          :resource="resource"
          :type="type"
        >
          <template #header>
            <p class="mt-4">
              <translate>Select the groups you want to share this folder with</translate>.
            </p>
          </template>
        </ResourceShareGroupInput>
      </TabPanel>
    </TabView>
    <div v-else-if="calculatingPermissions" style="position: absolute; width: 150px; height: 150px; top: calc(50% - 75px); left: calc(50% - 75px)">
      <ProgressSpinner />
    </div>
    <div v-else>
      <table class="w-100" style="border-spacing: 0.5rem; border-collapse: separate">
        <thead>
          <tr>
            <th><p><translate>User</translate></p></th>
            <th><p><translate>Permission</translate></p></th>
            <th><p><translate>Granted By</translate></p></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="share in calculatedPermissions" :key="share.targetId">
            <td>
              <div class="d-flex flex-row align-items-center">
                <Avatar
                    :key="share.targetId + '-avatar'"
                    :username="share.targetId"
                    :label="share.targetId"
                    :size="24"
                    generate-initials
                    class="flex-shrink-0 mr-2"
                />
                <p class="m-0">{{ share.targetId }}</p>
              </div>
            </td>
            <td>
              {{ permissionNames[share.permission] || share.permission }}
            </td>
            <td>
              {{ share.permission === 'OWNER' ? '' : (typeNames[share.targetType] || share.targetType) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <Button v-if="step === 0" class="p-button-secondary p-button-text" @click="hide">
        <translate translate-context="Abbrechen">
          Cancel
        </translate>
      </Button>
      <Button v-else class="p-button-secondary p-button-text" @click="step = 0">
        <translate translate-context="Zurück">
          Back
        </translate>
      </Button>
      <LoadingButton v-if="step === 0" variant="primary" :action="calculatePermissions">
        <translate>Check</translate>
      </LoadingButton>
      <LoadingButton v-else variant="success" :action="saveShares">
        <translate>Apply</translate>
      </LoadingButton>
      <LoadingButton v-if="step !== 0 && type === 'EMAIL_FOLDER'" variant="success" :action="saveSharesRecursively">
        <translate>Apply recursively</translate>
      </LoadingButton>
    </template>
  </Dialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import RpcError from "@/api/RpcError"
import LoadingButton from "@/components/common/LoadingButton.vue"
import useToast from "@/util/toasts"
import ResourceShareUserInput from "@/components/common/resourceShare/ResourceShareUserInput.vue"
import ResourceShareGroupInput from "@/components/common/resourceShare/ResourceShareGroupInput.vue"
import ResourceShare from "@/model/common/ResourceShare"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import {resourceShareServiceApi} from "@/api/ResourceShareServiceApi"
import i18n from "@/util/i18n"
import ProgressSpinner from "primevue/progressspinner"
import Avatar from "@/components/common/Avatar.vue"

@Options({
  //@ts-ignore
  props: {
    title: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: i18n.$gettext("Resource permissions changed")
    },
    errorMessage: {
      type: String,
      default: i18n.$gettext("Could not change resource permissions")
    },
    resource: String,
    type: String
  },
  components: {
    Dialog, Button, UserSharingInput, LoadingButton, TabView, TabPanel,
    ResourceShareUserInput, ResourceShareGroupInput, ProgressSpinner, Avatar
  },
  emits: [ 'hide' ]
})
export default class ResourceShareDialog extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  title: string = this.i18n.$gettext('Share resource')
  resource!: string
  type!: string
  successMessage!: string
  errorMessage!: string
  loading: boolean = false
  showSharingDialog = false
  step: number = 0
  calculatingPermissions: boolean = false
  calculatedPermissions: ResourceShare[] = []

  permissionNames: any = {
    'READ': this.i18n.$gettext('Read Only'),
    'WRITE': this.i18n.$gettext('Read & Write'),
    'OWNER': this.i18n.$gettext('Owner')
  }
  typeNames: any = {
    'USER': this.i18n.$pgettext('share-type', 'Direct'),
    'GROUP': this.i18n.$pgettext('share-type', 'Group')
  }

  get resourceShares(): ResourceShare[] {
    if (this.resource && this.type) {
      return resourceShareServiceApi.getSharesFor(this.resource, this.type).data || []
    }
    return []
  }

  get sharedToUsers(): ResourceShare[] {
    if (this.resource && this.type) {
      return this.resourceShares.filter((s: ResourceShare) => {
        return s.resource == this.resource && s.type == this.type && s.targetType === 'USER'
      }) || []
    }
    return []
  }

  get sharedToGroups(): ResourceShare[] {
    if (this.resource && this.type) {
      return this.resourceShares.filter((s: ResourceShare) => {
        return s.resource == this.resource && s.type == this.type && s.targetType === 'GROUP'
      }) || []
    }
    return []
  }

  @Watch('resource')
  watchResource(resource: string) {
    if (resource && this.type) {
      this.showSharingDialog = true
    } else {
      this.hide()
    }
  }

  calculatePermissions() {
    this.step = 1
    this.calculatingPermissions = true
    resourceShareServiceApi._calculatePermissions(this.resource, this.type, this.sharedToUsers.concat(this.sharedToGroups)).then((calculatedPermissions: ResourceShare[]) => {
      this.calculatedPermissions = calculatedPermissions
    }).catch((err: RpcError) => {
      this.toast.error(this.errorMessage, err.message)
      this.step = 0
    }).finally(() => {
      this.calculatingPermissions = false
    })
  }

  saveSharesRecursively(): Promise<void> {
    return this.save(true)
  }

  saveShares(): Promise<void> {
    return this.save(false)
  }

  save(recursive: boolean): Promise<void> {
    if (this.resource && this.type) {
      return resourceShareServiceApi._setShares(this.resource, this.type, this.sharedToUsers.concat(this.sharedToGroups), recursive).then(() => {
        this.toast.success(this.successMessage)
        this.hide()
      }).catch((err: RpcError) => {
        this.toast.error(this.errorMessage, err.message)
        //Refresh data
        this.refreshData()
      })
    } else {
      return Promise.resolve() //TODO: Display error?
    }
  }

  hide() {
    this.step = 0
    this.calculatedPermissions = []
    this.calculatingPermissions = false
    this.showSharingDialog = false
    this.$emit('hide')
  }

  refreshData() {
    if (this.resource && this.type) {
      resourceShareServiceApi.getSharesFor(this.resource, this.type, true)
    }
  }

  updated() {
    this.refreshData()
  }

  mounted() {
    this.watchResource(this.resource)
  }
}
</script>

<style scoped lang="scss">

</style>
