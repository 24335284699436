

import {Options, Vue} from "vue-class-component"
import EmailWidget from "@/components/dashboard/widgets/EmailWidget.vue"
import ChatWidget from "@/components/dashboard/widgets/ChatWidget.vue"
import CalendarWidget from "@/components/dashboard/widgets/CalendarWidget.vue"
import TaskWidget from "@/components/dashboard/widgets/TaskWidget.vue"
import featureSubset from "@/util/FeatureSubsets"
import IntroVideoWidget from "@/components/dashboard/widgets/IntroVideoWidget.vue"
import MigrationWidget from "@/components/dashboard/widgets/MigrationWidget.vue"
import SettingsUtil from "@/util/SettingsUtil"
import Constants from "@/util/Constants"
import migrationStatusService from "@/util/migrationStatus"
import {rpcClient} from "@/api/WebsocketClient"
import SearchBar from "@/components/common/SearchBar.vue"
import Avatar from "primevue/avatar"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ProgressSpinner from 'primevue/progressspinner'
import {ref} from "@vue/reactivity"
import Button from "primevue/button"
import DataImportWidget from "@/components/dashboard/widgets/DataImportWidget.vue"

@Options({
  components: {
    EmailWidget, ChatWidget, CalendarWidget, TaskWidget, IntroVideoWidget, MigrationWidget, SearchBar, Avatar,
    ProgressSpinner, Button, DataImportWidget
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DashboardView extends Vue {
  i18n: Language = useGettext()

  //@ts-ignore
  eMailWidgetRef: EmailWidget = ref<EmailWidget | null>(null)
  //@ts-ignore
  chatWidgetRef: ChatWidget = ref<ChatWidget | null>(null)
  //@ts-ignore
  calendarWidgetRef: CalendarWidget = ref<CalendarWidget | null>(null)
  //@ts-ignore
  taskWidgetRef: TaskWidget = ref<TaskWidget | null>(null)

  goToCalendar(): void {
    void this.$router.push('/calendar')
  }

  goToChat(): void {
    void this.$router.push('/chat')
  }

  goToMail(): void {
    void this.$router.push(this.eMailWidgetRef.inboxLink)
  }

  goToTasks(): void {
    void this.$router.push('/tasks')
  }

  get userImage(): CachedImage | null {
    const userName: string | undefined | null = this.userName
    if (userName) {
      return imageLoadingService.getCachedImage(`/groupware-api/v1/users/${userName}/picture`)
    } else {
      return null
    }
  }

  get cachedImageInstance(): CachedImage | null {
    return this.userImage || null
  }

  get userName(): string | null {
    return rpcClient.session?.user?.userName || null
  }

  get userDisplayName(): string | null {
    return rpcClient.session?.user?.displayName || null
  }

  get hasBookedEmails() {
    return featureSubset.hasMail
  }

  get hasBookedChat() {
    return featureSubset.hasChat
  }

  get hasBookedDav() {
    return featureSubset.hasDAV
  }

  get showIntroVideo() {
    return !SettingsUtil.getIntroVideoSeen() && Constants.introductionVideoUrl !== ""
  }

  get showDataImportWidget() {
    return SettingsUtil.showDataImportWidget()
  }

  get showMigrationWidget() {
    return migrationStatusService.hasAnyMigrationStatus && !this.hasAcknowledgedMigrationBanner //Show as long as any migration runs
  }

  get hasAcknowledgedMigrationBanner(): boolean {
    const settings = rpcClient.session?.user?.settings
    if(settings) {
      if(settings.hasOwnProperty("acknowledgedMigrationModal")) {
        return settings.acknowledgedMigrationModal
      } else {
        return false
      }
    } else {
      return false
    }
  }

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  get hasNothing() {
    return !this.hasBookedEmails && !this.hasBookedDav && !this.hasBookedChat
  }
}
