import GeneratedTaskBoardServiceApi from "./generated/GeneratedTaskBoardServiceApi"
import TaskBoard from "@/model/directory/TaskBoard"
import SWR from "@/api/SWR"
import TaskBoardList from "@/model/common/TaskBoardList"

class TaskBoardServiceApi extends GeneratedTaskBoardServiceApi {

    get taskBoards(): TaskBoard[] {
        const swr: SWR<TaskBoard[], string[]> = taskBoardServiceApi.getTaskBoards(false)
        return swr.data || []
    }

    getDefaultTaskList(taskBoard: TaskBoard, defaultListName: string): TaskBoardList {
        const taskLists: TaskBoardList[] = taskBoard.meta?.taskLists || []
        return taskLists.find(list => list.type === 'BACKLOG') ||
            taskLists.find(list => list.type === 'DEFAULT') ||
            taskLists.find(list => list.type === 'INBOX') ||
            Object.assign(new TaskBoardList(), { name: defaultListName, type: 'DEFAULT' })
    }
}

export const taskBoardServiceApi = new TaskBoardServiceApi()
