export default class INode {
  path!: string | null;
  originalId!: string | null;
  originalParentId!: string | null;
  name!: string | null;
  isDirectory!: boolean | null;
  parentPath!: string | null;
  hash!: string | null;
  size!: number | null;
  version!: number | null;
  canShare!: boolean | null;
  sharedFrom!: string | null;
  permission!: string | null;
  sizeHumanReadable!: string | null;
  encrypted!: boolean | null;
  virtual!: boolean | null;
  directLink!: string | null;
  thumbNailSmall!: string | null;
  thumbNailBig!: string | null;
  historyId!: string | null;
  modifiedBy!: string | null;
  lastModified!: string | null;
  backend!: string | null;
  queryId!: string | null;
  score!: number | null;

  constructor() {
    this.path = null;
    this.originalId = null;
    this.originalParentId = null;
    this.name = null;
    this.isDirectory = null;
    this.parentPath = null;
    this.hash = null;
    this.size = null;
    this.version = null;
    this.canShare = null;
    this.sharedFrom = null;
    this.permission = null;
    this.sizeHumanReadable = null;
    this.encrypted = null;
    this.virtual = null;
    this.directLink = null;
    this.thumbNailSmall = null;
    this.thumbNailBig = null;
    this.historyId = null;
    this.modifiedBy = null;
    this.lastModified = null;
    this.backend = null;
    this.queryId = null;
    this.score = null;
  }
}
