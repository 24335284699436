import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Group from '@/model/directory/Group';


export default class GeneratedGroupStore {

    state: {
        groups: Map<string, Group>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let groups: Map<string, Group> | null = null
        try {
            const persistedState = localStorage.getItem('GroupStore')
            if (persistedState) {
                groups = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            groups: groups || new Map<string, Group>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('GroupStore', JSON.stringify(Array.from(this.state.groups)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.groups) this.state.groups.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('GroupStore')
    }

    addOrReplaceGroup(newGroup: Group, persist: boolean = true): true | undefined {
        if (newGroup.name) {
            this.state.groups.set(newGroup.name, newGroup)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceGroups(newGroups: Group[]) {
        let persist = false
        for (const newGroup of newGroups) {
            const changed = !!this.addOrReplaceGroup(newGroup, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceGroups(groups: Group[]) {
        const newGroups = new Map<string, Group>()
        for (const newGroup of groups) {
            if (newGroup.name) {
                newGroups.set(newGroup.name, newGroup)
                
            }
        }
        this.state.groups = newGroups
        this.persistState()
    }

    removeGroup(name: string | undefined | null, persist: boolean = true): true | undefined {
        if (name !== undefined && name !== null) {
            if (this.state.groups.delete(name)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeGroups(names: (string | null)[] | undefined | null) {
        let persist = false
        if (names !== undefined && names !== null) {
            for (const name of names) {
                const changed = !!this.removeGroup(name, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}