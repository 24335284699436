
import {Vue} from "vue-class-component"

export default class MenuBar extends Vue {

  toggleNavBar() {
    const collapse: HTMLElement | null = this.$el.querySelector('#navbar-list')
    if (collapse && collapse.classList.contains("show")) {
      collapse.classList.remove("show")
    } else if (collapse) {
      collapse.classList.add("show")
    }
  }
}
