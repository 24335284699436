

import {Options, Vue} from "vue-class-component"
import StructuredName from "@/model/common/carddav/StructuredName"
import breakpointUtil from "@/util/BreakpointUtil"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import {useGettext} from "@jshmrtn/vue3-gettext"
import Tags from "@/components/common/Tags.vue"

@Options({
  components: {AnimatedInput, Tags},
  //@ts-ignore
  props: {
    name: [StructuredName, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    additional: {
      type: Boolean,
      default: true
    }
  },
  emits: [ 'blur' ]
})
export default class StructuredNameField extends Vue {

  i18n = useGettext()

  name!: StructuredName

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }

  get formattedName(): string {
    return (((this.name.given || '') + ' ' + this.joinAdditional).trim() + ' ' + (this.name.family || '')).trim()
  }

  get joinAdditional(): string {
    return (this.name?.additional || []).join(' ')
  }
}
