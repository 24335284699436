import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import TaskBoard from '@/model/directory/TaskBoard';


export default class GeneratedTaskBoardStore {

    state: {
        taskBoards: Map<string, TaskBoard>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let taskBoards: Map<string, TaskBoard> | null = null
        try {
            const persistedState = localStorage.getItem('TaskBoardStore')
            if (persistedState) {
                taskBoards = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            taskBoards: taskBoards || new Map<string, TaskBoard>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('TaskBoardStore', JSON.stringify(Array.from(this.state.taskBoards)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.taskBoards) this.state.taskBoards.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('TaskBoardStore')
    }

    addOrReplaceTaskBoard(newTaskBoard: TaskBoard, persist: boolean = true): true | undefined {
        if (newTaskBoard.originalId) {
            this.state.taskBoards.set(newTaskBoard.originalId, newTaskBoard)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceTaskBoards(newTaskBoards: TaskBoard[]) {
        let persist = false
        for (const newTaskBoard of newTaskBoards) {
            const changed = !!this.addOrReplaceTaskBoard(newTaskBoard, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceTaskBoards(taskBoards: TaskBoard[]) {
        const newTaskBoards = new Map<string, TaskBoard>()
        for (const newTaskBoard of taskBoards) {
            if (newTaskBoard.originalId) {
                newTaskBoards.set(newTaskBoard.originalId, newTaskBoard)
                
            }
        }
        this.state.taskBoards = newTaskBoards
        this.persistState()
    }

    removeTaskBoard(originalId: string | undefined | null, persist: boolean = true): true | undefined {
        if (originalId !== undefined && originalId !== null) {
            if (this.state.taskBoards.delete(originalId)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeTaskBoards(originalIds: (string | null)[] | undefined | null) {
        let persist = false
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                const changed = !!this.removeTaskBoard(originalId, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}