import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import FromAddress from '@/model/settings/FromAddress';


export default class GeneratedFromAddressStore {

    state: {
        fromAddresss: Map<string, FromAddress>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let fromAddresss: Map<string, FromAddress> | null = null
        try {
            const persistedState = localStorage.getItem('FromAddressStore')
            if (persistedState) {
                fromAddresss = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            fromAddresss: fromAddresss || new Map<string, FromAddress>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('FromAddressStore', JSON.stringify(Array.from(this.state.fromAddresss)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.fromAddresss) this.state.fromAddresss.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('FromAddressStore')
    }

    addOrReplaceFromAddress(newFromAddress: FromAddress, persist: boolean = true): true | undefined {
        if (newFromAddress.name) {
            this.state.fromAddresss.set(newFromAddress.name, newFromAddress)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceFromAddresss(newFromAddresss: FromAddress[]) {
        let persist = false
        for (const newFromAddress of newFromAddresss) {
            const changed = !!this.addOrReplaceFromAddress(newFromAddress, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceFromAddresss(fromAddresss: FromAddress[]) {
        const newFromAddresss = new Map<string, FromAddress>()
        for (const newFromAddress of fromAddresss) {
            if (newFromAddress.name) {
                newFromAddresss.set(newFromAddress.name, newFromAddress)
                
            }
        }
        this.state.fromAddresss = newFromAddresss
        this.persistState()
    }

    removeFromAddress(name: string | undefined | null, persist: boolean = true): true | undefined {
        if (name !== undefined && name !== null) {
            if (this.state.fromAddresss.delete(name)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeFromAddresss(names: (string | null)[] | undefined | null) {
        let persist = false
        if (names !== undefined && names !== null) {
            for (const name of names) {
                const changed = !!this.removeFromAddress(name, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}