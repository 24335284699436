
import {Options, Vue} from 'vue-class-component'
import {Watch} from "vue-property-decorator"
import AdminpanelHolder from "@/util/AdminpanelHolder"
import {reactive} from "@vue/reactivity"
import {rpcClient} from "@/api/WebsocketClient"

@Options({
  //@ts-ignore
  components: {},
  //@ts-ignore
  props: {
  },
  emits: []
})
export default class Adminpanel extends Vue {

  mounted () {
    //@ts-ignore
    AdminpanelHolder.instance = reactive(loadAdminpanel())
    this.onRouteChanged()
  }

  beforeUnmount() {
    if (AdminpanelHolder.instance) {
      AdminpanelHolder.instance.el?.removeChild(AdminpanelHolder.instance.vue.$el)
    }
  }

  get embeddedStyle(): boolean {
    return rpcClient.fullyLoggedIn
  }

  get initializing(): boolean {
    return rpcClient.initializing
  }

  @Watch('$route.path')
  onRouteChanged() {
    AdminpanelHolder.instance?.router.push(this.$route.path)
  }
}
