<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-md p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Create new Data Source
          </translate>
        </h2>
        <p>
          <translate>
          Please select the source you want to import data from and follow the next steps.
        </translate>
        </p>
        <div v-if="possibleSourceLoading">
          <Skeleton class="mt-6" height="40px" style="width: 80%" />
        </div>
        <div v-else>
          <p class="mt-4 mb-1"><translate>Please enter a display name for this data source:</translate></p>
          <InputText :placeholder="i18n.$gettext('My Datasource')" v-model="displayName" class="w-100" />
          <p class="mt-4 mb-1"><translate>Select the desired source:</translate></p>
          <Dropdown
              class="mb-4"
              v-model="selectedDataSource"
              :options="possibleSources"
              option-label="displayName"
              :placeholder="i18n.$gettext('Which service do you want to import data from?')">
          </Dropdown>
          <DataSourceInstanceForm
              v-if="selectedDataSource"
              :visible="!!selectedDataSource"
              :data-source="selectedDataSource"
              :display-name="displayName"
              @created="router.push('/settings/import')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import DataSource from "@/model/DataSource"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import Dropdown from "@/components/common/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast, {ToastAPI} from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import {Router, useRouter} from "vue-router"
import LoadingButton from "@/components/common/LoadingButton.vue"
import DataSourceInstance from "@/model/DataSourceInstance"
import InputText from "primevue/inputtext"
import ProgressBar from "primevue/progressbar"
import Skeleton from "primevue/skeleton"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import DataSourceInstanceForm from "@/components/settings/dataimportwizard/DataSourceInstanceForm.vue"

@Options({
  components: { LoadingButton, Dropdown, InputText, ProgressBar, Skeleton, DataSourceInstanceForm },
  //@ts-ignore
  props: {},
  emits: []
})
export default class NewSource extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()
  router: Router = useRouter()

  selectedDataSource: DataSource | null = null
  possibleSourceLoading: boolean = false
  possibleSources: DataSource[] = [] //We load these in the background, should be instant

  displayName: string = ""

  async loadData() {
    try {
      this.possibleSourceLoading = true
      this.possibleSources = (await dataSourceServiceApi._getAvailableDataSources()).filter(s => s.name !== 'usb')
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not fetch importable sources"))
    } finally {
      this.possibleSourceLoading = false
    }
  }

  mounted() {
    void this.loadData()
  }
}
</script>

<style lang="scss" scoped>

</style>
