export default class ChannelUnread {
  teamId!: string | null;
  channelId!: string | null;
  msgCount!: number | null;
  mentionCount!: number | null;
  properties!: any | null;
  backend!: string | null;

  constructor() {
    this.teamId = null;
    this.channelId = null;
    this.msgCount = null;
    this.mentionCount = null;
    this.properties = null;
    this.backend = null;
  }
}
