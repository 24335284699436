

import {Options, Vue} from "vue-class-component"
import SWR from "@/api/SWR"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import Skeleton from "primevue/skeleton"
import dayjs from "dayjs"
import Email from "@/model/entry/Email"
import {mailServiceApi} from "@/api/MailServiceApi"
import Page from "@/model/Page"
import RpcError from "@/api/RpcError"
import InfiniteList from "@/components/common/InfiniteList.vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import useToast from "@/util/toasts"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"

@Options({
  components: { Skeleton, InfiniteList },
  //@ts-ignore
  props: {},
  emits: []
})
export default class EmailWidget extends Vue {

  isLoading: boolean = false
  toast = useToast()
  pageSize = 50
  i18n: Language = useGettext()

  goToItem(item: Email): void {
    if (item.originalParentId && item.originalId) {
      void this.$router.push('/mail/' + encodeURIComponent(item.originalParentId) + '/' + encodeURIComponent(item.originalId))
    }
  }

  getSenderEmails(email: Email): string {
    const sender = email.senderEmail ? email.senderEmail : ''
    if (sender) return sender
    if (email.fromEmail) return email.fromEmail.join(', ')
    if (email.from) return email.from.join(', ')
    if (email.fromName) return email.fromName.join(', ')
    return email.sender ? email.sender : email.senderName || ''
  }

  getSender(email: Email): string {
    const sender = email.senderName ? email.senderName : (email.senderEmail ? email.senderEmail : email.sender) || ''
    if (sender) return sender
    if (email.fromName) return email.fromName.join(', ')
    if (email.fromEmail) return email.fromEmail.join(', ')
    if (email.from) return email.from.join(', ')
    return ''
  }

  formatEmailDate(email: Email): string {
    if (!email.receivedDate) return ""
    const d: Date = new Date(email.receivedDate)
    return dayjs(d).format('L')
  }

  get inboxFolder(): MailFolder | null {
    const swr: SWR<MailFolder[], string[]> = mailFolderServiceApi.getFolders()
    if (swr.call?.loading && swr.call?.promise) {
      this.isLoading = true
      swr.call.promise.finally(() => {
        this.isLoading = false
      })
    }
    return swr.data?.find((f: MailFolder) => {
      return f.name === "INBOX"
    }) || null
  }

  get inboxLink(): string {
    const id = this.inboxFolder?.originalId
    let link = "/mail"
    if (id) link += "/" + encodeURIComponent(id)
    return link
  }

  get allItems(): Email[] {
    if (!this.inboxFolder?.originalId) return []
    let emails: Email[] = mailServiceApi.getEmailsFilterByOriginalParentId(this.inboxFolder?.originalId, "receivedDate:desc")
    let systemFlagsContainsOne: string[] = ['UNSEEN']
    return SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
  }

  errorMessageInterval: number = 30000
  lastErrorMessage: number = Date.now() + this.errorMessageInterval
  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], Page<string>>) | null {
    if (this.inboxFolder?.originalId) {
      const originalFolderId: string = this.inboxFolder.originalId
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], Page<string>> = mailServiceApi.getEmailPreviews(originalFolderId, "receivedDate:desc", null, [ 'SEEN' ], pageIndex, pageSize, 120000)
        if (swr.call?.promise) swr.call?.promise.then((page: Page<string>) => {
          if (typeof page.total === 'number') {
            mailServiceApi.state.total = page.total
            return page.total
          } else {
            return page.hasMore
          }
        }).catch((e: RpcError) => {
          if (Date.now() > this.lastErrorMessage) {
            this.toast.error(e.message, this.i18n.$gettext('Failed to load results.'))
            this.lastErrorMessage = Date.now() + this.errorMessageInterval
          }
        })
        return swr
      }

    } else {
      return null
    }
  }

  get unreadEmailCnt(): number {
    if (!this.inboxFolder?.numberOfUnread) return 0
    return this.inboxFolder?.numberOfUnread
  }

}
