<template>
  <div class="dash-widget d-flex flex-column card card-lite bg-white z-1 mr-md-4 mr-3 mt-6">
    <div class="dash-widget-count z-2">
      {{ overDueCount }}
    </div>
    <div class="w-100 d-flex align-items-center px-3 py-3">
      <p class="h5 mb-0 flex-grow-1">
        <translate>Tasks</translate>
      </p>
      <a
        v-tooltip.left="i18n.$gettext('Settings')"
        class="nav-link flex-shrink-0"
        style="cursor: pointer"
        @click="toggleOverlayPanel"
      >
        <i class="cil-settings" style="font-size: 1.6rem" />
      </a>
    </div>
    <div class="flex-shrink-1 flex-grow-1 result-list" style="min-height: 22.8rem">
      <InfiniteList
        :get-all-items="overDueTasks"
        id-property="originalId"
      >
        <template #element="{ item }">
          <div class="pt-2 pb-2 px-3 separator-top cursor-pointer bg-hover-light" @click="goToItem(item)">
            <div class="w-100 d-flex flex-row">
              <div class="strong flex-grow-1" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                {{ item.summary }}
              </div>
            </div>
            <span
              v-if="item.due"
              :key="item.due + '-due'"
              class="badge mr-1 p-2"
              :class="badgeClassForDueDate(item)"
              style=""
            >
              <i class="cil-exclamation font-weight-bold" />
              {{ formattedDue(item) }}
            </span>
          </div>
        </template>
        <template #loading>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
        </template>
        <template #empty>
          <div class="d-flex flex-column justify-content-center" style="min-height: 22rem">
            <div class="text-center">
              <p><translate>No more tasks left for now!</translate></p>
            </div>
          </div>
        </template>
      </InfiniteList>
      <OverlayPanel
        ref="settingsPanel"
        show-close-icon
        class="no-arrow"
        style="{min-width: 20rem}"
      >
        <div v-if="localTaskSettings">
          <div class="mb-3">
            <translate>Which task boards should be shown?</translate>
            <div>
              <Checkbox
                  v-model="selectAllTaskBoards"
                  input-id="selectAllTaskBoards"
                  name="selectAllTaskBoards"
                  class="mb-1"
                  binary
                  :disabled="allSelected"
              />
              <label
                  for="selectAllTaskBoards"
                  class="ml-1"
              >
                <translate>All task boards</translate>
              </label>
            </div>
            <div
                v-for="taskBoard of allTaskBoards"
                :key="taskBoard.originalId"
                class="field-checkbox d-flex align-items-center"
            >
              <Checkbox
                  v-model="localTaskSettings.taskBoards"
                  :input-id="taskBoard.originalId"
                  :name="taskBoard.name"
                  class="mb-1"
                  :value="taskBoard.originalId"
              />
              <label
                  :for="taskBoard.originalId"
                  class="ml-1"
              >
                {{ taskBoard.name }}
              </label>
            </div>
          </div>
          <div class="mb-2">
            <p class="mb-0 mt-2"><translate>Which tasks should be displayed?</translate></p>
            <div>
              <Checkbox
                  v-model="localTaskSettings.showAll"
                  input-id="showAll"
                  class="mb-1"
                  :binary="true"
              />
              <label
                  for="showAll"
                  class="ml-1"
              >
                <translate>All tasks</translate>
              </label>
            </div>
            <div>
              <Checkbox
                  v-model="localTaskSettings.showIfOrganizer"
                  input-id="showIfOrganizer"
                  class="mb-1"
                  :binary="true"
              />
              <label
                  for="showIfOrganizer"
                  class="ml-1"
              >
                <translate>Tasks that I created</translate>
              </label>
            </div>
            <div>
              <Checkbox
                  v-model="localTaskSettings.showIfAssignee"
                  input-id="showIfAssignee"
                  class="mb-1"
                  :binary="true"
              />
              <label
                  for="showIfAssignee"
                  class="ml-1"
              >
                <translate>Tasks assigned to me</translate>
              </label>
            </div>
            <div>
              <Checkbox
                  v-model="localTaskSettings.showIfStakeholder"
                  input-id="showIfStakeholder"
                  class="mb-1"
                  :binary="true"
              />
              <label
                  for="showIfStakeholder"
                  class="ml-1"
              >
                <translate>Tasks where I'm a stakeholder</translate>
              </label>
            </div>
            <div>
              <Checkbox
                  v-model="localTaskSettings.showIfMember"
                  input-id="showIfMember"
                  class="mb-1"
                  :binary="true"
              />
              <label
                  for="showIfMember"
                  class="ml-1"
              >
                <translate>Tasks where I'm a member</translate>
              </label>
            </div>
          </div>
          <div class="mb-2">
            <translate :translate-params="{ days: localTaskSettings.days || 1 }">Display tasks which are due in less than %{ days } days.</translate>
            <Slider
                v-model="localTaskSettings.days"
                class="mt-3"
                style="{min-width: 15rem}"
                :min="1"
            />
          </div>
          <Button
              icon="cil-save"
              class="p-button-raised p-button-success w-100 mt-3"
              :label="i18n.$gettext('Apply')"
              @click="updateSelection"
          />
        </div>
      </OverlayPanel>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import TaskBoard from "@/model/directory/TaskBoard"
import SWR from "@/api/SWR"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import Task from "@/model/entry/Task"
import {taskServiceApi} from "@/api/TaskServiceApi"
import dayjs, {Dayjs} from "dayjs"
import Skeleton from "primevue/skeleton"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import {rpcClient} from "@/api/WebsocketClient"
import InfiniteList from "@/components/common/InfiniteList.vue"
import SettingsUtil from "@/util/SettingsUtil"
import OverlayPanel from "primevue/overlaypanel"
import {ref} from "@vue/reactivity"
import Checkbox from "primevue/checkbox"
import Slider from "primevue/slider"
import Button from "primevue/button"
import TaskSettings from "@/model/settings/dashboard/TaskSettings"
import DashboardSettings from "@/model/settings/DashboardSettings"
import Attendee from "@/model/common/caldav/Attendee"
import {Watch} from "vue-property-decorator"

@Options({
  components: { Skeleton, InfiniteList, OverlayPanel, Checkbox, Slider, Button },
  //@ts-ignore
  props: {},
  emits: []
})
export default class TaskWidget extends Vue {

  taskBoardsAreLoading: boolean = false
  tasksAreLoading: boolean = false
  i18n: Language = useGettext()

  //@ts-ignore
  settingsPanel: OverlayPanel = ref<OverlayPanel | null>(null);

  localTaskSettings: TaskSettings | null = null

  get isLoading(): boolean {
    return this.taskBoardsAreLoading || this.tasksAreLoading
  }

  get selectedTaskBoards(): string[] {
    return SettingsUtil.getDashboardSettings()?.tasks?.taskBoards || []
  }

  get daysToWatch(): number {
    return SettingsUtil.getDashboardSettings()?.tasks?.days || 1
  }

  toggleOverlayPanel(e: Event) {
    this.resetSettings()
    this.settingsPanel.toggle(e)
  }

  resetSettings(): void {
    const dashboardSettings: DashboardSettings | undefined = SettingsUtil.getDashboardSettings()
    if (dashboardSettings) {
      if (!dashboardSettings.tasks) {
        dashboardSettings.tasks = new TaskSettings()
        dashboardSettings.tasks.days = 30
        dashboardSettings.tasks.showAll = true
        dashboardSettings.tasks.showIfOrganizer = true
        dashboardSettings.tasks.showIfMember = true
        dashboardSettings.tasks.showIfAssignee = true
        dashboardSettings.tasks.showIfStakeholder = true
        dashboardSettings.tasks.taskBoards = []
      }
      if (!dashboardSettings.tasks.taskBoards?.length) {
        this.selectAllTaskBoards = true
      }
      this.localTaskSettings = JSON.parse(JSON.stringify(dashboardSettings.tasks)) //make a non-reactive copy
    }
  }

  get selectAllTaskBoards(): boolean {
    if (this.localTaskSettings) {
      return Boolean(!this.localTaskSettings.taskBoards?.length || this.allSelected)
    } else {
      return false
    }
  }

  set selectAllTaskBoards(all: boolean) {
    if (all && this.localTaskSettings) {
      this.localTaskSettings.taskBoards = this.allTaskBoards.map(b => b.originalId || '')
    }
  }

  get allSelected(): boolean {
    return this.allTaskBoards.every(b => this.localTaskSettings?.taskBoards?.includes(b.originalId || ''))
  }

  @Watch('localTaskSettings.showAll')
  watchShowAll() {
    if (this.localTaskSettings?.showAll) {
      this.localTaskSettings.showIfOrganizer = true
      this.localTaskSettings.showIfMember = true
      this.localTaskSettings.showIfAssignee = true
      this.localTaskSettings.showIfStakeholder = true
    }
  }

  @Watch('localTaskSettings.showIfOrganizer')
  watchShowIfOrganizer() {
    if (this.localTaskSettings && !this.localTaskSettings?.showIfOrganizer) {
      this.localTaskSettings.showAll = false
    }
  }

  @Watch('localTaskSettings.showIfMember')
  watchShowIfMember() {
    if (this.localTaskSettings && !this.localTaskSettings?.showIfMember) {
      this.localTaskSettings.showAll = false
    }
  }

  @Watch('localTaskSettings.showIfAssignee')
  watchShowIfAssignee() {
    if (this.localTaskSettings && !this.localTaskSettings?.showIfAssignee) {
      this.localTaskSettings.showAll = false
    }
  }

  @Watch('localTaskSettings.showIfStakeholder')
  watchShowIfStakeholder() {
    if (this.localTaskSettings && !this.localTaskSettings?.showIfStakeholder) {
      this.localTaskSettings.showAll = false
    }
  }

  updateSelection(): void {
    if (this.localTaskSettings) {
      SettingsUtil.setDashboardTaskSettings(this.localTaskSettings)
    }
    this.settingsPanel.hide()
  }

  goToItem(item: Task): void {
    if (item.originalParentId && item.originalId) {
      void this.$router.push('/tasks/' + encodeURIComponent(item.originalParentId) + '/' + encodeURIComponent(item.originalId))
    }
  }

  elementClass(t: Task): string[] {
    let result = []
    if (t.due) {
      const due = dayjs(t.due)
      const time = dayjs()
      if (due.isBefore(time)) {
        result.push('over-due')
      } else {
        result.push('due-today')
      }
    }
    return result
  }

  get allTaskBoards(): TaskBoard[] {
    const swr: SWR<TaskBoard[], string[]> = taskBoardServiceApi.getTaskBoards(false)
    if (swr.call?.loading && swr.call?.promise) {
      this.taskBoardsAreLoading = true
      swr.call.promise.finally(() => {
        this.taskBoardsAreLoading = false
      })
    }
    return swr.data || []
  }

  get taskBoards(): TaskBoard[] {
    if (this.selectedTaskBoards.length <= 0) {
      return this.allTaskBoards
    }
    let selected: TaskBoard[] = []
    this.selectedTaskBoards.forEach((originalId) => {
      const taskBoard: TaskBoard | undefined = this.allTaskBoards.find((tb) => tb.originalId && tb.originalId == originalId)
      if (taskBoard !== undefined) selected.push(taskBoard)
    })
    if (selected.length <= 0 && this.localTaskSettings) {
      this.localTaskSettings.taskBoards = [] //empty list means all
      SettingsUtil.setDashboardTaskSettings(this.localTaskSettings)
      return this.allTaskBoards
    }
    return selected
  }

  isAttendeeOrOrganizer(t: Task): boolean {
    return t.organizer?.email === this.userEmail || Boolean(t.attendees?.find(attendee =>
      attendee.email && (attendee.email.toLowerCase() === this.userId || attendee.email.toLowerCase() === this.userEmail)
    ))
  }

  get userEmail(): string | null {
    return rpcClient.session?.user?.email?.toLowerCase() || null
  }

  get userId(): string | null {
    return rpcClient.session?.user?.uid?.toLowerCase() || null
  }

  get tasks(): Task[] {
    let promises: Promise<string[]>[] = []
    let myTasks: Task[] = []

    this.taskBoards.forEach((board: TaskBoard) => {
      if (!board.originalId) return
      const swr = taskServiceApi.getTasks(board.originalId, false)
      if (swr.call?.loading && swr.call?.promise) {
        this.tasksAreLoading  = true
        promises.push(swr.call.promise)
      }
      const tasks: Task[] = swr.data ? [...swr.data] : []
      const taskSettings: TaskSettings | null | undefined = SettingsUtil.getDashboardSettings()?.tasks
      if (taskSettings) {
        tasks.forEach((t: Task) => {
          if (taskSettings.showAll) {
            myTasks.push(t)
          } else if (taskSettings.showIfOrganizer && t.organizer?.email === this.userEmail) {
            myTasks.push(t)
          } else if (taskSettings.showIfMember || taskSettings.showIfStakeholder || taskSettings.showIfAssignee) {
            const attendee: Attendee | undefined = t.attendees?.find(a => a.email === this.userEmail && ![ 'DECLINED', 'DELEGATED' ].includes(a.status || ''))
            if (attendee) {
              if (taskSettings.showIfMember && attendee.participationLevel == 'OPTIONAL') {
                myTasks.push(t)
              }
              if (taskSettings.showIfAssignee && attendee.participationLevel == 'REQUIRED') {
                myTasks.push(t)
              }
              if (taskSettings.showIfStakeholder && attendee.participationLevel == 'FYI') {
                myTasks.push(t)
              }
            }
          }
        })
      }
    })

    if (promises.length > 0) {
      Promise.all(promises).finally(() => {
        this.tasksAreLoading = false
      })
    }

    return myTasks
  }

  get overDueTasks(): Task[] {
    const overdue: Task[] = []
    this.tasks.forEach((t: Task) => {
      if ((t.percentCompleted || 0) < 100 && t.due) {
        const dueDate = dayjs(t.due)
        if (dueDate.isBefore(this.end)) {
          overdue.push(t)
        }
      }
    })

    SortAndFilterUtil.sort(overdue, 'due:asc')
    return overdue
  }

  get overDueCount(): number {
    return this.overDueTasks.length
  }

  get end(): Dayjs {
    let end = dayjs().endOf('day')
    if (this.daysToWatch > 1) {
      end = end.add(this.daysToWatch - 1, 'day')
    }
    return end
  }

  formattedDue(t: Task): string {
    if (t.due) {
      const date = dayjs(t.due)
      const today = dayjs().startOf('day')
      if (date.isBefore(today)) {
        return this.i18n.$gettext("Overdue since") + ": " + date.format("DD.MM.YYYY - HH:mm")
      } else if (date.isSame(today, 'day')) {
        return this.i18n.$gettext("Due Today") + ": " + date.format("HH:mm")
      } else {
        return this.i18n.$gettext("Upcoming") + ": " + date.format("DD.MM.YYYY - HH:mm")
      }
    } else {
      return this.i18n.$gettext("Unknown")
    }
  }

  badgeClassForDueDate(t: Task): string[] {
    if (t.due) {
      const due: number = new Date(t.due).getTime()
      const now: number = new Date().getTime()
      if (due <= now && t.percentCompleted !== 100) {
        return [ 'badge-danger', 'text-white' ]
      } else if (due < now + 86400000 && t.percentCompleted !== 100) {
        return [ 'badge-warning', 'text-dark' ]
      } else {
        return [ 'badge-success', 'text-white' ]
      }
    } else {
      return []
    }
  }
}
</script>

<style lang="scss" scoped>


@import "node_modules/elly-bs4/sass/variables";

.over-due {
  background-color: lightsalmon;
}
.due-today {
  background-color: lightyellow;
}

.strong {
  font-weight: 700;
}

</style>
