<template>
  <div class="flex-shrink-1 result-list p-2" style="min-height: 0; overflow: auto;">
    <div class="home container-lg p-0 ml-0">
      <SettingsListItem v-for="setting in settings" :key="setting.name" :setting="setting" />
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import SettingsListItem from "@/components/settings/SettingsListItem.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {rpcClient} from '@/api/WebsocketClient'
import adminpanelHolder from "@/util/AdminpanelHolder"

@Options({
  components: {
    SettingsListItem
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class UserSettingsList extends Vue {

  i18n: Language = useGettext()

  get settings() {
    const settings: any[] = [
      {
        name: this.i18n.$gettext('Mail Signatures'),
        icon: 'fa fa-file-signature',
        linkTo: '/settings/email-signature'
      },
      {
        name: this.i18n.$gettext('Out-Of-Office'),
        icon: 'fa fa-umbrella-beach',
        linkTo: '/settings/email-away'
      },
      {
        name: this.i18n.$gettext('Mail Rules'),
        icon: 'fa fa-directions',
        linkTo: '/settings/email-rules'
      }
    ]

    if (!this.isFetchmailDisabled) {
      settings.push({
        name: this.i18n.$gettext('Mail Server'),
        icon: 'fa fa-envelope',
        linkTo: '/settings/email'
      })
    }

    if (this.isKopanoInstalledAndRunning) {
      settings.push({
        name: this.i18n.$gettext('PST Import'),
        icon: 'fa fa-file-upload',
        linkTo: '/settings/pstupload'
      })
    }

    if (!this.isAdmin) {
      settings.push({
        name: this.i18n.$gettext('Share Links'),
        icon: 'cil cil-external-link',
        linkTo: '/settings/sharelinks'
      })
    }

    return settings
  }

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  get isFetchmailDisabled() {
    let disabledFeatures: string[] | null = rpcClient.status?.disabledFeatures
    if (disabledFeatures) {
      return disabledFeatures.indexOf("fetchmail") > -1
    } else {
      return false
    }
  }

  get isKopanoInstalledAndRunning() {
    if(adminpanelHolder && adminpanelHolder.instance){
      return adminpanelHolder.instance.store.getters.kopanoIsInstalled
    } else {
      return false //Hide feature in groupware by default
    }
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";



</style>
