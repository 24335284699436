import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import EntryLink from '@/model/EntryLink';


export default class GeneratedEntryLinkStore {

    state = reactive({
        entryLinks: new Map<number, EntryLink>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.entryLinks) this.state.entryLinks.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceEntryLink(newEntryLink: EntryLink) {
        if (newEntryLink.id) {
            this.state.entryLinks.set(newEntryLink.id, newEntryLink)
            
        }
    }

    addOrReplaceEntryLinks(newEntryLinks: EntryLink[]) {
        for (const newEntryLink of newEntryLinks) {
            this.addOrReplaceEntryLink(newEntryLink)
        }
    }

    replaceEntryLinks(entryLinks: EntryLink[]) {
        const newEntryLinks = new Map<number, EntryLink>()
        for (const newEntryLink of entryLinks) {
            if (newEntryLink.id) {
                newEntryLinks.set(newEntryLink.id, newEntryLink)
                
            }
        }
        this.state.entryLinks = newEntryLinks
    }

    removeEntryLink(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.entryLinks.delete(id)
        }
    }

    removeEntryLinks(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeEntryLink(id)
            }
        }
    }
}