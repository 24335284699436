import axios from "axios"
import {reactive} from "@vue/reactivity"
import {fileServiceApi} from "@/api/FileServiceApi"

const FILE_MAX_TRIES = 3

export class NestedFile {
    path: string
    file: File

    constructor(path: string, file: File) {
        this.path = path
        this.file = file
    }

    static fromFile(file: File) {
        return new NestedFile("/", file)
    }
}

class UploadUtil {

    api = fileServiceApi

    uploadProgress: { name: string | null, percent: number }[] = reactive([])

    uploadFiles(files: NestedFile[], currentPath: string | null): Promise<any> {
        const promises: Promise<void>[] = []
        const progresses: { name: string | null, percent: number }[] = []
        for(let i = 0; i < files.length; i++) {
            const file: NestedFile = files[i]
            if (this.isFileAllowedByFilename(file.file.name)) {
                const result: { name: string | null, percent: number } = reactive({ name: null, percent: 0 })
                progresses.push(result)
                promises.push(this.uploadSingleFile(file.file, file.path, currentPath, result))
            } else {
                console.warn("File failed the test")
            }
        }
        progresses.forEach(p => this.uploadProgress.push(p))
        return Promise.all(promises).finally(() => {
            progresses.forEach(p1 => {
                const index: number = this.uploadProgress.findIndex(p2 => p1.name === p2.name)
                if (index >= 0) {
                    this.uploadProgress.splice(index, 1)
                }
            })
        })
    }

    uploadSingleFile(file: File, path: string, currentPath: string | null, result: { name: string | null, percent: number }, counter: number = 0) : Promise<void> {
        return new Promise((resolve, reject) => {
            this._uploadSingleFile(file, path, currentPath, result).then(() => {
                resolve()
            }).catch(e1 => {
                if (counter < FILE_MAX_TRIES) {
                    setTimeout(() => {
                        this.uploadSingleFile(file, path, currentPath, result, counter + 1).then(() => {
                            resolve()
                        }).catch(e2 => {
                            reject(e2)
                        })
                    }, 10000) //Wait 10 Seconds until you try again
                } else {
                    reject(e1)
                }
            })
        })
    }

    _uploadSingleFile(file: File, path: string, currentPath: string | null, result: { name: string | null, percent: number }): Promise<void> {
        const pathForFileToUpload: string = currentPath || "/"
        const fileName = file.name
        result.name = (path && path !== "" && path !== "/") ? path + "/" + fileName : fileName
        return this.api._getUploadLink(pathForFileToUpload).then((uploadLink: string) => {
            let pathParts: string[] = currentPath?.split("/") || []
            pathParts = pathParts.filter((elem: string) => { return elem !== "" })
            pathParts.shift()
            const pathInBackend: string = "/" + pathParts.join("/")

            //Create Request:
            const data: FormData = new FormData()
            data.append('file', file)
            data.append('parent_dir', pathInBackend)
            if (path && path !== "" && path !== "/") {
                data.append('relative_path', path)
            }
            data.append('replace', "1")

            return axios.request({
                method: "post",
                url: uploadLink,
                headers: {"Content-Type": "multipart/form-data"},
                data: data,
                onUploadProgress: (p: ProgressEvent) => {
                    result.percent = Math.round((p.loaded / p.total) * 100)
                }
            })
        })
    }

    isFileAllowedByFilename(filename: string): boolean {
        const prohibited: string[] = [".DS_Store", "desktop.ini"]
        return (prohibited.indexOf(filename) === -1)
    }
}

const uploadUtil: UploadUtil = new UploadUtil()
export default uploadUtil