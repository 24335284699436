import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import QueryResult from '@/model/QueryResult';
import Page from '@/model/Page';
import { queryResultStore } from '@/store/QueryResultStore';

export default class GeneratedQueryResultServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _query(queryId: string, page: number, perPage: number, sortBy: string): Promise<Page<QueryResult>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('query', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let queryResults: QueryResult[] = data.items.map((queryResult: any) => Object.assign(new QueryResult(), queryResult))
                queryResultStore.addOrReplaceQueryResults(queryResults)
                return Object.assign(new Page<QueryResult>(), data)
            } else return Promise.reject()
        })
    }

    query(queryId: string, page: number, perPage: number, sortBy: string, refresh?: boolean | number): SWR<QueryResult[], Page<string>> {
        //@ts-ignore
        const result: SWR<QueryResult[], Page<string>> = reactive(new SWR<QueryResult[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_query' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_query[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const queryResults: QueryResult[] = []
                for (const id of data.items || []) {
                    const queryResult: QueryResult | undefined = queryResultStore.state.queryResults.get(id)
                    if (queryResult) {
                        queryResults.push(queryResult)
                    }
                }
                result.data = queryResults
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._query(queryId, page, perPage, sortBy).then((data: Page<QueryResult>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(queryResult => !!queryResult.id)?.map(queryResult => queryResult.id) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let queryResults: QueryResult[] = [...queryResultStore.state.queryResults.values()]
        if (queryId) queryResults = SortAndFilterUtil.filter(queryResults, { queryId: queryId })
        SortAndFilterUtil.sort(queryResults, sortBy)
        if (perPage !== null && perPage !== undefined) {
            queryResults = queryResults.slice((page || 0) * perPage, ((page || 0) + 1) * perPage)
        }
        result.data = queryResults
        return result
    }

    getQueryResults(pageIndex?: number, pageSize?: number): QueryResult[] {
        let queryResults: QueryResult[] = [...queryResultStore.state.queryResults.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            queryResults = queryResults.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return queryResults
    }

    getQueryResult(id: string): QueryResult | undefined {
        return queryResultStore.state.queryResults.get(id)
    }
}
