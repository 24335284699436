
class FeatureDetect {

    public readonly supportsNativeDownloadAttribute: boolean = false

    constructor() {
        //Check for Browser Support for downloadAttribute for links:
        const a = document.createElement('a')
        this.supportsNativeDownloadAttribute = (typeof a.download != "undefined")
    }
}

const featureDetect: FeatureDetect = new FeatureDetect()

export default featureDetect
