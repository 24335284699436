import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Channel from '@/model/directory/Channel';


export default class GeneratedChannelStore {

    state: {
        channels: Map<string, Channel>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let channels: Map<string, Channel> | null = null
        try {
            const persistedState = localStorage.getItem('ChannelStore')
            if (persistedState) {
                channels = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            channels: channels || new Map<string, Channel>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('ChannelStore', JSON.stringify(Array.from(this.state.channels)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.channels) this.state.channels.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('ChannelStore')
    }

    addOrReplaceChannel(newChannel: Channel, persist: boolean = true): true | undefined {
        if (newChannel.id) {
            this.state.channels.set(newChannel.id, newChannel)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceChannels(newChannels: Channel[]) {
        let persist = false
        for (const newChannel of newChannels) {
            const changed = !!this.addOrReplaceChannel(newChannel, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceChannels(channels: Channel[]) {
        const newChannels = new Map<string, Channel>()
        for (const newChannel of channels) {
            if (newChannel.id) {
                newChannels.set(newChannel.id, newChannel)
                
            }
        }
        this.state.channels = newChannels
        this.persistState()
    }

    removeChannel(id: string | undefined | null, persist: boolean = true): true | undefined {
        if (id !== undefined && id !== null) {
            if (this.state.channels.delete(id)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeChannels(ids: (string | null)[] | undefined | null) {
        let persist = false
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                const changed = !!this.removeChannel(id, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}