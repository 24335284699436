<template>
  <Dialog
    v-model:visible="show"
    :modal="true"
    :header="i18n.$gettext('Previous versions of this file')"
    :draggable="false"
    :breakpoints="{'960px': '80vw', '640px': '100vw'}"
    :style="{minWidth: '60vw'}"
    :content-style="{minHeight: '25rem'}"
    @hide="hide"
  >
    <div v-if="isLoading" class="row">
      <div class="col">
        <ProgressBar mode="indeterminate" />
      </div>
    </div>
    <div v-if="requestFailed" class="row">
      <div class="col">
        <p><translate>Fetching History Information failed, please try again</translate></p>
        <LoadingButton add-classes="btn-outline-primary" :action="loadData">
          <translate>Try again</translate>
        </LoadingButton>
      </div>
    </div>
    <div v-else class="h-100">
      <span class="p-input-icon-left w-100 ml-3 mt-2">
        <i class="cil-search" style="z-index: 10;" />
        <InputText
          v-model="historySearch"
          type="text"
          input-class="w-100 input-padding-left-for-icon"
        />
      </span>
      <InfiniteList
        v-if="hasHistory"
        :key="inode.path"
        :get-all-items="historyData"
        :id-property="'historyId'"
        show-scroll-to-start-button
        auto-scroll-at-start
        class="mt-4 p-timeline p-component p-timeline-left p-timeline-vertical"
      >
        <template #element="slotProps">
          <div class="fm-history-item p-timeline-event d-flex flex-row">
            <div class="p-timeline-event-content mr-4" style="width: 5rem">
              <Button
                v-if="slotProps.item !== historyData[0]"
                icon="cil-data-transfer-down"
                class="p-button-raised w-100"
                @click="downloadHistoricFile(slotProps.item)"
              />
              <Button
                v-if="slotProps.item !== historyData[0]"
                icon="cil-restore"
                class="p-button-raised mt-2 w-100"
                @click="revertFileToPointInHistory(slotProps.item)"
              />
            </div>
            <div class="p-timeline-event-separator d-flex flex-column align-items-center">
              <div class="p-timeline-event-marker flex-shrink-0" />
              <div class="p-timeline-event-connector h-100" />
            </div>
            <div class="p-timeline-event-content pl-4 pb-4 flex-grow-1">
              <p class="mb-3">
                {{ slotProps.item.description }}
              </p>
              <div class="d-flex flex-row flex-wrap align-items-center">
                <Avatar
                  v-if="slotProps.item.creatorEmail"
                  :key="slotProps.item.creatorEmail"
                  :username="slotProps.item.creatorEmail"
                  class="mr-2"
                  shape="circle"
                  generate-initials
                />
                <i v-else class="far fa-user mr-1" />
                {{ slotProps.item.creatorName }}
                <span>
                  <i class="far fa-clock ml-3 mr-1" />
                  {{ formatTimestamp(slotProps.item.ctime) }}
                </span>
                <span>
                  <i class="fas fa-database ml-3 mr-1" />
                  {{ slotProps.item.sizeHumanReadable }}
                </span>
                <p v-if="slotProps.item === historyData[0]" class="ml-3 mb-0 font-weight-bold text-muted">
                  <translate>Current version</translate>
                </p>
              </div>
            </div>
          </div>
        </template>
        <template #loading>
          <div class="w-100">
            <ProgressBar mode="indeterminate" />
          </div>
        </template>
      </InfiniteList>
      <div v-if="!hasHistory && !isLoading" class="d-flex flex-column justify-content-center p-4">
        <div class="text-center">
          <p class="h5 mb-2">
            <translate>No History available</translate>
          </p>
          <p><translate>If you overwrite the file, new entries will be added here.</translate></p>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ProgressBar from "primevue/progressbar"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {fileServiceApi} from "@/api/FileServiceApi"
import RpcError from "@/api/RpcError"
import {Watch} from "vue-property-decorator"
import INodeHistory from "@/model/common/INodeHistory"
import RpcCallStatus from "@/api/RpcCallStatus"
import FileHistoryData from "@/model/common/FileHistoryData"
import dayjs from "@/util/dayjs"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Button from "primevue/button"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Avatar from "@/components/common/Avatar.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import InputText from "primevue/inputtext"

@Options({
  components: {
    LoadingButton, Dialog, TabView, TabPanel, Avatar, ProgressBar, Button, InfiniteList, InputText
  },
  //@ts-ignore
  props: {
    inode: [ INode, Object ],
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide']
})
export default class HistoryModal extends Vue {

  api = fileServiceApi
  toast = useToast()
  confirm = useConfirm()
  i18n: Language = useGettext()

  inode!: INode
  readOnly!: boolean

  show = false
  rpcStatus: RpcCallStatus = RpcCallStatus.UNINITIALIZED
  fileHistory: INodeHistory | null = null
  historySearch: string = ''

  historicDownloadLoading = false
  historicReverseLoading = false

  @Watch('show')
  loadDataOnShow(): Promise<any> {
    return this.show ? this.loadData() : Promise.reject()
  }

  loadData(): Promise<any> {
    if (this.inode.path && !this.fileHistory) {
      this.rpcStatus = RpcCallStatus.LOADING
      const sharePromise = this.api._listINodeHistory(this.inode.path).then((history: INodeHistory) => {
        this.fileHistory = history
        this.rpcStatus = RpcCallStatus.SUCCESS
      }).catch((error: RpcError) => {
        this.toast.error(error.message)
        this.rpcStatus = RpcCallStatus.ERROR
      })
      return Promise.all([sharePromise])
    } else {
      return Promise.reject()
    }
  }

  hide(): void {
    this.fileHistory = null
    this.$emit('hide')
  }

  get hasHistory(): boolean {
    return this.historyData.length > 0
  }

  get isLoading(): boolean {
    return this.rpcStatus === RpcCallStatus.LOADING
  }

  get requestFailed(): boolean {
    return this.rpcStatus === RpcCallStatus.ERROR
  }

  get requestSuccess(): boolean {
    return this.rpcStatus === RpcCallStatus.SUCCESS
  }

  toggle(): void {
    this.show = !this.show
  }

  get historyData(): FileHistoryData[] {
    let data = this.fileHistory?.fileData || []
    if (this.historySearch && this.historySearch !== '') {
      const lowerSearch = this.historySearch.toLowerCase()
      data = data.filter(d => {
        return d.description?.toLowerCase()?.includes(lowerSearch) ||
          d.creatorEmail?.toLowerCase()?.includes(lowerSearch) ||
          d.creatorName?.toLowerCase()?.includes(lowerSearch) ||
          this.formatTimestamp(d.ctime).toLowerCase()?.includes(lowerSearch)
      })
    }
    return data
  }

  formatTimestamp(isoString: string | null) {
    return isoString ? dayjs(isoString).format("HH:mm DD.MM.YYYY") : ''
  }

  revertFileToPointInHistory(hist: FileHistoryData) {
    this.confirm.require({
      message: this.i18n.$gettext("Are you sure you want to revert this file to" ) + ' ' + this.formatTimestamp(hist.ctime) + '?',
      header: this.i18n.$gettext("Revert file"),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.i18n.$gettext("Yes"),
      rejectLabel: this.i18n.$gettext("No"),
      accept: () => {
        return this._revertFileToPointInHistory(hist)
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  _revertFileToPointInHistory(hist: FileHistoryData): Promise<void> {
    if (this.inode.path && hist.historyId) {
      this.historicReverseLoading = true
      return this.api._restoreFile(this.libraryPath + '/' + hist.path, hist.historyId).then(() => {
        this.toast.success(this.i18n.$gettext("File reverted"))
        this.loadData().catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Failed to load files"))
        })
      }).catch((error: RpcError) => {
        this.toast.error(error.message, this.i18n.$gettext("File could not be reverted"))
      }).finally(() => { this.historicReverseLoading = false })
    } else {
      return Promise.reject()
    }
  }

  downloadHistoricFile(hist: FileHistoryData) {
    if (hist.path && hist.historyId) {
      this.historicDownloadLoading = true
      const historicPath = (this.libraryPath || '') + hist.path
      return this.api._getDownloadLinkFromHistory(historicPath, hist.historyId).then((uri: string) => {
        let link = document.createElement("a")
        link.setAttribute('download', '')
        link.href = uri
        document.body.appendChild(link)
        link.click()
        link.remove()
      }).catch((error: RpcError) => {
        this.toast.error(this.i18n.$gettext("Could not access file in history") + ":" + error.message)
      }).finally(() => { this.historicDownloadLoading = false })
    } else {
      return Promise.reject()
    }
  }

  get libraryPath(): string | null {
    const pathParts: string[] = this.inode.parentPath?.split("/").filter(elem => elem !== "") || []
    if (!pathParts || pathParts.length === 0) return null
    return "/" + pathParts[0]
  }

}
</script>

<style scoped lang="scss">

.p-timeline-event-separator {
  position: relative;
}

.p-timeline .p-timeline-event-marker {
  width: 1rem;
  left: -0.45rem;
  top: 0.25rem;
  height: 1rem;
  position: absolute;
}

.p-timeline .p-timeline-event:first-child .p-timeline-event-connector {
  margin-top: 0.5rem;
}

</style>
