<template>
  <Dialog
    v-model:visible="show"
    :modal="true"
    :header="i18n.$gettext('Forward this message')"
    :draggable="false"
    :breakpoints="{'960px': '80vw', '640px': '100vw'}"
    :style="{minWidth: '60vw'}"
    :content-style="{}"
    @hide="$emit('hide')"
  >
    <div>
      <div v-if="!channelsAreLoading">
        <p class="text-muted">
          <translate>Select a channel to forward the message into.</translate>
        </p>
        <Listbox
          v-model="channelToForwardMessageInto"
          :options="allChannelsOptions"
          option-label="label"
          option-value="channel"
        />
        <div class="d-flex w-100 mt-2 justify-content-end">
          <!-- TODO: Use loading Button -->
          <LoadingButton :variant="success" :action="forwardMessage">
            <translate>Forward message</translate>
          </LoadingButton>
        </div>
      </div>
      <ProgressBar v-else mode="indeterminate" />
    </div>
  </Dialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import Message from "@/model/entry/Message"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {messageServiceApi} from "@/api/MessageServiceApi"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import Channel from "@/model/directory/Channel"
import Button from "primevue/button"
import Listbox from "primevue/listbox"
import ProgressBar from "primevue/progressbar"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import ChatUtil from "@/util/ChatUtil"
import LoadingButton from "@/components/common/LoadingButton.vue"
import useToast from "@/util/toasts"
import RpcError from "@/api/RpcError"
import SWR from "@/api/SWR"

@Options({
  components: {
    LoadingButton,
    Dialog, Button, Listbox, ProgressBar
  },
  //@ts-ignore
  props: {
    message: [ Message, Object ],

  },
  emits: ['hide']
})
export default class ForwardModal extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  messageApi = messageServiceApi
  channelApi = channelServiceApi

  message!: Message
  //@ts-ignore
  channelToForwardMessageInto: Channel | null = null

  show = false

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  get projects() {
    return projectServiceApi.getProjects().data || []
  }

  teamChannelSWR!: SWR<Channel[], string[]>
  get channels(): Channel[] {
    this.teamChannelSWR = this.channelApi.getChannelsForTeam(this.currentProjectId, false)
    return (this.teamChannelSWR.data || []).filter(c => !c.isDirect)
  }

  directChannelSWR!: SWR<Channel[], string[]>
  get privateChannels(): Channel[] {
    this.directChannelSWR = this.channelApi.getChannelsForTeam(null, false)
    return (this.directChannelSWR.data || []).filter(c => c.isDirect)
  }

  get allChannelsOptions(): { label: string, channel: Channel }[] {
    const result: {label: string, channel: Channel}[] = []
    this.channels.forEach((ch: Channel) => {
      const displayName: string = ChatUtil.getChannelDisplayName(ch) || ""
      result.push({ label: displayName, channel: ch})
    })
    this.privateChannels.forEach((ch: Channel) => {
      const displayName: string = ChatUtil.getChannelDisplayName(ch) || ""
      result.push({ label: displayName, channel: ch})
    })
    return result
  }

  get channelsAreLoading(): boolean {
    return Boolean(this.teamChannelSWR?.call?.loading || this.directChannelSWR?.call?.loading)
  }

  forwardMessage(): Promise<void> {
    //TODO: use generated forward message function
    return new Promise((resolve, reject) => {
      if (!this.message || !this.channelToForwardMessageInto?.id) {
        this.toast.error(this.i18n.$gettext("Could not determine channel"))
        reject()
        return
      }

      messageServiceApi._forwardMessage(this.message, [this.channelToForwardMessageInto?.id]).then(() => {
        this.toast.success(this.i18n.$gettext("Message forwarded"))
        this.show = false
        this.channelToForwardMessageInto = null
        resolve()
      }).catch((err: RpcError) => {
        this.toast.error(this.i18n.$gettext("Message could not be forwarded"), err.message)
        reject()
      })
    })
  }

  toggle(): void {
    this.show = !this.show
  }
}
</script>

<style scoped>

</style>
