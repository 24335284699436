import Duration from "@/model/common/caldav/Duration"
import CalendarEvent from "@/model/entry/Event"
import dayjs from "@/util/dayjs"
import RecurrenceRule from "@/model/common/caldav/RecurrenceRule"
import { RRule, Frequency, Weekday} from "rrule"
import {Dayjs} from "dayjs"

export default class DateTimeUtil {

    static getDuration(millis: number): Duration {
        const duration = new Duration()
        millis = Math.floor(millis / 1000)
        duration.weeks = Math.floor(millis / 604800)
        millis -= duration.weeks * 604800
        duration.days = Math.floor(millis / 86400)
        millis -= duration.days * 86400
        duration.hours = Math.floor(millis / 3600)
        millis -= duration.hours * 3600
        duration.minutes = Math.floor(millis / 60)
        duration.seconds = millis - duration.minutes * 60
        return duration
    }

    //Calculates the duration in milliseconds
    static getDurationInMillis(event: CalendarEvent): number {
        if (event.duration) {
            return this.getMillisFromDuration(event.duration)
        } else if (event.allDay) {
            return 86400000
        } else if (event.end && event.start) {
            return (Date.parse(event.end) - Date.parse(event.start))
        } else {
            return 0
        }
    }

    static getMillisFromDuration(duration: Duration) {
        return (duration.prior ? -1 : 1) * (
            (duration.weeks || 0) * 604800 +
            (duration.days || 0) * 86400 +
            (duration.hours || 0) * 3600 +
            (duration.minutes || 0) * 60 +
            (duration.seconds || 0)) * 1000
    }

    static isSameDay(d1: Date | null, d2: Date | null): boolean {
        return Boolean(d1 && d2 && d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate())
    }

    static isSameMinute(d1: Date | null, d2: Date | null): boolean {
        return Boolean(d1 && d2 && this.isSameDay(d1, d2) && d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes())
    }

    static getExceptionDates(event: CalendarEvent, events: CalendarEvent[]): any[] {
        const exceptionDates: any[] = [...(event.exceptionDates || [])]
        if (event.start) try {
            exceptionDates.push(...events
                .filter(other => other.uid === event.uid && other.originalId !== event.originalId && other.recurrenceId && !other.recurrenceId.range)
                .map(other => {
                    //Must match the exact time of the original event
                    let exceptionDate: Dayjs = dayjs(other.recurrenceId?.start || undefined)
                    const eventStart: Dayjs = dayjs(event.start || undefined)
                    exceptionDate = exceptionDate.set('second', eventStart.second())
                    exceptionDate = exceptionDate.set('minute', eventStart.minute())
                    exceptionDate = exceptionDate.set('hour', eventStart.hour())
                    return exceptionDate.toISOString()
                }))
        } catch (ignore) {}
        return exceptionDates.map((iso: string) => { //Shift into UTC
            const date: Date = new Date(iso)
            return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
        })
    }

    static getExceptionTimes(event: CalendarEvent, events: CalendarEvent[]): any[] {
        const exceptionTimes: number[] = event.exceptionDates?.map(s => Date.parse(s)) || []
        if (event.start) try {
            exceptionTimes.push(...events
                .filter(other => other.uid === event.uid && other.originalId !== event.originalId && other.recurrenceId && !other.recurrenceId.range)
                .map(other => {
                    //Must match the exact time of the original event
                    let exceptionDate: Dayjs = dayjs(other.recurrenceId?.start || undefined)
                    const eventStart: Dayjs = dayjs(event.start || undefined)
                    exceptionDate = exceptionDate.set('second', eventStart.second())
                    exceptionDate = exceptionDate.set('minute', eventStart.minute())
                    exceptionDate = exceptionDate.set('hour', eventStart.hour())
                    return exceptionDate.valueOf()
                }))
        } catch (ignore) {}
        return exceptionTimes
    }

    static getExceptionRules(event: CalendarEvent, events: CalendarEvent[]): any[] {
        const exceptionRules: any[] = []
        for (const exceptionRule of (event.exceptionRules || [])) {
            exceptionRules.push(this.getRrule(event, exceptionRule))
        }
        if (event.recurrenceRule) {
            for (const otherEvent of events) {
                if (otherEvent.uid === event.uid && otherEvent.originalId !== event.originalId && otherEvent.recurrenceId && otherEvent.recurrenceId.range && otherEvent.recurrenceId.start) {
                    const eventRule: any = {...this.getRrule(event, event.recurrenceRule)}
                    if (eventRule && otherEvent.recurrenceId.range === 'THIS_AND_FUTURE') {
                        eventRule.dtstart = otherEvent.recurrenceId.start
                        exceptionRules.push(eventRule)
                    } else if (eventRule && otherEvent.recurrenceId.range === 'THIS_AND_PRIOR') {
                        eventRule.dtstart = event.start
                        eventRule.until = otherEvent.recurrenceId.start
                        exceptionRules.push(eventRule)
                    }
                }
            }
        }
        return exceptionRules
    }

    static getRrule(event: CalendarEvent, recurrenceRule: RecurrenceRule): any {
        if(!event.start) return null
        const startDate: Date = new Date(Date.parse(event.start))
        const freq: Frequency | undefined = this.rruleFreqFrom(recurrenceRule.frequency)
        if(freq === undefined || freq === null) return null
        return {
            freq,
            dtstart: new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60 * 1000)), //Determines the time when rrule is set, start and end are ignored in that case
            interval: recurrenceRule.interval || 1,
            until: recurrenceRule.until,
            wkst: recurrenceRule.workweekStart,
            count: recurrenceRule.count,
            bysetpos: this.intArrayOrUndefined(recurrenceRule.bySetPosition),
            bymonth: this.intArrayOrUndefined(recurrenceRule.byMonth),
            bymonthday: this.intArrayOrUndefined(recurrenceRule.byMonthDay),
            byyearday: this.intArrayOrUndefined(recurrenceRule.byYearDay),
            byweekno: this.intArrayOrUndefined(recurrenceRule.byWeekNumber),
            byweekday: this.rruleByDayFrom(recurrenceRule.byDay),
            byhour: this.intArrayOrUndefined(recurrenceRule.byHour),
            byminute: this.intArrayOrUndefined(recurrenceRule.byMinute),
            bysecond: this.intArrayOrUndefined(recurrenceRule.bySecond)
        }
    }

    static getRRule(event: CalendarEvent, recurrenceRule: RecurrenceRule): RRule | null {
        try {
            const rruleWithStrings = this.getRrule(event, recurrenceRule)
            if(!rruleWithStrings) return null

            return new RRule({
                freq: rruleWithStrings.freq,
                dtstart: rruleWithStrings.dtstart ? new Date(rruleWithStrings.dtstart) : undefined,
                interval: rruleWithStrings.interval || 1,
                until: rruleWithStrings.until ? new Date(rruleWithStrings.until) : undefined,
                wkst: rruleWithStrings.workweekStart,
                count: rruleWithStrings.count,
                bysetpos: this.intArrayOrUndefined(recurrenceRule.bySetPosition),
                bymonth: this.intArrayOrUndefined(recurrenceRule.byMonth),
                bymonthday: this.intArrayOrUndefined(recurrenceRule.byMonthDay),
                byyearday: this.intArrayOrUndefined(recurrenceRule.byYearDay),
                byweekno: this.intArrayOrUndefined(recurrenceRule.byWeekNumber),
                byweekday: this.rruleByDayFrom(recurrenceRule.byDay),
                byhour: this.intArrayOrUndefined(recurrenceRule.byHour),
                byminute: this.intArrayOrUndefined(recurrenceRule.byMinute),
                bysecond: this.intArrayOrUndefined(recurrenceRule.bySecond)
            })
        } catch (e) {
            console.error("Could not create rrule instance", e)
            return null
        }
    }

    static intArrayOrUndefined(source: any): number[] | undefined {
        if (Array.isArray(source) && source.length > 0) {
            return source as number[]
        } else {
            return undefined
        }
    }

    static rruleFreqFrom(frequency: string | null | undefined): Frequency | undefined {
        switch (frequency?.toUpperCase()) {
            case 'YEARLY':
                return RRule.YEARLY
            case 'MONTHLY':
                return RRule.MONTHLY
            case 'WEEKLY':
                return RRule.WEEKLY
            case 'DAILY':
                return RRule.DAILY
            case 'HOURLY':
                return RRule.HOURLY
            case 'MINUTELY':
                return RRule.MINUTELY
            case 'SECONDLY':
                return RRule.SECONDLY
            default:
                return undefined
        }
    }

    static rruleByDayFrom(byDay: any): Weekday[] | undefined {
        if (!byDay) {
            return undefined
        }
        if (Array.isArray(byDay) && byDay.length === 0) {
            return undefined
        }
        const days: Weekday[] = []
        for (const day in byDay) {
            let today: Weekday | null = null
            switch (day) {
                case 'MONDAY':
                    today = RRule.MO
                    break
                case 'TUESDAY':
                    today = RRule.TU
                    break
                case 'WEDNESDAY':
                    today = RRule.WE
                    break
                case 'THURSDAY':
                    today = RRule.TH
                    break
                case 'FRIDAY':
                    today = RRule.FR
                    break
                case 'SATURDAY':
                    today = RRule.SA
                    break
                case 'SUNDAY':
                    today = RRule.SU
                    break
                default:
                    break
            }
            if (today) {
                const number: any | undefined = byDay[day]
                if (typeof number === 'number') {
                    today = today.nth(number)
                }
                days.push(today)
            }
        }
        if (days.length === 0){
            return undefined
        }
        return days
    }
}
