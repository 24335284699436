<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-md p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Global Signatures
          </translate>
        </h2>
        <p>
          <b><translate>What you can do here:</translate></b>&nbsp;<translate>
            Configure a global signature that will automatically be appended to every outgoing email.
          </translate>
        </p>
        <p><b><translate>What you can't do here:</translate></b>&nbsp;<translate>Go to user settings in order to add user specific signatures.</translate></p>
        <div v-if="signatures === null || settingsLoading">
          <Skeleton class="mt-6" height="40px" style="width: 80%" />
        </div>
        <div v-else class="row mt-6 mb-5">
          <div class="col-auto">
            <Button
              v-if="signatures.length === 0"
              :label="i18n.$gettext('Add Global Signature')"
              icon="cil-plus"
              class="p-button btn-raised mr-3"
              @click="createSignature"
            />
          </div>
        </div>
        <div v-if="signatures !== null" class="row">
          <div class="col">
            <div v-for="(signature, i) in signatures" :key="i" class="p-4 my-1 card separator-bottom">
              <div class="d-flex flex-row justify-content-end w-100">
                <Button
                  v-tooltip="i18n.$gettext('Edit signature')"
                  icon="cil-pencil"
                  class="p-button p-button-primary mr-3"
                  @click="editSignature(signature)"
                />
                <Button
                  v-tooltip="i18n.$gettext('Remove signature')"
                  icon="cil-trash"
                  class="p-button p-button-danger"
                  @click="removeSignature(signature)"
                />
              </div>
              <div v-if="signature.htmlSignature" class="mt-4">
                <p class="font-weight-bold mb-2">
                  <translate>HTML representation:</translate>
                </p>
                <div class="p-2 card" v-html="signature.htmlSignature" />
              </div>
              <div v-if="signature.textSignature" class="mt-4">
                <p class="font-weight-bold mb-2">
                  <translate>Plain text representation:</translate>
                </p>
                <pre v-if="signature.textSignature" style="white-space: pre-wrap">{{ signature.textSignature }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="showEditModal"
      :header="i18n.$gettext('Edit Signature')"
      :modal="true"
      :draggable="false"
    >
      <div v-if="editedSignature">
        <Editor
          ref="editor"
          v-model="editedSignature.htmlSignature"
          :init="{
            height: '400px',
            width: '100%',
            resize: false,
            branding: false,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount textcolor'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | image | removeformat | help',
            contextmenu: 'link linkchecker conferencelink image imagetools spellchecker table configurepermanentpen copy cut paste',
            file_picker_types: 'image',
            file_picker_callback: handleTinyMceFilePick,
            image_prepend_url: '',
            images_dataimg_filter: dataImageFilter,
          }"
        />
        <div class="d-flex justify-content-between mt-4">
          <div>
            <Button class="p-button-danger p-button-raised" :label="i18n.$gettext('Abort')" @click="showEditModal = false" />
          </div>
          <div>
            <Button
              icon="cil-save"
              class="p-button p-button-raised p-button-success"
              :loading="loadingSave"
              :label="i18n.$gettext('Save')"
              @click="saveSignature"
            />
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="showNewModal"
      :header="i18n.$gettext('New SignatureConfig')"
      :modal="true"
      :draggable="false"
    >
      <div v-if="newSignature">
        <Editor
          ref="editor"
          v-model="newSignature.html"
          :init="{
            height: '400px',
            width: '100%',
            resize: false,
            branding: false,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount textcolor'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | image | removeformat | help',
            contextmenu: 'link linkchecker conferencelink image imagetools spellchecker table configurepermanentpen copy cut paste',
            file_picker_types: 'image',
            file_picker_callback: handleTinyMceFilePick,
            image_prepend_url: '',
            images_dataimg_filter: dataImageFilter,
          }"
        />
        <div class="d-flex justify-content-between mt-4">
          <div>
            <Button class="p-button-danger p-button-raised" :label="i18n.$gettext('Abort')" @click="showNewModal = false" />
          </div>
          <div>
            <Button
              icon="cil-plus"
              class="p-button p-button-raised"
              :loading="loadingSave"
              :label="i18n.$gettext('Create')"
              @click="saveSignature"
            />
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import SettingsListItem from "@/components/settings/SettingsListItem.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dialog from "primevue/dialog"
import Editor from "@tinymce/tinymce-vue"
import SignatureConfig from "@/model/settings/SignatureConfig"
import Skeleton from "primevue/skeleton"
import {globalSignatureServiceApi} from "@/api/GlobalSignatureServiceApi"
import useToast from "@/util/toasts"
import RpcError from "@/api/RpcError"

@Options({
  components: {
    SettingsListItem, Button, Editor, InputText, Dialog, Skeleton
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class GlobalSignatureSettings extends Vue {

  toast = useToast()
  i18n: Language = useGettext()

  signatures: SignatureConfig[] | null = null
  newSignature: SignatureConfig | null = null
  editedSignature: SignatureConfig | null = null

  loadingSave: boolean = false
  settingsLoading:  boolean = false

  mounted() {
    this.settingsLoading = true
    void globalSignatureServiceApi._getGlobalSignatures().then((signatures: SignatureConfig[]) => {
      this.signatures = signatures
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Error while loading signatures"))
    }).finally(() => {
      this.settingsLoading = false
    })
  }

  get showEditModal(): boolean {
    return !!this.editedSignature
  }

  set showEditModal(show: boolean) {
    if (!show) {
      this.editedSignature = null
    }
  }

  get showNewModal(): boolean {
    return !!this.newSignature
  }

  set showNewModal(show: boolean) {
    if (!show) {
      this.newSignature = null
    }
  }

  createSignature() {
    this.newSignature = { name: '', textSignature: '', htmlSignature: '<p>---<br/><br/></p>', toRegex: '', fromRegex: '', notToRegex: '' }
    this.editedSignature = null
  }

  editSignature(signature: SignatureConfig) {
    this.editedSignature = signature
    this.newSignature = null
  }

  removeSignature(signature: SignatureConfig) {
    if (this.signatures) {
      const existing: number | undefined = this.signatures.indexOf(signature)
      if (existing >= 0) {
        this.signatures.splice(existing, 1)
      }
      void this.saveSignatures()
    }
  }

  saveSignature() {
    const editedSignature = this.editedSignature || this.newSignature
    if (this.signatures && editedSignature) {
      //@ts-ignore
      editedSignature.textSignature = window.tinyMCE.editors[0].getContent({format: 'text'})
      //@ts-ignore
      editedSignature.htmlSignature = window.tinyMCE.editors[0].getContent({format: 'html'})
      const existing: number = this.signatures.indexOf(editedSignature)
      if (existing >= 0) {
        this.signatures[existing] = editedSignature
      } else {
        this.signatures.push(editedSignature)
      }
      void this.saveSignatures()
    }
  }

  saveSignatures(): Promise<any> {
    if (this.signatures) {
      this.loadingSave = true
      return globalSignatureServiceApi._setGlobalSignatures(this.signatures).then(() => {
        this.newSignature = null
        this.editedSignature = null
        this.toast.success(this.i18n.$gettext("Signatures saved."))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Error while saving signatures"))
      }).finally(() => {
        this.loadingSave = false
      })
    } else {
      return Promise.reject()
    }
  }

  dataImageFilter(img: any) {
    return img.hasAttribute('internal-blob')
  }

  handleTinyMceFilePick(callback: any, value: any, meta: any) {

    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.setAttribute('multiple', 'multiple')
    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = () => {
      if (input && input.files) {
        //check file size limit. only up to 200kB allowed
        let filesize = 0 //in kB
        for (let f of input.files) {
          filesize += f.size // kB
          if (filesize > 200000) {
            this.toast.error(this.i18n.$gettext("File size exceeds limit of 200kB"))
            return
          }
        }

        for (let f of input.files) {
          let reader = new FileReader()
          reader.onload = (e) => {
            if (e?.target?.result) {
              callback(e.target.result, {alt: ''})
            }
          }
          reader.readAsDataURL(f)
        }
      }
    }

    input.click()
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";



</style>
