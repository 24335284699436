export default class Conference {
  uri!: string | null;
  text!: string | null;
  label!: string | null;
  features!: string[] | null;

  constructor() {
    this.uri = null;
    this.text = null;
    this.label = null;
    this.features = null;
  }
}
