import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Event from '@/model/entry/Event';


export default class GeneratedEventStore {

    state = reactive({
        events: new Map<string, Event>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.events) this.state.events.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceEvent(newEvent: Event) {
        if (newEvent.originalId) {
            this.state.events.set(newEvent.originalId, newEvent)
            
        }
    }

    addOrReplaceEvents(newEvents: Event[]) {
        for (const newEvent of newEvents) {
            this.addOrReplaceEvent(newEvent)
        }
    }

    replaceEvents(events: Event[]) {
        const newEvents = new Map<string, Event>()
        for (const newEvent of events) {
            if (newEvent.originalId) {
                newEvents.set(newEvent.originalId, newEvent)
                
            }
        }
        this.state.events = newEvents
    }

    removeEvent(originalId: string | undefined | null) {
        if (originalId !== undefined && originalId !== null) {
            this.state.events.delete(originalId)
        }
    }

    removeEvents(originalIds: (string | null)[] | undefined | null) {
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                this.removeEvent(originalId)
            }
        }
    }
}