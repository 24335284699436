export const mentionRegexWithoutAt = new RegExp("(?:^|\\s)@([a-zA-Z0-9_\\-\+\.]*)", "gm")
export const mentionRegexWithAt = new RegExp("(?:^|\\s)(@[a-zA-Z0-9_\\-\+\.]*)", "gm")

export function extractMentionNames (message: string): string[] {
    const foundMentions: string[] = []
    const matches = message.matchAll(mentionRegexWithoutAt)
    for(const match of matches){
        foundMentions.push(match[match.length - 1])
    }
    return foundMentions
}
