export default class ResourceShare {
  id!: number | null;
  resource!: string | null;
  type!: string | null;
  targetId!: string | null;
  targetName!: string | null;
  targetType!: string | null;
  permission!: string | null;
  createdBy!: string | null;
  created!: string | null;

  constructor() {
    this.id = null;
    this.resource = null;
    this.type = null;
    this.targetId = null;
    this.targetName = null;
    this.targetType = null;
    this.permission = null;
    this.createdBy = null;
    this.created = null;
  }
}
