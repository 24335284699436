import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MailFilter from '@/model/settings/MailFilter';
import Page from '@/model/Page';
import { mailFilterStore } from '@/store/MailFilterStore';

export default class GeneratedMailFilterServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _saveMailFilters(mailFilters: MailFilter[]): Promise<MailFilter[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('saveMailFilters', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new MailFilter(), it)) : Promise.reject()
        })
    }

    _getMailFilters(): Promise<MailFilter[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMailFilters', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailFilters: MailFilter[] = data.map(mailFilter => Object.assign(new MailFilter(), mailFilter))
                mailFilterStore.replaceMailFilters(mailFilters)
                return mailFilters
            } else return Promise.reject()
        })
    }


    getMailFilters(refresh?: boolean | number): SWR<MailFilter[], number[]> {
        //@ts-ignore
        const result: SWR<MailFilter[], number[]> = reactive(new SWR<MailFilter[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getMailFilters' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMailFilters[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const mailFilters: MailFilter[] = []
                for (const id of data) {
                    const mailFilter: MailFilter | undefined = mailFilterStore.state.mailFilters.get(id)
                    if (mailFilter) {
                        mailFilters.push(mailFilter)
                    }
                }
                result.data = mailFilters
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMailFilters().then((data: MailFilter[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(mailFilter => mailFilter.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let mailFilters: MailFilter[] = [...mailFilterStore.state.mailFilters.values()]
        
        
        
        result.data = mailFilters
        return result
    }

    getMailFilter(id: number): MailFilter | undefined {
        return mailFilterStore.state.mailFilters.get(id)
    }
}
