<template>
  <div class="d-flex flex-column flex-grow-1 h-100">
    <slot name="header">
      <p class="mt-4">
        <translate>Please select all groups you want to share this resource with</translate>
      </p>
    </slot>
    <div class="p-inputgroup">
      <span class="p-float-label">
        <Dropdown
          v-model="newShare"
          small
          class="w-100 mb-0 flex-fill"
          :options="availableGroups"
          option-value="cn"
          option-label="description"
          @change="addShare"
        />
        <label><slot name="placeholder"><translate>Add Group</translate></slot></label>
      </span>
    </div>
    <div v-if="sharedTo.length > 0" class="flex-grow-1" style="overflow-y: scroll">
      <div v-for="share in sharedTo" :key="JSON.stringify(share)" class="d-flex flex-row align-items-center mt-2">
        <p class="flex-grow-1 mb-0" style="width: 1px">
          {{ share.targetName || findGroupByCN(share.targetId)?.description || share.targetId }}
        </p>
        <Dropdown
          v-model="share.permission"
          small
          class="mb-0 mr-4"
          :options="tAccessOptions"
          option-label="name"
          option-value="id"
          style="flex-basis: 20%; min-width: 200px; flex-grow: 0"
          @change="updateShare(share)"
        />
        <Button
          v-tooltip="i18n.$gettext('Remove')"
          class="p-button-secondary"
          icon="cil-trash"
          @click="removeShare(share)"
        />
      </div>
    </div>
    <div v-else class="flex-grow-1">
      <p class="mb-0 mt-4 text-secondary">
        <translate>No group selected</translate>
      </p>
    </div>
    <slot name="footer" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import {groupServiceApi} from "@/api/GroupServiceApi"
import Group from "@/model/directory/Group"
import ResourceShare from "@/model/common/ResourceShare"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    resource: String,
    type: String,
    accessOptions: {
      type: Array,
      default: null
    }
  },
  components: {
    Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class ResourceShareGroupInput extends Vue {

  i18n: Language = useGettext()

  modelValue!: ResourceShare[]
  resource!: string
  type!: string
  accessOptions!: any[] | null

  sharedTo: ResourceShare[] = []
  newShare: string = ''

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  get availableGroups(): Group[] {
    return this.groups?.filter((group: Group) => {
      return !this.sharedTo.find((share: ResourceShare) => {
        return share.targetId?.toLowerCase() == group.cn?.toLowerCase()
      })
    })
  }

  get groups(): Group[] {
    return (groupServiceApi.getGroupsForCurrentUser().data || []).map((group: Group) => {
      const clonedGroup = Object.assign(new Group(), group)
      clonedGroup.description = clonedGroup.description || clonedGroup.cn
      return clonedGroup
    })
  }

  addShare() {
    const targetId = this.newShare
    if (targetId === "" || !!this.sharedTo.find(g => g.targetId === targetId)) {
      return
    } else {
      const share: ResourceShare = new ResourceShare()
      share.resource = this.resource
      share.targetId = targetId
      share.type = this.type
      share.targetType = 'GROUP'
      if (this.tAccessOptions && this.tAccessOptions.length > 0) share.permission = this.tAccessOptions[0].id
      this.sharedTo.push(share)
      this.$emit('added', share)
      this.$emit('update:modelValue', this.sharedTo)
      this.newShare = ""
    }
  }

  findGroupByCN(cn: string): Group | undefined {
    return this.groups.find(group => group.cn?.toLowerCase() === cn.toLowerCase())
  }

  updateShare(share: ResourceShare) {
    this.$emit('updated', share)
  }

  removeShare(share: ResourceShare) {
    const removed: number = this.sharedTo.indexOf(share)
    if (removed >= 0) {
      const removedShare: ResourceShare = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedShare)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged() {
    this.sharedTo = this.modelValue
  }

  mounted() {
    this.onValueChanged()
  }
}
</script>

<style scoped lang="scss">

</style>
