

import {Options, Vue} from "vue-class-component"
import {Watch} from "vue-property-decorator"

@Options({
  components: {},
  //@ts-ignore
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  emits: []
})
export default class MentionList extends Vue {

  selectedIndex: number = 0
  items: any[] = []
  command: (p: {id: any}) => void = (p) => {}

  @Watch('items')
  watchItems(){
    this.selectedIndex = 0
  }

  get augmentedItems(){
    const result = [ ...this.items ]
    result.push({
      givenname: "UniKI",
      surname: "- AI Assistent",
      cn: "uniki@rr1.uniki.cloud"
    })
    return result
  }

  onKeyDown({ event }: {event: KeyboardEvent}) {
    if (event.key === 'ArrowUp') {
      this.upHandler()
      return true
    }
    if (event.key === 'ArrowDown') {
      this.downHandler()
      return true
    }
    if (event.key === 'Enter') {
      this.enterHandler()
      return true
    }
    if (event.key === 'Tab') {
      this.enterHandler()
      return true
    }
    return false
  }

  upHandler() {
    this.selectedIndex = ((this.selectedIndex + this.augmentedItems.length) - 1) % this.augmentedItems.length
  }

  downHandler() {
    this.selectedIndex = (this.selectedIndex + 1) % this.augmentedItems.length
  }

  enterHandler() {
    this.selectItem(this.selectedIndex)
  }

  selectItem(index: number) {
    const completeUsername = this.augmentedItems[index].cn
    const namePart = completeUsername.split("@")[0] || completeUsername
    if (namePart) {
      this.command({ id: namePart })
    }
  }
}
