<template>
  <div class="contact-editor container-fluid bg-white" style="min-height: 100%">
    <div class="contact-image-row row mb-4 pt-4">
      <div class="col row">
        <div class="col-auto">
          <Avatar
            :size="128"
            :cached-image="photoSrc"
            :label="contact.formattedName.text"
            generate-initials
          />
          <input
            ref="pictureinput"
            type="file"
            style="display: none"
            @change="loadFile"
          >
          <Dialog v-model:visible="showCropDialog" :header="i18n.$gettext('Crop Picture')">
            <div class="p-4">
              <div style="height: 500px; width: 300px;">
                <Cropper
                  ref="cropper"
                  class="upload-example-cropper"
                  :src="image.src"
                  :stencil-props="{ aspectRatio: 1/1 }"
                />
              </div>
              <div class="d-flex d-flex-row justify-content-center">
                <Button
                  :label="i18n.$gettext('Upload')"
                  icon="cil-cloud-upload"
                  class="p-button-raised mr-2"
                  :loading="pictureLoading"
                  @click="uploadCroppedPicture"
                />
              </div>
            </div>
          </Dialog>
        </div>
        <div class="col pl-4">
          <p class="h4 mb-2 flex-grow-1" :class="{ 'my-lg-4': editMode }">
            {{ contact.formattedName.text }}
          </p>
          <p v-if="contact.titles && contact.titles[0]" class="lead mb-0">
            {{ contact.titles[0].text }}
          </p>
          <p v-if="contact.organization && contact.organization.titles" class="lead mb-0">
            <span v-for="(title, index) in contact.organization.titles" :key="title">
              <span v-if="index !== 0"> - </span>{{ title }}
            </span>
          </p>
          <p v-if="contact.nickNames && contact.nickNames.names" class="lead">
            <em>
              <span v-for="(name, index) in contact.nickNames.names" :key="name">
                <span v-if="index !== 0">, </span> "{{ name }}"
              </span>
            </em>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-auto" :class="{ 'd-flex justify-content-center mt-4': isOnMobile }">
        <div v-if="!editMode">
          <Button
            v-if="isAllowedToEdit"
            :label="i18n.$gettext('Edit')"
            icon="cil-pencil"
            class="p-button-raised mr-2"
            @click="enableEditMode"
          />
          <Button
            v-if="isAllowedToEdit"
            :label="i18n.$gettext('Delete')"
            icon="cil-trash-alt"
            class="p-button-raised p-button-danger"
            :loading="deleteIsLoading"
            @click="confirmDelete"
          />
        </div>
        <div v-else>
          <Menu
            ref="addfieldmenu"
            :model="fieldMenu"
            :popup="true"
            :base-z-index="8000"
          />
          <Button
            :label="i18n.$gettext('Add Field')"
            icon="cil-plus"
            class="p-button-raised p-button-success mr-2 mb-2"
            @click="toggleMenu"
          />
          <Button
            :label="i18n.$gettext('New Picture')"
            icon="cil-image"
            class="p-button-raised p-button-success mr-2 mb-2"
            @click="openFileDialog"
          />
          <Button
            :label="i18n.$gettext('Save')"
            icon="cil-save"
            class="p-button-raised p-button-success mr-2 mb-2"
            :loading="saveIsLoading"
            @click="saveChanges"
          />
          <Button
            :label="i18n.$gettext('Abort')"
            icon="cil-backspace"
            class="p-button-raised p-button-secondary  mb-2"
            @click="rollbackChanges"
          />
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <h6 v-if="hasBirthday || (hasAnniversary && isV4vCard)">
          <translate>Personal</translate>
        </h6>
        <span v-if="editMode" class="p-float-label mb-4">
          <AnimatedInput v-model="contact.formattedName.text" type="text" :label="i18n.$gettext('Show as')" :disabled="!editMode" />
        </span>
        <StructuredNameField
          v-if="contact.structuredName"
          class="mb-4"
          :name="contact.structuredName"
          :read-only="!editMode"
        />
        <div v-else-if="contact.structuredName">

        </div>
        <div v-if="hasNicknames && editMode" class="d-flex mb-2">
          <span class="p-float-label inline" style="min-width: 30%">
            <Tags
                v-model="contact.nickNames.names"
                multiple
                :disabled="!editMode"
                :get-autocomplete-items="() => []"
            />
            <label><translate>Nicknames</translate></label>
          </span>
          <Button
            v-if="editMode"
            icon="cil-trash-alt"
            class="p-button-danger after-inline-label ml-2"
            @click="delNickname"
          />
        </div>
        <div class="mb-4">
          <DateOrTimeField
            v-if="hasBirthday"
            class="mr-2"
            :date="contact.birthDay"
            :name="i18n.$gettext('Birthday')"
            :read-only="!editMode"
            @delete="delBirthday"
          />
          <DateOrTimeField
            v-if="hasAnniversary && isV4vCard"
            :date="contact.anniversary"
            :name="i18n.$gettext('Anniversary')"
            :read-only="!editMode"
            @delete="delAnniversary"
          />
        </div>
        <div v-if="editMode" class="mb-4">
          <div v-if="hasTitles" class="mb-4">
            <span class="p-float-label inline mr-2" style="min-width: 30%">
              <AnimatedInput v-model="contact.titles[0].text" type="text" :label="i18n.$gettext('Title')" :disabled="!editMode" />
            </span>
            <Button
                icon="cil-trash-alt"
                class="p-button-danger after-inline-label mr-2"
                @click="delTitles"
            />
          </div>
          <OrganizationField
              v-if="hasCompany"
              :organization="contact.organization"
              :read-only="!editMode"
              @delete="delCompany"
          />
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div v-if="hasTelephone" class="col-12 col-md-6">
        <h6><translate>Phones</translate></h6>
        <PhoneField
          v-for="(phone, index) in contact.phones"
          class="mb-4"
          :key="phone"
          :phone="phone"
          :read-only="!editMode"
          :isv4="isV4vCard"
          @delete="delPhone(index)"
        />
      </div>
      <div v-if="hasEmails" class="col-12 col-md-6">
        <h6><translate>E-Mail</translate></h6>
        <EmailField
          v-for="(email, index) in contact.emailAddresses"
          :key="email"
          :email="email"
          :read-only="!editMode"
          :isv4="isV4vCard"
          @delete="delEmail(index)"
        />
      </div>
    </div>
    <div v-if="hasAddresses" class="row mb-4">
      <div class="col">
        <h6><translate>Addresses</translate></h6>
        <AddressField
          v-for="(address, index) in contact.addresses"
          :key="index"
          :address="address"
          :read-only="!editMode"
          :isv4="isV4vCard"
          @delete="delAddress(index)"
        />
      </div>
    </div>
    <div v-if="hasNotes" class="row">
      <div v-if="editMode" class="col">
        <div class="row">
          <div class="col flex-grow-1">
            <h6><translate>Notes</translate></h6>
          </div>
          <div class="col-auto">
            <Button
              v-if="editMode"
              icon="cil-trash-alt"
              class="p-button-danger"
              @click="delNotes"
            />
          </div>
        </div>
        <span class="p-float-label">
          <Textarea
            v-model="contact.notes[0].text"
            rows="10"
            style="width: 100%;"
            :disabled="!editMode"
          />
          <label><translate>Notes</translate></label>
        </span>
      </div>
      <div v-else class="col">
        <div class="row">
          <div class="col flex-grow-1">
            <h6><translate>Notes</translate></h6>
          </div>
        </div>
        <div v-if="contact.notes[0].text">
          {{ contact.notes[0].text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Contact from "@/model/entry/Contact"
import Avatar from "@/components/common/Avatar.vue"
import PhoneField from "@/components/contacts/subcomponents/PhoneField.vue"
import EmailField from "@/components/contacts/subcomponents/EmailField.vue"
import AddressField from "@/components/contacts/subcomponents/AddressField.vue"
import StructuredNameField from "@/components/contacts/subcomponents/StructuredNameField.vue"
import DateOrTimeField from "@/components/contacts/subcomponents/DateOrTimeField.vue"
import Textarea from "primevue/textarea"
import OrganizationField from "@/components/contacts/subcomponents/OrganizationField.vue"
import {useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import {contactServiceApi} from "@/api/ContactServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import RpcError from "@/api/RpcError"
import NickNames from "@/model/common/carddav/NickNames"
import DateOrTime from "@/model/common/carddav/DateOrTime"
import Organization from "@/model/common/carddav/Organization"
import TextValue from "@/model/common/carddav/TextValue"
import Telephone from "@/model/common/carddav/Telephone"
import EmailAddress from "@/model/common/EmailAddress"
import Address from "@/model/common/carddav/Address"
import Menu from "primevue/menu"
import Dialog from 'primevue/dialog'
import {Cropper} from 'vue-advanced-cropper'
import { ref } from "@vue/reactivity"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import DAVFile from "@/model/common/carddav/File"
import breakpointUtil from "@/util/BreakpointUtil"
import dayjs from "@/util/dayjs"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import Tags from "@/components/common/Tags.vue"

@Options({
  components: {
    OrganizationField, Tags, Button, Menu, Cropper, Dialog,
    DateOrTimeField, StructuredNameField, AddressField, EmailField, PhoneField, Avatar, AnimatedInput, Textarea},
  //@ts-ignore
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false
    },
    contactId: String
  },
  emits: []
})
export default class ContactEditor extends Vue {

  contactId!: string

  i18n = useGettext()
  toast = useToast()
  confirm = useConfirm()

  editedContact: Contact | false = false

  saveIsLoading = false
  deleteIsLoading = false

  //@ts-ignore
  addfieldmenu: Menu = ref<Menu | null>(null)
  //@ts-ignore
  pictureinput: HTMLInputElement = ref<HTMLInputElement | null>(null)

  fieldMenu: any[] = []

  image : {src: any, type: any} = {
    src: null,
    type: null
  }

  //@ts-ignore
  cropper: Cropper = ref<Cropper | null>(null)

  showCropDialog = false
  pictureLoading = false

  get editMode(): boolean {
    return !!this.editedContact
  }

  get contact(): Contact {
    if (this.editedContact) {
      return this.editedContact
    } else {
      const contact: Contact | undefined = contactServiceApi.getContact(this.contactId || '')
      return contact || Object.assign(new Contact(), { formattedName: new TextValue() })
    }
  }

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }

  get hasNicknames(): boolean {
    return !!this.contact.nickNames
  }

  get hasBirthday(): boolean {
    return !!this.contact.birthDay
  }

  get hasAnniversary(): boolean {
    return !!this.contact.anniversary
  }

  get hasCompany(): boolean {
    return !!this.contact.organization
  }

  get hasTitles(): boolean {
    return !!(this.contact.titles && this.contact.titles.length > 0)
  }

  get hasTelephone(): boolean {
    return !!(this.contact.phones && this.contact.phones.length > 0)
  }

  get hasEmails(): boolean {
    return !!(this.contact.emailAddresses && this.contact.emailAddresses.length > 0)
  }

  get hasAddresses(): boolean {
    return !!(this.contact.addresses && this.contact.addresses.length > 0)
  }

  get hasNotes(): boolean {
    return !!(this.contact.notes && this.contact.notes.length > 0)
  }

  addNickname(): void {
    if (!this.hasNicknames) {
      this.contact.nickNames = new NickNames()
      this.contact.nickNames.names = []
    }
  }

  delNickname(): void {
    this.contact.nickNames = null
  }

  addBirthday(): void {
    const bDay = new DateOrTime()
    bDay.calendar = (new Date()).toISOString()
    if (!this.hasBirthday) this.contact.birthDay = bDay
  }

  delBirthday(): void {
    this.contact.birthDay = null
  }

  addAnniversary(): void {
    const anniv = new DateOrTime()
    anniv.calendar = (new Date()).toISOString()
    if (!this.hasAnniversary) this.contact.anniversary = anniv
  }

  delAnniversary(): void {
    this.contact.anniversary = null
  }

  addCompany(): void {
    const newOrg = new Organization()
    newOrg.titles = []
    if (!this.hasCompany) this.contact.organization = newOrg
  }

  delCompany(): void {
    this.contact.organization = null
  }

  addTitles(): void {
    if (!this.hasTitles) this.contact.titles = [ new TextValue() ]
  }

  delTitles(): void {
    this.contact.titles = []
  }

  addPhone(): void {
    if (!this.contact.phones) this.contact.phones = []
    this.contact.phones.push(new Telephone())
  }

  delPhone(idx: number): void {
    this.contact.phones?.splice(idx, 1)
  }

  addEmail(): void {
    if (!this.contact.emailAddresses) {
      this.contact.emailAddresses = []
    }
    const email = new EmailAddress()
    email.types = []
    this.contact.emailAddresses.push(email)
  }

  delEmail(idx: number): void {
    this.contact.emailAddresses?.splice(idx, 1)
  }

  addAddress(): void {
    if (!this.contact.addresses) {
      this.contact.addresses = []
    }
    const address = new Address()
    address.types = []
    this.contact.addresses.push(address)
  }

  delAddress(idx: number): void {
    this.contact.addresses?.splice(idx, 1)
  }

  addNotes(): void {
    if (!this.hasNotes) this.contact.notes = [ new TextValue() ]
  }

  delNotes(): void {
    this.contact.notes = []
  }

  toggleMenu(e: Event): void {
    this.setFieldMenu()
    void this.$nextTick(() =>{
      this.addfieldmenu.show(e)
    })
  }

  setFieldMenu(): void {
    let result = []
    if (!this.hasNicknames) {
      result.push({label: this.i18n.$gettext("Nicknames"), command: () => { this.addNickname() }})
    }
    if (!this.hasBirthday) {
      result.push({label: this.i18n.$gettext("Birthday"), command: () => { this.addBirthday() }})
    }
    if (!this.hasAnniversary && this.isV4vCard) {
      result.push({label: this.i18n.$gettext("Anniversary"), command: () => { this.addAnniversary() }})
    }
    if (!this.hasCompany) {
      result.push({label: this.i18n.$gettext("Company"), command: () => { this.addCompany() }})
    }
    if (!this.hasTitles) {
      result.push({label: this.i18n.$gettext("Titles"), command: () => { this.addTitles() }})
    }

    result.push({label: this.i18n.$gettext("Phone"), command: () => { this.addPhone() }})
    result.push({label: this.i18n.$gettext("Email"), command: () => { this.addEmail() }})
    result.push({label: this.i18n.$gettext("Address"), command: () => { this.addAddress() }})

    if (!this.hasNotes) {
      result.push({label: this.i18n.$gettext("Notes"), command: () => { this.addNotes() }})
    }

    this.fieldMenu = result
  }

  get photoSrc(): CachedImage | null {
    if (this.contact?.photos && this.contact.photos.length > 0) {
      if (this.contact.photos[0].data == null) {
        return imageLoadingService.getCachedImage(`/groupware-api/v1/contacts/${this.contact.originalId}/image`)
      }
      if (this.contact.photos[0].url !== null && this.contact.photos[0].url !== "") {
        return imageLoadingService.getCachedImage(this.contact.photos[0].url)
      }
    }
    return null
  }

  enableEditMode(): void {
    this.editedContact = JSON.parse(JSON.stringify(this.contact))
  }

  rollbackChanges(): void {
    this.editedContact = false
  }

  cleanContact(): void {
    if (!this.contact) return
    if (this.contact.organization) {
      if (this.contact.organization.titles === null || this.contact.organization.titles.length === 0) {
        this.contact.organization = null
      }
    }

    this.contact.phones = this.contact.phones?.filter((phone: Telephone) => {
      return phone.text !== null && phone.text !== ""
    }) || null
    this.contact.emailAddresses = this.contact.emailAddresses?.filter((emailAddress: EmailAddress) => {
      return emailAddress.address !== null && emailAddress.address !== ""
    }) || null

    if (this.contact.nickNames?.names && this.contact.nickNames.names.length === 0) {
      this.contact.nickNames = null
    }
  }


  saveChanges(): Promise<void> {
    const toSave = this.contact
    this.saveIsLoading = true
    return contactServiceApi._updateContact(toSave).then(() => {
      this.toast.success(this.i18n.$gettext("Contact saved"))
      this.editedContact = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not save contact"))
    }).finally(() => {
      this.saveIsLoading = false
    })
  }

  confirmDelete(event: Event): void {
    this.confirm.require({
      //@ts-ignore
      target: event.currentTarget || undefined,
      message: this.i18n.$gettext("Do you really want to delete this contact?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-exclamation',
      accept: () => {
        void this.deleteContact()
      },
      reject: () => {}
    })
  }

  deleteContact(): Promise<void> {
    const toDelete = this.contact.originalId
    if (!toDelete) return Promise.reject()
    this.deleteIsLoading = true
    return contactServiceApi._deleteContact(toDelete).then(() => {
      this.toast.success(this.i18n.$gettext("Contact deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete contact"))
    }).finally(() => {
      this.deleteIsLoading= false
    })
  }

  // This function is used to detect the actual image type,
  getMimeType(file: any, fallback: any = null): string {
    const byteArray = (new Uint8Array(file)).subarray(0, 4)
    let header = ''
    for (let i = 0; i < byteArray.length; i++) {
      header += byteArray[i].toString(16)
    }
    switch (header) {
      case "89504e47":
        return "image/png"
      case "47494638":
        return "image/gif"
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
      case "ffd8ffe3":
      case "ffd8ffe8":
        return "image/jpeg"
      default:
        return fallback
    }
  }

  openFileDialog() {
    if (this.editMode) {
      this.pictureinput.click()
    }
  }

  normalizeBirthday(chosenBirthday: Date): Date{
    const chosenDay = dayjs(chosenBirthday).hour(0).minute(0).second(0).millisecond(0)
    return chosenDay.utc(true).toDate()
  }

  loadFile(event: any): void {
    // Reference to the DOM input element
    const {files} = event.target
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src)
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(files[0])

      // 3. The steps below are designated to determine a file mime type to use it during the
      // getting of a cropped image from the canvas. You can replace it them by the following string,
      // but the type will be derived from the extension and it can lead to an incorrect result:
      //
      // this.image = {
      //    src: blob;
      //    type: files[0].type
      // }

      // Create a new FileReader to read this image binary data
      const reader = new FileReader()
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e) => {
        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
        this.image = {
          // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
          src: blob,
          // Determine the image type to preserve it during the extracting the image from canvas:
          type: this.getMimeType(e.target?.result, files[0].type),
        }

        this.showCropDialog = true
      }
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsArrayBuffer(files[0])
    }
  }

  uploadCroppedPicture(): void {
    const id = this.contact.originalId
    const token = rpcClient.session.token
    if (!id || !token) return
    this.pictureLoading = true
    const {canvas} = this.cropper.getResult()
    if (canvas) {
      const form = new FormData()
      canvas.toBlob((blob : any) => {
        form.append('photo', blob)
        const h: Headers = new Headers()
        h.append('X-Auth-Token', token)

        fetch(`/groupware-api/v1/contacts/${id}/image`, {
          method: 'POST',
          body: form,
          headers: h
        }).then((response: Response) => {
          this.pictureLoading = false
          if (response.ok) {
            this.toast.success(this.i18n.$gettext("Picture uploaded"))
            this.showCropDialog = false
            URL.revokeObjectURL(this.image.src)
            this.image = {src: null, type: null}
            if (!this.contact.photos || this.contact.photos.length === 0) {
              this.contact.photos = [ Object.assign(new DAVFile(), { data: null }) ]
            }
            let cachedImage = this.photoSrc
            if (cachedImage) imageLoadingService.refreshImage(cachedImage)
          } else {
            this.toast.error(this.i18n.$gettext("Picture could not be uploaded"))
          }
        }).catch(() => {
          this.toast.error(this.i18n.$gettext("Could not upload picture"))
        })
      }, this.image.type)
    }
  }

  get isV4vCard(): boolean {
    if (!this.contact || !this.contact.vCardVersion) return false
    return parseInt(this.contact.vCardVersion) === 4
  }

}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.clickable {
  position: relative;
}

.clickable:hover {
  cursor: pointer;
}

.clickable::before {
  content: "\eb27";
  position: absolute;
  z-index: 20;
  color: $uniki_primary;
  height: 100%;
  width: 100%;
  font-family: 'CoreUI-Icons-Linear' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 200%;
  font-size: 4rem;
  pointer-events: none;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.cropper {
  background: #DDD;
}

.vue-advanced-cropper {
  overflow: hidden;
}


</style>
