

import {Options, Vue} from "vue-class-component"
import Calendar from "@/model/directory/Calendar"
import SWR from "@/api/SWR"
import {calendarServiceApi} from "@/api/CalendarServiceApi"
import {eventServiceApi} from "@/api/EventServiceApi"
import CalendarEvent from "@/model/entry/Event"
import dayjs from "@/util/dayjs"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Skeleton from "primevue/skeleton"
import DateTimeUtil from "@/util/DateTimeUtil"
import {rpcClient} from "@/api/WebsocketClient"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {ref} from "@vue/reactivity"
import OverlayPanel from "primevue/overlaypanel"
import Checkbox from "primevue/checkbox"
import DashboardSettings from "@/model/settings/DashboardSettings"
import SettingsUtil from "@/util/SettingsUtil"
import Slider from "primevue/slider"
import Button from "primevue/button"
import {toEvent} from "@/router"
import CalendarSettings from "@/model/settings/dashboard/CalendarSettings"
import Task from "@/model/entry/Task"
import Attendee from "@/model/common/caldav/Attendee"
import {Watch} from "vue-property-decorator"

@Options({
  components: { Skeleton, InfiniteList, OverlayPanel, Checkbox, Slider, Button },
  //@ts-ignore
  props: {},
  emits: []
})
export default class CalendarWidget extends Vue {

  calendarsAreLoading : boolean = false
  eventsAreLoading: boolean = false
  i18n: Language = useGettext()

  //@ts-ignore
  settingsPanel: OverlayPanel = ref<OverlayPanel | null>(null);

  localCalendarSettings: CalendarSettings | null = null

  goToItem(item: CalendarEvent): void {
    if (item.originalParentId && item.originalId) {
      toEvent(item.originalParentId, item.originalId)
    }
  }

  calendarCheckboxStyle(cal: Calendar): any {
    if (cal.colorHex) {
      return {
        'background-color': cal.colorHex
      }
    }
  }

  toggleOverlayPanel(e: Event) {
    this.resetSettings()
    this.settingsPanel.toggle(e)
  }

  resetSettings(): void {
    const dashboardSettings: DashboardSettings | undefined = SettingsUtil.getDashboardSettings()
    if (dashboardSettings) {
      if (!dashboardSettings.calendar) {
        dashboardSettings.calendar = new CalendarSettings()
        dashboardSettings.calendar.days = 30
        dashboardSettings.calendar.showAll = true
        dashboardSettings.calendar.showIfOrganizer = true
        dashboardSettings.calendar.showIfAccepted = true
        dashboardSettings.calendar.showIfInvited = true
        dashboardSettings.calendar.calendars = []
      }
      if (!dashboardSettings.calendar.calendars?.length) {
        this.selectAllCalendars = true
      }
      this.localCalendarSettings = JSON.parse(JSON.stringify(dashboardSettings.calendar)) //make a non-reactive copy
    }
  }

  get selectAllCalendars(): boolean {
    if (this.localCalendarSettings) {
      return Boolean(!this.localCalendarSettings.calendars?.length || this.allSelected)
    } else {
      return false
    }
  }

  set selectAllCalendars(all: boolean) {
    if (all && this.localCalendarSettings) {
      this.localCalendarSettings.calendars = this.allCalendars.map(b => b.originalId || '')
    }
  }

  get allSelected(): boolean {
    return this.allCalendars.every(b => this.localCalendarSettings?.calendars?.includes(b.originalId || ''))
  }

  @Watch('localCalendarSettings.showAll')
  watchShowAll() {
    if (this.localCalendarSettings?.showAll) {
      this.localCalendarSettings.showIfOrganizer = true
      this.localCalendarSettings.showIfInvited = true
    }
  }

  @Watch('localCalendarSettings.showIfInvited')
  watchShowIfInvited() {
    if (this.localCalendarSettings?.showIfInvited) {
      this.localCalendarSettings.showIfAccepted = true
    } else if (this.localCalendarSettings) {
      this.localCalendarSettings.showAll = false
    }
  }

  @Watch('localCalendarSettings.showIfAccepted')
  watchShowIfAccepted() {
    if (this.localCalendarSettings && !this.localCalendarSettings?.showIfAccepted) {
      this.localCalendarSettings.showAll = false
      this.localCalendarSettings.showIfInvited = false
    }
  }

  @Watch('localCalendarSettings.showIfOrganizer')
  watchShowIfOrganizer() {
    if (this.localCalendarSettings && !this.localCalendarSettings?.showIfOrganizer) {
      this.localCalendarSettings.showAll = false
    }
  }

  updateSelection(): void {
    if (this.localCalendarSettings) {
      SettingsUtil.setDashboardCalendarSettings(this.localCalendarSettings)
    }
    this.settingsPanel.hide()
  }

  itemDayString(event: CalendarEvent) : string {
    if (!event.start) return ""
    let start = dayjs(event.start)

    if (start.isToday()) {
      return this.i18n.$gettext("Today at")
    } else {
      return start.fromNow() + start.format(', dddd DD.MM ') + this.i18n.$gettext('at')
    }
  }

  elementClass(event: CalendarEvent, beginn: number): string[] {
    let result = []
    const time = dayjs().unix()
    const min = 60 //in seconds
    if (event.allDay) {
      result.push('is-all-day')
    } else if (beginn <= time) {
      result.push('in-progress')
    } else if (beginn - time < 5*min) {
      result.push('about-to-start')
    } else if (beginn - time < 15*min) {
      result.push('get-ready')
    }
    return result
  }

  get isLoading(): boolean {
    return this.calendarsAreLoading || this.eventsAreLoading
  }

  get selectedCalendars(): string[] {
    return SettingsUtil.getDashboardSettings()?.calendar?.calendars || []
  }

  get daysToWatch(): number {
    return SettingsUtil.getDashboardSettings()?.calendar?.days || 1
  }

  get start(): string {
    let startOfDay = new Date()
    startOfDay.setHours(23,59,59,999)
    startOfDay.setDate(startOfDay.getDate() - 1)
    return startOfDay.toISOString()
  }

  get end(): string {
    const endOfDay = new Date()
    endOfDay.setHours(23,59,59,999)
    if (this.daysToWatch > 1) {
      endOfDay.setDate(endOfDay.getDate() + this.daysToWatch)
    }
    return endOfDay.toISOString()
  }

  get allCalendars(): Calendar[] {
    const swr: SWR<Calendar[], string[]> = calendarServiceApi.getCalendars(false)
    if (swr.call?.loading && swr.call?.promise) {
      this.calendarsAreLoading = true
      swr.call.promise.finally(() => {
        this.calendarsAreLoading = false
      })
    }
    return swr.data || []
  }

  get calendars(): Calendar[] {
    if (this.selectedCalendars.length <= 0) {
      return this.allCalendars
    }
    let selected: Calendar[] = []
    this.selectedCalendars.forEach((originalId) => {
      const cal: Calendar | undefined = this.allCalendars.find((c) => c.originalId && c.originalId == originalId)
      if (cal !== undefined) selected.push(cal)
    })
    if (selected.length <= 0 && this.localCalendarSettings) {
      this.localCalendarSettings.calendars = [] //empty list means all
      SettingsUtil.setDashboardCalendarSettings(this.localCalendarSettings)
      return this.allCalendars
    }
    return selected
  }

  get eventCount(): number {
    return this.allItems?.length || 0
  }

  get userEmail(): string | null {
    return rpcClient.session?.user?.email || null
  }

  get allItems(): { id: string, desc: string, date: string, start: string, end: string, duration: string, begin: number,calendarName: string, event: CalendarEvent }[] {
    const allEvents: { id: string, desc: string, date: string, start: string, end: string, duration: string, begin: number,calendarName: string, event: CalendarEvent }[] = []
    let promises: Promise<any>[] = []
    for (const calendar of this.calendars) {
      const calendarId = calendar.originalId
      if (!calendarId) continue
      try {
        const swr = eventServiceApi.getEvents(calendarId, this.start, this.end, false)
        if (swr.call?.loading && swr.call?.promise) {
          this.eventsAreLoading = true
          promises.push(swr.call.promise)
        }
        const events: CalendarEvent[] = swr.data ? [...swr.data] : []
        const calendarSettings: CalendarSettings | null | undefined = SettingsUtil.getDashboardSettings()?.calendar
        const stamp: Date = new Date()
        if (calendarSettings) {
          events.forEach((ce: CalendarEvent) => {
            if (ce.summary && ce.start && ce.status !== 'CANCELLED') {
              let include: boolean = false
              if (calendarSettings.showAll) {
                include = true
              } else if (calendarSettings.showIfOrganizer && ce.organizer?.email === this.userEmail) {
                include = true
              } else if (calendarSettings.showIfAccepted || calendarSettings.showIfInvited) {
                const attendee: Attendee | undefined = ce.attendees?.find(a => a.status && a.email === this.userEmail)
                if (attendee && calendarSettings.showIfInvited && ![ 'DECLINED', 'DELEGATED' ].includes(attendee.status || '')) {
                  include = true
                } else if (attendee && [ 'ACCEPTED', 'TENTATIVE' ].includes(attendee.status || '')) {
                  include = true
                }
              }
              if (include) {
                if (!ce.end) {
                  ce.end = new Date(Date.parse(ce.start) + DateTimeUtil.getDurationInMillis(ce)).toISOString()
                }
                if (stamp < new Date(ce.end)) {
                  const newElem = {
                    id: ce.originalId || "",
                    desc: ce.summary,
                    date: dayjs(ce.start).format("DD.MM.YYYY"),
                    start: dayjs(ce.start).format("HH:mm"),
                    begin: dayjs(ce.start).unix(),
                    end: dayjs(ce.end).format("HH:mm"),
                    duration: this.getDurationString(ce),
                    calendarName: (calendar?.name) ? calendar.name : "",
                    event: ce
                  }
                  allEvents.push(newElem)
                }
              }
            }
          })
        }
      } catch (error) {}
    }
    if (promises.length > 0) {
      Promise.all(promises).finally(() => {
        this.eventsAreLoading = false
      })
    }
    return allEvents.sort(({begin:a}, {begin:b}) => a-b)
  }

  getDurationString(event: CalendarEvent): string {
    const millis = DateTimeUtil.getDurationInMillis(event)
    const seconds = Math.floor(millis / 1000)
    const hours =  Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - (hours * 3600)) / 60)
    if (hours === 0) {
      return minutes + " " + this.i18n.$gettext("Minutes")
    } else {
      return `${hours}:${minutes}`
    }
  }
}
