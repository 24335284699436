export default class TaskSettings {
  days!: number | null;
  showAll!: boolean | null;
  showIfOrganizer!: boolean | null;
  showIfMember!: boolean | null;
  showIfAssignee!: boolean | null;
  showIfStakeholder!: boolean | null;
  filterTaskBoards!: boolean | null;
  taskBoards!: string[] | null;

  constructor() {
    this.days = null;
    this.showAll = null;
    this.showIfOrganizer = null;
    this.showIfMember = null;
    this.showIfAssignee = null;
    this.showIfStakeholder = null;
    this.filterTaskBoards = null;
    this.taskBoards = null;
  }
}
