export default class Organizer {
  uri!: string | null;
  email!: string | null;
  name!: string | null;
  language!: string | null;
  directoryEntry!: string | null;

  constructor() {
    this.uri = null;
    this.email = null;
    this.name = null;
    this.language = null;
    this.directoryEntry = null;
  }
}
