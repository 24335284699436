export default class Organization {
  titles!: string[] | null;
  type!: string | null;
  sortAs!: string[] | null;
  preference!: number | null;
  language!: string | null;
  alternativeId!: string | null;

  constructor() {
    this.titles = null;
    this.type = null;
    this.sortAs = null;
    this.preference = null;
    this.language = null;
    this.alternativeId = null;
  }
}
