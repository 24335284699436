import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import UploadLink from '@/model/common/UploadLink';


export default class GeneratedUploadLinkStore {

    state: {
        uploadLinks: Map<string, UploadLink>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let uploadLinks: Map<string, UploadLink> | null = null
        try {
            const persistedState = localStorage.getItem('UploadLinkStore')
            if (persistedState) {
                uploadLinks = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            uploadLinks: uploadLinks || new Map<string, UploadLink>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('UploadLinkStore', JSON.stringify(Array.from(this.state.uploadLinks)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.uploadLinks) this.state.uploadLinks.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('UploadLinkStore')
    }

    addOrReplaceUploadLink(newUploadLink: UploadLink, persist: boolean = true): true | undefined {
        if (newUploadLink.token) {
            this.state.uploadLinks.set(newUploadLink.token, newUploadLink)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceUploadLinks(newUploadLinks: UploadLink[]) {
        let persist = false
        for (const newUploadLink of newUploadLinks) {
            const changed = !!this.addOrReplaceUploadLink(newUploadLink, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceUploadLinks(uploadLinks: UploadLink[]) {
        const newUploadLinks = new Map<string, UploadLink>()
        for (const newUploadLink of uploadLinks) {
            if (newUploadLink.token) {
                newUploadLinks.set(newUploadLink.token, newUploadLink)
                
            }
        }
        this.state.uploadLinks = newUploadLinks
        this.persistState()
    }

    removeUploadLink(token: string | undefined | null, persist: boolean = true): true | undefined {
        if (token !== undefined && token !== null) {
            if (this.state.uploadLinks.delete(token)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeUploadLinks(tokens: (string | null)[] | undefined | null) {
        let persist = false
        if (tokens !== undefined && tokens !== null) {
            for (const token of tokens) {
                const changed = !!this.removeUploadLink(token, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}