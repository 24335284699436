<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-md p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Import Data
          </translate>
        </h2>
        <p>
          <b>
            <translate>What you can do here:</translate>
          </b>&nbsp;
          <translate>
            Import data from other services. This is a two-step process. First you define the sources of your data. In the second step you can import data from these sources into ELLY.
          </translate>
        </p>
        <div class="d-flex align-items-center mb-4">
          <Checkbox v-model="showWidget" binary inputId="showWidget" />
          <label for="showWidget" class="ml-2"><translate>Show Data Import Widget on Start Page</translate></label>
        </div>
        <!-- Data Sources -->
        <div class="row justify-content-between">
          <div class="col-auto">
            <h3><translate>Data Sources</translate></h3>
          </div>
          <div class="col-auto">
            <Button
              v-tooltip="i18n.$gettext('Neu')"
              icon="cil-plus"
              class="p-button p-button-primary mr-4"
              @click="gotToNewSourcePage"
            />
          </div>
        </div>
        <div class="row">
          <div v-if="setupSourcesLoading" class="col-12">
            <Skeleton class="mt-6" height="40px" style="width: 100%" />
          </div>
          <div v-else-if="setupSources.length > 0" class="col-12">
            <div class="row">
              <div v-for="source in setupSources" :key="source.id" class="col-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <p class="lead mb-0">
                          <span v-html="source.displayName" />
                        </p>
                        <p class="text-muted mb-0">
                          {{ (getDataSourceForInstance(source)?.displayName || i18n.$gettext("Unknown type")) }}, <translate>Created at:</translate> {{ formatCreatedDate(source.created) }}
                        </p>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <Button
                          v-tooltip="i18n.$gettext('Delete')"
                          icon="cil-trash"
                          class="p-button p-button-danger"
                          @click="confirmSourceInstanceDeletion(source)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-12">
            <p class="w-100 text-center text-muted p-4 mb-4">
              <translate>No data sources configured. Click above to configure one.</translate>
            </p>
          </div>
        </div>
        <!-- Data Jobs -->
        <div class="row justify-content-between">
          <div class="col-auto">
            <h3><translate>Data Import Jobs</translate></h3>
          </div>
          <div class="col-auto">
            <Button
              v-tooltip="i18n.$gettext('Neu')"
              icon="cil-plus"
              class="p-button p-button-primary mr-4"
              @click="showWizard = true"
            />
          </div>
        </div>
        <div class="row">
          <div v-if="currentJobsLoading" class="col-12">
            <Skeleton class="mt-6" height="40px" style="width: 100%" />
          </div>
          <div v-else-if="currentJobs.length > 0" class="col-12">
            <div class="row">
              <div v-for="job in currentJobs" :key="job.id" class="col-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <DataImportJobListItem :job="job" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-12">
            <p class="w-100 text-center text-muted p-4">
              <translate>No data jobs started. Click above to start one.</translate>
            </p>
          </div>
        </div>
      </div>
    </div>
    <DataImportWizard v-model="showWizard" :style="{width: '80vw', height: '80vh'}" />
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast, {ToastAPI} from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Button from "primevue/button"
import DataSource from "@/model/DataSource"
import DataSourceInstance from "@/model/DataSourceInstance"
import DataImportJob from "@/model/DataImportJob"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import Skeleton from "primevue/skeleton"
import {Router, useRouter} from "vue-router"
import dayjs from "@/util/dayjs"
import RpcError from "@/api/RpcError"
import ScrollPanel from "primevue/scrollpanel"
import Dialog from "primevue/dialog"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import Checkbox from "primevue/checkbox"
import SettingsUtil from "@/util/SettingsUtil"
import DataImportWizard from "@/components/settings/dataimportwizard/DataImportWizard.vue"
import DataImportJobListItem from "@/components/settings/dataimport/DataImportJobListItem.vue"

@Options({
  components: { Button, Skeleton, ScrollPanel, Dialog, Checkbox, DataImportWizard, DataImportJobListItem },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DataImport extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()
  router: Router = useRouter()

  availableSources: DataSource[] = []
  setupSources: DataSourceInstance[] = []
  setupSourcesLoading: boolean = false
  currentJobs: DataImportJob[] = []
  currentJobsLoading: boolean = false
  logIntervalHandle: number = 0
  showWizard: boolean = false

  get showWidget() {
    return SettingsUtil.showDataImportWidget()
  }

  set showWidget(show: boolean) {
    SettingsUtil.setShowDataImportWidget(show)
  }

  gotToNewSourcePage() {
    void this.router.push("/settings/import/newsource")
  }

  async loadData() {
    try {
      this.setupSourcesLoading = true
      this.availableSources = await dataSourceServiceApi._getAvailableDataSources()
      this.setupSources = await dataSourceServiceApi._getDataSourceInstances()
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not load data sources"))
    } finally {
      this.setupSourcesLoading = false
    }

    try {
      this.currentJobsLoading = true
      this.currentJobs = await dataImportServiceApi._getDataImportJobs()
      if (this.logIntervalHandle === 0) {
        //reload this every x:
        this.logIntervalHandle = window.setInterval(() => {
          void this.fetchJobsInBackground()
        }, 5000)
      }
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not load data import jobs"))
    } finally {
      this.currentJobsLoading = false
    }
  }

  getDataSourceForInstance(instance: DataSourceInstance): DataSource | null {
    let result: DataSource | null = null
    this.availableSources.forEach((source: DataSource) => {
      if (source.name == instance.datasourceName) {
        result = source
      }
    })
    return result
  }

  async fetchJobsInBackground() {
    try {
      this.currentJobs = await dataImportServiceApi._getDataImportJobs()
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Getting jobs failed"))
    }
  }

  formatCreatedDate(date: Date | null): string {
    if (date) {
      return dayjs(date).format("HH:mm DD.MM.YYYY")
    } else {
      return this.i18n.$gettext("Unknown creation date")
    }

  }

  confirmSourceInstanceDeletion(instance: DataSourceInstance) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this data source?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (instance) {
          dataSourceServiceApi._deleteDataSourceInstance(instance).then(() => {
            this.toast.success(this.i18n.$gettext("Data source deleted"))
            void this.loadData()
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Data source could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  mounted() {
    void this.loadData()
  }

  unmounted() {
    if (this.logIntervalHandle !== 0) {
      window.clearInterval(this.logIntervalHandle)
    }
  }

}
</script>

<style lang="scss" scoped>

.p-scrollpanel-content {

}

</style>
