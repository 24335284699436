import Signature from '@/model/settings/Signature';
import MailFilter from '@/model/settings/MailFilter';
import OutOfOffice from '@/model/settings/OutOfOffice';
import ProjectCollectionOrder from '@/model/settings/ProjectCollectionOrder';
import DashboardSettings from '@/model/settings/DashboardSettings';

export default class UserSettings {
  signatures!: Signature[] | null;
  mailFilters!: MailFilter[] | null;
  outOfOffices!: OutOfOffice[] | null;
  loadImagesBySender!: string[] | null;
  projectCollections!: ProjectCollectionOrder | null;
  lastSetCalendarView!: string | null;
  helpCenterSeen!: boolean | null;
  introVideoSeen!: boolean | null;
  hideDataImportWidget!: boolean | null;
  acknowledgedMigrationModal!: boolean | null;
  hideNoBackupJobsWarning!: boolean | null;
  disableLoadImagesDialog!: boolean | null;
  dashboardSettings!: DashboardSettings | null;

  constructor() {
    this.signatures = null;
    this.mailFilters = null;
    this.outOfOffices = null;
    this.loadImagesBySender = null;
    this.projectCollections = null;
    this.lastSetCalendarView = null;
    this.helpCenterSeen = null;
    this.introVideoSeen = null;
    this.hideDataImportWidget = null;
    this.acknowledgedMigrationModal = null;
    this.hideNoBackupJobsWarning = null;
    this.disableLoadImagesDialog = null;
    this.dashboardSettings = null;
  }
}
