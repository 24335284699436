

import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import {ref} from "@vue/reactivity"

@Options({
  components: { Dialog, Button },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DemoNaggingPopup extends Vue {

  showDialog: boolean = false
  link: string = ""

  i18n: Language = useGettext()


  //@ts-ignore
  formContent: HTMLDivElement =  ref<HTMLDivElement | null>(null);
  form: HTMLFormElement | null = null

  show(link: string){

    /*
    //@ts-ignore
    window.invokeCaptchaAndSendForm = () => {
      this.invokeCaptchaAndSendForm(...arguments)
    }
    //Fetch HTML, query for form container and append it to container:
    axios.get('https://ellyserver.de/contact_modal.html').then((res: AxiosResponse) => {
      const html: string = res.data as string
      const off = document.createElement("div")
      off.insertAdjacentHTML("afterbegin", html)
      const form = off.querySelector("#contactForm") as HTMLFormElement
      if(form){
        const place = this.formContent
        place.innerHTML = ''
        place.appendChild(form)
        this.form = form
      }
      document.removeChild(off)
    }).catch((e) => {
      console.warn("Could not fetch form data", e)
    }) */

    if(!this.showDialog){
      this.showDialog = true
      this.link = link


      this.$nextTick(() => {
        console.log("Running assets script for contactform")
        //@ts-ignore
        window.waitForJQueryThenAppendContactForms()
      })
    }

  }

  /*

  invokeCaptchaAndSendForm(e: Event | null = null){
    if(e){
      e.stopPropagation()
      e.preventDefault()
    }

    let formId = this.form?.id.substring(12)
    let phoneField = this.form?.querySelector('#phoneFieldRequired-' + formId) as HTMLInputElement
    let errorText =this.form?.querySelector('#phoneError-' + formId) as HTMLDivElement;
    const phoneRegex = /^([0-9\ \+\_\-\#\(\)]){1,20}$/

    if (!phoneField || (this.form?.querySelector('#phoneField') as HTMLInputElement | null)?.value.match(phoneRegex)) {
      if (errorText) {
        errorText.style.display = 'none';
      }
      if(this.form){
        (this.form.querySelector('#formSubmitButton-' + formId) as HTMLButtonElement).disabled = true
      }

      //@ts-ignore
      window.submittedForm = this.form?.target

      try {
        //@ts-ignore
        window.grecaptcha.render('captchaContainer-' + formId, {
          'sitekey': '6LdCLL0UAAAAADodQl2IEQfOsWxFX8sCMAKUYxNT',
          'callback': this.submitForm,
          'size': 'invisible',
        });

        //@ts-ignore
        grecaptcha.execute();
      } catch (e) {
        //@ts-ignore
        this.submitForm();
        setTimeout( () => {
          if(this.form){
            (this.form.querySelector('#formSubmitButton-' + formId) as HTMLButtonElement).disabled = true
          }
        }, 3000);
      }
    } else if (errorText) {
      errorText.style.display = 'block';
    }

  }

  submitForm(token: string | null = null) {
    return new Promise((resolve, reject) => {
      let formId = this.form?.id.substring(12);
      if (this.form) {
        this.form.onsubmit = function () {
          return true;
        };
      }

      this.form?.submit()

      setTimeout(() => {
        if (this.form) {
          (this.form.querySelector('#formSubmitButton-' + formId) as HTMLButtonElement).disabled = false
        }
      }, 3000)
      resolve();
    });
  }

  mounted(){

  }

  unmounted(){
    //@ts-ignore
    window.invokeCaptchaAndSendForm = () => {
      console.warn("Contactform Callback called without Modal Instance")
    }
  } */
}
