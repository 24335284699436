import {reactive} from "@vue/reactivity"

class AdminpanelHolder {
    integrateAdminpanel = true
    watchers: ((state: any) => any)[] = []
    instance: {
        el: HTMLElement,
        vue: any,
        router: any,
        store: any
    } | null = null
}

const adminpanelHolder = reactive(new AdminpanelHolder())
//@ts-ignore
window["adminpanelholder"] = adminpanelHolder

export default adminpanelHolder
