import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import VirtualMailbox from '@/model/settings/VirtualMailbox';


export default class GeneratedVirtualMailboxStore {

    state: {
        virtualMailboxs: Map<string, VirtualMailbox>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let virtualMailboxs: Map<string, VirtualMailbox> | null = null
        try {
            const persistedState = localStorage.getItem('VirtualMailboxStore')
            if (persistedState) {
                virtualMailboxs = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            virtualMailboxs: virtualMailboxs || new Map<string, VirtualMailbox>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('VirtualMailboxStore', JSON.stringify(Array.from(this.state.virtualMailboxs)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.virtualMailboxs) this.state.virtualMailboxs.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('VirtualMailboxStore')
    }

    addOrReplaceVirtualMailbox(newVirtualMailbox: VirtualMailbox, persist: boolean = true): true | undefined {
        if (newVirtualMailbox.address) {
            this.state.virtualMailboxs.set(newVirtualMailbox.address, newVirtualMailbox)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceVirtualMailboxs(newVirtualMailboxs: VirtualMailbox[]) {
        let persist = false
        for (const newVirtualMailbox of newVirtualMailboxs) {
            const changed = !!this.addOrReplaceVirtualMailbox(newVirtualMailbox, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceVirtualMailboxs(virtualMailboxs: VirtualMailbox[]) {
        const newVirtualMailboxs = new Map<string, VirtualMailbox>()
        for (const newVirtualMailbox of virtualMailboxs) {
            if (newVirtualMailbox.address) {
                newVirtualMailboxs.set(newVirtualMailbox.address, newVirtualMailbox)
                
            }
        }
        this.state.virtualMailboxs = newVirtualMailboxs
        this.persistState()
    }

    removeVirtualMailbox(address: string | undefined | null, persist: boolean = true): true | undefined {
        if (address !== undefined && address !== null) {
            if (this.state.virtualMailboxs.delete(address)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeVirtualMailboxs(addresss: (string | null)[] | undefined | null) {
        let persist = false
        if (addresss !== undefined && addresss !== null) {
            for (const address of addresss) {
                const changed = !!this.removeVirtualMailbox(address, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}