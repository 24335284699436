export enum ClipboardAction {
    COPY,
    CUT
}

export class ClipboardEntry {
    public path: string
    public name: string
    public action: ClipboardAction

    constructor(path: string, name: string, action: ClipboardAction) {
        this.path = path
        this.name = name
        this.action = action
    }
}
