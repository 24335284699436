import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import DataSourceInstance from '@/model/DataSourceInstance';
import Page from '@/model/Page';
import JsUiTreeModel from '@/model/JsUiTreeModel';
import DataSource from '@/model/DataSource';
import { dataSourceInstanceStore } from '@/store/DataSourceInstanceStore';

export default class GeneratedDataSourceServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getDirectoryTreeOfSourceInstance(id: number, path: string): Promise<JsUiTreeModel[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDirectoryTreeOfSourceInstance', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new JsUiTreeModel(), it)) : Promise.reject()
        })
    }

    _getTargetDirectoryTreeOfSourceInstance(id: number, userName: string | null, path: string, scopes: string[] | null): Promise<JsUiTreeModel[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getTargetDirectoryTreeOfSourceInstance', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new JsUiTreeModel(), it)) : Promise.reject()
        })
    }

    _addTargetDirectoryForSourceInstance(id: number, userName: string | null, name: string, scope: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addTargetDirectoryForSourceInstance', rpcParams, false) as Promise<void>
    }

    _getAvailableDataSources(): Promise<DataSource[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getAvailableDataSources', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new DataSource(), it)) : Promise.reject()
        })
    }

    _createDataSourceInstance(dataSourceInstance: DataSourceInstance): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createDataSourceInstance', rpcParams, false).then((data: any) => {
            const model = Object.assign(new DataSourceInstance(), data)
            dataSourceInstanceStore.addOrReplaceDataSourceInstance(model)
            return model.id
        })
    }

    _getDataSourceInstances(): Promise<DataSourceInstance[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDataSourceInstances', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const dataSourceInstances: DataSourceInstance[] = data.map(dataSourceInstance => Object.assign(new DataSourceInstance(), dataSourceInstance))
                dataSourceInstanceStore.replaceDataSourceInstances(dataSourceInstances)
                return dataSourceInstances
            } else return Promise.reject()
        })
    }


    _getDataSourceInstance(id: number): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDataSourceInstance', rpcParams, false).then((data: any) => {
            const model = Object.assign(new DataSourceInstance(), data)
            dataSourceInstanceStore.addOrReplaceDataSourceInstance(model)
            return model.id
        })
    }

    _deleteDataSourceInstance(dataSourceInstance: DataSourceInstance): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteDataSourceInstance', rpcParams, false).then(() => {
            dataSourceInstanceStore.removeDataSourceInstance(dataSourceInstance.id)
        })
    }

    getDataSourceInstances(refresh?: boolean | number): SWR<DataSourceInstance[], number[]> {
        //@ts-ignore
        const result: SWR<DataSourceInstance[], number[]> = reactive(new SWR<DataSourceInstance[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getDataSourceInstances' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getDataSourceInstances[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const dataSourceInstances: DataSourceInstance[] = []
                for (const id of data) {
                    const dataSourceInstance: DataSourceInstance | undefined = dataSourceInstanceStore.state.dataSourceInstances.get(id)
                    if (dataSourceInstance) {
                        dataSourceInstances.push(dataSourceInstance)
                    }
                }
                result.data = dataSourceInstances
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getDataSourceInstances().then((data: DataSourceInstance[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(dataSourceInstance => dataSourceInstance.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let dataSourceInstances: DataSourceInstance[] = [...dataSourceInstanceStore.state.dataSourceInstances.values()]
        
        
        
        result.data = dataSourceInstances
        return result
    }

    getDataSourceInstance(id: number, refresh?: boolean | number): SWR<DataSourceInstance | null, number> {
        //@ts-ignore
        const result: SWR<DataSourceInstance | null, number> = reactive(new SWR<DataSourceInstance | null, number>())
        const callId: string = '_getDataSourceInstance' + JSON.stringify(id)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = dataSourceInstanceStore.state.dataSourceInstances.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getDataSourceInstance(id).then((id: number) => {
                call.data = id
                result.data = dataSourceInstanceStore.state.dataSourceInstances.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = dataSourceInstanceStore.state.dataSourceInstances.get(id) || null
        return result
    }

}
