import Permission from '@/model/entry/Permission';
import MailFilter from '@/model/settings/MailFilter';
import OutOfOffice from '@/model/settings/OutOfOffice';
import Signature from '@/model/settings/Signature';

export default class VirtualMailbox {
  address!: string | null;
  active!: boolean | null;
  accessDescriptions!: string[] | null;
  permissions!: Permission[] | null;
  mailFilters!: MailFilter[] | null;
  oooFilters!: OutOfOffice[] | null;
  signatures!: Signature[] | null;
  addedPassword!: string | null;

  constructor() {
    this.address = null;
    this.active = null;
    this.accessDescriptions = null;
    this.permissions = null;
    this.mailFilters = null;
    this.oooFilters = null;
    this.signatures = null;
    this.addedPassword = null;
  }
}
