import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Project from '@/model/directory/Project';
import Page from '@/model/Page';
import { projectStore } from '@/store/ProjectStore';

export default class GeneratedProjectServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getProjects(): Promise<Project[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getProjects', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const projects: Project[] = data.map(project => Object.assign(new Project(), project))
                projectStore.replaceProjects(projects)
                return projects
            } else return Promise.reject()
        })
    }


    getProjects(refresh?: boolean | number, sortBy?: string[] | string): SWR<Project[], string[]> {
        //@ts-ignore
        const result: SWR<Project[], string[]> = reactive(new SWR<Project[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getProjects' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getProjects[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const projects: Project[] = []
                for (const id of data) {
                    const project: Project | undefined = projectStore.state.projects.get(id)
                    if (project) {
                        projects.push(project)
                    }
                }
                result.data = projects
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getProjects().then((data: Project[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(project => project.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let projects: Project[] = [...projectStore.state.projects.values()]
        
        SortAndFilterUtil.sort(projects, sortBy)
        
        result.data = projects
        return result
    }

    getProjectsFilterByCompanyName(companyName: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<Project[], string[]> {
        //@ts-ignore
        const result: SWR<Project[], string[]> = reactive(new SWR<Project[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getProjects' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getProjects[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const projects: Project[] = []
                for (const id of data) {
                    const project: Project | undefined = projectStore.state.projects.get(id)
                    if (project) {
                        projects.push(project)
                    }
                }
                result.data = projects
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getProjects().then((data: Project[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(project => project.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let projects: Project[] = [...projectStore.state.projects.values()]
        projects = SortAndFilterUtil.filter(projects, { companyName: companyName })
        SortAndFilterUtil.sort(projects, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            projects = projects.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = projects
        return result
    }

    getProjectsFilterByDeleted(deleted: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<Project[], string[]> {
        //@ts-ignore
        const result: SWR<Project[], string[]> = reactive(new SWR<Project[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getProjects' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getProjects[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const projects: Project[] = []
                for (const id of data) {
                    const project: Project | undefined = projectStore.state.projects.get(id)
                    if (project) {
                        projects.push(project)
                    }
                }
                result.data = projects
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getProjects().then((data: Project[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(project => project.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let projects: Project[] = [...projectStore.state.projects.values()]
        projects = SortAndFilterUtil.filter(projects, { deleted: deleted })
        SortAndFilterUtil.sort(projects, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            projects = projects.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = projects
        return result
    }

    getProject(id: string): Project | undefined {
        return projectStore.state.projects.get(id)
    }
}
