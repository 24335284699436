import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import MailAlias from '@/model/settings/MailAlias';


export default class GeneratedMailAliasStore {

    state: {
        mailAliass: Map<string, MailAlias>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let mailAliass: Map<string, MailAlias> | null = null
        try {
            const persistedState = localStorage.getItem('MailAliasStore')
            if (persistedState) {
                mailAliass = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            mailAliass: mailAliass || new Map<string, MailAlias>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('MailAliasStore', JSON.stringify(Array.from(this.state.mailAliass)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.mailAliass) this.state.mailAliass.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('MailAliasStore')
    }

    addOrReplaceMailAlias(newMailAlias: MailAlias, persist: boolean = true): true | undefined {
        if (newMailAlias.name) {
            this.state.mailAliass.set(newMailAlias.name, newMailAlias)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceMailAliass(newMailAliass: MailAlias[]) {
        let persist = false
        for (const newMailAlias of newMailAliass) {
            const changed = !!this.addOrReplaceMailAlias(newMailAlias, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceMailAliass(mailAliass: MailAlias[]) {
        const newMailAliass = new Map<string, MailAlias>()
        for (const newMailAlias of mailAliass) {
            if (newMailAlias.name) {
                newMailAliass.set(newMailAlias.name, newMailAlias)
                
            }
        }
        this.state.mailAliass = newMailAliass
        this.persistState()
    }

    removeMailAlias(name: string | undefined | null, persist: boolean = true): true | undefined {
        if (name !== undefined && name !== null) {
            if (this.state.mailAliass.delete(name)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeMailAliass(names: (string | null)[] | undefined | null) {
        let persist = false
        if (names !== undefined && names !== null) {
            for (const name of names) {
                const changed = !!this.removeMailAlias(name, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}