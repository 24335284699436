<template>
  <div class="message-editor p-inputtext p-0">
    <editor-content class="px-3 pt-2 pb-0" :editor="editor" />
    <div v-if="editor" class="d-flex flex-row justify-content-between">
      <div class="flex-shrink-1 compose-buttons-format">
        <Button
          icon="cil-bold"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        />
        <Button
          icon="cil-italic"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        />
        <!--<Button
          icon="cil-underline"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
        />-->
        <Button
          icon="cil-strikethrough"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
        />
        <Button
          icon="cil-list"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
        />
        <Button
          icon="cil-list-numbered"
          class="p-button-text p-button-secondary"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from "@tiptap/extension-typography"

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {Mention} from "@tiptap/extension-mention"
import {Placeholder} from "@tiptap/extension-placeholder"
import {Underline} from "@tiptap/extension-underline"
//@ts-ignore
import suggestion from "./suggestion"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import turndownService from "@/util/Turndown"

@Options({
  components: { EditorContent, Button },
  //@ts-ignore
  props: {
    canUseMentions: Boolean
  },
  emits: ['enter']
})
export default class ChatMessageComposer extends Vue {

  editor: Editor | null = null

  i18n: Language = useGettext()

  canUseMentions!: boolean

  mounted() {
    const extensions: any[] = [
      StarterKit,
      Underline,
      Typography,
      Highlight,
      Placeholder.configure({
        placeholder: this.i18n.$gettext("Type a message and press Enter to send."),
      }),
    ]

    if (this.canUseMentions) {
      extensions.push(Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        }, suggestion
      }))
    }
    this.editor = new Editor({
      content: '',
      extensions: extensions,
      editorProps: {
        attributes: {
          class: 'custom-editor p-2',
        },
        handleDOMEvents: {
          //Must be added here, because adding @keydown to editor-content handles the event too late,
          //after a newline has already been inserted in the editor
          keydown: (view, event) => {
            if (this.editor &&
              event.which == 13 && !event.shiftKey &&
              !this.editor.isActive('bulletList') &&
              !this.editor.isActive('orderedList') &&
              !this.editor.isActive('codeBlock')) {
              event.preventDefault() //Prevent newline from being inserted
              this.$emit("enter")
              return true
            } else {
              return false
            }
          }
        }
      }
    })
  }

  getHTML(): string | undefined {
    return this.editor?.getHTML()
  }

  insertContent(content: string | undefined) {
    if (content && this.editor) this.editor.chain().focus().insertContent(content).run()
  }

  setContent(content: string | undefined) {
    if (content && this.editor) {
      this.editor.chain().focus().setContent(content).run()
    }
  }

  //we do not use reactivity here since we'd have to run turndown everytime the user types. Its more efficient this way
  //Also: This isolates the message and provides a real draft that does not affect the state
  getMarkdownMessage(): string {
    const html: string | undefined = this.getHTML()
    if (html) {
      console.log(html)
      const md: string | undefined = turndownService.turndown(html)
      console.log(md)
      return md ? md.trim() : ""
    } else {
      return ""
    }
  }

  addEmojiToEditor(emoji: string): void {
    if (this.editor) this.editor.chain().focus().insertContent(emoji).run()
  }

  clearMessage(): void {
    if (this.editor) this.editor.commands.clearContent()
  }

  beforeUnmount() {
    this.editor?.destroy()
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/elly-bs4/sass/variables";


$button_hover_color_editor: $uniki_primary_dark;
.p-button.is-active {
  color: $button_hover_color_editor;

  &:hover {
    color: $button_hover_color_editor !important;
  }
}

@media screen and (max-width:767px){
  .compose-buttons-format {
    display: none;
  }
}

</style>
