import { Node, mergeAttributes, wrappingInputRule } from '@tiptap/core'

export interface TaskItemOptions {
    nested: boolean,
    HTMLAttributes: Record<string, any>,
}

export const inputRegex = /^\s*(\[([ |x])\])\s$/

export const TaskItem = Node.create<TaskItemOptions>({
    name: 'taskItem',

    addOptions() {
        return {
            nested: false,
            HTMLAttributes: {},
        }
    },

    content() {
        return this.options.nested ? 'paragraph block*' : 'paragraph+'
    },

    defining: true,

    addAttributes() {
        return {
            checked: {
                default: false,
                keepOnSplit: false,
                parseHTML: element => element.getAttribute('data-checked') === 'true',
                renderHTML: attributes => ({
                    'data-checked': attributes.checked,
                })
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: `li[data-checked]`,
                priority: 51
            }
        ]
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            'li',
            mergeAttributes(
                this.options.HTMLAttributes,
                HTMLAttributes
            ),
            [
                'div',
                {
                    'class': 'p-field-checkbox'
                },
                [
                    'div',
                    {
                        'class': 'p-checkbox p-component' + (node.attrs.checked ? ' p-checkbox-checked' : '')
                    },
                    [
                        'div',
                        {
                            'class': 'hidden-accessible'
                        },
                        [
                            'input',
                            {
                                type: 'checkbox',
                                checked: node.attrs.checked
                                    ? 'checked'
                                    : null,
                            },
                        ]
                    ],
                    [
                        'div',
                        {
                            role: 'checkbox',
                            'aria-checked': node.attrs.checked ? 'true' : 'false',
                            'class': 'p-checkbox-box'
                        },
                        [
                            'span',
                            {
                                'class': 'p-checkbox-icon' + (node.attrs.checked ? ' pi pi-check' : '')
                            }
                        ]
                    ]
                ],
                [
                    'div',
                    0,
                ]
            ]
        ]
    },

    addKeyboardShortcuts() {
        const shortcuts = {
            Enter: () => this.editor.commands.splitListItem(this.name),
            'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
        }

        if (!this.options.nested) {
            return shortcuts
        }

        return {
            ...shortcuts,
            Tab: () => this.editor.commands.sinkListItem(this.name),
        }
    },

    addNodeView() {
        return ({
                    node,
                    HTMLAttributes,
                    getPos,
                    editor,
                }) => {
            const listItem = document.createElement('li')
            const wrapper = document.createElement('div')
            const checkboxWrapper = document.createElement('div')
            const checkboxHider = document.createElement('div')
            const checkboxStyler = document.createElement('div')
            const fakeCheckbox = document.createElement('span')
            const checkbox = document.createElement('input')
            const content = document.createElement('div')

            wrapper.classList.add('p-field-checkbox')
            checkboxWrapper.classList.add('p-checkbox', 'p-component', 'mr-2')
            checkboxHider.classList.add('p-hidden-accessible')
            checkboxStyler.classList.add('p-checkbox-box')
            checkboxStyler.setAttribute('role', 'checkbox')
            fakeCheckbox.classList.add('p-checkbox-icon')

            checkboxWrapper.contentEditable = 'false'
            checkbox.type = 'checkbox'
            checkboxStyler.addEventListener('click', () => {
                checkbox.click()
            })
            checkbox.addEventListener('change', event => {
                // We allow toggling task items when !isEditable

                const { checked } = event.target as any

                if (typeof getPos === 'function') {
                    editor
                        .chain()
                        .focus(undefined)
                        .command(({ tr }) => {
                            tr.setNodeMarkup(getPos(), undefined, {
                                checked,
                            })

                            return true
                        })
                        .run()
                }
            })

            Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
                listItem.setAttribute(key, value)
            })

            listItem.dataset.checked = node.attrs.checked
            if (node.attrs.checked) {
                checkboxWrapper.classList.add('p-checkbox-checked')
                checkbox.setAttribute('checked', 'checked')
                checkboxStyler.classList.add('p-highlight')
                fakeCheckbox.classList.add('pi', 'pi-check')
                checkboxStyler.setAttribute('aria-checked', 'true')
            } else {
                checkboxStyler.setAttribute('aria-checked', 'false')
            }

            checkboxHider.append(checkbox)
            checkboxStyler.append(fakeCheckbox)
            checkboxWrapper.append(checkboxHider, checkboxStyler)
            wrapper.append(checkboxWrapper, content)
            listItem.append(wrapper)

            Object
                .entries(HTMLAttributes)
                .forEach(([key, value]) => {
                    listItem.setAttribute(key, value)
                })

            return {
                dom: listItem,
                contentDOM: content,
                update: updatedNode => {
                    if (updatedNode.type !== this.type) {
                        return false
                    }

                    listItem.dataset.checked = updatedNode.attrs.checked
                    if (updatedNode.attrs.checked) {
                        checkboxWrapper.classList.add('p-checkbox-checked')
                        checkboxStyler.classList.add('p-highlight')
                        fakeCheckbox.classList.add('pi', 'pi-check')
                        checkboxStyler.setAttribute('aria-checked', 'true')
                        checkbox.setAttribute('checked', 'checked')
                    } else {
                        checkboxWrapper.classList.remove('p-checkbox-checked')
                        checkboxStyler.classList.remove('p-highlight')
                        fakeCheckbox.classList.remove('pi', 'pi-check')
                        checkboxStyler.setAttribute('aria-checked', 'false')
                        checkbox.removeAttribute('checked')
                    }

                    return true
                },
            }
        }
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: match => ({
                    checked: match[match.length - 1] === 'x',
                }),
            }),
        ]
    },
})
