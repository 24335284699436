<template>
  <router-link :to="setting.linkTo">
    <div class="p-5 d-flex mb-2 card flex-row separator-bottom">
      <h4 class="mb-0">
        <i :class="setting.icon" />
        <span>&emsp;{{ setting.name }}</span>
      </h4>
    </div>
  </router-link>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"

@Options({
  components: {},
  //@ts-ignore
  props: {
    setting: Object
  },
  emits: []
})
export default class SettingsListItem extends Vue {

  setting!: {
    name: string,
    icon: string,
    linkTo: string
  }

}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";



</style>
