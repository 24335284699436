
export default class JsUiTreeModel {
  key!: string | null;
  label!: string | null;
  icon!: string | null;
  scopes!: string[] | null;
  children!: JsUiTreeModel[] | null;
  selectable!: boolean | null;

  constructor() {
    this.key = null;
    this.label = null;
    this.icon = null;
    this.scopes = null;
    this.children = null;
    this.selectable = null;
  }
}
