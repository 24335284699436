export default class LastViewedCollections {
  files!: string | null;
  chatChannel!: string | null;
  mailFolder!: string | null;
  calendar!: string | null;
  taskBoard!: string | null;
  addressBook!: string | null;

  constructor() {
    this.files = null;
    this.chatChannel = null;
    this.mailFolder = null;
    this.calendar = null;
    this.taskBoard = null;
    this.addressBook = null;
  }
}
