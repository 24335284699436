<template>
  <div class="h-100 d-flex flex-column" style="min-width: 0">
    <teleport v-if="!isOnMobile" to="#topmenubar">
      <div>
        <Button
          v-tooltip.bottom="i18n.$gettext('Mark read')"
          :disabled="!selectedEmails.length && !emailId"
          class="p-button-raised mr-2"
          icon="cil-eye"
          @click="setFlag(true, null)"
        />
        <Button
          v-tooltip.bottom="i18n.$gettext('Mark unread')"
          :disabled="!selectedEmails.length && !emailId"
          class="p-button-raised mr-2"
          icon="cil-eye-slash"
          @click="setFlag(false, null)"
        />
        <Button
          v-tooltip.bottom="i18n.$gettext('Flag')"
          :disabled="!selectedEmails.length && !emailId"
          class="p-button-raised mr-2"
          icon="cil-flag"
          @click="setFlag(null, true)"
        />
        <Button
          v-tooltip.bottom="i18n.$gettext('Unflag')"
          :disabled="!selectedEmails.length && !emailId"
          class="p-button-raised mr-2 fa-stack fa-lg"
          icon="cil-flag fas fa-slash"
          @click="setFlag(null, false)"
        />
        <Button
          v-tooltip.bottom="i18n.$gettext('Move')"
          :disabled="!selectedEmails.length && !emailId"
          class="p-button-raised mr-2"
          icon="cil-folder-move"
          @click="showMoveModal = true"
        />
        <Button
          v-tooltip.bottom="i18n.$gettext('Move to trash')"
          :disabled="!selectedEmails.length && !emailId"
          class="p-button-raised mr-2"
          icon="cil-trash"
          @click="deleteEmail(null)"
        />
      </div>
    </teleport>
    <!--<div class="d-flex flex-row card card-lite z-1 pl-4 pr-4 sort-bar">
      <h6 class="mt-auto mb-auto d-inline text-dark"><translate>Messages</translate></h6>
      <MultiSelect class="mt-auto mb-auto ml-6 mr-6 flex-grow-1" v-model="sortBy" :options="sortOptions" :canDeselect="false" label="Sort By" inline/>
      <MultiSelect class="mt-auto mb-auto flex-grow-1" v-model="sortBy" :options="sortOptions" :canDeselect="false" label="Sort By" inline/>
    </div>-->
    <div class="flex-column flex-shrink-0 sort-bar separator-bottom bg-white mb-1 d-none d-md-flex">
      <MenuBar class="mt-auto mb-auto w-100 ">
        <template #start>
          <h1 class="h5 d-inline">
            <translate>Messages</translate>
          </h1>
        </template>
        <template #content>
          <div class="p-2 justify-content-end w-100 d-flex">
            <Dropdown
              v-model="sortBy"
              inline
              class="mt-auto mb-auto"
              label="Sort By"
              display="button"
              :options="sortOptions"
              option-label="label"
              :can-deselect="false"
            >
              <template #value>
                <translate>Sort By:</translate>&nbsp;{{ sortBy.label }}&nbsp;<i :class="sortBy.icon" />
              </template>
              <template #option="slotProps">
                <span><i :class="slotProps.option.icon" />&nbsp;&nbsp;{{ slotProps.option.label }}</span>
              </template>
            </Dropdown>
            <MultiSelect
              v-if="!searchQuery"
              v-model="filterBy"
              inline
              class="mt-auto mb-auto"
              label="Filter"
              display="button"
              placeholder="Filter"
              :options="filterOptions"
              option-label="label"
              :can-deselect="false"
            >
              <template #value>
                <translate>Filter</translate>
              </template>
            </MultiSelect>
          </div>
        </template>
      </MenuBar>
    </div>
    <div class="flex-shrink-1 flex-grow-1 result-list" style="min-height: 0">
      <InfiniteList
        v-if="folderId"
        :get-all-items="allItems"
        :get-item-page="itemPage"
        :page-size="pageSize"
        id-property="originalId"
      >
        <template #element="{ item }">
          <EmailListItem
            :folder-id="folderId"
            :item="item"
            :selected-emails="selectedEmails"
            :selected="emailId === item.originalId"
            :is-sent="isSentFolder"
            @click="selectEmail(item)"
            @email:checked="mailChecked"
            @email:unchecked="mailUnchecked"
            @email:reset-selection="selectedEmails = []"
            @email:task="showTaskCreator"
            @email:reply="replyMessage"
            @email:forward="forwardMessage"
            @email:flag="flagMessage"
            @email:move="moveToFolder"
            @email:delete="deleteEmail"
          />
        </template>
        <template #empty>
          <div class="d-flex flex-column justify-content-center" style="min-height: 30rem">
            <div class="text-center">
              <p class="h5 mb-2">
                <translate>No Emails in this Folder</translate>
              </p>
              <p><translate>Emails will be shown as soon as you move them here or receive new ones.</translate></p>
            </div>
          </div>
        </template>
      </InfiniteList>
      <div v-else class="p-4">
        <p class="text-dark">
          <translate>Please enter a search term...</translate>
        </p>
      </div>
    </div>
    <Dialog
      v-model:visible="showMoveModal"
      :header="i18n.$gettext('Move to folder')"
      :modal="true"
      :draggable="false"
      style="min-width: 24rem"
      @hide="showMoveModal = false"
    >
      <div>
        <TreeSelect
          v-model="selectedRootFolder"
          :options="moveToOptions"
          selection-mode="single"
          :placeholder="i18n.$gettext('Select a folder')"
        />
      </div>
      <template #footer>
        <Button
          class="mt-4"
          variant="primary"
          :disabled="!selectedRootFolder"
          @click="moveCheckedToFolder"
        >
          <translate>Move</translate>
        </Button>
      </template>
    </Dialog>
    <EmailComposer ref="composer" />
    <TaskCreator
      :show-dialog="showTaskCreatorDialog && emailToCreateTaskFrom != null"
      :email="emailToCreateTaskFrom"
      position="right"
      @hide="showTaskCreatorDialog = false"
    />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import {mailServiceApi} from "@/api/MailServiceApi"
import SWR from "@/api/SWR"
import EmailListItem from "@/components/email/EmailListItem.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import EmailUtil from "@/util/EmailUtil"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Email from "@/model/entry/Email"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MultiSelect from "primevue/multiselect"
import Dropdown from "primevue/dropdown"
import {mailFolderStore} from "@/store/MailFolderStore"
import FocusListener from "@/util/focusUtil"
import demoService from "@/util/demoService"
import Page from "@/model/Page"
import Query from "@/model/common/Query"
import TaskCreator from "@/components/common/TaskCreator.vue"
import Checkbox from "primevue/checkbox"
import Button from "primevue/button"
import {toEmail, toEmailFolder} from "@/router"
import {useConfirm} from "primevue/useconfirm"
import Dialog from "primevue/dialog"
import TreeSelect from "primevue/treeselect"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  components: {
    //@ts-ignore
    InfiniteList, Dropdown, EmailListItem, MenuBar, EmailComposer, MultiSelect, TaskCreator, Checkbox, Button,
    Dialog, TreeSelect
  },
  //@ts-ignore
  props: {
    folderId: String,
    emailId: String,
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: ['compose:email']
})
export default class EmailList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  folderId!: string
  emailId!: string
  searchQuery!: Query | null
  showTaskCreatorDialog: boolean = false
  emailToCreateTaskFrom: Email|null = null

  selectedRootFolder: any = null
  showMoveModal: boolean = false

  selectedEmails: string[] = []

  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  focusListener: FocusListener = new FocusListener(() => {
    if (this.folderId) {
      mailServiceApi.getEmailPreviews(this.folderId, this.sortBy.value, this.systemFlagsContainsOne, null, 0, 100, 30000)
    }
  })

  pageSize = 50
  sortOptions: { value: string, label: string, icon: string, searchValue: string }[] = [
    { value: 'receivedDate:desc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-down', searchValue: 'ctime:desc' },
    { value: 'receivedDate:asc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-up', searchValue: 'ctime:asc' },
    { value: 'from:asc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-up', searchValue: 'meta:from:asc' },
    { value: 'from:desc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-down', searchValue: 'meta:from:desc' }
  ]
  sortBy: { value: string, label: string, icon: string, searchValue: string } = this.sortOptions[0]

  filterOptions: { value: string, label: string, icon: string }[] = [
    { value: 'flagged', label: this.i18n.$gettext('Flagged'), icon: '' },
    { value: 'unread', label: this.i18n.$gettext('Unread'), icon: '' },
  ]
  filterBy: { value: string, label: string, icon: string }[] = []

  setSortBy(event: any): void {
    if (event?.item?.value) {
      this.sortBy = event.item.value
    }
  }

  get moveToOptions(): { key: string, type: string, label: string, icon: string }[] {
    const folders: MailFolder[] = mailFolderServiceApi.getFolders(120000).data || []
    const folderTree = folders.map(folder => this.treeFromFolder(folder)) || []
    console.log(folderTree)
    return folderTree.sort((a: { key: string, type: string, label: string, icon: string },b: { key: string, type: string, label: string, icon: string }) => {
      return EmailUtil.compareType(a.type, b.type)
    })
  }

  treeFromFolder(folder: MailFolder): { key: string, type: string, label: string, icon: string } {
    const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
    const item: any = {
      key: folder.originalId,
      type: folder.type || folder.name?.toLowerCase(),
      label: mapping?.name || folder.name,
      icon: mapping?.icon || 'cil-inbox',

    }

    if (folder.subFolders && folder.subFolders.length > 0) {
      item['children'] = folder.subFolders.map(subFolder => this.treeFromFolder(subFolder))
    }

    return item
  }

  get sortLabel(): string {
    const sortBy = this.sortBy
    const option: any = this.sortOptions.find(option => option.value === sortBy.value)
    return option ? option.label : ''
  }

  get total(): number | null {
    return this.folderId ? Math.max(mailServiceApi.state.total || 0, this.allItems.length) : this.allItems.length
  }

  get allItems(): Email[] {
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    let emails: Email[] = mailServiceApi.getEmailsFilterByOriginalParentId(this.folderId, sortBy.value)
    let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
    if (this.filterBy.find(f => f.value.includes('flagged'))) {
      emails = SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
    }
    return emails
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], Page<string>>) | null {
    const folderId = this.folderId
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    if (this.searchQuery) {
      const searchQuery = this.searchQuery
      return (pageIndex: number, pageSize: number) => {
        //Short refresh threshold because otherwise changing sort order does not always work => needs investigation!
        return mailServiceApi.queryEmails(searchQuery, pageIndex, pageSize, sortBy.searchValue, 1000)
      }
    } else if (folderId) {
      let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], Page<string>> = mailServiceApi.getEmailPreviews(folderId, sortBy.value, systemFlagsContainsOne, null, pageIndex, pageSize, 120000)
        if (swr.call?.promise) swr.call?.promise.then((page: Page<string>) => {
          if (typeof page.total === 'number') {
            mailServiceApi.state.total = page.total
            return page.total
          } else {
            return page.hasMore
          }
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext('Failed to load results.'))
        })
        return swr
      }
    } else {
      return null
    }
  }

  get systemFlagsContainsOne(): string[] | null {
    const filterBy = this.filterBy
    let systemFlagsContainsOne: string[] | null = null
    if (filterBy.find(f => f.value.includes('unread'))) {
      systemFlagsContainsOne = []
      systemFlagsContainsOne.push('UNSEEN')
    }
    if (filterBy.find(f => f.value.includes('flagged'))) {
      if (!systemFlagsContainsOne) {
        systemFlagsContainsOne = []
      }
      systemFlagsContainsOne.push('FLAGGED')
    }
    return systemFlagsContainsOne
  }

  get isSentFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Sent')
  }

  get isDraftFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Drafts')
  }

  get isOnMobile(): boolean {
    return breakpointUtil.isOnMobile()
  }

  setFlag(seen: boolean|null, flag: boolean|null): void {
    if (seen == null && flag == null) return
    if (!this.selectedEmails.length && !this.emailId) return
    const originalIds: string[] = this.selectedEmails.length ? this.selectedEmails : [this.emailId ]
    const systemFlags: {[k: string]: boolean} = {}
    if (seen != null) systemFlags.SEEN = seen
    if (flag != null) systemFlags.FLAGGED = flag
    mailServiceApi._updateFlagsFor(originalIds, systemFlags, {}).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to update flags"))
    })
  }

  selectEmail(email: Email): void {
    if (email && email.originalId && email.systemFlags && this.isDraftFolder) {
      mailServiceApi.getFullMail(email.originalId).then((fullMail: Email | null) => {
        if (fullMail) this.composer.show(fullMail, email.originalId, this.folderId, null, null)
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
      })
    } else if (email.originalId) {
      const originalId: string = email.originalId
      void mailServiceApi.getFullMail(originalId).then((fullMail: Email | null) => {
        /*if (fullMail && !fullMail.systemFlags) {
          fullMail.systemFlags = [ 'SEEN' ]
        } else if (fullMail && !fullMail.systemFlags?.includes('SEEN')) {
          fullMail.systemFlags?.push('SEEN')
        }*/
        if (!email.seen || !email.systemFlags?.includes('SEEN')) {
          void mailServiceApi._updateFlags(originalId, {SEEN: true}, {})
        }
      })
      toEmail(this.folderId, email.originalId)
    }
  }

  flagMessage(event: {id: string, folderId: string, systemFlags: any}) {
    mailServiceApi._updateFlags(event.id, event.systemFlags, {}).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not update flag"))
    })
  }

  deleteEmail(event: {id: string, folderId: string} | null) {
    if (this.selectedEmails.length || (this.emailId && !event)) {
      const count = this.selectedEmails.length || 1
      if (count > 1) {
        const translated = this.i18n.$ngettext("Do you want to delete this email?", "Do you want to delete %{ n } emails?", count)
        this.confirm.require({
          message: this.i18n.interpolate(translated, {'n': count}),
          header: this.i18n.$gettext("Confirmation"),
          icon: 'cil-warning',
          accept: () => {
            if (this.selectedEmails.length) {
              this.deleteMessages()
            } else {
              this.deleteMessage({id: this.emailId, folderId: this.folderId})
            }
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        })
      } else {
        if (this.selectedEmails.length) {
          this.deleteMessages()
        } else {
          this.deleteMessage({id: this.emailId, folderId: this.folderId})
        }
      }
    } else if (event) {
      this.deleteMessage(event)
    }
  }

  deleteMessage(event: {id: string, folderId: string}) {
    mailServiceApi._deleteMail(event.id).then(() => {
      this.toast.success(this.i18n.$gettext("Email deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete message"))
    })
    if (this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id) {
      toEmailFolder(this.folderId)
    } else {
      return null
    }
  }

  mailChecked(originalId: string) {
    this.selectedEmails.push(originalId)
  }
  mailUnchecked(originalId: string) {
    for (let i = 0; i < this.selectedEmails.length; i++) {
      const id: string = this.selectedEmails[i]
      if (originalId === id) {
        this.selectedEmails.splice(i, 1)
      }
    }
  }

  deleteMessages() {
    if (!this.selectedEmails) return
    let folderId: string = ""
    if (this.$route?.params?.hasOwnProperty("email") && this.selectedEmails.find((id) => id === this.$route.params["email"])) {
      folderId = this.folderId
    }
    mailServiceApi.deleteMails(this.selectedEmails).then(() => {
      this.toast.success(this.i18n.$gettext("Emails deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete messages"))
    }).finally(() => {
      this.selectedEmails = []
    })
    if (folderId) {
      toEmailFolder(folderId)
    }
  }

  moveCheckedToFolder() {
    this.showMoveModal = false
    let originalId: string = ""
    for (const [key, value] of Object.entries(this.selectedRootFolder)) {
      if(value === true){
        originalId = key //use first match
        break
      }
    }
    this.selectedRootFolder = null
    console.log(originalId)
    if (!originalId) return
    this.moveToFolder({id: "", sourceFolderId: this.folderId, targetFolderId: originalId})
  }

  moveToFolder(event: {id: string, sourceFolderId: string, targetFolderId: string}) {
    let folderId: string = ""
    if (this.$route?.params?.hasOwnProperty("email")) {
      if ((this.selectedEmails.length && this.selectedEmails.find((id) => id === this.$route.params["email"]) ||
        (!this.selectedEmails.length && this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id))) {
        folderId = this.folderId
      }
    }
    const originalIds: string[] = this.selectedEmails.length ? this.selectedEmails : [ event.id ]
    mailServiceApi._move(originalIds, event.sourceFolderId, event.targetFolderId, false).then(() => {
      this.toast.success(this.i18n.$gettext("Email moved"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not move email"))
    }).finally(() => {
      this.selectedEmails = []
    })
    if (folderId) {
      toEmailFolder(folderId)
    }
  }

  showTaskCreator(email: Email) {
    this.emailToCreateTaskFrom = email
    this.showTaskCreatorDialog = true
  }

  replyMessage(event: {id: string, folderId: string, replyAll: boolean}): Promise<void> {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const reply: Email = EmailUtil.createReply(email, event.replyAll)
        this.composer.show(reply, null, event.folderId, event.id, null)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  forwardMessage(event: {id: string, folderId: string, replyAll: boolean}) {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const forward: Email = EmailUtil.createForward(email)
        this.composer.show(forward, null, event.folderId, null, event.id)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  unmounted() {
    demoService.setIntervalCallback(() => {})
    this.focusListener.remove()
  }
}
</script>

<style scoped lang="scss">
.sort-bar {
  //height: 80px
}
.result-list {
  //height: calc(100% - 180px)
}
@media screen and (min-width: 1200px) {
  .sort-bar {
    height: 80px
  }
  .result-list {
    //height: calc(100% - 80px)
  }
}
</style>
