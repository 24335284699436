import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import MailDomain from '@/model/settings/MailDomain';


export default class GeneratedMailDomainStore {

    state: {
        mailDomains: Map<string, MailDomain>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let mailDomains: Map<string, MailDomain> | null = null
        try {
            const persistedState = localStorage.getItem('MailDomainStore')
            if (persistedState) {
                mailDomains = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            mailDomains: mailDomains || new Map<string, MailDomain>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('MailDomainStore', JSON.stringify(Array.from(this.state.mailDomains)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.mailDomains) this.state.mailDomains.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('MailDomainStore')
    }

    addOrReplaceMailDomain(newMailDomain: MailDomain, persist: boolean = true): true | undefined {
        if (newMailDomain.name) {
            this.state.mailDomains.set(newMailDomain.name, newMailDomain)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceMailDomains(newMailDomains: MailDomain[]) {
        let persist = false
        for (const newMailDomain of newMailDomains) {
            const changed = !!this.addOrReplaceMailDomain(newMailDomain, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceMailDomains(mailDomains: MailDomain[]) {
        const newMailDomains = new Map<string, MailDomain>()
        for (const newMailDomain of mailDomains) {
            if (newMailDomain.name) {
                newMailDomains.set(newMailDomain.name, newMailDomain)
                
            }
        }
        this.state.mailDomains = newMailDomains
        this.persistState()
    }

    removeMailDomain(name: string | undefined | null, persist: boolean = true): true | undefined {
        if (name !== undefined && name !== null) {
            if (this.state.mailDomains.delete(name)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeMailDomains(names: (string | null)[] | undefined | null) {
        let persist = false
        if (names !== undefined && names !== null) {
            for (const name of names) {
                const changed = !!this.removeMailDomain(name, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}