import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Calendar from '@/model/directory/Calendar';


export default class GeneratedCalendarStore {

    state: {
        calendars: Map<string, Calendar>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let calendars: Map<string, Calendar> | null = null
        try {
            const persistedState = localStorage.getItem('CalendarStore')
            if (persistedState) {
                calendars = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            calendars: calendars || new Map<string, Calendar>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('CalendarStore', JSON.stringify(Array.from(this.state.calendars)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.calendars) this.state.calendars.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('CalendarStore')
    }

    addOrReplaceCalendar(newCalendar: Calendar, persist: boolean = true): true | undefined {
        if (newCalendar.originalId) {
            this.state.calendars.set(newCalendar.originalId, newCalendar)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceCalendars(newCalendars: Calendar[]) {
        let persist = false
        for (const newCalendar of newCalendars) {
            const changed = !!this.addOrReplaceCalendar(newCalendar, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceCalendars(calendars: Calendar[]) {
        const newCalendars = new Map<string, Calendar>()
        for (const newCalendar of calendars) {
            if (newCalendar.originalId) {
                newCalendars.set(newCalendar.originalId, newCalendar)
                
            }
        }
        this.state.calendars = newCalendars
        this.persistState()
    }

    removeCalendar(originalId: string | undefined | null, persist: boolean = true): true | undefined {
        if (originalId !== undefined && originalId !== null) {
            if (this.state.calendars.delete(originalId)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeCalendars(originalIds: (string | null)[] | undefined | null) {
        let persist = false
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                const changed = !!this.removeCalendar(originalId, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}