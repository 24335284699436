
import {Options, Vue} from "vue-class-component"
import CalendarEvent from "../../model/entry/Event"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Attendee from "@/model/common/caldav/Attendee"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import Button from "primevue/button"
import useToast from "@/util/toasts"
import DateTimeUtil from "@/util/DateTimeUtil"
import Chip from 'primevue/chip'
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import dayjs from "@/util/dayjs"
import SchedulingObject from "@/model/SchedulingObject"
import {Router, useRouter} from "vue-router"

@Options({
  components: {
    //@ts-ignore
    Button, Chip
  },
  //@ts-ignore
  props: {
    i: {
      type: Object,
      default: ""
    },
    showDismiss: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: false
    }
  },
  //@ts-ignore
  emits: [
    'message:dismiss', 'event:delete', 'event:goto', 'invite:decline', 'invite:tentative', 'invite:accept', 'event:cancel', 'counter:accept', 'counter:decline', 'invite:resend', 'attendee:accept', 'attendee:decline'
  ]
})
export default class InboxItem extends Vue {

  rpcClient = rpcClient

  i!: { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }
  showDismiss!: boolean
  showCancel!: boolean

  i18n: Language = useGettext()
  toast = useToast()
  router: Router = useRouter()

  handlingIMIPLoading: boolean = false

  isInvitation(index: number, method: string): boolean {
    if (method != this.ITIP_REQUEST) return false
    return !this.isOrganizer && (index == (this.i.messages.length - 1))
  }

  get userEmail(): string | null {
    return rpcClient.session?.user?.email || null
  }

  attendeeStatusColor(status: string): string {
    let classString: string = "mr-2 "
    if (status === this.Participation_ACCEPTED) classString += " text-success"
    else if (status === this.Participation_TENTATIVE) classString += " text-info"
    else classString += " text-danger"
    return classString
  }

  getAttendeeStatus(status: string | null): string {
    let participation: any | null = this.participationOptions.find(o => o.id === status)
    if (!participation) {
      participation = this.participationOptions.find(o => o.id === this.Participation_NEEDS_ACTION)
    }
    return participation.name
  }

  ITIP_REQUEST: string = "REQUEST"
  ITIP_REPLY: string = "REPLY"
  ITIP_ADD: string = "ADD"
  ITIP_CANCEL: string = "CANCEL"
  ITIP_REFRESH: string = "REFRESH"
  ITIP_COUNTER: string = "COUNTER"
  ITIP_DECLINECOUNTER: string = "DECLINECOUNTER"

  Participation_NEEDS_ACTION: string = "NEEDS_ACTION"
  Participation_ACCEPTED: string = "ACCEPTED"
  Participation_DECLINED: string = "DECLINED"
  Participation_TENTATIVE: string = "TENTATIVE"
  Participation_DELEGATED: string = "DELEGATED"

  participationOptions: any[] = [
    {
      id: this.Participation_NEEDS_ACTION,
      name: this.i18n.$gettext('Needs Action')
    },
    {
      id: this.Participation_ACCEPTED,
      name: this.i18n.$gettext('Accepted')
    },
    {
      id: this.Participation_DECLINED,
      name: this.i18n.$gettext('Declined')
    },
    {
      id: this.Participation_TENTATIVE,
      name: this.i18n.$gettext('Tentative')
    },
    {
      id: this.Participation_DELEGATED,
      name: this.i18n.$gettext('Delegated')
    }
  ]

  getAttendees(request: CalendarEvent | null): Attendee[] {
    if (!request) return []
    if (request.requestFor && request.requestFor.attendees && request.requestFor.attendees.length > 0) {
      return request.requestFor.attendees
    }
    return request.attendees || []
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  getFormattedEventTime(event: CalendarEvent): string {
    if (!event) return ""
    if (event?.start) {
      const start = new Date(event.start)
      let end: Date | null = null
      if (event.duration) {
        end = new Date(start.getTime() + DateTimeUtil.getMillisFromDuration(event.duration))
      } else if (event.end) {
        end = new Date(event.end)
      }
      const startString = event.allDay ? dayjs(start).format('dddd, L') : dayjs(start).format('dddd, LLL')
      const allDayEnd = end ? dayjs(end).add(-1, 'day').toDate(): null
      const sameDay: boolean = DateTimeUtil.isSameDay(start, event.allDay ? allDayEnd : end)
      if (!end || (sameDay && event.allDay)) {
        return startString
      } else if (end && sameDay) {
        return startString + ' - ' + dayjs(end).format('LT')
      } else if (end && event.allDay) {
        return startString + ' - ' + dayjs(end).add(-1, 'day').format('dddd, L')
      } else {
        return startString + ' - ' + dayjs(end).format('dddd, LLL')
      }
    } else {
      return ''
    }
  }

  getAttendeeInvitedHimselfAndNeedsAccept(so: SchedulingObject, requestFor: CalendarEvent | null): boolean {
    const request: CalendarEvent | undefined = (so.eventsFromRequest && so.eventsFromRequest.length > 0) ? so.eventsFromRequest[0] : undefined
    if (!request || !requestFor) return false
    if (!request.attendees || request.attendees.length != 1) return false
    if (!request.attendees[0].email) return false
    if (!requestFor.attendees || requestFor.attendees.length == 0) return true
    let attendeeMail: string = request.attendees[0].email || ""
    return Boolean(!requestFor.attendees.find(attendee => attendee.email === attendeeMail))
  }

  getAttendeeCanBeCanceled(request: CalendarEvent | null): boolean {
    if (!request || !request.requestFor) return false
    if (!request.attendees || request.attendees.length != 1) return false
    if (!request.attendees[0].email) return false
    if (!request.requestFor.attendees || request.requestFor.attendees.length == 0) return false
    let attendeeMail: string = request.attendees[0].email || ""
    let status: string = request.requestFor.attendees.find(attendee => attendee.email === attendeeMail)?.status || this.Participation_DECLINED
    return status != this.Participation_DECLINED
  }

  getRecurrenceText(event: CalendarEvent): string {
    if (event?.recurrenceRule) {
      try {
        return DateTimeUtil.getRRule(event, event.recurrenceRule)?.toText() || this.i18n.$gettext("Could not determine recurrence")
      } catch (ignore) {
        console.log(ignore)
        return ''
      }
    } else {
      return ''
    }
  }

  getReplyText(request: CalendarEvent | null): string {
    let text
    if (request && request.attendees && request.attendees.length > 0) {
      if (request.attendees[0].status === this.Participation_ACCEPTED) {
        text = this.i18n.$gettext('accepted this invite')
      } else if (request.attendees[0].status === this.Participation_DECLINED) {
        text = this.i18n.$gettext('declined this invite')
      } else if (request.attendees[0].status === this.Participation_TENTATIVE) {
        text = this.i18n.$gettext('accepted this invite tentatively')
      } else {
        text = this.i18n.$gettext('replied to this invite')
      }
    } else {
      text = this.i18n.$gettext('An attendee replied to this invite')
    }
    const message: string = this.getReplyMessage(request)
    if (message && message !== '') {
      text += ' ' + this.i18n.$gettext('with message') + ': ' + message
    } else {
      text += '.'
    }
    return text
  }

  getReplyMessage(request: CalendarEvent | null): string {
    if (request && request.comments && request.comments[0]) {
      return request.comments[0]
    }
    return ""
  }

  attendeeImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  attendeeName(email: string, name: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return name ? name : email
    }
  }

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Optional')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Required')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Fyi')
    }
  ]

  getAttendeeStatusTooltip(attendee: Attendee | null): string {
    if (!attendee) return ''
    let tooltip: string = this.attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || ''
    if (tooltip)
      tooltip += ': '
    tooltip += this.getAttendeeStatus(attendee.status)

    return tooltip
  }

  humanreadableDate(s: SchedulingObject): string {
    if (s.eventsFromRequest && s.eventsFromRequest.length > 0 && s.eventsFromRequest[0].stamp) try {
      return dayjs(s.eventsFromRequest[0].stamp).format("DD.MM.YYYY, HH:mm")
    } catch (e) {
      return ''
    } else {
      return ''
    }
  }

  get isOrganizer(): boolean {
    return !!(this.userEmail && this.i?.event?.requestFor?.organizer?.email?.toLowerCase() === this.userEmail?.toLowerCase())
  }

  needsAction(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_NEEDS_ACTION))
  }

  isAccepted(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_ACCEPTED))
  }

  isDeclined(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_DECLINED))
  }

  isTentative(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_TENTATIVE))
  }

  canGoToEvent(event: CalendarEvent): boolean {
    return !!(event && event.originalParentId && event.originalId)
  }
}
