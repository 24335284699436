<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-xl p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Out-Of-Office Notification
          </translate>
          <h2>{{ virtualMailbox }}</h2>
        </h2>
        <p>
          <b><translate>What you can do here:</translate></b>&nbsp;<translate>
            Set filters for incoming mails to distribute, reject or sort mails into folders automatically on the server.
          </translate>
        </p>
        <p><b><translate>What you can't do here:</translate></b>&nbsp;<translate>Set Filters for your mobile phone or email client.</translate></p>
        <div v-if="loading || settingsLoading">
          <Skeleton class="mt-6" height="40px" style="width: 80%" />
        </div>
        <div v-else class="row mt-6 mb-5">
          <div class="col-auto">
            <Button
              :label="i18n.$gettext('Add Out-Of-Office Time')"
              :disabled="mailBoxNotExists"
              icon="cil-plus"
              class="p-button btn-raised mr-3"
              @click="createNewFilter"
            />
          </div>
        </div>
        <UserOutOfOfficeEditor
          v-if="newOutOfOffice"
          :filter="newOutOfOffice"
          :signatures="signatures"
          create-mode
          class="p-4 d-flex my-1 card separator-bottom"
          @save="saveAll"
          @cancel="newOutOfOffice = null"
        />
        <div v-if="filters !== null">
          <UserOutOfOfficeEditor
            v-for="filter in filters"
            :key="'ooo' + filter.id"
            :filter="filter"
            :signatures="signatures"
            class="p-4 d-flex my-1 card separator-bottom"
            @save="saveAll"
            @delete="confirmDelete(filter, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import OutOfOffice from "@/model/settings/OutOfOffice"
import Button from "primevue/button"
import UserOutOfOfficeEditor from "@/components/settings/UserOutOfOfficeEditor.vue"
import {rpcClient} from "@/api/WebsocketClient"
import RpcError from "@/api/RpcError"
import SWR from "@/api/SWR"
import {outOfOfficeServiceApi} from "@/api/OutOfOfficeServiceApi"
import Skeleton from "primevue/skeleton"
import {outOfOfficeStore} from "@/store/OutOfOfficeStore"
import {useConfirm} from "primevue/useconfirm"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import VirtualMailbox from "@/model/settings/VirtualMailbox"
import Signature from "@/model/settings/Signature"

@Options({
  //@ts-ignore
  props: {
  },
  components: {
    UserOutOfOfficeEditor, Button, Skeleton
  }
})
export default class UserOutOfOfficeSettings extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  mailBoxNotExists: boolean = false
  virtualMailbox: string = ""
  virtualMailboxData!: VirtualMailbox | null
  newOutOfOffice: OutOfOffice | null  = null
  loading: boolean = false
  settingsLoading:  boolean = false

  mounted() {
    this.virtualMailbox = this.$route?.params?.hasOwnProperty("virtual_mailbox") ?
      this.$route.params["virtual_mailbox"] as string : ""
    if (!this.virtualMailbox) {
      this.settingsLoading = true
      void rpcClient.refreshSettings().then(() => {
        this.settingsLoading = false
      })
    }
  }

  get signatures(): Signature[] | null {
    if (this.virtualMailbox) return this.virtualMailboxData?.signatures || []
    if (rpcClient.session.user) {
      if (!rpcClient.session.user.settings) {
        rpcClient.session.user.settings = { signatures: [] }
      } else if (!rpcClient.session.user.settings.signatures) {
        rpcClient.session.user.settings.signatures = []
      }
      return rpcClient.session.user.settings.signatures
    } else {
      return null
    }
  }

  get filters(): OutOfOffice[] {
    if (!this.virtualMailbox) {
      const swr: SWR<OutOfOffice[], number[]> = outOfOfficeServiceApi.getOutOfOffices()
      if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
        this.loading = true
        swr.call.promise.catch(() => {
          this.toast.error(this.i18n.$gettext("Error while fetching the Out of Office Settings"))
        })
        swr.call.promise.finally(() => {
          this.loading = false
        })
      }
      return swr.data || rpcClient.session.user?.settings?.outOfOffices || []
    } else {
      const swr: SWR<VirtualMailbox[], string[]> = virtualMailboxServiceApi.getVirtualMailboxes()
      if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
        this.loading = true
        swr.call.promise.catch(() => {
          this.toast.error(this.i18n.$gettext("Error while fetching the Mail Rules"))
        })
        swr.call.promise.finally(() => {
          this.loading = false
        })
      }
      this.virtualMailboxData = swr.data?.find((vmb: VirtualMailbox) => vmb.address === this.virtualMailbox) || null
      if (!this.virtualMailboxData) {
        this.mailBoxNotExists = true
        this.toast.error(this.i18n.$gettext("Mailbox does not exist"))
      }
      return this.virtualMailboxData?.oooFilters || []
    }
  }

  createNewFilter() {
    let newOne = new OutOfOffice()
    newOne.start = new Date().toISOString()
    newOne.subject = ''
    newOne.textBody = ''
    newOne.htmlBody = ''
    this.newOutOfOffice = newOne
  }

  saveAll(): void {
    const filters: OutOfOffice[] = [...this.filters]
    if (this.newOutOfOffice && this.newOutOfOffice.start && this.newOutOfOffice.end && (this.newOutOfOffice.subject || this.newOutOfOffice.htmlBody)) {
      filters.splice(0, 0, this.newOutOfOffice)
    }
    this.saveFilters(filters)
  }

  confirmDelete(filter: OutOfOffice, event: Event | undefined): void {
    this.confirm.require({
      //@ts-ignore
      target: event?.currentTarget || undefined,
      message: this.i18n.$gettext("Delete this Out-Of-Office notification?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-exclamation',
      accept: () => {
        const filters: OutOfOffice[] = [...this.filters.filter(f => f !== filter)]
        this.saveFilters(filters)
      },
      reject: () => {}
    })
  }

  saveFilters(filters: OutOfOffice[]) {
    this.loading = true
    if (!this.virtualMailbox) {
      void outOfOfficeServiceApi._saveOutOfOffices(filters).then((newFilters: OutOfOffice[]) => {
        this.newOutOfOffice = null
        outOfOfficeStore.replaceOutOfOffices(newFilters)
        this.toast.success(this.i18n.$gettext("Saved Out-of-Office settings."))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not save Out-of-Office settings."))
      }).finally(() => {
        this.loading = false
      })
    } else {
      void virtualMailboxServiceApi._setOutOfOfficeFilters(this.virtualMailbox, filters).then((address: string) => {
        this.newOutOfOffice = null
        this.toast.success(this.i18n.$gettext("Saved Out-of-Office settings."))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not save Out-of-Office settings."))
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">

</style>
