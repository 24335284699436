import DashboardView from "@/components/dashboard/DashboardView.vue"
import FileView from "@/components/filemanger/FileView.vue"
import ChatView from "@/components/chat/ChatView.vue"
import EmailInterface from "@/components/email/EmailInterface.vue"
import ContactsView from "@/components/contacts/ContactsView.vue"
import CalendarView from "@/components/calendar/CalendarView.vue"
import TaskView from "@/components/tasks/TaskView.vue"
import SearchView from "@/components/search/SearchView.vue"
import Adminpanel from "@/components/Adminpanel.vue"
import SystemSettingsList from "@/components/settings/SystemSettingsList.vue"
import UserSettingsList from "@/components/settings/UserSettingsList.vue"
import UserSignatureSettings from "@/components/settings/UserSignatureSettings.vue"
import UserMailFilterSettings from "@/components/settings/UserMailFilterSettings.vue"
import UserOutOfOfficeSettings from "@/components/settings/UserOutOfOfficeSettings.vue"
import VirtualMailboxSettings from "@/components/settings/VirtualMailboxesSettings.vue"
import GlobalSignatureSettings from "@/components/settings/GlobalSignatureSettings.vue"
import {createRouter, createWebHistory, NavigationFailure, RouteLocationNormalizedLoaded} from "vue-router"
import adminpanelHolder from "@/util/AdminpanelHolder"
import DataImport from "@/components/settings/dataimport/DataImport.vue"
import NewSource from "@/components/settings/dataimport/NewSource.vue"
import ShareLinkAdministration from "@/components/settings/ShareLinkAdministration.vue"

//Named route constants:
const nameFiles = "Files"
const nameChat = "Chat"
const nameEmail = "Email"
const nameContacts = "Contacts"
const nameCalendar = "Calendar"
const nameTasks = "Tasks"

const nameVirtualMailboxMailFilter = "virtual-email-rules"
const nameVirtualMailboxOOO = "virtual-email-away"
const nameVirtualMailboxSignature = "virtual-email-signature"

const routes = [
    {
        path: "/",
        alias: "/setup/initial",
        component: DashboardView
    },
    {
        path: "/files/:path*",
        name: nameFiles,
        component: FileView
    },
    {
        path: "/chat/:channel?",
        name: nameChat,
        component: ChatView
    },
    {
        path: "/mail/:folder?/:email?",
        name: nameEmail,
        component: EmailInterface
    },
    {
        path: "/contacts/:book?/:contact?",
        name: nameContacts,
        component: ContactsView
    },
    {
        path: "/calendar/:calendar?/:event?",
        name: nameCalendar,
        component: CalendarView
    },
    {
        path: "/tasks/:board?/:task?",
        name: nameTasks,
        component: TaskView
    },
    {
        path: "/search",
        component: SearchView
    },
    {
        path: '/password/forgotten',
        component: Adminpanel
    },
    {
        path: '/password/reset',
        component: Adminpanel
    },
    {
        path: '/oauth2/authorize',
        component: Adminpanel
    },
    {
        path: '/apps',
        name: 'apps',
        component: Adminpanel
    },
    {
        path: '/app/:id',
        name: 'app',
        component: Adminpanel
    },
    {
        path: '/account',
        name: 'account',
        component: Adminpanel
    },
    {
        path: '/store',
        name: 'store',
        component: Adminpanel
    },
    {
        path: '/store/:id',
        name: 'storeapp',
        component: Adminpanel
    },
    {
        path: '/messages',
        name: 'messages',
        component: Adminpanel
    },
    {
        path: '/settings/system',
        name: 'systemsettings',
        component: SystemSettingsList
    },
    {
        path: '/settings',
        name: 'usersettings',
        component: UserSettingsList
    },
    {
        path: '/settings/sharelinks',
        name: 'sharelinkadministration',
        component: ShareLinkAdministration
    },
    {
        path: '/settings/email-signature',
        name: 'email-signature',
        component: UserSignatureSettings
    },
    {
        path: '/settings/email-signature/:virtual_mailbox',
        name: nameVirtualMailboxSignature,
        component: UserSignatureSettings
    },
    {
        path: '/settings/email-rules',
        name: 'email-rules',
        component: UserMailFilterSettings
    },
    {
        path: '/settings/email-rules/:virtual_mailbox',
        name: nameVirtualMailboxMailFilter,
        component: UserMailFilterSettings
    },
    {
        path: '/settings/email-away',
        name: 'email-away',
        component: UserOutOfOfficeSettings
    },
    {
        path: '/settings/email-away/:virtual_mailbox',
        name: nameVirtualMailboxOOO,
        component: UserOutOfOfficeSettings
    },
    {
        path: '/settings/virtual-mailboxes',
        name: 'virtual-mailboxes',
        component: VirtualMailboxSettings,
    },
    {
        path: '/settings/global-signature',
        name: 'global-signature',
        component: GlobalSignatureSettings
    },
    {
        path: '/settings/domains',
        name: 'domains',
        component: Adminpanel
    },
    {
        path: '/settings/networkshares',
        name: 'networkshares',
        component: Adminpanel
    },
    {
        path: '/settings/dataimport',
        name: 'dataimport',
        component: Adminpanel
    },
    {
        path: '/settings/import',
        name: 'import',
        component: DataImport
    },
    {
        path: '/settings/import/newsource',
        name: 'newimportsource',
        component: NewSource
    },
    {
        path: '/settings/synchronization',
        name: 'synchronization',
        component: Adminpanel
    },
    {
        path: '/settings/failover',
        name: 'failover',
        component: Adminpanel
    },
    {
        path: '/settings/feedback',
        name: 'feedback',
        component: Adminpanel
    },
    {
        path: '/settings/firewall',
        name: 'firewall',
        component: Adminpanel
    },
    {
        path: '/settings/hosting',
        name: 'hosting',
        component: Adminpanel
    },
    {
        path: '/settings/mailaliases',
        name: 'mailaliases',
        component: Adminpanel
    },
    {
        path: '/settings/mailaliases/new',
        name: 'mailaliasesnew',
        component: Adminpanel
    },
    {
        path: '/settings/maildomains',
        name: 'maildomains',
        component: Adminpanel
    },
    {
        path: '/settings/maintenance',
        name: 'maintenance',
        component: Adminpanel
    },
    {
        path: '/settings/passwords',
        name: 'passwords',
        component: Adminpanel
    },
    {
        path: '/settings/pstupload',
        name: 'pstupload',
        component: Adminpanel
    },
    {
        path: '/settings/email',
        name: 'email',
        component: Adminpanel
    },
    {
        path: '/settings/backup',
        name: 'backup',
        component: Adminpanel
    },
    {
        path: '/settings/backup/new',
        name: 'backupjobnew',
        component: Adminpanel
    },
    {
        path: '/settings/info',
        name: 'info',
        component: Adminpanel
    },
    {
        path: '/settings/notifications',
        name: 'notifications',
        component: Adminpanel
    },
    {
        path: '/settings/licenses',
        name: 'licenses',
        component: Adminpanel
    },
    {
        path: '/settings/reset',
        name: 'reset',
        component: Adminpanel
    },
    {
        path: '/settings/updates',
        name: 'updates',
        component: Adminpanel
    },
    {
        path: '/settings/authentication',
        name: 'authentication',
        component: Adminpanel
    },
    {
        path: '/settings/users',
        name: 'users',
        component: Adminpanel
    },
    {
        path: '/settings/groups',
        name: 'groups',
        component: Adminpanel
    },
    {
        path: '/settings/users/new',
        name: 'usersnew',
        component: Adminpanel
    },
    {
        path: '/settings/users/:id',
        name: 'user',
        component: Adminpanel
    },
    {
        path: '/settings/wallpapers',
        name: 'wallpapers',
        component: Adminpanel
    },
    {
        path: '/manual/howto/:id',
        name: 'howto',
        component: Adminpanel
    },
    {
        path: '/manual/:id',
        name: 'manual',
        component: Adminpanel
    },
    {
        path: '/manual/',
        name: 'manuals',
        component: Adminpanel
    }
]

//Router construction:

const router = createRouter({
    history: createWebHistory(),
    //@ts-ignore
    routes
})

window.groupwareAfterRouterCallback = (to: {fullPath: string, path: string, query: any, hash: string, name: string}) => {
    const isAdminpanelRoute: boolean = (routes.findIndex((def) => {
        if(to.name === def.name && def.component == Adminpanel){ //Names have to be the same or aliased in groupware.
            return true
        } else {
            return false
        }
    }) > -1)

    if(isAdminpanelRoute){
        console.log("Is Adminpanel route:", to.path)
        if(to.path === "/"){
            void router.replace("/apps")
        } else {
            void router.replace(to.path)
        }
    }
}

window.groupwareBeforeRouterCallback = (to: {fullPath: string, path: string, query: any, hash: string, name: string}) => {
    //console.log("Adminpanel before each", to)
}

router.afterEach((to) => {
    if(adminpanelHolder?.instance?.router?.currentRoute && adminpanelHolder.instance.router.currentRoute !== to){
        window.setTimeout(() => {
            adminpanelHolder?.instance?.router.replace(to)
        },0)
    }
})

//Quick jump methods:

export function toFiles(): void {
    void router.push({
        name: nameFiles
    })
}
export function toFile(path: string[]): void {
    void router.push({
        name: nameFiles,
        params: {
            path: path
        }
    })
}

export function toChatsPromise(): Promise<void | NavigationFailure | undefined> {
    return router.push({
        name: nameChat
    })
}

export function toChats(): void {
    void toChatsPromise()
}

export function toChatChannel(channelId: string): void {
    void router.push({
        name: nameChat,
        params: {
            channel: channelId
        }
    })
}

export function toEmails(): void {
    void router.push({
        name: nameEmail
    })
}


export function toEmailFolder(folderId: string): void {
    void router.push({
        name: nameEmail,
        params: {
            folder: folderId
        }
    })
}

export function toEmail(folderId: string, emailId: string): void {
    void router.push({
        name: nameEmail,
        params: {
            folder: folderId,
            email: emailId
        }
    })
}

export function toContacts(): void {
    void router.push({
        name: nameContacts
    })
}

export function toAddressBook(addressBookId: string): void {
    void router.push({
        name: nameContacts,
        params: {
            book: addressBookId
        }
    })
}

export function toContact(addressBookId: string, contactId: string): void {
    void router.push({
        name: nameContacts,
        params: {
            book: addressBookId,
            contact: contactId
        }
    })
}

export function toCalendars(): void {
    void router.push({
        name: nameCalendar
    })
}

export function toCalendar(calendarId: string): void {
    void router.push({
        name: nameCalendar,
        params: {
            calendar: calendarId
        }
    })
}

export function toEvent(calendarId: string, eventId: string): void {
    void router.push({
        name: nameCalendar,
        params: {
            calendar: calendarId,
            event: eventId
        }
    })
}

export function toTasks(): void {
    void router.push({
        name: nameTasks
    })
}

export function toTaskBoard(taskBoardId: string): void {
    void router.push({
        name: nameTasks,
        params: {
            board: taskBoardId
        }
    })
}

export function toTask(taskBoardId: string, taskId: string): void {
    void router.push({
        name: nameTasks,
        params: {
            board: taskBoardId,
            task: taskId
        }
    })
}

export function toVirtualMailboxOutOfOffice(address: string): void {
    void router.push({
        name: nameVirtualMailboxOOO,
        params: {
            virtual_mailbox: address
        }
    })
}

export function toVirtualMailboxMailFilter(address: string): void {
    void router.push({
        name: nameVirtualMailboxMailFilter,
        params: {
            virtual_mailbox: address
        }
    })
}

export function toVirtualMailboxSignatures(address: string): void {
    void router.push({
        name: nameVirtualMailboxSignature,
        params: {
            virtual_mailbox: address
        }
    })
}

export function isViewingChatChannel(channelId: string): boolean {
    const location: RouteLocationNormalizedLoaded = router?.currentRoute?.value
    if (!location || location.name !== nameChat) return false
    return location.params?.channel == channelId
}

export function isViewingMailFolder(folderId: string): boolean {
    const location: RouteLocationNormalizedLoaded = router?.currentRoute?.value
    if (!location || location.name !== nameEmail) return false
    return location.params?.folder == folderId
}

export default router
