export default class EntryLinkMeta {
  description!: string | null;
  leftOriginalParentId!: string | null;
  rightOriginalParentId!: string | null;

  constructor() {
    this.description = null;
    this.leftOriginalParentId = null;
    this.rightOriginalParentId = null;
  }
}
