export default class UploadLink {
  token!: string | null;
  userName!: string | null;
  viewCnt!: number | null;
  ctime!: string | null;
  expireDate!: string | null;
  repoId!: string | null;
  link!: string | null;
  name!: string | null;
  path!: string | null;
  isExpired!: boolean | null;
  repoName!: string | null;

  constructor() {
    this.token = null;
    this.userName = null;
    this.viewCnt = null;
    this.ctime = null;
    this.expireDate = null;
    this.repoId = null;
    this.link = null;
    this.name = null;
    this.path = null;
    this.isExpired = null;
    this.repoName = null;
  }
}
