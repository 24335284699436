<template>
  <div class="attachment-item badge-border-dark flex-shrink-0 flex-grow-0 d-flex align-items-center mr-2 mb-2" v-tooltip.bottom="this.attachment.file?.fileName">
    <Image v-if="isImage" :src="image.url" :preview="true" style="flex-grow: 1"/>
    <div v-else class="flex-grow-1 w-100 d-flex align-items-center justify-content-center">
      <i class="fa fa-paperclip fa-4x text-secondary"></i>
    </div>

    <div class="attachment-item-label">
      <Button v-if="!editMode" class="p-button-rounded p-button-sm mx-1" icon="fa fa-download" @click="downloadAttachment" />
      <Button v-else class="p-button-rounded p-button-danger p-button-sm mx-1" icon="fa fa-trash" @click="sendDelete" />
      {{ shortName }}
    </div>

    <div class="attachment-item-loading">
      <slot name="loading"></slot>
    </div>


  </div>


</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Attachment from "@/model/common/caldav/Attachment"
import CalDavFile from "@/model/common/caldav/File"
import Button from "primevue/button"
import Image from "primevue/image"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService";

@Options({
  //@ts-ignore
  props: {
    attachment: Object,
    editMode: Boolean
  },
  components: {Image, Button},
  emits: ['delete']
})
export default class AttachmentItem extends Vue {

  attachment!: Attachment
  editMode: boolean = false

  get isImage(): boolean {
    if(!this.attachment.file || !this.attachment.file.uri || !this.attachment.file.formatType) return false
    return this.attachment.file.formatType?.startsWith('image/')
  }

  get image(): CachedImage | null {
    if(!this.attachment?.file) return null
    return imageLoadingService.getCachedImage(this.attachment.file?.uri || '')
  }

  downloadAttachment (){
    const file = this.attachment.file
    if(!file) return
    if (file.uri) {
      window.open(file.uri, '_blank')
    } else if (file.fileName) {
      window.open('/groupware-api/v1/uploads/' + encodeURIComponent(file.fileName), '_blank')
    }
  }

  get shortName(): string {
    if(!this.attachment.file?.fileName) return ""
    return this.attachment.file?.fileName?.length > 15 ? this.attachment.file?.fileName.substring(0,15) + "..." : ""
  }

  sendDelete(){
    this.$emit("delete", this.attachment)
  }
}
</script>

<style lang="scss">

$attachment_item_size: 128px;
$attachment_label_size: 1.7rem;
.attachment-item {
  display: flex;
  flex-direction: column;
  height: $attachment_item_size;
  max-width: $attachment_item_size * 3;
  border-radius: 3px;
  position: relative;

  & .p-image {
    height: calc(100% - 1.7rem);
    width: 100%;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .attachment-item-label {
    height: $attachment_label_size;
  }

  & .attachment-item-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

  }
}


</style>