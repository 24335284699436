<template>
  <div id="searchbar" class="flex-shrink-1 flex-grow-1 d-flex flex-row" style="flex-basis: 30%; min-width: 20rem">
    <span class="p-input-icon-left w-100">
      <i v-if="!term || term === ''" class="cil-search ml-1" style="z-index: 10;" />
      <i
        v-else
        class="cil-x ml-1"
        style="z-index: 10;  padding-top: 1px; cursor: pointer"
        @click="clear"
      />
      <AutoComplete
        v-model="term"
        type="text"
        :min-length="2"
        :placeholder="i18n.$gettext('Type and press enter to search')"
        input-class="w-100 input-padding-left-for-icon"
        scroll-height="400px"
        :suggestions="suggestions"
        class="w-100"
        @complete="getSuggestions($event)"
        @keyup.enter="searchFromEnter"
        @item-select="searchFromAutocomplete"
        @update:modelValue="emitNullIfEmpty"
      >
        <template #item="slotProps">
          <p class="my-1 d-flex align-items-center">
            <i v-if="slotProps.item.icon" :class="slotProps.item.icon" />&emsp;
            <span v-html="safeRender(slotProps.item.text)" />
          </p>
        </template>
      </AutoComplete>
    </span>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import AutoComplete from "@/components/common/AutoComplete.vue"
import insane from "insane"

const marked = require("marked")

class SearchQuery {
  icon!: string | null
  text!: string
  query!: string
  collectionId!: string | null
  type!: string | null
}

@Options({
  components: {
    //@ts-ignore
    AutoComplete
  },
  //@ts-ignore
  props: {
    collectionId: String,
    collectionName: String,
    collectionIcon: String,
    collectionType: String
  },
  emits: [ 'search' ]
})
export default class SearchBar extends Vue {

  i18n: Language = useGettext()

  collectionId!: string
  collectionName!: string
  collectionIcon!: string
  collectionType!: string

  term: string = ''
  suggestions: SearchQuery[] = []

  types: { type: string, name: string, icon: string, fields: string[] }[] = [
    {
      type: 'INODE',
      name: this.i18n.$gettext('Files'),
      icon: 'cil cil-folder',
      fields: [ 'meta:name', 'content' ]
    },
    {
      type: 'CHAT_MESSAGE',
      name: this.i18n.$gettext('Chat Messages'),
      icon: 'cil cil-chat-square',
      fields: []
    },
    {
      type: 'TASK',
      name: this.i18n.$gettext('Tasks'),
      icon: 'cil cil-task',
      fields: []
    },
    {
      type: 'EMAIL',
      name: this.i18n.$gettext('Mail'),
      icon: 'cil cil-mail',
      fields: [ 'meta:subject', 'content' ]
    },
    {
      type: 'EVENT',
      name: this.i18n.$gettext('Events'),
      icon: 'cil cil-calendar',
      fields: []
    },
    {
      type: 'CONTACT',
      name: this.i18n.$gettext('Contacts'),
      icon: 'cil cil-group',
      fields: []
    }
  ]

  searchFromAutocomplete(event: { value: SearchQuery }) {
    const searchQuery: SearchQuery = event.value
    this.term = searchQuery.query || ''
    if (this.term === '') {
      this.$emit('search', null)
    } else if (searchQuery?.collectionId && searchQuery.type) {
      this.$emit('search', {
        id: null,
        all: searchQuery.query,
        type: [ searchQuery.type ],
        fields: this.types.find(t => t.type === searchQuery.type)?.fields || null,
        collectionIds: searchQuery.collectionId ? [ searchQuery.collectionId ] : null
      })
    } else if (searchQuery) {
      const query: any = { term: searchQuery?.query }
      if (searchQuery.type) {
        query.type = searchQuery.type
      }
      void this.$router.push({ path: '/search', query: query })
    }
  }

  searchFromEnter() {
    if (this.term && this.term !== '') {
      if (this.term === '') {
        this.$emit('search', null)
      } else if (this.collectionId && this.collectionType) {
        this.$emit('search', {
          id: null,
          all: this.term,
          type: [ this.collectionType ],
          fields: this.types.find(t => t.type === this.collectionType)?.fields || null,
          collectionIds: this.collectionId ? [ this.collectionId ] : null
        })
      } else {
        const query: any = { term: this.term }
        if (this.collectionType) {
          query.type = this.collectionType
        }
        void this.$router.push({ path: '/search', query: query })
      }
    } else {
      this.$emit('search', null)
    }
  }

  emitNullIfEmpty(term: string | null | undefined) {
    if (!term || term === '') {
      this.$emit('search', null)
    }
  }

  clear() {
    this.term = ''
    this.$emit('search', null)
  }

  get collectionTypeName() : string | null {
    if (this.collectionType) {
      return this.types.find(t => t.type === this.collectionType)?.name || null
    } else {
      return null
    }
  }

  getSuggestions(event: { query: string }): void {
    const suggestions: SearchQuery[] = []

    if (this.collectionName && this.collectionId && this.collectionType) {
      const translateParams = {
        term: '_**' + event.query + '**_',
        collection: this.collectionName ? '**' + this.collectionName + '**' : ''
      }
      suggestions.push({
        icon: this.collectionIcon,
        text: this.i18n.interpolate(this.i18n.$pgettext("search_bar", "Find %{ term } in %{ collection }"), translateParams),
        query: event.query,
        collectionId: this.collectionId,
        type: this.collectionType
      })
    }

    if (this.collectionType && this.collectionTypeName) {
      const translateParams = {
        term: '_**' + event.query + '**_',
        collection: '**' + this.collectionTypeName + '**'
      }
      suggestions.push({
        icon: this.collectionIcon,
        text: this.i18n.interpolate(this.i18n.$pgettext("search_bar", "Find %{ term } in %{ collection }"), translateParams),
        query: event.query,
        collectionId: null,
        type: this.collectionType
      })
    }

    for (let type of this.types) {
      if (!this.collectionType || this.collectionType != type.type) {
        const translateParams = {
          term: '_**' + event.query + '**_',
          collection: '**' + type.name + '**'
        }
        suggestions.push({
          icon: type.icon,
          text: this.i18n.interpolate(this.i18n.$pgettext("search_bar", "Find %{ term } in %{ collection }"), translateParams),
          query: event.query,
          collectionId: null,
          type: type.type
        })
      }
    }

    suggestions.push({
      icon: null,
      text: this.i18n.interpolate(this.i18n.$pgettext("search_bar", "Find %{ term } in all places"), { term: '**' + event.query + '**' }),
      query: event.query,
      collectionId: null,
      type: null
    })

    this.suggestions = suggestions
  }

  safeRender(text: string | null): string {
    return text ? insane(marked.parseInline(text)) : null
  }
}
</script>

<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";

#navbar-top {
  background-color: transparent !important;
  box-shadow: none;
}

.collapse {
  display: block !important;
  overflow: hidden;
  transition: max-height .5s ease-in-out;
}
.collapse.show {
  max-height: 99em;
}
@media screen and (max-width: 991px) {
  .collapse:not(.show) {
    max-height: 0;
  }
}

</style>
