export default class Telephone {
  text!: string | null;
  uri!: string | null;
  types!: string[] | null;
  preference!: number | null;
  alternativeId!: string | null;

  constructor() {
    this.text = null;
    this.uri = null;
    this.types = null;
    this.preference = null;
    this.alternativeId = null;
  }
}
