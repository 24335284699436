export default class DataSourceInstance {
  id!: number | null;
  created!: any | null;
  createdBy!: string | null;
  datasourceName!: string | null;
  displayName!: string | null;
  authenticationDetails!: any | null;
  confirmed!: boolean | null;
  providerUrl!: string | null;

  constructor() {
    this.id = null;
    this.created = null;
    this.createdBy = null;
    this.datasourceName = null;
    this.displayName = null;
    this.authenticationDetails = null;
    this.confirmed = null;
    this.providerUrl = null;
  }
}
