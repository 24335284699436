<template>
  <Draggable v-if="item">
    <div class="separator-bottom card task-card bg-hover-light p-2 mx-2 my-1" :style="{ 'border-left-color': (item.color || 'transparent') + ' !important' }">
      <div v-if="loading" style="position: absolute; top: 0; left: 0; width: 99%">
        <ProgressBar mode="indeterminate" />
      </div>
      <div class="my-2 d-flex justify-content-between">
        <p class="mb-0 flex-shrink-0 flex-grow-1" style="width: 0; color: black">
          {{ item.summary }}
        </p>
        <Button
          v-if="hasMenu"
          icon="cil-menu"
          class="p-button-text p-button-sm context-menu-btn"
          @click.stop.prevent="$emit('contextmenu', $event)"
        />
      </div>
      <div
        v-if="image && !image.error"
        class="my-2 d-flex w-100"
        @click.stop.prevent="() => {}"
      >
        <Image
          :preview="true"
          :src="image.cached"
          :image-style="{
            'max-height': '250px',
            'max-width': '100%',
            'border': '1px solid',
            'borderRadius': '3px'
          }"
        />
      </div>
      <div class="d-flex justify-content-between">
        <div class="badges d-flex flex-row" style="flex-wrap: wrap;">
          <span
            v-for="label in labels"
            :key="label.name"
            class="badge mr-2 mt-2 p-2"
            :class="label.classes"
            style="height: 1.6rem; vertical-align: middle"
          >
            <i v-if="label.icon" :class="label.icon" />
            {{ label.name || '&emsp;' }}
          </span>
        </div>
        <div class="avatars d-flex flex-row justify-content-end" style="flex-wrap: wrap;">
          <Avatar
            v-for="member in members"
            :key="member.email"
            :username="member.name"
            generate-initials
          />
        </div>
      </div>
      <div class="d-flex flex-row mt-2">
        <div v-if="item.start">
          <span
            :key="item.start + '-start'"
            class="badge badge-info text-white mr-1 p-2"
            style=""
          >
            <i class="cil-media-play font-weight-bold" />
            {{ startDate }}
          </span>
        </div>
        <div v-if="item.due">
          <span
            :key="item.due + '-due'"
            class="badge mr-1 p-2"
            :class="badgeClassForDueDate"
            style=""
          >
            <i class="cil-exclamation font-weight-bold" />
            {{ dueDate }}
          </span>
        </div>
        <i v-if="item.description" class="cil-align-left font-weight-bold ml-2 my-2" />
        <i v-if="item.attachments && item.attachments.length > 0" class="cil-paperclip font-weight-bold ml-2 my-2" />
      </div>
      <div v-if="item.percentCompleted" class="task-progress-label">
        {{ item.percentCompleted }}%
      </div>
      <div v-if="item.percentCompleted" class="task-progress">
        <div class="task-progress-bar" :style="progressStyle" />
      </div>
    </div>
  </Draggable>
</template>

<script lang="ts">
import {Draggable} from 'vue3-smooth-dnd'
import {Options, Vue} from "vue-class-component"
import Task from "@/model/entry/Task"
import ProgressBar from "primevue/progressbar"
import Avatar from "@/components/common/Avatar.vue"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import TaskBoard from "@/model/directory/TaskBoard"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import Button from "primevue/button"
import dayjs from "dayjs"
import Attachment from "@/model/common/caldav/Attachment"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import i18n from "@/util/i18n"
import Image from 'primevue/image'

@Options({
  components: {
    Draggable, ProgressBar, Avatar, Button, Image
  },
  //@ts-ignore
  props: {
    item: [ Task, Object ],
    loading: {type: Boolean, default: false},
    hasMenu: {type: Boolean, default: true}
  }
})
export default class TaskCard extends Vue {
  loading!: boolean
  hasMenu!: boolean
  item!: Task

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  get members(): { name: string, email: string }[] {
    const members: { name: string, email: string }[] = []
    for (const attendee of (this.item?.attendees || [])) {
      const user: User | undefined = this.users.find(u => u.email === attendee.email)
      if (user && user.email) {
        members.push({
          name: user.userName || user.email,
          email: user.email
        })
      } else if (attendee.email) {
        members.push({
          name: attendee.name || attendee.email,
          email: attendee.email
        })
      }
    }
    return members
  }

  get labels(): { classes: string[], name: string, icon: string | null }[] {
    const labels: { classes: string[], name: string, icon: string | null }[] = []
    if (this.item.priority === 'HIGH') {
      labels.push({
        icon: 'cil-list-high-priority',
        classes: [ 'badge-danger', 'text-white' ],
        name: i18n.$gettext('High')
      })
    } else if (this.item.priority === 'MEDIUM') {
      labels.push({
        icon: 'cil-list-high-priority',
        classes: [ 'badge-warning', 'text-dark' ],
        name: i18n.$gettext('Medium')
      })
    } else if (this.item.priority === 'LOW') {
      labels.push({
        icon: 'cil-list-high-priority',
        classes: [ 'badge-success', 'text-white' ],
        name: i18n.$gettext('Low')
      })
    }
    for (const label of (this.item.categories || [])) {
      if (!this.taskBoard?.meta?.taskLists?.map(list => list.name).includes(label)) {
        labels.push({
          classes: [ 'badge-info', 'text-white' ],
          name: label,
          icon: null
        })
      }
    }
    return labels
  }

  get badgeClassForDueDate(): string[] {
    if (this.item.due) {
      const due: number = new Date(this.item.due).getTime()
      const now: number = new Date().getTime()
      if (due <= now && this.item.percentCompleted !== 100) {
        return [ 'badge-danger', 'text-white' ]
      } else if (due < now + 86400000 && this.item.percentCompleted !== 100) {
        return [ 'badge-warning', 'text-dark' ]
      } else {
        return [ 'badge-success', 'text-white' ]
      }
    } else {
      return []
    }
  }

  get taskBoard(): TaskBoard | null {
    return this.item.originalParentId ? (taskBoardServiceApi.getTaskBoard(this.item.originalParentId) || null) : null
  }

  get startDate(): string {
    return this.item?.start ? dayjs(this.item.start).format('L') : ''
  }

  get dueDate(): string {
    return this.item?.due ? dayjs(this.item.due).format('L') : ''
  }

  get completedDate(): string {
    return this.item?.completed ? dayjs(this.item.completed).format('L') : ''
  }

  get image(): CachedImage | null {
    const imageAttachment: Attachment | undefined = (this.item?.attachments || []).find((a: Attachment) => {
      return a.file && a.file.uri && a.file.formatType?.startsWith('image/')
    })
    if (imageAttachment) {
      return imageLoadingService.getCachedImage(imageAttachment.file?.uri || '')
    } else {
      return null
    }
  }

  get progressStyle(){
    return { 'width': this.item.percentCompleted + '%', 'background-color': (this.item.color || '#47BBC7') }
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.task-card {
  cursor: move;

  border-left-width: 4px !important;
  border-left-style: solid !important;
}

.rotate-3.scale-110 {
  transform: rotate(3deg) scale(1.1);
  transition-duration: .1s;
  background-color: white;
}

.-rotate-2.scale-90 {
  transform: rotate(2deg) scale(0.9);
  transition-duration: .1s;
}

.task-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;

  & .task-progress-bar {
    background-color: $uniki_primary;
    height: 100%;
    width: 0;
  }
}
</style>
