
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import DownloadLinkForm from "@/components/filemanger/subcomponents/DownloadLinkForm.vue"
import UploadLinkFrom from "@/components/filemanger/subcomponents/UploadLinkForm.vue"
import ShareWithUsersForm from "@/components/filemanger/subcomponents/ShareWithUsersForm.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ShareWithGroupsForm from "@/components/filemanger/subcomponents/ShareWithGroupsForm.vue"

@Options({
  components: {
    Dialog, TabView, TabPanel, DownloadLinkForm, UploadLinkFrom, ShareWithUsersForm, ShareWithGroupsForm
  },
  //@ts-ignore
  props: {
    inode: [ INode, Object ],
  },
  emits: ['hide']
})
export default class ShareModal extends Vue {

  i18n: Language = useGettext()

  inode!: INode

  show = false

  toggle(): void {
    this.show = !this.show
  }

}
