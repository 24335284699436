<template>
  <div v-if="isLoading">
    <ProgressBar mode="indeterminate" />
  </div>
  <UserSharingInput
    v-if="permissions !== null"
    v-model="permissions"
    :access-options="permissionOptions"
    only-allow-suggestions
    @added="addShare"
    @updated="doUpdate"
    @removed="doRevoke"
  >
    <template #header>
      <p class="mt-4">
        <translate>Select the users you want to share this folder with</translate>
      </p>
    </template>
  </UserSharingInput>
</template>

<script lang="ts">
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import {fileServiceApi} from "@/api/FileServiceApi"
import {userServiceApi} from "@/api/UserServiceApi"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import LoadingButton from "@/components/common/LoadingButton.vue"
import InputSwitch from "primevue/inputswitch"
import InputNumber from "primevue/inputnumber"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import RpcError from "@/api/RpcError"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import RpcCallStatus from "@/api/RpcCallStatus"
import Permission from "@/model/entry/Permission"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import {Watch} from "vue-property-decorator"
import useToast from "@/util/toasts"

@Options({
  components: {
    //@ts-ignore
    Dialog, TabView, TabPanel, LoadingButton, InputSwitch, InputNumber, Password, ProgressBar, UserSharingInput
  },
  //@ts-ignore
  props: {
    inode: INode,
  },
  emits: ["shouldClose"]
})
export default class ShareWithUsersForm extends Vue {

  toast = useToast()
  i18n: Language = useGettext()
  fileApi = fileServiceApi
  userApi = userServiceApi

  inode!: INode

  permissions: any[] | null = null
  rpcStatus: RpcCallStatus = RpcCallStatus.UNINITIALIZED

  permissionOptions = [
    {
      id: "READ",
      name: this.i18n.$gettext("Read only")
    },
    {
      id: "WRITE",
      name: this.i18n.$gettext("Read-Write")
    }
  ]

  mounted(): void {
    if (this.rpcStatus !== RpcCallStatus.SUCCESS) {
      this.watchINode(this.inode)
      this.loadShareData().catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to retrieve share information"))
      })
    }
  }

  loadShareData(): Promise<any> {
    if (this.inode.path === null) {
      return Promise.reject()
    } else {
      this.rpcStatus = RpcCallStatus.LOADING
      return this.fileApi._listSharedUsersOfINode(this.inode.path).then((permissions: Permission[]) => {
        this.permissions = permissions.map((permission: Permission) => {
          return {
            commonName: permission.userName,
            userName: permission.userName,
            accessRight: permission.mode
          }
        })
        this.rpcStatus = RpcCallStatus.SUCCESS
      }).catch((error: RpcError) => {
        this.permissions = null
        this.rpcStatus = RpcCallStatus.ERROR
        this.toast.error(error.message)
      })
    }
  }

  get isLoading(): boolean {
    return this.rpcStatus === RpcCallStatus.LOADING
  }

  addShare(sharee: any): Promise<void> {
    if (this.inode.path && sharee.userName && sharee.accessRight) {
      return this.fileApi._shareINodeToUsers(this.inode.path, [sharee.userName], sharee.accessRight).then(() => {
        this.toast.success("Permissions granted")
        void this.loadShareData()
      }).catch((error: RpcError) => {
        this.toast.error(error.message)
      })
    } else {
      return Promise.reject()
    }
  }

  doUpdate(sharee: any): Promise<void> {
    if (this.inode.path && sharee.userName && sharee.accessRight) {
      return this.fileApi._updateSharedINodeToUserPermission(this.inode.path, sharee.userName, sharee.accessRight).then(() => {
        this.toast.success(this.i18n.$gettext("Permissions updated"))
        void this.loadShareData()
      }).catch((error: RpcError) => {
        this.toast.error(error.message)
      })
    } else {
      return Promise.reject()
    }
  }

  doRevoke(sharee: any): Promise<void> {
    if (this.inode.path && sharee.userName) {
      return this.fileApi._unshareINodeFromUser(this.inode.path, sharee.userName).then(() => {
        this.toast.success(this.i18n.$gettext("Permissions revoked"))
        void this.loadShareData()
      }).catch((error: RpcError) => {
        this.toast.error(error.message)
      })
    } else {
      return Promise.reject()
    }
  }

  @Watch('inode')
  watchINode(inode: INode) {
    if (inode) {
      void this.loadShareData()
    } else {
      this.permissions = []
    }
  }
}
</script>

<style scoped lang="scss">

</style>
