
import {Draggable} from 'vue3-smooth-dnd'
import {Options, Vue} from "vue-class-component"
import Task from "@/model/entry/Task"
import ProgressBar from "primevue/progressbar"
import Avatar from "@/components/common/Avatar.vue"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import TaskBoard from "@/model/directory/TaskBoard"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import Button from "primevue/button"
import dayjs from "dayjs"
import Attachment from "@/model/common/caldav/Attachment"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import i18n from "@/util/i18n"
import Image from 'primevue/image'

@Options({
  components: {
    Draggable, ProgressBar, Avatar, Button, Image
  },
  //@ts-ignore
  props: {
    item: [ Task, Object ],
    loading: {type: Boolean, default: false},
    hasMenu: {type: Boolean, default: true}
  }
})
export default class TaskCard extends Vue {
  loading!: boolean
  hasMenu!: boolean
  item!: Task

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  get members(): { name: string, email: string }[] {
    const members: { name: string, email: string }[] = []
    for (const attendee of (this.item?.attendees || [])) {
      const user: User | undefined = this.users.find(u => u.email === attendee.email)
      if (user && user.email) {
        members.push({
          name: user.userName || user.email,
          email: user.email
        })
      } else if (attendee.email) {
        members.push({
          name: attendee.name || attendee.email,
          email: attendee.email
        })
      }
    }
    return members
  }

  get labels(): { classes: string[], name: string, icon: string | null }[] {
    const labels: { classes: string[], name: string, icon: string | null }[] = []
    if (this.item.priority === 'HIGH') {
      labels.push({
        icon: 'cil-list-high-priority',
        classes: [ 'badge-danger', 'text-white' ],
        name: i18n.$gettext('High')
      })
    } else if (this.item.priority === 'MEDIUM') {
      labels.push({
        icon: 'cil-list-high-priority',
        classes: [ 'badge-warning', 'text-dark' ],
        name: i18n.$gettext('Medium')
      })
    } else if (this.item.priority === 'LOW') {
      labels.push({
        icon: 'cil-list-high-priority',
        classes: [ 'badge-success', 'text-white' ],
        name: i18n.$gettext('Low')
      })
    }
    for (const label of (this.item.categories || [])) {
      if (!this.taskBoard?.meta?.taskLists?.map(list => list.name).includes(label)) {
        labels.push({
          classes: [ 'badge-info', 'text-white' ],
          name: label,
          icon: null
        })
      }
    }
    return labels
  }

  get badgeClassForDueDate(): string[] {
    if (this.item.due) {
      const due: number = new Date(this.item.due).getTime()
      const now: number = new Date().getTime()
      if (due <= now && this.item.percentCompleted !== 100) {
        return [ 'badge-danger', 'text-white' ]
      } else if (due < now + 86400000 && this.item.percentCompleted !== 100) {
        return [ 'badge-warning', 'text-dark' ]
      } else {
        return [ 'badge-success', 'text-white' ]
      }
    } else {
      return []
    }
  }

  get taskBoard(): TaskBoard | null {
    return this.item.originalParentId ? (taskBoardServiceApi.getTaskBoard(this.item.originalParentId) || null) : null
  }

  get startDate(): string {
    return this.item?.start ? dayjs(this.item.start).format('L') : ''
  }

  get dueDate(): string {
    return this.item?.due ? dayjs(this.item.due).format('L') : ''
  }

  get completedDate(): string {
    return this.item?.completed ? dayjs(this.item.completed).format('L') : ''
  }

  get image(): CachedImage | null {
    const imageAttachment: Attachment | undefined = (this.item?.attachments || []).find((a: Attachment) => {
      return a.file && a.file.uri && a.file.formatType?.startsWith('image/')
    })
    if (imageAttachment) {
      return imageLoadingService.getCachedImage(imageAttachment.file?.uri || '')
    } else {
      return null
    }
  }

  get progressStyle(){
    return { 'width': this.item.percentCompleted + '%', 'background-color': (this.item.color || '#47BBC7') }
  }
}
