export default class LibraryHistoryData {
  historyId!: string | null;
  repoId!: string | null;
  name!: string | null;
  time!: string | null;
  description!: string | null;
  creator!: string | null;
  email!: string | null;
  contactEmail!: string | null;

  constructor() {
    this.historyId = null;
    this.repoId = null;
    this.name = null;
    this.time = null;
    this.description = null;
    this.creator = null;
    this.email = null;
    this.contactEmail = null;
  }
}
