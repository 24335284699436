

import {Options, Vue} from "vue-class-component"
import Message from "@/model/entry/Message"
import Avatar from "@/components/common/Avatar.vue"
import dayjs from "@/util/dayjs"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {replaceColonsWithUTF8, replaceUTF8WithColons} from "@/util/emojiFromStringConverter"
import {messageServiceApi} from "@/api/MessageServiceApi"
import MessageReaction from "@/model/entry/MessageReaction"
import {rpcClient} from '@/api/WebsocketClient'
import RpcError from "@/api/RpcError"
import Formats from "@/util/formats"
import ShareModal from "@/components/filemanger/subcomponents/ShareModal.vue"
import featureDetect from "@/util/FeatureDetect"
import Button from "primevue/button"
import Textarea from "primevue/textarea"
import Dialog from "primevue/dialog"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import ChatUtil from "@/util/ChatUtil"
import Image from "primevue/image"

const max_attachment_name_length = 24

@Options({
  components: {ShareModal, Avatar, Button, Textarea, Dialog, Image},
  //@ts-ignore
  props: {
    message: [Message, Object],
    canAddReaction: Boolean,
    canRemoveReaction: Boolean,
    canDeletePosts: Boolean,
    canEditPosts: Boolean
  },
  emits: [ 'reply', 'edit', 'forward' ]
})
export default class ChatMessage extends Vue {

  message!: Message
  canAddReaction!: boolean
  canRemoveReaction!: boolean
  canDeletePosts!: boolean
  canEditPosts!: boolean

  i18n: Language = useGettext()
  messageApi = messageServiceApi
  rpcClient = rpcClient
  toast = useToast()
  confirm = useConfirm()

  showEditModal = false
  showMoreInFloatingMenu = false
  deleteIsLoading = false
  hovered = false

  get users(): Map<string, User> {
    const users: User[] = userServiceApi.getUsers().data || []
    const map: Map<string, User> = new Map<string, User>()
    users.forEach((u: User) => {
      if (u.userName) map.set(u.userName, u)
    })
    return map
  }

  get citedPerson(): string {
    let message: Message | null  = this.citedMessage
    if (!message || message.userId === null) return ""
    const user = this.users.get(message.userId)
    if (!user || !user.displayName) return message.userId
    return user.displayName
  }

  get safeRenderCitedMessageText(): string {
    let message: Message | null  = this.citedMessage
    if (!message ) return ""
    let content = ChatUtil.safeRenderMessageText(message.text, true, this.isSystemMessage, true)
    return content //TODO: Maybe shorten this?
  }

  get citedMessage(): Message | null {
    return this.message.parentMessage
  }

  get isSystemMessage(): boolean {
    if (!this.message) return false
    return this.message.type !== "DEFAULT"
  }

  get isNewDay(): boolean {
    return this.message.isFirstMessageOfTheDay || false
  }

  get messageDate(): string | null {
    return this.message.created ? dayjs(this.message.created).format("dddd, D. MMMM") : null
  }

  get isFollowUpMessage(): boolean {
    return this.message.isFollowup || false
  }

  get messageTime(): string | null {
    return this.message.created ? dayjs(this.message.created).format("HH:mm") : null
  }

  get fullMessageDateTime(): string | null {
    return this.message.created ? dayjs(this.message.created).format("dddd, D. MMMM, YYYY HH:mm") : null
  }

  get editDateTime(): string | null {
    return this.message.edited ? dayjs(this.message.edited).format("DD. MM. YYYY, HH:mm") : null
  }

  get deleteDateTime(): string | null {
    return this.message.deleted ? dayjs(this.message.deleted).format("DD. MM. YYYY, HH:mm") : null
  }

  get messageAuthor(): string {
    if (this.isSystemMessage) return this.i18n.$gettext("SYSTEM")
    if (!this.message.userId) {
      if(this.message.props.from_bot) {
        return "UniKI"
      } else {
        return ""
      }
    }
    const user = this.users.get(this.message.userId)
    if (!user || !user.displayName) return this.message.userId
    return user.displayName
  }

  get isAIMessage(): boolean{
    try {
      return this.message.props.from_bot ? this.message.props.from_bot : false
    } catch (e) {
      return false
    }
  }

  get renderedText(): string {
    if (this.message.deleted) {
      return this.i18n.$gettext("Deleted Message") + (this.deleteDateTime ? " (" + this.deleteDateTime + ")" : '')
    } else {
      return ChatUtil.safeRenderMessageText(this.message.text, false, this.isSystemMessage, true)
    }
  }

  get shouldDirectlyDisplayImage() {
    return this.message.files ? (this.message.files.length === 1 && ["jpeg", "jpg", "png", "svg"].includes(this.message.files[0].extension || "")): false
  }

  get hasAttachedFiles(): boolean {
    return this.message.files ? this.message.files.length > 0 : false
  }

  get supportsDirectDownload(): boolean {
    return featureDetect.supportsNativeDownloadAttribute
  }

  get hasLinkedFile(): boolean {
    if (!this.message.props) return false
    return this.message.props.hasOwnProperty("linkedfile")
  }

  getEmojiForName(name: string) {
    const emojiWithColons = `:${name}:`
    return replaceColonsWithUTF8(emojiWithColons)
  }

  formatAttachmentName(name: string) {
    if (name.length > max_attachment_name_length) {
      return name.substr(0, max_attachment_name_length) + "..."
    } else {
      return name
    }
  }

  formatAttachmentSize(size: number) {
    return Formats.formatBytes(size)
  }

  addReaction(emoji: string) {
    let userName = this.rpcClient.session.user?.userName
    const emojiName: string = replaceUTF8WithColons(emoji).replaceAll(":", "")
    let alreadyReactedLikeThis = false
    //Check if necessary:
    this.message.reactions?.forEach((reaction: MessageReaction) => {
      if (reaction.emojiName === emojiName && userName) {
        if (reaction.userIds && reaction.userIds.indexOf(userName) > -1) {
          alreadyReactedLikeThis = true
        }
      }
    })
    if (!this.message.id || alreadyReactedLikeThis) return
    this.messageApi._addReaction(this.message.id, emojiName).then(() => {
      this.message.reactions?.forEach((reaction: MessageReaction) => {
        if (reaction.emojiName === emojiName && userName) {
          reaction.userIds?.push(userName) //We made sure above that we do not react a second time!
        }
      })

    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not add reaction"))
    })
  }

  removeReaction(reaction: MessageReaction) {
    if (this.canRemoveReaction) {
      let thisUsername = this.rpcClient.session.user?.userName
      if (!reaction.userIds || !thisUsername || !this.message.id || !reaction.emojiName || reaction.userIds?.indexOf(thisUsername) === -1 ) {
        return //This user has not reacted to this message or something else went wrong
      }

      this.messageApi._removeReaction(this.message.id, reaction.emojiName).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to remove reaction"))
      })
    }
  }

  getTooltipTextForReaction(reaction: MessageReaction) {
    const username = this.rpcClient.session.user?.userName
    let otherUsers = [...( reaction.userIds || [])]
    let isInUsers: number = ( !!username ? otherUsers.indexOf(username) : -1)

    if (isInUsers == 0 && reaction.userIds?.length == 1) {
      return this.i18n.$gettext("You reacted. (Click to remove)")
    }

    if (isInUsers > -1) {
      otherUsers.splice(isInUsers, 1)
    }

    let text: string = otherUsers.join(", ")
    if (isInUsers > -1) {
      text += " " + this.i18n.$gettext("and you. (Click to remove)")
    }
    return text
  }

  reply() {
    this.$emit('reply', this.message.rootId || this.message.id)
  }

  forward() {
    this.$emit('forward', Object.assign(new Message(), this.message))
  }

  edit() {
    this.$emit('edit', Object.assign(new Message(), this.message))
  }

  deleteMessage() {
    this.confirm.require({
      message: this.i18n.$gettext("Do you want to delete this message"),
      header: this.i18n.$gettext("Are you sure?"),
      icon: 'cil-warning',
      accept: () => {
        if (!this.message?.id) {
          return
        }
        this.deleteIsLoading  = true
        messageServiceApi._deleteMessage(this.message.id).then(() => {
          this.toast.success(this.i18n.$gettext("Message deleted"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Message could not be deleted"))
        }).finally(() => {
          this.deleteIsLoading = false
        })
        //callback to execute when user confirms the action
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  get meetingLink(): string | null {
    return this.message.props?.meeting_link
  }

  copyMeetingLink() {
    if (this.meetingLink) {
      const el = document.createElement('textarea')
      el.value = this.meetingLink
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    } else {
      this.toast.error(this.i18n.$gettext('Could not copy link to clipboard.'))
    }
  }
}
