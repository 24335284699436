export default class SignatureConfig {
  name!: string | null;
  fromRegex!: string | null;
  toRegex!: string | null;
  notToRegex!: string | null;
  htmlSignature!: string | null;
  textSignature!: string | null;

  constructor() {
    this.name = null;
    this.fromRegex = null;
    this.toRegex = null;
    this.notToRegex = null;
    this.htmlSignature = null;
    this.textSignature = null;
  }
}
