

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import Button from "primevue/button"
import UserMailFilterEditor from "@/components/settings/UserMailFilterEditor.vue"
import RpcError from "@/api/RpcError"
import {useConfirm} from "primevue/useconfirm"
import Skeleton from "primevue/skeleton"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"

@Options({
  //@ts-ignore
  props: {
    address: {type: String, default: ''},
    accessDescription: {type: String, default: ''}
  },
  components: {
    UserMailFilterEditor, Button, Skeleton
  }
})
export default class VirtualMailboxesPasswordItem extends Vue {

  address!: string
  accessDescription!: string

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  deleting: boolean = false

  confirmDelete(event: Event | undefined): void {
    this.confirm.require({
      //@ts-ignore
      target: event?.currentTarget || undefined,
      message: this.i18n.$gettext("Do you really want to delete this password?") + '\n"' + this.accessDescription + '"',
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-exclamation',
      accept: () => {
        this.deletePassword()
      },
      reject: () => {}
    })
  }

  deletePassword() {
    this.deleting = true
    void virtualMailboxServiceApi._deleteVirtualMailboxPassword(this.address, this.accessDescription).then(() => {
      this.toast.success(this.i18n.$gettext("Deleted password."))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete password."))
      this.deleting = false
    })
  }
}
