import GeneratedChannelStore from "./generated/GeneratedChannelStore"
import Channel from "@/model/directory/Channel"
import Message from "@/model/entry/Message"
import {messageServiceApi} from "@/api/MessageServiceApi"

class ChannelStore extends GeneratedChannelStore {
    addOrReplaceChannel(newChannel: Channel, persist: boolean = true): true | undefined {
        if (newChannel.id && newChannel.unreadMsgCount) {
            const messages: Message[] = messageServiceApi.getMessagesInChannel(newChannel.id, null, null, "created:desc",  -1).data || []
            if (messages.length > 0 && messages[0].id) {
                messageServiceApi.getMessagesInChannelAfter(newChannel.id, messages[0].id, 10000)
            } else {
                messageServiceApi.getMessagesInChannel(newChannel.id, null, null, "created:desc", 10000)
            }
        }
        return super.addOrReplaceChannel(newChannel, persist)
    }
}

export const channelStore = new ChannelStore()
