

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {ref} from "@vue/reactivity"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  components: { Button },
  //@ts-ignore
  props: {
    headerLabel: {
      type: String,
      default: ""
    },
    hideCreateButton: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ["created-clicked"]
})
export default class InnerLayoutWithSidebar extends Vue {

  //@ts-ignore
  sidepanelcontent: HTMLElement = ref<HTMLElement>()
  showDropdown: boolean = false
  defaultOpen: boolean = false

  handleCreatedClicked(){
    this.$emit("created-clicked")
  }

  handleCollapseButtonClicked(){
    this.showDropdown = !this.showDropdown
  }

  handleClickOnLinkElement(event: Event){
    this.handleCollapseButtonClicked()
  }

  get isOnMobile(): boolean {
    return breakpointUtil.isOnMobile()
  }

  mounted(){
    this.$nextTick(() => {
      this.showDropdown = this.defaultOpen
    })
  }

}
