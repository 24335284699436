import SchedulingObject from '@/model/SchedulingObject';

export default class Email {
  originalId!: string | null;
  originalParentId!: string | null;
  sentDate!: string | null;
  receivedDate!: string | null;
  messageId!: string | null;
  sender!: string | null;
  senderName!: string | null;
  senderEmail!: string | null;
  replyTo!: string[] | null;
  replyToEmail!: string[] | null;
  from!: string[] | null;
  fromEmail!: string[] | null;
  fromName!: string[] | null;
  recipients!: string[] | null;
  to!: string[] | null;
  toEmail!: string[] | null;
  cc!: string[] | null;
  ccEmail!: string[] | null;
  bcc!: string[] | null;
  headers!: any | null;
  subject!: string | null;
  threadId!: string | null;
  attachments!: string[] | null;
  hasAttachment!: boolean | null;
  isSpam!: boolean | null;
  size!: number | null;
  systemFlags!: string[] | null;
  userFlags!: string[] | null;
  textBody!: string | null;
  htmlBody!: string | null;
  charset!: string | null;
  rawEmail!: string | null;
  backend!: string | null;
  queryId!: string | null;
  score!: number | null;
  isPreview!: boolean | null;
  userName!: string | null;
  toUserName!: string | null;
  fileTokens!: string[] | null;
  seen!: boolean | null;
  priority!: number | null;
  requestDeliveryReceipt!: string | null;
  requestReadReceipt!: string | null;
  schedulingObject!: SchedulingObject | null;

  constructor() {
    this.originalId = null;
    this.originalParentId = null;
    this.sentDate = null;
    this.receivedDate = null;
    this.messageId = null;
    this.sender = null;
    this.senderName = null;
    this.senderEmail = null;
    this.replyTo = null;
    this.replyToEmail = null;
    this.from = null;
    this.fromEmail = null;
    this.fromName = null;
    this.recipients = null;
    this.to = null;
    this.toEmail = null;
    this.cc = null;
    this.ccEmail = null;
    this.bcc = null;
    this.headers = null;
    this.subject = null;
    this.threadId = null;
    this.attachments = null;
    this.hasAttachment = null;
    this.isSpam = null;
    this.size = null;
    this.systemFlags = null;
    this.userFlags = null;
    this.textBody = null;
    this.htmlBody = null;
    this.charset = null;
    this.rawEmail = null;
    this.backend = null;
    this.queryId = null;
    this.score = null;
    this.isPreview = null;
    this.userName = null;
    this.toUserName = null;
    this.fileTokens = null;
    this.seen = null;
    this.priority = null;
    this.requestDeliveryReceipt = null;
    this.requestReadReceipt = null;
    this.schedulingObject = null;
  }
}
