import TurndownService from "turndown"

const escape = TurndownService.prototype.escape
TurndownService.prototype.escape = function (string) {
    const tokens = string.split(/(\s+)/).map((token: string) => {
        if (token.startsWith('http://') || token.startsWith('https://')) {
            return token
        } else {
            return escape(token)
        }
    })
    let result = ''
    for (const token of tokens) { //can't use join with empty string
        result += token
    }
    return result
}

const turndownService = new TurndownService({ bulletListMarker: '-' })

turndownService.addRule('strikethrough', {
    //@ts-ignore
    filter: ['del', 's', 'strike'],
    replacement: function (content) {
        return '~' + content + '~'
    }
})
//TODO: ++ is not standard markdown, does our chat support it?
turndownService.addRule('underline', {
    filter: ['u', 'ins'],
    replacement: function (content) {
        return '++' + content + '++'
    }
})
turndownService.addRule('code', {
    filter: ['pre'],
    replacement: function (content) {
        return '```\n' + content + '\n```'
    }
})
turndownService.addRule('taskList', {
    filter: function (node: any) {
        return node.className?.includes('taskItem') && node.attributes['data-checked']
    },
    replacement: function (content: any, node: any) {
        return (node.attributes['data-checked'].value === 'true' ? '- [x]' : '- [ ]') + (content ? (' ' + content.trim()) : '') + '\n'
    }
})

export default turndownService
