import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MailAlias from '@/model/settings/MailAlias';
import Page from '@/model/Page';
import { mailAliasStore } from '@/store/MailAliasStore';

export default class GeneratedMailAliasServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getMailAliases(): Promise<MailAlias[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMailAliases', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const mailAliass: MailAlias[] = data.map(mailAlias => Object.assign(new MailAlias(), mailAlias))
                mailAliasStore.replaceMailAliass(mailAliass)
                return mailAliass
            } else return Promise.reject()
        })
    }


    getMailAliases(refresh?: boolean | number): SWR<MailAlias[], string[]> {
        //@ts-ignore
        const result: SWR<MailAlias[], string[]> = reactive(new SWR<MailAlias[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getMailAliases' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMailAliases[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const mailAliass: MailAlias[] = []
                for (const id of data) {
                    const mailAlias: MailAlias | undefined = mailAliasStore.state.mailAliass.get(id)
                    if (mailAlias) {
                        mailAliass.push(mailAlias)
                    }
                }
                result.data = mailAliass
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMailAliases().then((data: MailAlias[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(mailAlias => mailAlias.name || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let mailAliass: MailAlias[] = [...mailAliasStore.state.mailAliass.values()]
        
        
        
        result.data = mailAliass
        return result
    }

    getMailAlias(name: string): MailAlias | undefined {
        return mailAliasStore.state.mailAliass.get(name)
    }
}
