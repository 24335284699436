<template>
  <Dialog
    :header="i18n.$gettext('Manage mailbox passwords')"
    style="height: 20rem; min-height: 80vh; max-width: 90%; min-width: 30%;"
    :modal="true"
    :draggable="false"
    @hide="hide()"
  >
    <div class="d-flex justify-center align-center mb-3">
      <span class="p-float-label m-1" style="flex-grow: 2">
        <InputText v-model="newAccess" class="w-100" />
        <label><translate>Describe the purpose of the access to create</translate></label>
      </span>
      <Button
        v-tooltip.left="i18n.$gettext('Create new password')"
        :disabled="!canCreate"
        :loading="creating"
        icon="cil-save"
        class="p-button p-button-success btn-raised m-1"
        @click="createNewPassword"
      />
    </div>
    <hr>
    <VirtualMailboxesPasswordItem
      v-for="description in accessDescriptions"
      :key="description"
      :address="address"
      :access-description="description"
      class="m-1"
    />
  </Dialog>
</template>

<script lang="ts">

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import Button from "primevue/button"
import VirtualMailboxesPasswordItem from "@/components/settings/virtualmailboxes/VirtualMailboxesPasswordItem.vue"
import RpcError from "@/api/RpcError"
import {useConfirm} from "primevue/useconfirm"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import Skeleton from "primevue/skeleton"

@Options({
  //@ts-ignore
  props: {
    accessDescriptions: {type: Array, default: []},
    address: {type: String, default: ''}
  },
  components: {
    VirtualMailboxesPasswordItem, Button, Dialog, InputText, Skeleton
  },
  emits: [ 'hide' ]
})
export default class VirtualMailboxesPasswordManager extends Vue {

  accessDescriptions!: string[]
  address!: string

  newAccess: string = ''
  password: string = ''

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  creating: boolean = false

  hide() {
    this.showManagePwDialog = false
    this.$emit('hide')
  }

  get canCreate(): boolean {
    if (!this.newAccess || (this.accessDescriptions && this.newAccess && this.accessDescriptions.includes(this.newAccess))) {
      return false
    }
    return true
  }

  createNewPassword(event: Event | undefined) {
    this.creating = true
    void virtualMailboxServiceApi.generateVirtualMailboxPassword(this.address, this.newAccess).then((password: string) => {
      this.confirm.require({
        //@ts-ignore
        target: event?.currentTarget || undefined,
        message: this.i18n.$gettext("After closing this dialog you will not be able to look this password up again!") + '\n"' + password + '"',
        header: this.i18n.$gettext("Your new password"),
        icon: 'cil-exclamation',
        accept: () => {},
        acceptLabel: this.i18n.$gettext("Close"),
        rejectClass: "hidden-button"
      })
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create password."))
    }).finally(() => {
      this.creating = false
    })
  }
}
</script>

<style scoped lang="scss">
.hidden-button {
  visibility: hidden;
}
</style>
