import DataImportJob$Progress from '@/model/DataImportJob$Progress';

export default class DataImportJob {
  id!: number | null;
  created!: any | null;
  createdBy!: string | null;
  displayName!: string | null;
  dataSourceInstanceId!: number | null;
  sourcePath!: string | null;
  targetPath!: string | null;
  targetUser!: string | null;
  progress!: DataImportJob$Progress | null;
  lastRunFinished!: any | null;
  lastRunMessage!: string | null;
  logFile!: string | null;
  canStart!: boolean | null;
  canStop!: boolean | null;
  canDelete!: boolean | null;

  constructor() {
    this.id = null;
    this.created = null;
    this.createdBy = null;
    this.displayName = null;
    this.dataSourceInstanceId = null;
    this.sourcePath = null;
    this.targetPath = null;
    this.targetUser = null;
    this.progress = null;
    this.lastRunFinished = null;
    this.lastRunMessage = null;
    this.logFile = null;
    this.canStart = null;
    this.canStop = null;
    this.canDelete = null;
  }
}
