import CalendarSettings from '@/model/settings/dashboard/CalendarSettings';
import TaskSettings from '@/model/settings/dashboard/TaskSettings';
import MailSettings from '@/model/settings/dashboard/MailSettings';
import ChatSettings from '@/model/settings/dashboard/ChatSettings';

export default class DashboardSettings {
  widgetOrder!: string[] | null;
  calendar!: CalendarSettings | null;
  tasks!: TaskSettings | null;
  mail!: MailSettings | null;
  chat!: ChatSettings | null;
  selectedCalendars!: string[] | null;
  daysToWatchEvents!: number | null;
  myEventsOnly!: boolean | null;
  selectedTaskBoards!: string[] | null;
  daysToWatchTasks!: number | null;
  selectedChannels!: string[] | null;

  constructor() {
    this.widgetOrder = null;
    this.calendar = null;
    this.tasks = null;
    this.mail = null;
    this.chat = null;
    this.selectedCalendars = null;
    this.daysToWatchEvents = null;
    this.myEventsOnly = null;
    this.selectedTaskBoards = null;
    this.daysToWatchTasks = null;
    this.selectedChannels = null;
  }
}
