
import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import PrimeVueColorPicker from "primevue/colorpicker"

@Options({
  //@ts-ignore
  props: {
    modelValue: String
  },
  components: {
    PrimeVueColorPicker
  }
})
export default class ColorPicker extends Vue {

  i18n: Language = useGettext()

  modelValue!: string

  color: string | null = null
  colorOptions: string[] = [
    '#00AAA0', '#ffb74d', '#32b865', '#505050', '#c62828', '#462066', '#31d6af', '#cb4d4d', '#34b27d', '#dbdb57', '#e09952', '#9933cc', '#4d77cb'
  ]

  updateColor(color: string) {
    this.$emit('update:modelValue', color)
  }
}
