<template>
  <div class="d-flex flex-column h-100 p-2">
    <div class="container-fluid flex-grow-1 card card-lite bg-light z-2 w-100" style="overflow: auto">
      <div class="row" data-v-6956d1df=""><h1 class="col-12 mt-4 mb-0 h5 d-block" data-v-6956d1df=""><span data-v-6956d1df="">Willkommen, {{ userDisplayName }}!</span></h1></div>
      <div v-if="!hasNothing" class="row mb-4">
        <div v-if="showDataImportWidget" class="col-12 col-md-6 d-flex flex-column">
          <DataImportWidget class="mr-md-4 mr-3 mt-6" />
        </div>
        <div v-if="showMigrationWidget" class="col-12 col-md-6 d-flex flex-column">
          <MigrationWidget class="mr-md-4 mr-3 mt-6" />
        </div>
        <div v-if="showIntroVideo" class="col-12 col-md-6 d-flex flex-column">
          <IntroVideoWidget class="mr-md-4 mr-3 mt-6" />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column">
          <CalendarWidget
            v-if="hasBookedDav"
            ref="calendarWidgetRef"
            class="mr-md-2 mr-1"
          />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column">
          <TaskWidget
            v-if="hasBookedDav"
            ref="taskWidgetRef"
            class="mr-md-2 mr-1"
          />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column">
          <EmailWidget
            v-if="hasBookedEmails"
            ref="eMailWidgetRef"
            class="mr-md-2 mr-1"
          />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column">
          <ChatWidget
            v-if="hasBookedChat"
            ref="chatWidgetRef"
            class="mr-md-2 mr-1"
          />
        </div>
      </div>
      <div v-else class="d-flex flex-grow-1 flex-column justify-content-center h-100">
        <div class="text-center">
          <p class="h5 mb-2">
            <translate>Dashboard has nothing to show</translate>
          </p>
          <p><translate>You have neither Files, nor Calendars, Tasks or Chat booked. So we cannot display anything here.</translate></p>
        </div>
      </div>
    </div>
    <teleport to="#menubarcontent">
      <SearchBar class="pt-2" />
    </teleport>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import EmailWidget from "@/components/dashboard/widgets/EmailWidget.vue"
import ChatWidget from "@/components/dashboard/widgets/ChatWidget.vue"
import CalendarWidget from "@/components/dashboard/widgets/CalendarWidget.vue"
import TaskWidget from "@/components/dashboard/widgets/TaskWidget.vue"
import featureSubset from "@/util/FeatureSubsets"
import IntroVideoWidget from "@/components/dashboard/widgets/IntroVideoWidget.vue"
import MigrationWidget from "@/components/dashboard/widgets/MigrationWidget.vue"
import SettingsUtil from "@/util/SettingsUtil"
import Constants from "@/util/Constants"
import migrationStatusService from "@/util/migrationStatus"
import {rpcClient} from "@/api/WebsocketClient"
import SearchBar from "@/components/common/SearchBar.vue"
import Avatar from "primevue/avatar"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ProgressSpinner from 'primevue/progressspinner'
import {ref} from "@vue/reactivity"
import Button from "primevue/button"
import DataImportWidget from "@/components/dashboard/widgets/DataImportWidget.vue"

@Options({
  components: {
    EmailWidget, ChatWidget, CalendarWidget, TaskWidget, IntroVideoWidget, MigrationWidget, SearchBar, Avatar,
    ProgressSpinner, Button, DataImportWidget
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DashboardView extends Vue {
  i18n: Language = useGettext()

  //@ts-ignore
  eMailWidgetRef: EmailWidget = ref<EmailWidget | null>(null)
  //@ts-ignore
  chatWidgetRef: ChatWidget = ref<ChatWidget | null>(null)
  //@ts-ignore
  calendarWidgetRef: CalendarWidget = ref<CalendarWidget | null>(null)
  //@ts-ignore
  taskWidgetRef: TaskWidget = ref<TaskWidget | null>(null)

  goToCalendar(): void {
    void this.$router.push('/calendar')
  }

  goToChat(): void {
    void this.$router.push('/chat')
  }

  goToMail(): void {
    void this.$router.push(this.eMailWidgetRef.inboxLink)
  }

  goToTasks(): void {
    void this.$router.push('/tasks')
  }

  get userImage(): CachedImage | null {
    const userName: string | undefined | null = this.userName
    if (userName) {
      return imageLoadingService.getCachedImage(`/groupware-api/v1/users/${userName}/picture`)
    } else {
      return null
    }
  }

  get cachedImageInstance(): CachedImage | null {
    return this.userImage || null
  }

  get userName(): string | null {
    return rpcClient.session?.user?.userName || null
  }

  get userDisplayName(): string | null {
    return rpcClient.session?.user?.displayName || null
  }

  get hasBookedEmails() {
    return featureSubset.hasMail
  }

  get hasBookedChat() {
    return featureSubset.hasChat
  }

  get hasBookedDav() {
    return featureSubset.hasDAV
  }

  get showIntroVideo() {
    return !SettingsUtil.getIntroVideoSeen() && Constants.introductionVideoUrl !== ""
  }

  get showDataImportWidget() {
    return SettingsUtil.showDataImportWidget()
  }

  get showMigrationWidget() {
    return migrationStatusService.hasAnyMigrationStatus && !this.hasAcknowledgedMigrationBanner //Show as long as any migration runs
  }

  get hasAcknowledgedMigrationBanner(): boolean {
    const settings = rpcClient.session?.user?.settings
    if(settings) {
      if(settings.hasOwnProperty("acknowledgedMigrationModal")) {
        return settings.acknowledgedMigrationModal
      } else {
        return false
      }
    } else {
      return false
    }
  }

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  get hasNothing() {
    return !this.hasBookedEmails && !this.hasBookedDav && !this.hasBookedChat
  }
}
</script>

<style lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.dash-widget {
  position: relative;
  min-height: 30rem;
  max-height: 50vh;
  flex-grow: 1;

  .dash-widget-count {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    //width: 3rem;
    height: 3rem;
    background: $uniki_primary_dark;
    z-index: 10;
    border-radius: 3px;
    line-height: 3rem;
    font-size: 1.8rem;
    text-align: center;
    color: white;

    &:hover {
      cursor: pointer;
      background: $uniki_primary;
    }
  }
}

</style>
