export default class QueryResult {
  id!: string | null;
  queryId!: string | null;
  title!: string | null;
  description!: string | null;
  type!: string | null;
  subtype!: string | null;
  mtime!: string | null;
  backendId!: string | null;

  constructor() {
    this.id = null;
    this.queryId = null;
    this.title = null;
    this.description = null;
    this.type = null;
    this.subtype = null;
    this.mtime = null;
    this.backendId = null;
  }
}
