
import {Options, Vue} from "vue-class-component"
import Task from "../../model/entry/Task"
import TaskBoard from "../../model/directory/TaskBoard"
import { ref } from "@vue/reactivity"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import ContextMenu from "primevue/contextmenu"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Avatar from "@/components/common/Avatar.vue"
import Tree from "@/components/common/Tree.vue"
import SWR from "@/api/SWR"
import TaskBoardView from "@/components/tasks/TaskBoardView.vue"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import TaskDetails from "@/components/tasks/TaskDetails.vue"
import {taskServiceApi} from "@/api/TaskServiceApi"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import RpcError from "@/api/RpcError"
import Dialog from "primevue/dialog"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {useConfirm} from "primevue/useconfirm"
import Menu from "primevue/menu"
import ColorPicker from "@/components/common/ColorPicker.vue"
import featureSubset from "@/util/FeatureSubsets"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import breakpointUtil from "@/util/BreakpointUtil"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import ProgressSpinner from "primevue/progressspinner"
import {eventServiceApi} from "@/api/EventServiceApi"
import AttachmentUpload from "@/util/AttachmentUpload"
import ResourceShareDialog from "@/components/common/resourceShare/ResourceShareDialog.vue"
import {rpcClient} from "@/api/WebsocketClient"

@Options({
  components: {
    ResourceShareDialog,
    //@ts-ignore
    InnerLayoutWithSidebar, TaskBoardView, TaskDetails, AnimatedInput, Tree, Avatar, ContextMenu,
    Button, InputText, Dialog, Skeleton, Menu, ColorPicker, SearchBar, TokenAttachmentList, ProgressSpinner
  }
})
export default class TaskView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  menuItems: any[] = []
  taskBoardsAreLoading: boolean = true

  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)
  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  attachments: AttachmentUpload[] = []
  importingICS: boolean = false
  uploadingICS: boolean = false
  uploadingAttachment: AttachmentUpload | null = null

  newBoardLoading = false
  showNewBoardModal = false
  newBoardName = ''

  resourceToShare: string = ''
  boardToRename: TaskBoard | null = null
  boardToColorPick: TaskBoard | null = null
  boardToImportTo: string | null = null
  renameLoading: boolean = false
  refresh: number | boolean = 10000

  taskDraft: Task | null = null

  openMobileMenuDefault: boolean = false

  searchQuery: Query | null = null

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  get showColorPicker() {
    return !!this.boardToColorPick
  }

  set showColorPicker(show: boolean) {
    if (!show) {
      this.boardToColorPick = null
    }
  }

  get columnNames(): string[] {
    let names: string[] = []
    if (this.taskBoard?.meta?.taskLists) {
      for (let list of this.taskBoard.meta?.taskLists) {
        if (list?.name) {
          names.push(list.name)
        }
      }
    }
    return names
  }

  get boards(): TaskBoard[] {
    const swr: SWR<TaskBoard[], string[]> = taskBoardServiceApi.getTaskBoards(this.refresh)
    this.taskBoardsAreLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise) {
      swr.call.promise.finally(() => {
        this.taskBoardsAreLoading = false
      })
    }
    const collections = swr.data ? [...swr.data] : []
    const orderedCollections: TaskBoard[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('taskBoard')
    if (order) {
      for (let id of order) {
        const collectionIndex: number = collections.findIndex(b => b.originalId === id || b.id === id)
        if (collectionIndex >= 0) {
          orderedCollections.push(collections[collectionIndex])
          collections.splice(collectionIndex, 1)
        }
      }
    }
    orderedCollections.push(...collections.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name)))
    if (order) void SettingsUtil.setLastViewedCollectionOrder('taskBoard', orderedCollections.map(b => b.originalId || ''))
    return orderedCollections
  }

  get task(): Task | null {
    return this.taskDraft || (this.taskId ? (taskServiceApi.getTask(this.taskId) || null) : null)
  }

  showBoardContextMenu(board: TaskBoard, event: Event) {
    this.menuItems = this.getBoardMenuItems(board)
    this.menu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.contextMenu.toggle(event)
      })
    }
  }

  showBoardMenu(board: TaskBoard, event: Event) {
    this.menuItems = this.getBoardMenuItems(board)
    this.contextMenu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  getBoardMenuItems(board: TaskBoard): any[] {
    const menuItems = []
    if (['OWNER'].includes(board.shareAccess || '')) {
      menuItems.push({
        label: this.i18n.$gettext('Share with...'),
        icon: 'cil-share',
        command: () => {
          if (board.originalId) {
            this.resourceToShare = board.originalId
          } else {
            this.resourceToShare = ''
          }
        }
      })
      menuItems.push({
        label: this.i18n.$gettext('Rename Task Board'),
        icon:'cil-pencil',
        command: () => {
          this.boardToRename = Object.assign(new TaskBoard(), board)
        }
      })
    } else if (this.isAdmin) {
      menuItems.push({
        label: this.i18n.$gettext('Share with...'),
        icon: 'cil-share',
        command: () => {
          if (board.originalId) {
            this.resourceToShare = board.originalId
          } else {
            this.resourceToShare = ''
          }
        }
      })
    }
    if (['WRITE', 'OWNER'].includes(board.shareAccess || '')) {
      menuItems.push({
        label: this.i18n.$gettext('Import ICS...'),
        icon: 'cil-cloud-upload',
        command: () => {
          this.boardToImportTo = board.originalId
          this.attachmentcontrol.openNativeFileChooser()
        }
      })
    }
    /*menuItems.push({
      label: this.i18n.$gettext('Choose Color'),
      icon:'cil-color-palette',
      command: () => {
        this.boardToColorPick = Object.assign(new TaskBoard(), board)
      }
    })*/
    if (!board.isDefault) {
      menuItems.push({
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteTaskBoard(board)
        }
      })
    }

    return menuItems
  }

  importICS() {
    const boardId = this.boardToImportTo
    if (this.attachments?.length && boardId && this.attachments[0].promise) {
      const attachment = this.attachments[0]
      this.uploadingICS = true
      this.uploadingAttachment = attachment
      attachment.promise?.then(() => {
        this.uploadingICS = false
        this.uploadingAttachment = null
        this.importingICS = true
        taskServiceApi._importTasks(boardId, attachment.handle).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Tasks could not be imported from .ics file."))
        }).finally(() => {
          this.importingICS = false
        })
      })
      this.boardToImportTo = null
      this.attachments = []
    }
  }

  saveColor() {
    if (this.boardToColorPick) {
      taskBoardServiceApi._updateTaskBoard(this.boardToColorPick).then(() => {
        this.toast.success(this.i18n.$gettext("Task board updated"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Task board could not be updated"))
      })
      this.boardToColorPick = null
    }
  }

  saveName() {
    if (this.boardToRename) {
      this.renameLoading = true
      taskBoardServiceApi._updateTaskBoard(this.boardToRename).then(() => {
        this.toast.success(this.i18n.$gettext("Task board renamed"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Task board could not be renamed"))
      }).finally(() => {
        this.renameLoading = false
      })
      this.boardToRename = null
    }
  }

  deleteTaskBoard(board: TaskBoard) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this task board?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (board.originalId) {
          taskBoardServiceApi._deleteTaskBoard(board.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Board deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Board could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  taskBoardIsActive(taskBoard: TaskBoard): boolean {
    if (!this.taskBoard || !this.taskBoard.originalId || !taskBoard || !taskBoard.originalId) return false
    return this.taskBoard.originalId === taskBoard.originalId
  }

  newTask() {
    this.taskDraft = new Task()
  }

  goToTaskBoard(board: TaskBoard) {
    if (board.originalId) {
      this.goToTaskBoardId(board.originalId)
    }
  }

  goToTaskBoardId(taskBoardId: string) {
    void this.$router.push('/tasks/' + taskBoardId)
    void SettingsUtil.setLastViewedCollection('taskBoard', taskBoardId)
  }

  openTask(task: Task) {
    void this.$router.push('/tasks/' + task.originalParentId + '/' + task.originalId)
  }

  closeTask(): void {
    this.taskDraft = null
    if (this.$route?.params?.hasOwnProperty("board")) {
      void this.$router.push('/tasks/' + this.$route.params["board"] as string)
    } else {
      void this.$router.push('/tasks')
    }
  }

  get taskBoardId(): string | null {
    return this.$route?.params?.hasOwnProperty("board") ? this.$route.params["board"] as string : null
  }

  get taskBoard(): TaskBoard | null {
    if (this.taskBoardId) {
      const boardId = this.$route.params["board"] as string
      const boards: TaskBoard[] = taskBoardServiceApi.getTaskBoards(this.refresh).data || []
      return boards.find((board: TaskBoard) => {
        return board.originalId == boardId
      }) || null
    } else {
      return null
    }
  }

  get taskBoardName(): string | null {
    return this.taskBoard?.name || null
  }

  get taskId(): string | null {
    if (this.$route?.params?.hasOwnProperty("task")) {
      return this.$route.params["task"] as string
    } else {
      return null
    }
  }

  createTaskBoard(): void {
    let toCreate = new TaskBoard()
    toCreate.name = this.newBoardName
    this.newBoardLoading = true
    void taskBoardServiceApi._createTaskBoard(toCreate).then(() => {
      this.newBoardLoading = false
      this.showNewBoardModal = false
      this.newBoardName = ""
      this.toast.success(this.i18n.$gettext("Taskboard created"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create the taskboard"))
    }).finally(() => {
      this.newBoardLoading = false
    })
  }

  get hasTasksBooked(): boolean {
    return featureSubset.hasDAV
  }

  get isOnMobile() {
    return breakpointUtil.isOnMobile()
  }

  mounted() {
    if (!this.taskBoardId) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('taskBoard')
      const taskBoard = (this.boards || []).find(t => t.originalId === lastViewed || t.id === lastViewed)
      if (!this.taskBoardsAreLoading && !taskBoard) {
        //This taskBoard no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('taskBoard', '')
      }
      if (taskBoard?.originalId) {
        this.goToTaskBoardId(taskBoard.originalId)
      } else {
        if (this.isOnMobile) {
          this.openMobileMenuDefault = true
        } else {
          //Select default board:
          if (this.boards.length > 0) {
            const defaultTaskBoard: string | null = this.boards[0].originalId
            if (defaultTaskBoard) {
              this.goToTaskBoardId(defaultTaskBoard)
            }
          }
        }
      }
    }
  }
}
