import i18n from "@/util/i18n"

export default function (){
    return [
        {
            name: i18n.$gettext("Files"),
            path: "/files",
            icon: "cil-folder cil-fw",
            manuals: [
                {
                    title: i18n.$gettext("Difference between file sync (Sync Client) and cloud drive (Drive Client)"),
                    url: null,
                    file: 'seadrive_vs_seafile_sync.md'
                },
                {
                    title: i18n.$gettext("How to import files and folders into the Uniki Collaboration Platform"),
                    url: null,
                    file: 'seafile_import.md'
                },
                {
                    title: i18n.$gettext("How to synchronize files between the Uniki Collaboration Platform and Windows (Sync Client)"),
                    url: null,
                    file: 'seafile_windows.md'
                },
                {
                    title: i18n.$gettext("Adding the cloud drive to Windows Explorer (Drive Client)"),
                    url: null,
                    file: 'seadrive-client-windows.md'
                },
                {
                    title: i18n.$gettext("How to synchronize files between the Uniki Collaboration Platform and macOS (Sync Client)"),
                    url: null,
                    file: 'seafile_macos.md'
                },
                {
                    title: i18n.$gettext("Adding the cloud drive to macOS Finder (Drive Client)"),
                    url: null,
                    file: 'seadrive_macos.md'
                },
                {
                    title: i18n.$gettext("Installing the Files App on iOS (iPhone, iPad)"),
                    url: null,
                    file: 'seafile_ios.md'
                },
                {
                    title: i18n.$gettext("Installing the Files App on Android Smartphones"),
                    url: null,
                    file: 'seafile_android.md'
                },

            ]
        },
        {
            name: i18n.$gettext("Chat"),
            path: "/chat",
            icon: 'cil-chat-square cil-fw',
            manuals: [
                {
                    title: i18n.$gettext("Installing the Chat App on iPhone and iPad"),
                    url: null,
                    file: 'mattermost_ios.md'
                },
                {
                    title: i18n.$gettext("Installing the Chat App on Android Smartphones"),
                    url: null,
                    file: 'mattermost_android.md'
                },
                {
                    title: i18n.$gettext("Installing the Chat App on Windows"),
                    url: null,
                    file: 'mattermost-windows.md'
                },
                {
                    title: i18n.$gettext("Installing the Chat App on macOS"),
                    url: null,
                    file: 'mattermost_macos.md'
                }
            ]
        },
        {
            name: i18n.$gettext("Mail"),
            path: "/mail",
            icon: 'cil-mail cil-fw',
            manuals: [
                {
                    title: i18n.$gettext("Connecting Mail, Calendars, Tasks and Contacts to Outlook on Windows (Exchange ActiveSync)"),
                    url: null,
                    file: 'outlook_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Mail, Calendars, Tasks and Contacts to iPhone and iPad (Exchange ActiveSync)"),
                    url: null,
                    file: 'ios_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Mail, Calendars and Contacts to Android Smartphones (Exchange ActiveSync)"),
                    url: null,
                    file: 'mail-kalender-kontakte-gmail-android.md'
                },
                {
                    title: i18n.$gettext("Connecting Apple Mail and Notes on macOS (IMAP)"),
                    url: null,
                    file: 'apple_mail.md'
                },
                {
                    title: i18n.$gettext("Connecting Notes to iPhone and iPad (IMAP)"),
                    url: null,
                    file: 'ios_notizen.md'
                }
            ]
        },
        {
            name: i18n.$gettext("Calendar"),
            path: "/calendar",
            icon: 'cil-calendar cil-fw',
            manuals: [
                {
                    title: i18n.$gettext("Connecting Calendars, Mail, Tasks and Contacts to Outlook on Windows (Exchange ActiveSync)"),
                    url: null,
                    file: 'outlook_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Calendars, Mail, Tasks and Contacts to iPhone and iPad (Exchange ActiveSync)"),
                    url: null,
                    file: 'ios_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Calendars, Mail and Contacts to Android Smartphones (Exchange ActiveSync)"),
                    url: null,
                    file: 'mail-kalender-kontakte-gmail-android.md'
                },
                {
                    title: i18n.$gettext("Connecting Apple Calendar and Tasks on macOS (CalDAV)"),
                    url: null,
                    file: 'apple_kalender.md'
                }
            ]
        },
        {
            name: i18n.$gettext("Tasks"),
            path: "/tasks",
            icon: 'cil-task cil-fw',
            manuals: [
                {
                    title: i18n.$gettext("Connecting Tasks, Calendars, Mail and Contacts to Outlook on Windows (Exchange ActiveSync)"),
                    url: null,
                    file: 'outlook_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Tasks, Calendars, Mail and Contacts to iPhone and iPad (Exchange ActiveSync)"),
                    url: null,
                    file: 'ios_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Apple Tasks and Calendar on macOS (CalDAV)"),
                    url: null,
                    file: 'apple_kalender.md'
                }
            ]
        },
        {
            name: i18n.$gettext("Contacts"),
            path: "/contacts",
            icon: 'cil-group cil-fw',
            manuals: [
                {
                    title: i18n.$gettext("Connecting Contacts, Tasks, Calendars, and Mail to Outlook on Windows (Exchange ActiveSync)"),
                    url: null,
                    file: 'outlook_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Contacts, Tasks, Calendars, and Mail to iPhone and iPad (Exchange ActiveSync)"),
                    url: null,
                    file: 'ios_activesync.md'
                },
                {
                    title: i18n.$gettext("Connecting Contacts, Mail and Calendars to Android Smartphones (Exchange ActiveSync)"),
                    url: null,
                    file: 'mail-kalender-kontakte-gmail-android.md'
                },
                {
                    title: i18n.$gettext("Connecting Apple Contacts on macOS (CardDAV)"),
                    url: null,
                    file: 'apple_kontakte.md'
                }
            ]
        },
        {
            name: i18n.$gettext("Password Manager"),
            path: "/passwords",
            icon: "/api/v1/images/de.uniki.bitwarden/icon.png",
            manuals: [
                {
                    title: i18n.$gettext("Installing the Bitwarden Browser Extension"),
                    url: null,
                    file: 'bitwarden_browser.md'
                },
                {
                    title: i18n.$gettext("Installing the Bitwarden App on iPhone and iPad"),
                    url: null,
                    file: 'bitwarden_ios.md'
                },
                {
                    title: i18n.$gettext("Installing the Bitwarden App on Android Smartphones"),
                    url: null,
                    file: 'bitwarden_android.md'
                },
                {
                    title: i18n.$gettext("Installing the Bitwarden App on Windows"),
                    url: null,
                    file: 'bitwarden_windows.md'
                },
                {
                    title: i18n.$gettext("Installing the Bitwarden App on macOS"),
                    url: null,
                    file: 'bitwarden_macOS.md'
                }
            ]
        },
        {
            name: i18n.$gettext("Administration"),
            path: "/administration",
            icon: "cil-settings",
            manuals: [
                {
                    title: i18n.$gettext("Setup local DNS redirection"),
                    url: null,
                    file: 'local-dns.md'
                }
            ]
        }
    ]
}
