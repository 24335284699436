

import {Options, Vue} from "vue-class-component"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Avatar from "@/components/common/Avatar.vue"
import Checkbox from "primevue/checkbox"
import {Watch} from "vue-property-decorator"
import Chips from "primevue/chips"

@Options({
  //@ts-ignore
  props: {
    users: Array,
    selection: Array,
    multiSelect: Boolean,
    allowAdd: Boolean,
    placeholder: String,
    hint: String
  },
  components: {
    //@ts-ignore
    InfiniteList, Checkbox, Avatar, Chips
  },
  emits : [
    'update', 'save'
  ]
})
export default class UserPicker extends Vue {

  i18n: Language = useGettext()

  users!: {id: string, name: string | undefined, email: string | undefined, image: string | undefined}[] | null
  selection!: string[]
  multiSelect!: boolean
  allowAdd!: boolean
  placeholder!: string
  hint!: string
  filter = ''

  selectableUsers: {user: any, selected: boolean}[] = []
  selectedUserEmails: string[] = []

  emitSave() {
    this.$emit('save', this.selectedUserEmails)
  }

  updateSelection() {
    this.filterUsers('')
    this.$emit('update', this.selectedUserEmails)
  }

  toggleUser(user: any, selected: boolean) {
    if (selected && user.email) {
      this.selectedUserEmails.push(user.email)
    } else if (!selected) {
      this.selectedUserEmails = this.selectedUserEmails.filter((u: string) => {
        return u !== user.email
      })
    }
  }

  filterUsers(input: string) {
    this.filter = input.toLowerCase()
    this.watchUsers((this.users || []))
  }

  @Watch('selection')
  watchSelection(newSelection: string[], /* oldSelection: string[] */) {
    this.selectedUserEmails = newSelection
  }

  @Watch('users')
  watchUsers(newUsers: any[], /* oldUsers: any[] */) {
    this.selectableUsers = newUsers.filter((user: any) => {
      return !this.filter ||
        (user.email && user.email.toLowerCase().includes(this.filter)) ||
        (user.userName && user.userName.toLowerCase().includes(this.filter))
    }).map((user: any) => {
      const selected: string | undefined = this.selectedUserEmails.find((u: string) => {
        return u === user.email
      })
      return {user: user, selected: !!selected}
    })
  }

  mounted() {
    this.watchSelection((this.selection || []))
    this.watchUsers((this.users || []))
  }

}
