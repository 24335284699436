<template>
  <div class="fm d-flex flex-column">
    <teleport v-if="!isEmbedded" to="#topmenubar">
      <input
        ref="uploadButton"
        style="display: none"
        type="file"
        directory
        multiple
        @change="uploadFilesViaButton"
      >
      <Button
        v-if="isLibraryLevel && !isHistoricListing"
        class="p-button p-button-inverse mr-2"
        :disabled="isTopLevel"
        :label="i18n.$gettext('History')"
        @click="toggleHistoryView"
      />
      <Button
        v-if="!isHistoricListing && !isReadOnly"
        class="p-button p-button-inverse mr-2"
        :label="i18n.$gettext('Upload')"
        @click="promptUpload"
      />
      <Button
        v-if="!isHistoricListing && !isReadOnly"
        class="p-button p-button-inverse mr-2"
        :label="i18n.$gettext('New')"
        @click="toggleNewMenu"
      />
      <Button
        v-if="!isHistoricListing && !isTopLevel && !isReadOnly"
        v-tooltip="hasSelection ? i18n.$gettext('Delete selected files.') : i18n.$gettext('Select one or more files to delete them.')"
        class="p-button p-button-inverse mr-2"
        :style="{ 'opacity': hasSelection ? '1' : '0.8' }"
        :label="i18n.$gettext('Delete')"
        @click="doDeleteAction"
      />
      <Button
        v-if="!isHistoricListing && !isTopLevel"
        v-tooltip="hasSelection ? i18n.$gettext('Add selected files to clipboard for copying.') : i18n.$gettext('Select one or more files to copy.')"
        class="p-button p-button-inverse mr-2"
        :style="{ 'opacity': hasSelection ? '1' : '0.8' }"
        :label="i18n.$gettext('Copy')"
        @click="doCopyToClipboard"
      />
      <Button
        v-if="!isHistoricListing && !isReadOnly && !isTopLevel"
        v-tooltip="hasSelection ? i18n.$gettext('Add selected files to clipboard for moving.') : i18n.$gettext('Select one or more files to move.')"
        class="p-button p-button-inverse mr-2"
        :style="{ 'opacity': hasSelection ? '1' : '0.8' }"
        :label="i18n.$gettext('Cut')"
        @click="doCutToClipboard"
      />
      <Button
        v-if="hasClipboard && !isHistoricListing"
        class="p-button p-button-inverse mr-2"
        :disabled="isReadOnly"
        :loading="pasteLoading"
        :label="i18n.$gettext('Paste')"
        @click="doPasteClipboard"
      />
      <Button
        v-if="hasClipboard && !isHistoricListing && !pasteLoading"
        class="p-button p-button-inverse mr-2"
        :label="i18n.$gettext('Clipboard')"
        :badge="clipboardCount"
        @click="openClipboardPanel($event)"
      />
      <Button
        v-if="uploadsActive && !isHistoricListing"
        class="p-button p-button-inverse mr-2"
        :label="i18n.$gettext('Upload Progress')"
        :badge="uploadCount"
        @click="toggleUploadProgressModal"
      />
      <Dialog
        v-model:visible="uploadProgressModal"
        :header="i18n.$gettext('Upload Progress')"
        :style="{ minWidth: '50vw', minHeight: '80vh' }"
        :modal="true"
        :draggable="false"
        @close="uploadProgressModal = false"
      >
        <div>
          <div v-for="entry in uploadProgress" :key="entry.name" class="row separator-bottom">
            <div class="col-6 upload-progress-label">
              {{ entry.name }}
            </div>
            <div class="col-6 p-2">
              <ProgressBar :value="entry.percent" :show-value="false" />
            </div>
          </div>
        </div>
      </Dialog>
      <Menu ref="newMenu" :model="newMenuItems" :popup="true" />
      <Dialog
        v-model:visible="showNewModal"
        :header="i18n.$gettext('Create new')"
        :modal="true"
        :draggable="false"
      >
        <div>
          <div class="mb-2">
            <translate v-if="newModalType === 'dir'">
              Please enter the name for the new directory
            </translate>
            <translate v-else>
              Please enter the name for the new file
            </translate>
          </div>
          <span v-if="newModalType === 'dir' || newModalType === 'file'" class="p-float-label">
            <InputText v-model="newModalInodeName" class="w-100" />
            <label><translate>Name</translate></label>
          </span>
          <div v-else class="p-inputgroup">
            <InputText
              v-model="newModalInodeName"
              @keydown.enter="createNewInode"
            />
            <span class="p-inputgroup-addon">.{{ newModalType }}</span>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <Button
              :label="i18n.$gettext('Abort')"
              class="p-button-raised p-button-secondary mr-2"
              @click="doCloseNewModal"
            />
            <Button
              :loading="newIsLoading"
              :label="i18n.$gettext('Create')"
              class="p-button-raised p-button-success"
              @click="createNewInode"
            />
          </div>
        </div>
      </Dialog>
      <OverlayPanel v-if="hasClipboard" ref="clipboardpanel">
        <div v-for="(entry, idx) in clipboard" :key="entry.name" class="d-flex">
          <div class="flex-grow-1 flex-shrink-1">
            {{ entry.name }}
          </div>
          <div class="flex-grow-0 flex-shrink-0">
            <Button icon="cil-trash" class="p-button-text p-button-sm context-menu-btn " @click.stop.prevent="removeClipboardEntry(idx)" />
          </div>
        </div>
      </OverlayPanel>
    </teleport>
    <Dialog
      v-model:visible="showCommentChannelDialog"
      :header="i18n.$gettext('Comment on file')"
      :modal="true"
      :draggable="false"
      @hide="iNodeForComment = null"
    >
      <div>
        <div v-if="channelSWR && channelSWR.data">
          <p class="text-muted">
            <translate>Select a channel to post the file into.</translate>
          </p>
          <Listbox
            v-model="channelToCommentInto"
            :options="channelCommentOptions"
            option-label="label"
            option-value="channel"
          />
          <div class="d-flex w-100 mt-2 justify-content-end">
            <Button :label="i18n.$gettext('Post file')" @click="postINodeInChannel" />
          </div>
        </div>
        <ProgressBar v-else mode="indeterminate" />
      </div>
    </Dialog>
    <Dialog
      v-model:visible="showThreadDialog"
      :header="i18n.$gettext('Thread')"
      :modal="true"
      :draggable="false"
      position="right"
      style="width: 50%; margin: 0 2rem; height: 100vh"
      @hide="rootMessageId = null"
    >
      <ChatChannel
        v-if="rootMessageId"
        :key="channelId + rootMessageId"
        :channel-id="channelId"
        :project-id="projectId"
        :thread-root="rootMessageId"
      />
    </Dialog>
    <Dialog
      v-model:visible="showHistoryPanel"
      :header="i18n.$gettext('History')"
      :modal="true"
      :draggable="false"
      position="right"
      style="width: 50%; margin: 0 2rem; height: 100vh"
    >
      <div v-if="historyIsLoading" class="row mb-2">
        <div class="col">
          <ProgressBar mode="indeterminate" />
        </div>
      </div>
      <span class="p-input-icon-left w-100 ml-3">
        <i class="cil-search" style="z-index: 10;" />
        <InputText
          v-model="historySearch"
          type="text"
          input-class="w-100 input-padding-left-for-icon"
        />
      </span>
      <InfiniteList
        :key="currentPath"
        :get-item-page="historyPage"
        :get-all-items="allHistoryItems"
        :id-property="'historyId'"
        show-scroll-to-start-button
        auto-scroll-at-start
        class="mt-4 pt-2 p-timeline p-component p-timeline-left p-timeline-vertical"
      >
        <template #element="slotProps">
          <div class="fm-history-item p-timeline-event d-flex flex-row">
            <div class="p-timeline-event-content">
              <Button
                v-if="!isReadOnly && !isCurrentHistoryItem(slotProps.item.historyId)"
                class="p-button-raised mb-2 mr-2"
                :label="i18n.$gettext('Show')"
                style="width: 6rem"
                @click="showFolderInHistory(slotProps.item)"
              />
            </div>
            <div class="p-timeline-event-separator d-flex flex-column align-items-center">
              <div class="p-timeline-event-marker flex-shrink-0" />
              <div class="p-timeline-event-connector h-100" />
            </div>
            <div class="p-timeline-event-content px-2 pb-4 flex-grow-1" style="margin-top: -3px">
              <span class="font-weight-bold">{{ formatTimestamp(slotProps.item.time) }}</span>
              <span>&nbsp;<translate>by</translate>&nbsp;{{ slotProps.item.name }}</span>
              <br>
              <p>{{ slotProps.item.description }}</p>
            </div>
          </div>
        </template>
        <template #loading>
          <div class="w-100">
            <ProgressBar mode="indeterminate" />
          </div>
        </template>
      </InfiniteList>
    </Dialog>
    <div class="fm-view h-100 d-flex flex-row flex-grow-1">
      <div class="fm-table d-flex flex-column flex-grow-1 w-100">
        <div v-if="(currentPath || '').startsWith('/trash/')" class="alert alert-primary d-flex justify-content-between mb-0">
          <p class="lead font-weight-bolder mt-1 mb-0">
            <translate>Trash of Library</translate>&nbsp;
            <span class="text-dark">{{ trashPath }}</span>
          </p>
          <Button
            v-if="isLibraryLevel && library && library.path"
            class="p-button-raised p-button-danger"
            :label="i18n.$gettext('Empty Trash')"
            @click="emptyTrash"
          />
        </div>
        <div v-else-if="isHistoricListing" class="alert alert-primary d-flex justify-content-between mb-0">
          <p class="my-2">
            <translate>You are viewing the folder at</translate>&nbsp;{{ historyTimestamp }}
          </p>
          <Button class="p-button-raised" :label="i18n.$gettext('Return to present')" @click="resetHistory" />
        </div>
        <Breadcrumb
          v-if="isEmbedded && !isTopLevel"
          :home="{ label: i18n.$gettext('Libraries') }"
          :model="breadCrumbItems"
          class="list-item"
          style="border: none; border-radius: 0; border-bottom: solid 1px #ced4da"
        >
          <template #item="{ item }">
            <div class="h-100 d-flex align-items-center">
              <img v-if="item.img" class="collection-icon mr-2" :src="item.img">
              <a v-if="item.inode" :href="'javascript:;'" @click.stop.prevent="goToInode(item.inode)">{{ item.label || item.inode.name }}</a>
              <router-link
                v-else-if="!isEmbedded && item.to"
                :to="item.to"
              >
                {{ item.label }}
              </router-link>
              <a v-else-if="isEmbedded" :href="'javascript:;'" @click.stop.prevent="goToInode(null)">{{ item.label }}</a>
            </div>
          </template>
        </Breadcrumb>
        <Breadcrumb
          v-else-if="library && !isTopLevel && !isLibraryLevel"
          :home="{ img: library.thumbNailSmall, inode: library, to: '/files/' + (libraryId || '') }"
          :model="breadCrumbItems"
          class="list-item"
          style="border: none; border-radius: 0; border-bottom: solid 1px #ced4da"
        >
          <template #item="{ item }">
            <div class="h-100 d-flex align-items-center">
              <img v-if="item.img" class="collection-icon mr-2" :src="item.img">
              <a v-if="item.inode" :href="'javascript:;'" @click.stop.prevent="goToInode(item.inode)">{{ item.label || item.inode.name }}</a>
              <router-link
                v-else-if="!isEmbedded && item.to"
                :to="item.to"
              >
                {{ item.label }}
              </router-link>
            </div>
          </template>
        </Breadcrumb>
        <div
          v-else-if="library && !isTopLevel"
          class="p-breadcrumb p-component d-flex align-items-center"
          style="border: none; border-radius: 0; border-bottom: solid 1px #ced4da"
        >
          <img class="collection-icon mr-2" :src="library.thumbNailSmall">
          <a :href="'javascript:;'" @click.stop.prevent="goToInode(library)">{{ library.name }}</a>
        </div>
        <div class="fm-header d-flex flex-row separator-bottom font-weight-bold">
          <div class="flex-grow-1 p-2">
            <div
              v-if="(currentPath || '').startsWith('/trash/')"
              :key="currentPath"
              class="p-2 table-column-name"
              @click="sort('name')"
            >
              <translate>Name</translate>&emsp;(<translate>Directory</translate>)
              <span style="width: 15px" :class="(sortBy.length && sortBy[0].endsWith('asc') ? 'cil-arrow-top' : 'cil-arrow-bottom') + (sortBy.length && sortBy[0].startsWith('name') ? '' : ' opacity-0')" />
            </div>
            <div
              v-else
              :key="currentPath"
              class="p-2 table-column-name"
              @click="sort('name')"
            >
              <translate>Name</translate>
              <span style="width: 15px" :class="(sortBy.length && sortBy[0].endsWith('asc') ? 'cil-arrow-top' : 'cil-arrow-bottom') + (sortBy.length && sortBy[0].startsWith('name') ? '' : ' opacity-0')" />
            </div>
          </div>
          <div class="p-2 col-fixed-fm d-flex flex-row font-weight-bold" style="max-width: 30%" :style="{ 'margin-right': itemCount > 16 ? '16px' : '0' }">
            <div class="py-2 flex-grow-1 sizecol table-column-name" @click="sort('size')">
              <span style="width: 15px" :class="(sortBy.length && sortBy[0].endsWith('asc') ? 'cil-arrow-top' : 'cil-arrow-bottom') + (sortBy.length && sortBy[0].startsWith('size') ? '' : ' opacity-0')" />
              <translate>Size</translate>
            </div>
            <div
              v-if="(currentPath || '').startsWith('/trash/')"
              :key="currentPath"
              class="p-2 flex-grow-1 datecol table-column-name"
              @click="sort('lastModified')"
            >
              <span style="width: 15px" :class="(sortBy.length && sortBy[0].endsWith('asc') ? 'cil-arrow-top' : 'cil-arrow-bottom') + (sortBy.length && sortBy[0].startsWith('lastModified') ? '' : ' opacity-0')" />
              <translate>Deleted At</translate>
            </div>
            <div
              v-else-if="!isHistoricListing"
              :key="currentPath"
              class="p-2 flex-grow-1 datecol table-column-name"
              @click="sort('lastModified')"
            >
              <span style="width: 15px" :class="(sortBy.length && sortBy[0].endsWith('asc') ? 'cil-arrow-top' : 'cil-arrow-bottom') + (sortBy.length && sortBy[0].startsWith('lastModified') ? '' : ' opacity-0')" />
              <translate>Last Modification</translate>
            </div>
          </div>
        </div>
        <div
          class="fm-body d-flex flex-column flex-grow-1"
          style="height: 1px"
          @dragover.prevent="isDropzone = true"
          @dragenter.prevent="isDropzone = true"
          @dragleave="isDropzone = false"
          @dragend="isDropzone = false"
        >
          <!-- Back to top level -->
          <div
            v-if="isDropzone"
            class="fm-dropzone d-flex justify-content-center align-items-center"
            @dragleave="isDropzone = false"
            @dragend="isDropzone = false"
            @drop.prevent.stop="handleFileDrop"
          >
            <p class="h5">
              <translate>Drop files here....</translate>
            </p>
          </div>
          <div v-else class="fm-content d-flex flex-column flex-grow-1" style="height: 1px">
            <div class="fm-lines flex-grow-1" style="height: 1px">
              <InfiniteList
                :key="currentPath || ''"
                :get-all-items="getInodes"
                :id-property="'originalId'"
                show-scroll-to-start-button
                auto-scroll-at-start
              >
                <template #element="props">
                  <INodeEntry
                    :inode="props.item"
                    :path="currentPath"
                    :selection="selected"
                    :clipboard="clipboard"
                    :history-id="historyId || props.item.historyId || ''"
                    :read-only="isReadOnly"
                    :is-embedded="isEmbedded"
                    @inode-deleted="getInodes(true)"
                    @inode-selected="onLineSelected"
                    @inode-deselected="onLineDeselected"
                    @comment="comment(props.item)"
                    @email="email(props.item)"
                    @share="openShareModal"
                    @history="openHistoryModal"
                    @inode-chosen="goToInode"
                  />
                </template>
                <template #loading>
                  <div class="w-100">
                    <ProgressBar mode="indeterminate" />
                  </div>
                </template>
                <template #empty>
                  <div class="d-flex flex-column justify-content-center" style="min-height: 30rem">
                    <div class="text-center">
                      <p class="h5 mb-2">
                        <translate>Unfortunately, this folder is empty</translate>
                      </p>
                      <p><translate>Add some files to get started.</translate></p>
                    </div>
                  </div>
                </template>
              </InfiniteList>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShareModal ref="shareModal" :inode="inodeToShare" @hide="inodeToShare = null" />
    <HistoryModal
      ref="historyModal"
      :inode="inodeToShowHistory"
      :read-only="isReadOnly"
      @hide="inodeToShowHistory = null"
    />
    <EmailComposer ref="composer" />
    <teleport to="#menubarcontent">
      <SearchBar
        v-if="!isEmbedded && !isHistoricListing"
        class="pt-2"
        collection-type="INODE"
        :collection-id="currentINode?.originalId || null"
        collection-icon="cil cil-folder"
        :collection-name="currentINode?.name || null"
        @search="searchQuery = $event"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import INode from '@/model/entry/INode'
import {Options, Vue} from "vue-class-component"
import INodeEntry from "./INodeEntry.vue"
import {fileServiceApi} from "@/api/FileServiceApi"
import InfiniteList from "@/components/common/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import SWR from "@/api/SWR"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Menu from "primevue/menu"
import Dialog from "primevue/dialog"
import { ref } from "@vue/reactivity"
import LoadingButton from "@/components/common/LoadingButton.vue"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import RpcError from "@/api/RpcError"
import {ClipboardAction, ClipboardEntry} from "@/components/filemanger/subcomponents/Clipboard"
import RpcCallStatus from "@/api/RpcCallStatus"
import Breadcrumb from 'primevue/breadcrumb'
import LibraryHistoryData from "@/model/common/LibraryHistoryData"
import dayjs from "@/util/dayjs"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import Message from "@/model/entry/Message"
import {messageServiceApi} from "@/api/MessageServiceApi"
import {entryLinkServiceApi} from "@/api/EntryLinkServiceApi"
import EntryLink from "@/model/EntryLink"
import ChatChannel from "@/components/chat/ChatChannel.vue"
import Channel from "@/model/directory/Channel"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import OverlayPanel from "primevue/overlaypanel"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Listbox from "primevue/listbox"
import ChatUtil from "@/util/ChatUtil"
import {iNodeStore} from "@/store/INodeStore"
import ShareModal from "@/components/filemanger/subcomponents/ShareModal.vue"
import HistoryModal from "@/components/filemanger/subcomponents/HistoryModal.vue"
import {Watch} from "vue-property-decorator"
import uploadUtil, {NestedFile} from "@/util/UploadUtil"
import ShareLink from "@/model/common/ShareLink"
import EmailComposer from "@/components/email/EmailComposer.vue"
import Email from "@/model/entry/Email"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import Page from "@/model/Page"
import {libHistoryServiceApi} from "@/api/LibHistoryServiceApi"
import {libraryHistoryDataStore} from "@/store/LibraryHistoryDataStore"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import {shareLinkServiceApi} from "@/api/ShareLinkServiceApi"

@Options({
  components: {
    LoadingButton,
    INodeEntry,
    InfiniteList,
    ProgressBar,
    Menu,
    Dialog,
    AnimatedInput,
    ChatChannel,
    Button,
    InputText,
    OverlayPanel,
    Listbox,
    ShareModal,
    HistoryModal,
    EmailComposer,
    SearchBar,
    Breadcrumb
  },
  //@ts-ignore
  props: {
    projectId: String,
    currentPath: {
      type: String,
      default: "/"
    },
    currentINode: {
      type: [ INode, Object ],
      default: null
    },
    isEmbedded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['inodeChosen', 'upperChosen'],
  name: "INodeList"
})
export default class INodeList extends Vue {

  i18n: Language = useGettext()
  api = fileServiceApi
  toast = useToast()
  confirm = useConfirm()

  showNewModal = false
  newModalType = ""
  newModalInodeName = ""

  projectId!: string
  currentPath!: string | null
  currentINode!: INode | null
  isEmbedded!: boolean

  //@ts-ignore
  newMenu: Menu = ref(null)
  //@ts-ignore
  shareModal: ShareModal = ref(null)
  //@ts-ignore
  historyModal: HistoryModal = ref(null)
  //@ts-ignore
  uploadButton: HTMLInputElement = ref(null)
  //@ts-ignore
  uploadProgressModal: boolean = false
  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)
  //@ts-ignore
  clipboardpanel: OverlayPanel = ref(null)

  pasteLoading = false

  inodeToShowHistory: INode | null = null
  inodeToShare: INode | null = null

  selected: INode[] = []

  clipboard: ClipboardEntry[] = []

  isDropzone = false

  newIsLoading = false

  showHistoryPanel = false
  historyStatus: RpcCallStatus = RpcCallStatus.UNINITIALIZED
  historySearch: string = ''
  historyTimestamp = ""

  channelSWR: SWR<Channel[], string[]> | null = null
  directChannelSWR: SWR<Channel[], string[]> | null = null
  iNodeForComment: INode | null = null
  showCommentChannelDialog = false
  channelId: string | null = null
  rootMessageId: string | null = null
  showThreadDialog = false
  channelToCommentInto: Channel | null = null
  sortBy: string[] = []
  itemCount: number = 0
  searchQuery: Query | null = null

  get newMenuItems(): any {
    return [
      {
        label: this.i18n.$pgettext('Files New Menu', 'New Directory'),
        icon: 'far fa-folder',
        command: () => {
          this.doShowNewModal("dir")
        }
      },
      {
        label: this.i18n.$pgettext('Files New Menu', 'New File'),
        icon: 'far fa-file',
        command: () => {
          this.doShowNewModal("file")
        },
        disabled: this.isTopLevel
      },
      {
        label: this.i18n.$pgettext('Files New Menu', 'New XLSX File'),
        icon: 'far fa-file-excel',
        command: () => {
          this.doShowNewModal("xlsx")
        },
        disabled: this.isTopLevel
      },
      {
        label: this.i18n.$pgettext('Files New Menu', 'New DOCX File'),
        icon: 'far fa-file-word',
        command: () => {
          this.doShowNewModal("docx")
        },
        disabled: this.isTopLevel
      },
      {
        label: this.i18n.$pgettext('Files New Menu', 'New PPTX File'),
        icon: 'far fa-file-powerpoint',
        command: () => {
          this.doShowNewModal("pptx")
        },
        disabled: this.isTopLevel
      }
    ] }

  toggleNewMenu(e: Event): void {
    this.newMenu.toggle(e)
  }

  getInodes(refresh: number | boolean = 10000): SWR<INode[], string[]> | null {
    if (this.sortBy.length === 0) {
      this.sortBy = this.currentPath?.startsWith('/trash/') ? ["lastModified:desc"] : ["name:asc"]
    }
    if (this.currentPath === null) {
      return null
    } else if (this.searchQuery) {
      return fileServiceApi.queryINodes(this.searchQuery)
    } else {
      const swr: SWR<INode[], string[]> = fileServiceApi.getINodesByPath(this.currentPath, refresh, this.sortBy)
      if (swr.call?.promise) {
        swr.call.promise.then((ids: string[]) => {
          //Replace the state, to make sure no deleted files remain in it
          this.itemCount = ids.length
          const inodes = [...iNodeStore.state.iNodes.values()]
          iNodeStore.replaceINodes(inodes.filter(inode => (inode.parentPath !== this.currentPath) || ids.includes(inode.path || '')) || [])
        }).catch((e: RpcError) => {
          if (e.message?.includes('Invalid path')) {
            this.goToUpperDir()
          }
        })
      }
      this.itemCount = swr.data ? swr.data.length : 0
      return swr
    }
  }

  sort(by: string) {
    let direction: string
    if (this.sortBy.length > 0 && this.sortBy[0].startsWith(by + ':')) {
      direction = this.sortBy[0].endsWith('desc') ? 'asc' : 'desc'
    } else {
      direction = by === 'lastModified' ? 'desc' : 'asc'
    }
    this.sortBy[0] = by + ':' + direction
  }

  get isTopLevel(): boolean {
    return this.actualPath === "/" || this.actualPath === ""
  }

  goToInode(inode: INode){
    this.$emit('inodeChosen', inode)
  }

  goToUpperDir(): void {
    this.$emit('upperChosen')
  }

  doShowNewModal(type: string): void {
    this.newModalType = type
    this.newModalInodeName = ""
    this.showNewModal = true
  }

  doCloseNewModal(): void {
    this.newModalType = ""
    this.showNewModal = false
  }

  createNewInode(): Promise<void> {
    let name = this.newModalInodeName.trim()
    if(name.endsWith(".")){
      this.toast.error(this.i18n.$gettext("File and Directory Names may not end with a dot."))
      return Promise.reject()
    }
    if (this.newModalType === "dir"){
      if (this.isTopLevel) {
        return Promise.reject()
      } else {
        return this.createDir(name)
      }
    }
    //Check suffix for office files:
    if (this.newModalType !== "dir" && this.newModalType !== "file") {
      const suffix: string = "." + this.newModalType
      if (!name.endsWith(suffix)){ //Cut off ending, if user enters it
        name = name + suffix
      }
    }
    const type = this.newModalType === "dir" ? this.i18n.$gettext("Directory") : this.i18n.$gettext("File")
    this.newIsLoading = true
    return this.api._createFile(this.currentPath + name).then(() => {
      this.doCloseNewModal()
      this.toast.success( type + " " + this.i18n.$gettext("created"))
      this.getInodes(true) //Reload data
    }).catch((error: RpcError) => {
      this.toast.error(error.message, type + " " +  this.i18n.$gettext("could not be created"))
    }).finally(() => { this.newIsLoading = false })
  }

  createDir(name: string): Promise<void> {
    this.newIsLoading = true
    return this.api._createDirectory( this.currentPath + name, null).then(() => {
      this.doCloseNewModal()
      this.toast.success(this.i18n.$gettext("Directory created"))
      this.getInodes(true) //Reload data
    }).catch((error: RpcError) => {
      this.toast.error(error.message, this.i18n.$gettext("Directory could not be created"))
    }).finally(() => { this.newIsLoading = false })
  }

  onLineSelected(inode: INode): void {
    this.selected.push(inode)
  }

  onLineDeselected(inode: INode): void {
    for(  let i = 0; i < this.selected.length; i++){
      const position: INode = this.selected[i]
      if (!position.path || !inode.path) continue

      if (position.path === inode.path) {
        this.selected.splice(i, 1)
      }
    }
  }

  doCopyToClipboard(): void {
    if (this.hasSelection) {
      this.doClipboardAction(ClipboardAction.COPY)
    }
  }

  doCutToClipboard(): void {
    if (this.hasSelection) {
      this.doClipboardAction(ClipboardAction.CUT)
    }
  }

  doClipboardAction(action: ClipboardAction): void {
    this.selected.forEach((inode: INode) => {
      if(this.clipboard.find((it : ClipboardEntry) => { return it.path === inode.path})){
        return //do not add same entry twice
      }
      this.clipboard.push(new ClipboardEntry(`${inode.path}`, `${inode.name}`, action))
    })
    this.selected.splice(0, this.selected.length)
  }

  doDeleteAction(){
    const count = this.selected.length
    const translated = this.i18n.$ngettext("Do you want to delete one item?", "Do you want to delete %{ n } items?", count)
    this.confirm.require({
      message: this.i18n.interpolate(translated, {'n': count}),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-warning',
      accept: () => {
        this._deleteSelectedElements()
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  _deleteSelectedElements(){
    const promises : Promise<void>[] = []
    this.selected.forEach((inode: INode) => {
      if(inode.path) promises.push(fileServiceApi._deleteINode(inode.path))
    })
    Promise.all(promises).then(() => {
      this.toast.success(this.i18n.$gettext("Deletion successful"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("One or more items could not be deleted"), this.i18n.$gettext("An error occured"))
    }).finally(() => {
      this.getInodes(true)
    })
  }

  get hasSelection(): boolean {
    return this.selected.length > 0
  }

  get hasClipboard(): boolean {
    return this.clipboard.length > 0
  }

  get clipboardCount(): number {
    return this.clipboard.length
  }

  doPasteClipboard(): void {
    const count = this.clipboardCount
    const translated = this.i18n.$ngettext("Do you want to paste one item?", "Do you want to paste %{ n } items?", count)
    this.confirm.require({
      message: this.i18n.interpolate(translated, {'n': count}),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-warning',
      accept: () => {
        this._doPasteClipboard()
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  _doPasteClipboard(): void {
    this.pasteLoading = true
    const actionPromises: Promise<string | void>[] = []
    for(  let i = 0; i < this.clipboard.length; i++){
      const entry: ClipboardEntry = this.clipboard[i]
      actionPromises.push(this.executeClipboardEntry(entry))
    }
    void Promise.all(actionPromises).finally(() => {
      this.toast.info(this.i18n.$gettext("Moved/Copied all items from the clipboard"))
      this.clipboard.splice(0, this.clipboard.length)
      this.getInodes(true)
      this.pasteLoading = false
    })
  }

  executeClipboardEntry(entry: ClipboardEntry): Promise<string | void> {
    if (this.currentPath === null) return Promise.reject()
    const newPath: string = this.currentPath
    if (entry.action === ClipboardAction.COPY) {
      return this.api._copyINode(entry.path, newPath, false).catch(() => {
        this.toast.error(entry.path, this.i18n.$gettext("Could not copy"))
      })
    } else {
      return this.api._moveINode(entry.path, newPath, false).catch(() => {
        this.toast.error(entry.path, this.i18n.$gettext("Could not copy"))
      })
    }
  }

  promptUpload(): void {
    this.uploadButton?.click()
  }

  uploadFilesViaButton(): void {
    const files: FileList = this.uploadButton?.files || new FileList()
    const filesToUpload: NestedFile[] = []
    for (let i = 0; i < files.length; i++) {
      filesToUpload.push(NestedFile.fromFile(files[i]))
    }
    this.uploadFiles(filesToUpload)
  }

  handleFileDrop(e: DragEvent): void {
    this.isDropzone = false

    const items: DataTransferItemList | undefined = e.dataTransfer?.items
    if (!items) {
      return
    }
    for( let i = 0; i < items.length; i++) {
      const item: DataTransferItem = items[i]
      const webkitItem = item.webkitGetAsEntry()

      if (!item) {
        console.error("Could not construct item")
        continue
      }
      this.traverseDroppedDir(webkitItem, "").then((files: NestedFile[]) => {
        this.uploadFiles(files)
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext('Could not Upload files'))
      })
    }
  }

  uploadFiles(files: NestedFile[]): void {
    uploadUtil.uploadFiles(files, this.currentPath).then(() => {
      this.toast.success(this.i18n.$gettext("All Uploads completed"))
      this.uploadProgressModal = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Some files failed to upload"))
      this.uploadProgressModal = false
    }).finally(() => {
      this.getInodes(true)
    })
  }

  get uploadProgress(): { name: string | null, percent: number }[] {
    return uploadUtil.uploadProgress
  }

  get uploadsActive(): boolean {
    return uploadUtil.uploadProgress.length !== 0
  }

  get uploadCount(): number {
    return uploadUtil.uploadProgress.length
  }

  async traverseDroppedDir(item: any, path: string): Promise<NestedFile[]> {
    let result: NestedFile[] = []
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that = this
    if (item.isDirectory) {
      const entries: any[] = await that.parseDirectory(item, path)
      for (let i = 0; i < entries.length; i++) {
        const dirEntries: NestedFile[] = await that.traverseDroppedDir(entries[i], path + item.name + "/")
        result.push(...dirEntries)
      }
    } else if (item.isFile) {
      const thisFile: NestedFile = await that.parseFile(item, path)
      result.push(thisFile)
    } else {
      console.error("Item on drop event is not a file or directory, aborting...", item)
    }
    return result
  }

  parseDirectory(item: any, path: string): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      const dirReader: any = item.createReader()
      dirReader.readEntries(function(entries: any) {
        resolve(entries)
      })
    })

  }

  parseFile(item: any, path: string): Promise<NestedFile> {
    return new Promise<NestedFile>((resolve) => {
      item.file(function(file: File) {
        resolve(new NestedFile(path, file))
      })
    })
  }

  wait(msecs: number): Promise<void> {
    return new Promise<void>((resolve => {
      window.setTimeout(() => {
        resolve()
      }, msecs)
    }))
  }

  get actualPath(): string | null | undefined {
    if (this.currentPath?.startsWith('/trash/')) {
      return this.currentPath?.substring(7)
    } else if (this.currentPath?.startsWith('/history/')) {
      let actualPath = this.currentPath?.substring(9)
      if (actualPath.includes('/') && actualPath !== '/') {
        return actualPath.substring(actualPath.indexOf('/'))
      } else {
        return this.currentPath
      }
    } else {
      return this.currentPath
    }
  }

  get breadCrumbItems(): { label?: string, inode: INode | undefined, to?: string }[] {
    let path = this.actualPath?.replace(this.libraryId || '', '') || ''
    while (path.startsWith('/')) {
      path = path.substring(1)
    }
    while (path.endsWith('/')) {
      path = path.substring(0, path.length - 1)
    }
    let parent = '/files/' + this.libraryId + '/'
    const items: { label?: string, inode: INode | undefined, to?: string }[] = path.split('/').map(s => {
      parent += s + '/'
      return { label: s, inode: fileServiceApi.getINode(parent.substring(6)), to: parent }
    }) || []
    if (this.isEmbedded && this.library) {
      items.unshift({ inode: this.library })
    }
    return items
  }

  get historyId(): string | null {
    if (this.currentPath?.startsWith('/history/')) {
      let actualPath = this.currentPath?.substring(9)
      if (actualPath.includes('/') && actualPath !== '/') {
        return actualPath.split('/')[0]
      }
    }
    return null
  }

  get isLibraryLevel(): boolean {
    return this.actualPath?.split("/").filter(i=> i !== "").length === 1
  }

  get libraryId(): string | null {
    const pathParts: string[] = this.actualPath?.split("/").filter(elem => elem !== "") || []
    if (pathParts?.length) {
      return pathParts[0]
    } else {
      return null
    }
  }

  get library(): INode | null {
    const pathParts: string[] = this.actualPath?.split("/").filter(elem => elem !== "") || []
    if (!pathParts || pathParts.length === 0) return null
    const libPath = "/" + pathParts[0]
    return this.api.getINode(libPath) || null
  }

  get trashPath(): string {
    let path = this.currentPath?.replace('/trash' + (this.library?.path || ''), (this.library?.name || '')) || ''
    if (path.endsWith('/')) {
      path = path.substr(0, path.length - 1)
    }
    return path
  }

  get isReadOnly(): boolean {
    const lib: INode | null = this.library
    if (lib && lib.permission){
      return lib.permission === "READ"
    } else {
      return false
    }
  }

  @Watch('library')
  watchLibraryChange(newLibrary: INode, oldLibrary: INode) {
    if (oldLibrary && newLibrary?.path !== oldLibrary?.path) {
      this.resetHistory()
    }
  }

  toggleUploadProgressModal() {
    this.uploadProgressModal =! this.uploadProgressModal
  }

  toggleHistoryView(): void {
    this.showHistoryPanel = !this.showHistoryPanel
    if (!this.showHistoryPanel) {
      this.resetHistory()
    }
  }

  get historyIsLoading(): boolean {
    return this.historyStatus === RpcCallStatus.LOADING
  }

  get historyPage(): ((pageIndex: number, pageSize: number) => SWR<LibraryHistoryData[], Page<string>>) | null {
    if (this.currentPath) {
      return (pageIndex: number, pageSize: number) => {
        return libHistoryServiceApi.getLibHistory(this.libraryId || "", pageIndex, pageSize, 120000)
      }
    } else {
      return null
    }
  }

  get allHistoryItems() {
    return SortAndFilterUtil.filter([...libraryHistoryDataStore.state.libraryHistoryDatas.values()], { repoId: this.libraryId })
  }

  formatTimestamp(isoString: string | null) {
    return isoString ? dayjs(isoString).format("DD.MM.YYYY, HH:mm") : ''
  }

  showFolderInHistory(item: any) {
    if (item.historyId && !this.isEmbedded) {
      this.$router.push('/files/history/' + item.historyId + this.actualPath)
    }
    this.historyTimestamp = item.time ? this.formatTimestamp(item.time) : ""
    this.showHistoryPanel = false
  }

  resetHistory(): void {
    if (!this.isEmbedded && this.currentPath?.startsWith('/history/')) {
      this.$router.push('/files' + this.actualPath)
    }
  }

  emptyTrash(): void {
    this.confirm.require({
      message: this.i18n.$gettext("Do you really want to permanently delete all files from the trash?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-warning',
      accept: () => {
        if (this.library?.path) {
          this.api._emptyTrash(this.library.path.replace('/', '')).then(() => {
            this.toast.success(this.i18n.$gettext("Delete successful"))
            this.getInodes(true)
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Failed to delete"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  isCurrentHistoryItem(historyId: string) {
    return this.currentPath?.startsWith('/history/') && (historyId === this.currentPath?.substring(9).split('/')[0])
  }

  get isHistoricListing(): boolean {
    return Boolean(this.currentPath?.startsWith('/history/') || this.currentPath?.startsWith('/trash/'))
  }

  comment(inode: INode) {
    if (inode.originalId) {
      void entryLinkServiceApi._getLinks('INODE', 'CHAT_MESSAGE', inode.originalId).then((links: EntryLink[]) => {
        const messageId : string | null | undefined = links.find(l => !!l.rightBackendId)?.rightBackendId
        if (messageId) {
          const swr: SWR<Message | null, string> = messageServiceApi.getMessage(messageId)
          if ((!swr.data || swr.call?.loading) && swr.call?.promise) {
            swr.call?.promise?.then(() => {
              if (swr.data) {
                this.showChannelDialog(swr.data as Message)
              } else {
                this.showCommentInTeamChannelDialog(inode)
              }
            })
          } else if (swr.data) {
            this.showChannelDialog(swr.data as Message)
          } else {
            this.showCommentInTeamChannelDialog(inode)
          }
        } else {
          this.showCommentInTeamChannelDialog(inode)
        }
      })
    }
  }

  email(inode: INode) {
    this.composer.show(new Email(), null, null, null, null, inode)
  }

  showChannelDialog(message: Message) {
    this.channelId = message.channelId
    this.rootMessageId = message.id
    this.showThreadDialog = true
  }

  showCommentInTeamChannelDialog(inode: INode) {
    this.channelSWR = channelServiceApi.getChannelsForTeam(this.projectId)
    this.directChannelSWR = channelServiceApi.getChannelsForTeam(null)
    this.iNodeForComment = inode
    this.showCommentChannelDialog = true
  }

  postINodeInChannel() {
    const channel: Channel | null = this.channelToCommentInto
    if (!channel || !channel.id){
      return
    }
    const channelId: string = channel.id
    if (this.iNodeForComment) {
      const iNode: INode = this.iNodeForComment
      if (!iNode.path) return
      shareLinkServiceApi._createShareLink(iNode.path, null, null, true, true, false, true, true).then((shareLink: ShareLink) => {
        let message: Message | null = new Message()
        message.text = `[${iNode.name}](${shareLink.link})`
        message.channelId = channelId
        if (iNode.path) {
          if (!message.props) message.props = {}
          message.props["linkedfile"] = iNode.path
        }
        messageServiceApi._createMessage(message, null).then((messageId: string) => {
          const entryLink: EntryLink = new EntryLink()
          entryLink.leftType = 'INODE'
          entryLink.leftBackendId = iNode.originalId
          entryLink.rightType = 'CHAT_MESSAGE'
          entryLink.rightBackendId = messageId
          entryLinkServiceApi._createLink(entryLink).finally(() => {
            this.channelId = channelId
            this.rootMessageId = messageId
            this.showThreadDialog = true
          })
        }).finally(() => {
          this.iNodeForComment = null
          this.showCommentChannelDialog = false
        })

      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not post file"))
      })

    }
  }

  openClipboardPanel(e: Event){
    this.clipboardpanel.toggle(e)
  }

  removeClipboardEntry(idx: number){
    this.clipboard.splice(idx, 1)
  }

  openShareModal(inode: INode) {
    this.inodeToShare = inode
    void this.$nextTick(() => {
      this.shareModal.toggle()
    })
  }

  openHistoryModal(inode: INode) {
    this.inodeToShowHistory = inode
    void this.$nextTick(() => {
      this.historyModal.toggle()
    })
  }

  get channelCommentOptions(): { label: string, channel: Channel }[] {
    const channels: Channel[] | null | undefined = this.channelSWR?.data
    const privateChannels: Channel[] | null | undefined = this.directChannelSWR?.data
    const result: {label: string, channel: Channel}[] = []
    if (!channels){
      return result
    }
    channels.forEach((c: Channel) => {
      const displayName: string = ChatUtil.getChannelDisplayName(c) || ""
      result.push({ label: displayName, channel: c})
    })
    if(privateChannels){
      privateChannels.forEach((privateChannel: Channel) => {
        if(!privateChannel.isDirect){
          return
        }
        const displayName: string = ChatUtil.getChannelDisplayName(privateChannel) || ""
        result.push({ label: displayName, channel: privateChannel})
      })
    }
    result.sort((first: {label: string, channel: Channel}, second: {label: string, channel: Channel}) => {
      return first.label < second.label ? -1 : 1
    })
    return result
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.col-fixed-fm {
  width: 22rem !important;
  @media screen and (max-width:767px){
    display: none;
  }
}

.sizecol {
  width: 105px;
  justify-content: right;
  text-align: right;
  flex-shrink: 1;
}

.datecol {
  width: 170px;
  justify-content: right;
  text-align: right;
  flex-shrink: 1;
}

.fm-dropzone {
  height: 100%;
  width: 100%;
  min-height: 50vh;
}

.fm-content {
  height: 100%;
  width: 100%;
  min-height: 50vh;
}

.upload-progress-label {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.fm-table {
  transition: all 0.25s;
}

.fm-history {

  .p-timeline-event-opposite {
    max-width: 0px !important;
  }

  .fm-history-item-current {
    background-color: $uniki_secondary !important;
  }

}

.upper-dir-row:hover {
  cursor: pointer;
}

.p-breadcrumb a {
  white-space: nowrap;
}
</style>
