<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-md p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Mail Signatures
          </translate>
          <h2>{{ virtualMailbox }}</h2>
        </h2>
        <p>
          <b><translate>What you can do here:</translate></b>&nbsp;<translate>
            Configure signatures that can be appended to emails you send. Clients like Outlook or smartphones will need to be configured separately.
          </translate>
        </p>
        <div v-if="signatures === null || settingsLoading">
          <Skeleton class="mt-6" height="40px" style="width: 80%" />
        </div>
        <div v-else class="row mt-6 mb-5">
          <div class="col-auto">
            <Button
              :label="i18n.$gettext('Add New Signature')"
              :disabled="mailBoxNotExists"
              icon="cil-plus"
              class="p-button btn-raised mr-3"
              @click="createSignature"
            />
          </div>
        </div>
        <div v-if="signatures !== null" class="row">
          <div class="col">
            <div v-for="signature in signatures" :key="signature.name" class="p-4 my-1 card separator-bottom">
              <div class="d-flex flex-row w-100">
                <div class="flex-grow-1" style="min-width: 0">
                  <p class="lead text-dark font-weight-bold">
                    {{ signature.name }}
                  </p>
                </div>
                <Button
                  v-tooltip="signature.isDefault ? i18n.$gettext('Unset default signature') : i18n.$gettext('Set as default signature')"
                  icon="cil-check"
                  class="p-button p-button-success mr-3"
                  :class="{ 'p-button-outlined': !signature.isDefault, 'p-button-primary': !signature.isDefault }"
                  @click="toggleDefault(signature)"
                />
                <Button
                  v-tooltip="i18n.$gettext('Edit signature')"
                  icon="cil-pencil"
                  class="p-button p-button-primary mr-3"
                  @click="editSignature(signature)"
                />
                <Button
                  v-tooltip="i18n.$gettext('Remove signature')"
                  icon="cil-trash"
                  class="p-button p-button-danger"
                  @click="removeSignature(signature)"
                />
              </div>
              <div v-if="signature.html" class="mt-4">
                <p class="font-weight-bold mb-2">
                  <translate>HTML representation:</translate>
                </p>
                <div class="p-2 card" v-html="signature.html" />
              </div>
              <div v-if="signature.text" class="mt-4">
                <p class="font-weight-bold mb-2">
                  <translate>Plain text representation:</translate>
                </p>
                <pre v-if="signature.text" style="white-space: pre-wrap">{{ signature.text }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="showEditModal"
      :header="i18n.$gettext('Edit Signature')"
      :modal="true"
      :draggable="false"
    >
      <div v-if="editedSignature">
        <Editor
          ref="editor"
          v-model="editedSignature.html"
          :init="{
            height: '400px',
            width: '100%',
            resize: false,
            branding: false,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount textcolor'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | image | removeformat | help',
            contextmenu: 'link linkchecker conferencelink image imagetools spellchecker table configurepermanentpen copy cut paste',
            file_picker_types: 'image',
            file_picker_callback: handleTinyMceFilePick,
            image_prepend_url: '',
            images_dataimg_filter: dataImageFilter,
          }"
        />
        <div class="d-flex justify-content-between mt-4">
          <div>
            <Button class="p-button-secondary p-button-raised" :label="i18n.$gettext('Abort')" @click="showEditModal = false" />
          </div>
          <div>
            <Button
              icon="cil-save"
              class="p-button p-button-raised p-button-success"
              :loading="loadingSave"
              :label="i18n.$gettext('Save')"
              :disabled="!isValid"
              @click="saveSignature"
            />
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="showNewModal"
      :header="i18n.$gettext('New Signature')"
      :modal="true"
      :draggable="false"
    >
      <div v-if="newSignature">
        <div class="p-inputgroup my-4">
          <span class="p-float-label">
            <InputText v-model="newSignature.name" :class="{ 'p-invalid': !nameValid }" autofocus />
            <label><translate>Name</translate></label>
          </span>
        </div>
        <Editor
          ref="editor"
          v-model="newSignature.html"
          :init="{
            height: '400px',
            width: '100%',
            resize: false,
            branding: false,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount textcolor'
            ],
            toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | image | link | removeformat | help',
            contextmenu: 'link linkchecker conferencelink image imagetools spellchecker table configurepermanentpen copy cut paste',
            file_picker_types: 'image',
            file_picker_callback: handleTinyMceFilePick,
            image_prepend_url: '',
            images_dataimg_filter: dataImageFilter,
          }"
        />
        <div class="d-flex justify-content-between mt-4">
          <div>
            <Button class="p-button-secondary p-button-raised" :label="i18n.$gettext('Abort')" @click="showNewModal = false" />
          </div>
          <div>
            <Button
              icon="cil-plus"
              class="p-button p-button-raised"
              :loading="loadingSave"
              :label="i18n.$gettext('Create')"
              :disabled="!isValid"
              @click="saveSignature"
            />
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import SettingsListItem from "@/components/settings/SettingsListItem.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {rpcClient} from '@/api/WebsocketClient'
import Button from "primevue/button"
import {userServiceApi} from "@/api/UserServiceApi"
import InputText from "primevue/inputtext"
import Dialog from "primevue/dialog"
import Editor from "@tinymce/tinymce-vue"
import Signature from "@/model/settings/Signature"
import Skeleton from "primevue/skeleton"
import useToast from "@/util/toasts"
import SWR from "@/api/SWR"
import VirtualMailbox from "@/model/settings/VirtualMailbox"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import htmlToPlainText from "@/util/textversion"
import RpcError from "@/api/RpcError"

@Options({
  components: {
    SettingsListItem, Button, Editor, InputText, Dialog, Skeleton
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class UserSignatureSettings extends Vue {

  i18n: Language = useGettext()

  mailBoxNotExists: boolean = false
  virtualMailbox: string = ""
  toast = useToast()
  newSignature: Signature | null = null
  editedSignature: Signature | null = null

  loadingSave: boolean = false
  settingsLoading:  boolean = false

  mounted() {
    this.virtualMailbox = this.$route?.params?.hasOwnProperty("virtual_mailbox") ?
      this.$route.params["virtual_mailbox"] as string : ""
    if (!this.virtualMailbox) {
      this.settingsLoading = true
      void rpcClient.refreshSettings().then(() => {
        this.settingsLoading = false
      })
    }
  }

  get nameValid() {
    if (this.newSignature) {
      const name: string | null = this.newSignature.name
      return Boolean(name && !(this.signatures || []).find(s => s.name === name))
    } else if (this.editedSignature) {
      return Boolean(this.editedSignature.name)
    } else {
      return false
    }
  }

  get isValid() {
    return this.nameValid && Boolean(this.newSignature?.name || this.editedSignature?.name)
  }

  get showEditModal(): boolean {
    return !!this.editedSignature
  }

  set showEditModal(show: boolean) {
    if (!show) {
      this.editedSignature = null
    }
  }

  get showNewModal(): boolean {
    return !!this.newSignature
  }

  set showNewModal(show: boolean) {
    if (!show) {
      this.newSignature = null
    }
  }

  dataImageFilter(img: any) {
    return img.hasAttribute('internal-blob')
  }

  handleTinyMceFilePick(callback: any, value: any, meta: any){

    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.setAttribute('multiple', 'multiple')
    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = () => {
      if (input && input.files) {
        //check file size limit. only up to 200kB allowed
        let filesize = 0 //in kB
        for (let f of input.files) {
          filesize += f.size // kB
          if (filesize > 200000) {
            this.toast.error(this.i18n.$gettext("File size exceeds limit of 200kB"))
            return
          }
        }

        for (let f of input.files) {
          let reader = new FileReader()
          reader.onload = (e) => {
            if (e?.target?.result) {
              callback(e.target.result, {alt: ''})
            }
          }
          reader.readAsDataURL(f)
        }
      }
    }

    input.click()
  }

  get signatures(): Signature[] | null {
    if (!this.virtualMailbox) {
      if (rpcClient.session.user) {
        if (!rpcClient.session.user.settings) {
          rpcClient.session.user.settings = {signatures: []}
        } else if (!rpcClient.session.user.settings.signatures) {
          rpcClient.session.user.settings.signatures = []
        }
        return rpcClient.session.user.settings.signatures
      } else {
        return null
      }
    } else {
      const swr: SWR<VirtualMailbox[], string[]> = virtualMailboxServiceApi.getVirtualMailboxes()
      if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
        this.loading = true
        swr.call.promise.catch(() => {
          this.toast.error(this.i18n.$gettext("Error while fetching the Mail Rules"))
        })
        swr.call.promise.finally(() => {
          this.loading = false
        })
      }
      const virtualMailboxData: VirtualMailbox | null = swr.data?.find((vmb: VirtualMailbox) => vmb.address === this.virtualMailbox) || null
      if (!virtualMailboxData) {
        this.mailBoxNotExists = true
        this.toast.error(this.i18n.$gettext("Mailbox does not exist"))
      }
      return virtualMailboxData?.signatures || []
    }
  }

  createSignature() {
    this.newSignature = { name: '', text: '', html: '<p>---<br/><br/></p>', isDefault: false }
    this.editedSignature = null
  }

  editSignature(signature: Signature) {
    this.editedSignature = { ...signature }
    this.newSignature = null
  }

  toggleDefault(signature: Signature) {
    if (signature.isDefault) {
      signature.isDefault = false
    } else {
      for (const other of (this.signatures || [])) {
        other.isDefault = false
      }
      signature.isDefault = true
      void this.saveSignatures()
    }
  }

  removeSignature(signature: Signature) {
    if (rpcClient.session.user && this.signatures) {
      const existing: number | undefined = this.signatures.findIndex(s => s.name === signature.name)
      if (existing >= 0) {
        this.signatures.splice(existing, 1)
      }
      void this.saveSignatures()
    }
  }

  htmlToText(html: string): string {
    if (!html) return ""
    const options = {
      linkProcess: (href: string, linkText: string) => {
        return `${linkText} (${href})`
      },
      imgProcess: (src: string, alt: string) => {
        return (alt && alt !== "") ? alt : ""
      }
    }
    return htmlToPlainText(html, options)
  }

  saveSignature() {
    const editedSignature = this.editedSignature || this.newSignature
    if (this.signatures && editedSignature) {
      //@ts-ignore
      editedSignature.htmlSignature = window.tinyMCE.editors[0].getContent({format: 'html'})
      //@ts-ignore
      editedSignature.text = this.htmlToText(window.tinyMCE.editors[0].getContent({format: 'html'}))

      const existing: number = this.signatures.findIndex(s => s.name === editedSignature.name)
      if (existing >= 0) {
        this.signatures[existing] = editedSignature
      } else {
        if (this.signatures.length === 0) {
          editedSignature.isDefault = true
        }
        this.signatures.push(editedSignature)
      }
      this.loadingSave = true
      if (!this.virtualMailbox) {
        void this.saveSignatures().then(() => {
          this.newSignature = null
          this.editedSignature = null
          this.toast.success(this.i18n.$gettext("Signature saved!"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Error while saving signatures"))
        }).finally(() => {
          this.loadingSave = false
        })
      } else {
        virtualMailboxServiceApi._setSignatures(this.virtualMailbox, this.signatures).then(() => {
          this.newSignature = null
          this.editedSignature = null
          this.toast.success(this.i18n.$gettext("Signature saved!"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Error while saving signatures"))
        }).finally(() => {
          this.loadingSave = false
        })
      }
    }
  }

  saveSignatures(): Promise<any> {
    if (rpcClient.session.user) {
      return userServiceApi._saveSettings('signatures', rpcClient.session.user.settings || {})
    } else {
      return Promise.reject()
    }
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";



</style>
