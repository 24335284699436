import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import ShareLink from '@/model/common/ShareLink';


export default class GeneratedShareLinkStore {

    state: {
        shareLinks: Map<string, ShareLink>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let shareLinks: Map<string, ShareLink> | null = null
        try {
            const persistedState = localStorage.getItem('ShareLinkStore')
            if (persistedState) {
                shareLinks = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            shareLinks: shareLinks || new Map<string, ShareLink>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('ShareLinkStore', JSON.stringify(Array.from(this.state.shareLinks)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.shareLinks) this.state.shareLinks.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('ShareLinkStore')
    }

    addOrReplaceShareLink(newShareLink: ShareLink, persist: boolean = true): true | undefined {
        if (newShareLink.token) {
            this.state.shareLinks.set(newShareLink.token, newShareLink)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceShareLinks(newShareLinks: ShareLink[]) {
        let persist = false
        for (const newShareLink of newShareLinks) {
            const changed = !!this.addOrReplaceShareLink(newShareLink, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceShareLinks(shareLinks: ShareLink[]) {
        const newShareLinks = new Map<string, ShareLink>()
        for (const newShareLink of shareLinks) {
            if (newShareLink.token) {
                newShareLinks.set(newShareLink.token, newShareLink)
                
            }
        }
        this.state.shareLinks = newShareLinks
        this.persistState()
    }

    removeShareLink(token: string | undefined | null, persist: boolean = true): true | undefined {
        if (token !== undefined && token !== null) {
            if (this.state.shareLinks.delete(token)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeShareLinks(tokens: (string | null)[] | undefined | null) {
        let persist = false
        if (tokens !== undefined && tokens !== null) {
            for (const token of tokens) {
                const changed = !!this.removeShareLink(token, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}