import Permission from '@/model/entry/Permission';

export default class ShareLibraryStatus {
  failed!: any | null;
  success!: Permission[] | null;

  constructor() {
    this.failed = null;
    this.success = null;
  }
}
