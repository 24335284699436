<template>
  <div class="d-flex flex-column h-100 p-2">
    <div class="d-flex h-100 bg-light" style="border-radius: 3px; overflow: hidden" :class="{ 'flex-row': !isOnMobile, 'flex-column': isOnMobile}">
      <div class="flex-shrink-0 side-panel d-flex flex-column">
        <div class="py-3 pl-3 pr-2 flex-shrink-0 d-flex align-items-center side-panel-header mb-2" style="position: relative">
          <!-- <ProgressBar v-if="librariesAreLoading" mode="indeterminate" style="position: absolute; top: 0; left: 0; width: 100%;" /> -->
          <h6 class="mb-0 d-inline flex-grow-1" style="margin-top: 0.15rem">
            {{ headerLabel }}
          </h6>
          <Button
            v-if="!hideCreateButton"
            class="p-button-sm p-button-success p-button-raised"
            icon="cil-plus"
            @click="handleCreatedClicked"
          />
          <Button
            v-if="isOnMobile"
            class="p-button-sm p-button-secondary p-button-raised ml-2"
            icon="cil-menu"
            @click="handleCollapseButtonClicked"
          />
        </div>
        <div
          ref="sidepanelcontent"
          class="side-panel-content flex-grow-1"
          :class="{ 'show': showDropdown }"
          @click="handleClickOnLinkElement($event)"
        >
          <slot name="side-panel-content" />

        </div>
        <!--<div class="side-panel-footer-tools flex-shrink-0 d-flex flex-row align-items-center justify-content-between px-3">
          <i class="cil-filter" ></i>
          <i class="cil-settings" ></i>
        </div>-->
      </div>
      <div class="h-100 flex-grow-1" style="min-width: 1px; min-height: 1px;">
        <slot name="main-content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {ref} from "@vue/reactivity"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  components: { Button },
  //@ts-ignore
  props: {
    headerLabel: {
      type: String,
      default: ""
    },
    hideCreateButton: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ["created-clicked"]
})
export default class InnerLayoutWithSidebar extends Vue {

  //@ts-ignore
  sidepanelcontent: HTMLElement = ref<HTMLElement>()
  showDropdown: boolean = false
  defaultOpen: boolean = false

  handleCreatedClicked(){
    this.$emit("created-clicked")
  }

  handleCollapseButtonClicked(){
    this.showDropdown = !this.showDropdown
  }

  handleClickOnLinkElement(event: Event){
    this.handleCollapseButtonClicked()
  }

  get isOnMobile(): boolean {
    return breakpointUtil.isOnMobile()
  }

  mounted(){
    this.$nextTick(() => {
      this.showDropdown = this.defaultOpen
    })
  }

}
</script>

<style lang="scss" scoped>

.side-panel-footer-tools {
  height: 3rem;
  font-size: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

</style>
