<template>
  <div class="dash-widget d-flex flex-column card card-lite bg-white z-1">
    <div class="dash-widget-count z-2" @click="ack">
      X
    </div>
    <div class="w-100 d-flex align-items-center px-3 py-3 separator-bottom">
      <p class="h5 mb-0 flex-grow-1">
        <translate>Data import</translate>
      </p>
      <Button
          class="p-button-raised mr-4"
          :label="i18n.$gettext('Import data')"
          @click="showMainDialog = true"
      />
    </div>
    <div class="flex-shrink-1 flex-grow-1 result-list" style="min-height: 22.8rem">
      <InfiniteList
          :get-all-items="importJobs.data || []"
          id-property="id"
      >
        <template #element="{ item }" >
          <DataImportJobListItem class="py-3 px-3 row separator-bottom cursor-pointer bg-hover-light" :job="item" />
        </template>
        <template #loading>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
        </template>
        <template #empty>
          <div class="d-flex flex-column justify-content-center" style="min-height: 22rem">
            <div class="text-center">
              <p><translate>No data import jobs.</translate></p>
            </div>
          </div>
        </template>
      </InfiniteList>
    </div>
    <DataImportWizard v-model="showMainDialog" :style="{ width: '80vw', height: '100vh' }" />
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"
import ScrollPanel from "primevue/scrollpanel"
import Dialog from "primevue/dialog"
import Listbox from "primevue/listbox"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import useToast, {ToastAPI} from "@/util/toasts"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Badge from "primevue/badge";
import InputText from "primevue/inputtext"
import ProgressBar from "primevue/progressbar";
import TreeSelect from "primevue/treeselect"
import InfiniteList from "@/components/common/InfiniteList.vue"
import DataImportWizard from "@/components/settings/dataimportwizard/DataImportWizard.vue"
import DataImportJob from "@/model/DataImportJob"
import SWR from "@/api/SWR"
import {useConfirm} from "primevue/useconfirm"
import SettingsUtil from "@/util/SettingsUtil"
import DataImportJobListItem from "@/components/settings/dataimport/DataImportJobListItem.vue"

@Options({
  components: {
    DataImportWizard, Button, Badge, Skeleton, ScrollPanel, ProgressBar, InfiniteList,
    Dialog, Listbox, LoadingButton, InputText, TreeSelect, DataImportJobListItem
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DataImportWidget extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()

  showMainDialog: boolean = false

  get importJobs(): SWR<DataImportJob[], number[]> {
    return dataImportServiceApi.getDataImportJobs()
  }

  ack(): void {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to hide the data import widget? You will still be able use the data import feature inside System Settings.'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        SettingsUtil.setShowDataImportWidget(false)
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }
}
</script>

<style lang="scss" scoped>

@import "node_modules/elly-bs4/sass/variables";

</style>
