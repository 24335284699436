<template>
  <span class="p-float-label">
    <PrimeVueDropdown
      ref="myinput"
      v-model="internalValue"
      class="w-100"
      :placeholder="placeholder"
      :option-label="optionLabel"
      :option-value="optionValue"
      :options="options"
      :filter="filter"
      :show-clear="showClear"
      @change="handleChanged"
      @show="handleFocus"
      @hide="handleBlur"
    />
    <label>{{ label }}</label>
  </span>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import { ref } from "@vue/reactivity"
import MultiSelect from "primevue/multiselect"
import PrimeVueDropdown from "primevue/dropdown"

@Options({
  //@ts-ignore
  props: {
    label: String,
    placeholder: String,
    modelValue: [ String, Array, Object ],
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String,
    small: Boolean,
    noBackground: Boolean,
    optionLabel: String,
    optionValue: String,
    options: Array,
    filter: Boolean,
    showClear: Boolean
  },
  emits: ['update:modelValue', 'change', 'focus', 'blur'],
  components: {
    PrimeVueDropdown
  }
})
export default class Dropdown extends Vue {

  label!: string;
  placeholder!: string;
  modelValue!: any;
  inline!: boolean;
  autocomplete!: string;
  error!: string;
  type!: string;
  disabled!: boolean;
  name!: string;
  small!: boolean;
  noBackground!: boolean;

  optionLabel!: string
  optionValue!: string
  options!: any[]
  filter!: boolean
  showClear!: boolean

  focused = false;
  internalValue: any | null = this.modelValue || null;

  //@ts-ignore
  myinput: MultiSelect = ref<MultiSelect | null>(null);

  get animationClass (): string {
    if (this.noBackground) {
      return 'bg-white' + this.sizeClass + this.inlineClass
    } else if (this.focused || this.internalValue) {
      return 'ai-filled' + this.errorClass + this.sizeClass + this.inlineClass
    } else {
      return '' + this.errorClass + this.sizeClass + this.inlineClass
    }
  }

  get inlineClass (): string {
    return (this.inline ? ' animated-input-inline' : '')
  }

  get sizeClass(): string {
    return (this.small ? ' animated-input-sm' : '')
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger'
      } else {
        return ' border-danger danger'
      }
    } else {
      return ''
    }
  }

  @Watch('internalValue')
  handleInput (val: any, oldVal: any) {
    this.$emit('update:modelValue', this.internalValue)
  }

  handleChanged(event: any) {
    this.$emit('change', event)
  }

  handleFocus () {
    this.focused = true
    this.$emit('focus')
  }

  handleBlur () {
    this.focused = false
    this.$emit('blur')
  }

  @Watch('modelValue')
  onValueChanged (val: any, oldVal: any) {
    this.internalValue = val
  }
}
</script>

<style scoped lang="scss">
/*
.p-dropdown {
  align-items: center;
}
.p-dropdown-label {
  color: white;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  border: none;
  font-size: 0.8rem;
  cursor: text;
  z-index: 1;
  background-color: transparent;
  padding: 0.5rem 1rem;
}
.animated-input:not(.animated-input-sm) .p-dropdown {
  height: calc(3rem + 4px) !important;
}
.animated-input.animated-input-sm .p-dropdown {
  height: 2rem !important;
}
.p-dropdown, .p-dropdown:not(.p-disabled).p-focus {
  background: none;
  border: none;
  box-shadow: none;
}
.ai-filled .p-dropdown-label, .ai-filled .p-dropdown-trigger-icon, .ai-filled .p-dropdown-clear-icon {
  color: white !important;
}
.p-dropdown-clear-icon {
  z-index: 2;
}

 */
</style>
