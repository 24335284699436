import Rule from '@/model/settings/Rule';

export default class MailFilter {
  id!: number | null;
  rules!: Rule[] | null;
  action!: string | null;
  target!: string | null;

  constructor() {
    this.id = null;
    this.rules = null;
    this.action = null;
    this.target = null;
  }
}
