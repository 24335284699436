import PartialDate from '@/model/common/carddav/PartialDate';

export default class DateOrTime {
  text!: string | null;
  date!: string | null;
  calendar!: any | null;
  partialDate!: PartialDate | null;
  calendarScale!: string | null;
  hasTime!: boolean | null;
  language!: string | null;
  alternativeId!: string | null;

  constructor() {
    this.text = null;
    this.date = null;
    this.calendar = null;
    this.partialDate = null;
    this.calendarScale = null;
    this.hasTime = null;
    this.language = null;
    this.alternativeId = null;
  }
}
