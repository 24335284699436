

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast, {ToastAPI} from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Button from "primevue/button"
import DataSource from "@/model/DataSource"
import DataSourceInstance from "@/model/DataSourceInstance"
import DataImportJob from "@/model/DataImportJob"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import Skeleton from "primevue/skeleton"
import {Router, useRouter} from "vue-router"
import dayjs from "@/util/dayjs"
import RpcError from "@/api/RpcError"
import ScrollPanel from "primevue/scrollpanel"
import Dialog from "primevue/dialog"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import Checkbox from "primevue/checkbox"
import SettingsUtil from "@/util/SettingsUtil"
import DataImportWizard from "@/components/settings/dataimportwizard/DataImportWizard.vue"
import DataImportJobListItem from "@/components/settings/dataimport/DataImportJobListItem.vue"

@Options({
  components: { Button, Skeleton, ScrollPanel, Dialog, Checkbox, DataImportWizard, DataImportJobListItem },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DataImport extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()
  router: Router = useRouter()

  availableSources: DataSource[] = []
  setupSources: DataSourceInstance[] = []
  setupSourcesLoading: boolean = false
  currentJobs: DataImportJob[] = []
  currentJobsLoading: boolean = false
  logIntervalHandle: number = 0
  showWizard: boolean = false

  get showWidget() {
    return SettingsUtil.showDataImportWidget()
  }

  set showWidget(show: boolean) {
    SettingsUtil.setShowDataImportWidget(show)
  }

  gotToNewSourcePage() {
    void this.router.push("/settings/import/newsource")
  }

  async loadData() {
    try {
      this.setupSourcesLoading = true
      this.availableSources = await dataSourceServiceApi._getAvailableDataSources()
      this.setupSources = await dataSourceServiceApi._getDataSourceInstances()
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not load data sources"))
    } finally {
      this.setupSourcesLoading = false
    }

    try {
      this.currentJobsLoading = true
      this.currentJobs = await dataImportServiceApi._getDataImportJobs()
      if (this.logIntervalHandle === 0) {
        //reload this every x:
        this.logIntervalHandle = window.setInterval(() => {
          void this.fetchJobsInBackground()
        }, 5000)
      }
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not load data import jobs"))
    } finally {
      this.currentJobsLoading = false
    }
  }

  getDataSourceForInstance(instance: DataSourceInstance): DataSource | null {
    let result: DataSource | null = null
    this.availableSources.forEach((source: DataSource) => {
      if (source.name == instance.datasourceName) {
        result = source
      }
    })
    return result
  }

  async fetchJobsInBackground() {
    try {
      this.currentJobs = await dataImportServiceApi._getDataImportJobs()
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Getting jobs failed"))
    }
  }

  formatCreatedDate(date: Date | null): string {
    if (date) {
      return dayjs(date).format("HH:mm DD.MM.YYYY")
    } else {
      return this.i18n.$gettext("Unknown creation date")
    }

  }

  confirmSourceInstanceDeletion(instance: DataSourceInstance) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this data source?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (instance) {
          dataSourceServiceApi._deleteDataSourceInstance(instance).then(() => {
            this.toast.success(this.i18n.$gettext("Data source deleted"))
            void this.loadData()
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Data source could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  mounted() {
    void this.loadData()
  }

  unmounted() {
    if (this.logIntervalHandle !== 0) {
      window.clearInterval(this.logIntervalHandle)
    }
  }

}
