import ProjectMember from '@/model/common/ProjectMember';
import ProjectUnread from '@/model/common/ProjectUnread';
import ProjectStats from '@/model/common/ProjectStats';

export default class Project {
  id!: string | null;
  name!: string | null;
  displayName!: string | null;
  companyName!: string | null;
  deleted!: string | null;
  created!: string | null;
  members!: ProjectMember[] | null;
  unread!: ProjectUnread | null;
  stats!: ProjectStats | null;
  icon!: string | null;
  permissions!: string[] | null;
  updated!: string | null;
  teamIconUpdated!: string | null;
  description!: string | null;
  email!: string | null;
  type!: string | null;
  allowedDomains!: string | null;
  inviteId!: string | null;
  allowOpenInvite!: boolean | null;
  schemeId!: string | null;
  groupConstrained!: boolean | null;
  backend!: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.displayName = null;
    this.companyName = null;
    this.deleted = null;
    this.created = null;
    this.members = null;
    this.unread = null;
    this.stats = null;
    this.icon = null;
    this.permissions = null;
    this.updated = null;
    this.teamIconUpdated = null;
    this.description = null;
    this.email = null;
    this.type = null;
    this.allowedDomains = null;
    this.inviteId = null;
    this.allowOpenInvite = null;
    this.schemeId = null;
    this.groupConstrained = null;
    this.backend = null;
  }
}
