import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import QueryResult from '@/model/QueryResult';


export default class GeneratedQueryResultStore {

    state = reactive({
        queryResults: new Map<string, QueryResult>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.queryResults) this.state.queryResults.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceQueryResult(newQueryResult: QueryResult) {
        if (newQueryResult.id) {
            this.state.queryResults.set(newQueryResult.id, newQueryResult)
            
        }
    }

    addOrReplaceQueryResults(newQueryResults: QueryResult[]) {
        for (const newQueryResult of newQueryResults) {
            this.addOrReplaceQueryResult(newQueryResult)
        }
    }

    replaceQueryResults(queryResults: QueryResult[]) {
        const newQueryResults = new Map<string, QueryResult>()
        for (const newQueryResult of queryResults) {
            if (newQueryResult.id) {
                newQueryResults.set(newQueryResult.id, newQueryResult)
                
            }
        }
        this.state.queryResults = newQueryResults
    }

    removeQueryResult(id: string | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.queryResults.delete(id)
        }
    }

    removeQueryResults(ids: (string | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeQueryResult(id)
            }
        }
    }
}