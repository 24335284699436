<template>
  <div class="d-flex">
    <span class="p-float-label inline mr-2 flex-shrink-0" style="min-width: 30%">
        <AnimatedInput v-model="company" type="text" :disabled="readOnly" :label="i18n.$gettext('Company')" />
    </span>
    <span class="p-float-label inline mr-2 flex-grow-1">
      <Tags
          v-model="departments"
          multiple
          :disabled="readOnly"
          :get-autocomplete-items="() => []"
      />
      <label><translate>Departments</translate></label>
    </span>
    <Button
      v-if="!readOnly"
      icon="cil-trash-alt"
      class="p-button-danger after-inline-label"
      @click="emitDelete"
    />
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Organization from "@/model/common/carddav/Organization"
import Button from "primevue/button"
import Tags from "@/components/common/Tags.vue"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import i18n from "@/util/i18n"

@Options({
  components: {AnimatedInput, Tags, Button},
  //@ts-ignore
  props: {
    organization: [Organization, Object],
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'delete' ]
})
export default class OrganizationField extends Vue {

  i18n = i18n

  organization!: Organization;

  get company(): string {
    if(!this.organization || !this.organization.titles) return ""
    return this.organization.titles[0] || ""
  }

  set company(c: string) {
    if(!this.organization || !this.organization.titles) return
    this.organization.titles[0] = c
  }

  get departments(): string[] {
    if(!this.organization) return []
    return this.organization.titles?.slice(1) || []
  }

  set departments(d: string[]) {
    if(!this.organization || !this.organization.titles ) return
    this.organization.titles = [(this.organization.titles[0] || ""), ...d]
  }

  emitDelete(): void {
    this.$emit('delete')
  }

}
</script>

<style scoped lang="scss">

</style>
