export default class TaskBoardList {
  name!: string | null;
  type!: string | null;
  tasks!: string[] | null;

  constructor() {
    this.name = null;
    this.type = null;
    this.tasks = null;
  }
}
