<template>
  <div class="helpcenter-overlay p-xl-6 d-flex flex-column">
    <div class="flex-shrink-0 pr-xl-3" style="width: 100%">
      <h1 class="text-white flex-shrink-0">
        <translate>Help Center</translate>
      </h1>
      <p class="lead text-white mb-5">
        <span class="font-weight-bold"><translate>Welcome to the info center.</translate></span><br>
        <translate>Here you will find manuals and tips about how to use the Uniki Collaboration Platform and how to connect it to PCs, Smartphones and software like Outlook, Apple Mail and others.</translate><br>
        <translate>Choose a category to see a list of available manuals.</translate>
      </p>
      <div class="d-flex flex-row">
        <div class="helpcenter-sectionbar flex-shrink-0 d-flex flex-column mr-4">
          <div
            v-for="manualSection in manualSections"
            :key="manualSection.path"
            class="helpcenter-menuitem d-flex align-items-center px-4 mb-2"
            style="width: 20rem; height: 4rem"
            :class="{ 'selected': isActiveSection(manualSection.path) }"
            @click="manualSubpath = manualSection.path; currentManual = null"
          >
            <h6 class="mb-0">
              <i v-if="manualSection.icon && !manualSection.icon.startsWith('/')" :class="manualSection.icon" />
              <img v-else-if="manualSection.icon" :src="manualSection.icon" style="height: 1.125rem; margin-top: -2px">
              {{ manualSection.name }}
            </h6>
          </div>
        </div>
        <div v-if="manualSubpath && manualSubpath !== '' && !currentManualObject" class="flex-grow-1">
          <div
            v-for="manual in manualsForSelectedSection"
            :key="manual.file"
            class="helpcenter-menuitem d-flex align-items-center px-4 separator-bottom mb-2"
            style="height: 4rem"
            :class="{ 'selected': isActiveManual(manual.file) }"
            @click="currentManual = manual.file"
          >
            <span class="lead font-weight-bold">{{ manual.title }}</span>
          </div>
        </div>
        <div v-else-if="currentManualObject" class="flex-grow-1">
          <div
            :key="currentManualObject.file"
            class="helpcenter-menuitem d-flex align-items-center px-4 separator-bottom mb-2"
            style="height: 4rem"
            @click="currentManual = null"
          >
            <span class="lead font-weight-bold"><i class="cil-arrow-circle-left" />&nbsp;&nbsp;<translate>go back</translate></span>
          </div>
          <div v-if="currentManualObject.url" class="manual-video p-4 mb-2">
            <iframe
              style="min-width: 640px; min-height: 360px"
              :src="currentManualObject.url"
              title="Video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div class="manual-text p-4">
            <ManualDisplay
              v-if="currentManualObject.file"
              :key="currentManualObject.file"
              :markdown-file="currentManualObject.file"
              @switchManual="switchManual"
            />
          </div>
        </div>
        <div v-else-if="introVideoUrl && false" class="ml-6">
          <iframe
            v-if="introVideoUrl"
            class="intro-video"
            :src="introVideoUrl"
            title="Introduction Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </div>
    <div class="helpcenter-close" @click="handleClose">
      <i class="cil-x" />
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import manuals from "@/components/helpcenter/manuals"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import ManualDisplay from "@/components/common/ManualDisplay.vue"
import SettingsUtil from "@/util/SettingsUtil"
import Constants from "@/util/Constants"

@Options({
  components: { ManualDisplay },
  //@ts-ignore
  props: {},
  emits: ['close']
})
export default class HelpCenter extends Vue {

  manualSubpath: string = ""
  currentManual: string = ""

  i18n: Language = useGettext()
  toast = useToast()

  get manualSections(){
    return manuals()
  }

  get manualsForSelectedSection(){
    const section = this.manualSections.find((s: { name: string, path: string, manuals: any[] }) => {
      return this.manualSubpath === s.path
    })
    return section ? section.manuals : []
  }

  get currentManualObject(){
    const section = this.manualSections.find((s: { name: string, path: string, manuals: any[] }) => {
      return this.manualSubpath === s.path
    })

    if(!section) return null
    return section.manuals.find((m: { title: string, url: string | null , file: string }) => {
      return this.currentManual == m.file
    })
  }

  switchManual(file: string) {
    this.currentManual = file
  }

  get hasSeenIntroVideo(): boolean {
    return SettingsUtil.getIntroVideoSeen()
  }

  get introVideoUrl(): string {
    return Constants.introductionVideoUrl
  }

  isActiveSection(section: string) {
    return section === this.manualSubpath
  }

  isActiveManual(manual: string) {
    return this.currentManual === manual
  }

  handleClose(){
    this.$emit('close')
  }

}
</script>

<style lang="scss" scoped>
@import "node_modules/elly-bs4/sass/variables.scss";

.helpcenter-overlay {
  position: fixed;
  z-index: 32323;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($uniki_primary_dark, 0.1);
  //backdrop-filter: blur(2px);
  overflow-y: auto;

}

.helpcenter-menuitem {
  background-color: white;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: $uniki-secondary;
  }

  &.selected {
    border-left: 6px solid $uniki_primary;
  }
}

.manual-text {
  background-color: white;
  border-radius: 3px;
}

.manual-video {
  border-radius: 3px;
  background-color: white;
}

.helpcenter-close {
  position: absolute;
  right: 1rem;
  top: 0px;
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;

  &:hover {
    color: $uniki_primary;
  }
}

$padding-video: 0.5rem;
.intro-video {
  padding-left: $padding-video;
  padding-right: $padding-video;
  min-width: 640px;
  min-height: 360px;
}

</style>
