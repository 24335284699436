import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import ResourceShare from '@/model/common/ResourceShare';
import Page from '@/model/Page';
import { resourceShareStore } from '@/store/ResourceShareStore';

export default class GeneratedResourceShareServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getShares(): Promise<ResourceShare[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getShares', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const resourceShares: ResourceShare[] = data.map(resourceShare => Object.assign(new ResourceShare(), resourceShare))
                resourceShareStore.replaceResourceShares(resourceShares)
                return resourceShares
            } else return Promise.reject()
        })
    }


    _getSharesFor(resource: string, type: string): Promise<ResourceShare[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSharesFor', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const resourceShares: ResourceShare[] = data.map(resourceShare => Object.assign(new ResourceShare(), resourceShare))
                resourceShareStore.addOrReplaceResourceShares(resourceShares)
                return resourceShares
            } else return Promise.reject()
        })
    }


    _calculatePermissions(resource: string, type: string, shares: ResourceShare[]): Promise<ResourceShare[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('calculatePermissions', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new ResourceShare(), it)) : Promise.reject()
        })
    }

    _setShares(resource: string, type: string, shares: ResourceShare[], recursive: boolean): Promise<ResourceShare[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setShares', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                return data.map(resourceShare => {
                    const model = Object.assign(new ResourceShare(), resourceShare)
                    resourceShareStore.removeResourceShare(resourceShare.id)
                    resourceShareStore.addOrReplaceResourceShare(model)
                    return model
                })
            } else return Promise.reject()
        })
    }


    getShares(refresh?: boolean | number): SWR<ResourceShare[], number[]> {
        //@ts-ignore
        const result: SWR<ResourceShare[], number[]> = reactive(new SWR<ResourceShare[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getShares' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getShares[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const resourceShares: ResourceShare[] = []
                for (const id of data) {
                    const resourceShare: ResourceShare | undefined = resourceShareStore.state.resourceShares.get(id)
                    if (resourceShare) {
                        resourceShares.push(resourceShare)
                    }
                }
                result.data = resourceShares
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getShares().then((data: ResourceShare[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(resourceShare => resourceShare.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let resourceShares: ResourceShare[] = [...resourceShareStore.state.resourceShares.values()]
        
        
        
        result.data = resourceShares
        return result
    }

    getSharesFor(resource: string, type: string, refresh?: boolean | number): SWR<ResourceShare[], number[]> {
        //@ts-ignore
        const result: SWR<ResourceShare[], number[]> = reactive(new SWR<ResourceShare[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getSharesFor' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getSharesFor[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const resourceShares: ResourceShare[] = []
                for (const id of data) {
                    const resourceShare: ResourceShare | undefined = resourceShareStore.state.resourceShares.get(id)
                    if (resourceShare) {
                        resourceShares.push(resourceShare)
                    }
                }
                result.data = resourceShares
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getSharesFor(resource, type).then((data: ResourceShare[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(resourceShare => resourceShare.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const resourceShares: ResourceShare[] = []
            for (const id of cached.data) {
                const resourceShare: ResourceShare | undefined = resourceShareStore.state.resourceShares.get(id)
                if (resourceShare) {
                    resourceShares.push(resourceShare)
                }
            }
            result.data = resourceShares
        }
        return result
    }

    getResourceShare(id: number): ResourceShare | undefined {
        return resourceShareStore.state.resourceShares.get(id)
    }
}
