export class Call<T> {
    ended: number | null = null
    loading = false
    refreshing = false
    promise: Promise<T> | null = null
    data: T | null = null
}

export default class SWR<R,T> {
    data: R | null = null
    call: Call<T> | null = null
}
