<template>
  <div id="list-wrapper" class="h-100">
    <div v-if="initialLoadDone && loadingOrRefreshing" class="list-loading">
      <slot name="loading" />
    </div>
    <div
      id="root"
      ref="root"
      style="overflow: auto"
      :style="rootStyle"
    >
      <div id="viewport" ref="viewport" :style="viewportStyle">
        <div id="spacer" ref="spacer" :style="spacerStyle">
          <div v-if="scrollDirection < 0" :style="{ 'height': bottomSpacerHeight + 'px' }" />
          <div
            v-for="item in visibleItems"
            :key="item[idProperty]"
            :ref="el => { rows.set(item[idProperty], el) }"
            class="list-item"
          >
            <slot v-if="item.isDummy" name="skeleton">
              <div class="pl-3 pr-3 pt-2 pb-4 d-flex">
                <Skeleton height="36px" class="flex-grow-1" />
              </div>
            </slot>
            <slot v-else name="element" :item="item" />
          </div>
          <div v-if="visibleItems.length === 0">
            <slot name="empty" />
          </div>
          <div v-if="scrollDirection > 0" :style="{ 'height': bottomSpacerHeight + 'px' }" />
        </div>
      </div>
    </div>
    <div
      v-if="showScrollToStartButton && ((!isAtStart && scrollDirection < 0) || (isAtEnd && scrollDirection >= 0))"
      id="scrollToStartButton"
      class="z-2"
      @click="scrollToStart('smooth')"
    >
      <i v-if="scrollDirection < 0" class="cil-arrow-bottom" />
      <i v-else class="cil-arrow-top" />
    </div>
  </div>
</template>

<script lang="ts">
import {ref} from '@vue/reactivity'
import {Options, Vue} from 'vue-class-component'
import {Watch} from "vue-property-decorator"
import SWR from "@/api/SWR"
import Skeleton from "primevue/skeleton"
import ResizeObserver from 'resize-observer-polyfill'
import Page from "@/model/Page"

@Options({
  //@ts-ignore
  props: {
    getAllItems: [ Array, Function ],
    getItemPage: Function,
    loadItems: Function,
    pageSize: {type: Number, default: 100},
    containerHeight: {type: String, default: "100%"},
    scrollDirection: {type: Number, default: 1},
    idProperty: {type: String, default: 'id'},
    showScrollToStartButton: {type: Boolean, default: false},
    autoScrollAtStart: {type: Boolean, default: false},
    bottomSpacerHeight: {type: Number, default: 40}
  },
  components: { Skeleton },
  emits: ["initialloaddone", "isatstart", "isatend"]
})
export default class InfiniteList extends Vue {

  //@ts-ignore
  root: HTMLElement = ref<HTMLElement | null>(null)
  //@ts-ignore
  viewport: HTMLElement = ref<HTMLElement | null>(null)
  //@ts-ignore
  spacer: HTMLElement = ref<HTMLElement | null>(null)
  //@ts-ignore
  rows: Map<any, HTMLElement> = new Map<any, HTMLElement>()
  //@ts-ignore
  elementHeights: Map<HTMLElement, number> = new Map<HTMLElement, number>()
  rowHeights: Map<any, number> = new Map<any, number>()

  // The array of all available items or a function returning all available items
  getAllItems!: (() => SWR<any[], void> | any[] | null) | any[]
  // A function that returns a page of items
  getItemPage!: ((pageIndex: number, pageSize: number) => SWR<any[], Page<any>>) | null
  // A function that loads more items, adds them to the state of getItems and returns either the known total number of items or nothing
  loadItems!: ((pageIdx: number, max: number) => Promise<Page<any>>) | null
  pageSize!: number
  containerHeight!: string | null
  // The scroll direction, 1 for down, -1 for up
  scrollDirection!: number
  idProperty!: string
  showScrollToStartButton!: boolean
  bottomSpacerHeight!: number
  autoScrollAtStart!: boolean

  // Total number of items if known (returned by loadItems)
  totalItems: number | null = null
  // When scroll direction is inverse, the total height should only be estimated once
  initialTotalItems = 0
  // Are items currently loading as part of the infinite scroll?
  loadingOrRefreshing = false
  // Index of the page which contains the last visible item
  currentPage = 0
  // Total height per page
  // On page 0 , lets say all PAGE_SIZE rows add up to 2000
  // On page 1, lets say all PAGE_SIZE rows add up to 2500, then
  // pagePositions: [2000, 4500]
  // page 1 = page 0 height of PAGE_SIZE items + page 1 height of PAGE_SIZE items
  pagePositions: number[] = []
  // How much to shift the spacer vertically so that the scrollbar is not disturbed when hiding items
  translateY = 0
  // Total height of all the rows of all the pages
  listHeight: number | null = null
  // Whether the scroll position is at the end of the list
  isAtEnd = false
  // Whether the scroll position is at the start of the list
  isAtStart = true
  // The scroll position
  pixelPosition: number | null = null
  //If on next scroll event we should scroll to the bottom of the list
  shouldScrolltoBottom = true

  browserSupportsPassiveScroll: boolean | null = null

  generatedDummyId: number = 0
  initialLoadDone = false
  resizeObserver: ResizeObserver | null = null

  scrollToStart(behavior:  "auto" | "smooth" | undefined = 'smooth') {
    if (this.root) this.root.scrollTo({
      top: this.scrollDirection < 0 ? Math.max(this.root.scrollHeight || 0, this.listHeight || 0) : 0,
      left: 0,
      behavior: behavior
    })
  }

  get items(): any[] { // Wrap the function in a getter so that the result gets cached!
    if (Array.isArray(this.getAllItems)) {
      return this.getAllItems
    } else {
      let something: SWR<any[], void> | any[] | null = this.getAllItems()
      if (Array.isArray(something)) {
        return something
      } else if (something) {
        return something.data || []
      } else {
        return []
      }
    }
  }

  /**
   Subset of list items rendered on the DOM
   */
  //TODO: If endIndex < TOTAL_ITEMS we can display some dummy icons or if we don't know TOTAL_ITEMS we can display a loading row
  get visibleItems(): any[] {
    let visibleItems = []
    if (this.getItemPage) {
      let swrPage1: SWR<any[], Page<any>> = this.getItemPage(Math.max(this.currentPage - 1, 0), this.pageSize)
      let swrPage2: SWR<any[], Page<any>> = this.getItemPage(Math.max(this.currentPage, 1), this.pageSize)
      let swrPage3: SWR<any[], Page<any>> = this.getItemPage(Math.max(this.currentPage + 1, 2), this.pageSize)

      if (swrPage1.call?.promise || swrPage2.call?.promise || swrPage3.call?.promise) {
        this.loadingOrRefreshing = true
        let max: number | boolean | void
        let setMax = (page: Page<any>) => {
          if (typeof page.total === 'number' && ((typeof max !== 'number' || page.total > max))) {
            max = page.total
          } else if (page?.hasMore) {
            max = page.hasMore
          }
        }
        new Promise(resolve => {
          (swrPage1.call?.promise || Promise.reject()).then(setMax).finally(() => {
            (swrPage2.call?.promise || Promise.reject()).then(setMax).finally(() => {
              (swrPage3.call?.promise || Promise.reject()).then(setMax).finally(() => {
                resolve(max)
              })
            })
          })
        }).then(max => {
          //let max = values.reduce((previousValue, currentValue) => { return currentValue || previousValue })
          let totalItems: number | null = null
          if (typeof max === 'number') {
            totalItems = Math.max(max, this.items.length)
          } else if (typeof max === 'boolean' && max) {
            totalItems = Math.max((this.totalItems || 0), this.items.length) + 1
          } else if (this.totalItems === 0) {
            totalItems = Math.max(0, this.items.length)
          }
          if (totalItems !== null && this.totalItems !== totalItems) {
            this.totalItems = totalItems
          }
        }).finally(() => {
          this.loadingOrRefreshing = false
          if (!this.initialLoadDone && this.autoScrollAtStart) {
            this.initialLoadDone = true
            this.$emit("initialloaddone")
            void this.$nextTick(() => {
              this.scrollToStart('auto')
            })
          }
          void this.$nextTick(() => {
            this.update()
          })
        })
      } else if (!this.initialLoadDone && this.autoScrollAtStart) {
        this.initialLoadDone = true
        this.$emit("initialloaddone")
        void this.$nextTick(() => {
          this.scrollToStart('auto')
        })
      }

      if (this.loadingOrRefreshing) { //Important: This adds reactivity so that visibleItems gets called again when loading is done
        visibleItems = (this.fillWithDummies(swrPage1)).concat(this.fillWithDummies(swrPage2)).concat(this.fillWithDummies(swrPage3))
      } else {
        visibleItems = [...(swrPage1.data || [])].concat([...(swrPage2.data || [])]).concat([...(swrPage3.data || [])])
      }
    } else if (this.getAllItems) try {
      if (Array.isArray(this.getAllItems)) {
        visibleItems = this.items.slice(Math.max(this.currentPage - 1, 0) * this.pageSize, Math.max(this.currentPage + 2, 2) * this.pageSize)
      } else {
        let something: SWR<any[], void> | any[] | null = this.getAllItems()
        if (Array.isArray(something)) {
          this.totalItems = something.length
          visibleItems = this.items.slice(Math.max(this.currentPage - 1, 0) * this.pageSize, Math.max(this.currentPage + 2, 2) * this.pageSize)
        } else if (something) {
          const data: any[] = ((something as SWR<any[], void>).data  || [])
          this.totalItems = data.length
          if ((something.call?.loading || something.call?.refreshing) && something.call?.promise) {
            this.loadingOrRefreshing = true
            something.call.promise.finally(() => { this.loadingOrRefreshing = false })
            const maxSkeletons = something.call?.refreshing ? 1 : 1 + Math.ceil(Math.random() * 4)
            //Important: Access this.loadingOrRefreshing to add reactivity so that visibleItems gets called again when loading is done
            for (let i = data.length; this.loadingOrRefreshing && (i < this.currentPage * this.pageSize + maxSkeletons); i++) {
              const dummy: any = { isDummy: true }
              this.generatedDummyId++
              dummy[this.idProperty] = this.generatedDummyId
              data.push(dummy)
            }
          }
          visibleItems = data.slice(Math.max(this.currentPage - 1, 0) * this.pageSize, Math.max(this.currentPage + 2, 2) * this.pageSize)
        } else {
          this.totalItems = 0
          visibleItems = []
        }
      }
    } finally {
      if (!this.initialLoadDone && this.autoScrollAtStart) {
        this.initialLoadDone = true
        this.$emit("initialloaddone")
        void this.$nextTick(() => {
          this.scrollToStart('auto')
        })
      }
    }

    void this.$nextTick(() => {
      // If this getter has been called then the underlying data has changed, then MAYBE the rendered items will change,
      // which will be checked in update() at the next tick....
      this.update()
    })

    return visibleItems
  }

  fillWithDummies(swr: SWR<any[], Page<any>>): any[] {
    const data: any[] = [...(swr.data || [])]
    for (let i = data.length; i < this.pageSize && swr.call?.loading; i++) {
      const dummy: any = { isDummy: true }
      this.generatedDummyId++
      dummy[this.idProperty] = this.generatedDummyId
      data.push(dummy)
    }
    return data
  }

  /**
   Translate the spacer vertically to keep the scrollbar intact
   We only show N items at a time so the scrollbar would get affected if we dont translate
   */
  get spacerStyle() {
    if (this.scrollDirection >= 0) {
      return {
        willChange: "auto",
        transform: "translateY(" + Math.sign(this.scrollDirection) * this.translateY + "px)",
        width: "100%"
      }
    } else {
      return {
        willChange: "auto",
        transform: "translateY(" + Math.sign(this.scrollDirection) * this.translateY + "px)",
        width: "100%",
        display: "flex",
        flexDirection: "column-reverse"
      }
    }
  }

  /**
   Set the height of the viewport
   For a list where all items are of equal height, height of the viewport = number of items x height of each item
   For a list where all items are of different height, it is the sum of height of each row
   */
  get viewportStyle() {
    if (this.scrollDirection >= 0) {
      return {
        minHeight: this.listHeight === null ? this.containerHeight : (Math.max(this.listHeight, this.root.scrollHeight) + "px"),
        position: "relative",
        willChange: "auto"
      }
    } else {
      return {
        display: 'flex',
        flexDirection: 'column-reverse',
        minHeight: this.listHeight === null ? this.containerHeight : (Math.max(this.listHeight, this.root.scrollHeight) + "px"),
        position: "relative",
        willChange: "auto"
      }
    }
  }

  /**
  Calculate the height of the root element
   */
  get rootStyle() {
    return {
      height: this.containerHeight || (window.innerHeight + "px"),
      overflow: "auto"
    }
  }

  //TODO: Test this
  @Watch('root.offsetHeight')
  watchRootHeight(/*newValue: number, oldValue: number */) {
    void this.$nextTick(() => {
      this.update()
    })
  }

  update() {
    let changed: boolean = Math.ceil(this.items.length / this.pageSize) !== this.pagePositions.length
    if (!changed) {
      for (let item of this.items) {
        if (!this.rows.has(item[this.idProperty])) {
          changed = true
          break
        }
      }
    }
    if (!changed) {
      for (let id of this.rows.keys()) {
        if (!this.items.find(item => item[this.idProperty] === id)) {
          changed = true
          break
        }
      }
    }

    if (changed) {
      const newPagePositions: Map<number, number> = new Map<number, number>()
      const minIndex = Math.max(this.currentPage - 1, 0) * this.pageSize
      const newRows: Map<any, HTMLElement> = new Map<any, HTMLElement>()
      const newHeights: Map<HTMLElement, number> = new Map<HTMLElement, number>()
      for (let index = minIndex; index < this.items.length; index++) {
        // Given an item index, compute the page index
        // For example, any item index from 0 to 40 would translate to page index 0
        // Any item with index 50 to 99 would translate to page index 1
        const pageIndex = Math.floor(index / this.pageSize)
        // Get the scroll height and update the height of the item at index
        const itemId: any = this.items[index][this.idProperty]
        const element: HTMLElement | undefined = this.rows.get(itemId)
        const offsetHeight: number | undefined = element ? element.offsetHeight : this.rowHeights.get(itemId)
        if (offsetHeight !== undefined) {
          // Add the height of the row to the total height of all rows on the current page
          newPagePositions.set(pageIndex, (newPagePositions.get(pageIndex) || 0) + offsetHeight)
          if (element) {
            newRows.set(itemId, element)
            newHeights.set(element, offsetHeight)
            if (this.resizeObserver) this.resizeObserver.observe(element)
          }
          this.rowHeights.set(itemId, offsetHeight)
        } else if (pageIndex > this.currentPage) { //TODO: Only if we have more items
          // We have reached the area where the items have not yet been rendered...
          // add one more page because when scrolling we need to know there is another page and we need to load more items.
          let averagePageHeight = Math.ceil((newPagePositions.get(pageIndex - 1) || 0) / newPagePositions.size)
          if (this.pagePositions.length > 0) {
            averagePageHeight = Math.ceil(this.pagePositions[this.pagePositions.length - 1] / this.pagePositions.length)
          } else {
            averagePageHeight = 0
            if (newPagePositions.size > 0) {
              newPagePositions.forEach((value: number) => {
                averagePageHeight += value
              })
              averagePageHeight /= newPagePositions.size
            }
          }
          newPagePositions.set(pageIndex, averagePageHeight)
        }
      }

      //Clean up because keeping all the HTMLElements in the map forever crashes browsers!
      this.rows = newRows
      this.elementHeights = newHeights

      // Map is sorted by insertion order, no need to sort again.
      newPagePositions.forEach((value, index, /* map */) => {
        this.pagePositions[index] = (index > 0 ? (this.pagePositions[index - 1] || 0) : 0) + value
      })

      const oldViewportHeight: number | undefined = this.viewport ? this.viewport.scrollHeight : undefined

      this.updateHeightEstimate()

      void this.$nextTick(() => {
        this.handleScroll() //Keep loading more until we got enough items.
        if (this.scrollDirection < 0 && oldViewportHeight !== undefined) {
          void this.$nextTick(() => {
            // If the list grows longer, the scroll position needs to be adjusted.
            const scrollDifference = Math.max(this.viewport.scrollHeight - oldViewportHeight, 0)
            if (scrollDifference) {
              this.root.scrollTop += scrollDifference
              this.handleScroll()
            }
          })
        }
      })
    }
  }

  updateHeightEstimate() {
    // Total height of the viewport is an estimate based on the height of the previously loaded items.
    let newHeight = this.pagePositions[this.pagePositions.length - 1] + this.bottomSpacerHeight
    if (this.totalItems && (this.scrollDirection >= 0 || this.initialTotalItems === 0)) {
      newHeight = Math.max(newHeight, Math.ceil(newHeight / this.pagePositions.length * this.totalItems / this.pageSize))
    } else if (this.totalItems) {
      newHeight = Math.max(newHeight, Math.ceil(newHeight / this.pagePositions.length * this.initialTotalItems / this.pageSize))
    }
    if (this.initialTotalItems === 0) {
      this.initialTotalItems = this.totalItems || 0
    }
    if (this.listHeight !== newHeight) {
      this.listHeight = newHeight
    }
  }

  handleScroll(/*e: Event | null = null */) { //TODO: Handle case where we overscrolled: Show loading...
    if (this.autoScrollAtStart && this.shouldScrolltoBottom) {
      this.shouldScrolltoBottom = !this.initialLoadDone
      void this.$nextTick(() => {
        this.scrollToStart(this.initialLoadDone ? 'smooth' : 'auto')
      })
      return
    }

    let pixelPosition = (this.pagePositions[this.currentPage - 2] || 0) - this.translateY
    if (this.scrollDirection >= 0 && this.root) {
      pixelPosition += this.root.scrollTop + this.root.offsetHeight
    } else if (this.root && this.viewport) {
      pixelPosition += this.viewport.scrollHeight - this.root.scrollTop
    }

    this.pixelPosition = pixelPosition
    if (this.root) this.isAtStart = pixelPosition < 1.1 * this.root.clientHeight
    if (this.viewport) this.isAtEnd = pixelPosition > 0.9 * this.viewport.scrollHeight


    //If the last page isn't full, then we can't be on the next one
    const maxPage = Math.floor(this.items.length / this.pageSize)
    const currentPage = Math.min(this.getPageForPosition(this.pagePositions, pixelPosition, this.currentPage), maxPage)
    const translateY = this.pagePositions[currentPage - 2] || 0

    // If we are on the last page and the page has changed or we know there are more items, load more
    if (this.pagePositions && currentPage >= this.pagePositions.length - 1 &&
      (currentPage !== this.currentPage || this.totalItems === null || this.items.length < this.totalItems)) {
      this.loadMore()
    }

    // Only modify start/end if the page has changed, in order to prevent unnecessary re-renders
    if (currentPage !== this.currentPage) {
      this.currentPage = currentPage
    }

    // Only modify translateY if the page has changed, in order to prevent unnecessary re-renders
    if (translateY !== this.translateY) {
      // Move the list down by the height of all pages that aren't rendered
      this.translateY = translateY
    }

    if (pixelPosition + this.translateY > this.pagePositions[this.pagePositions.length - 1]) {
      //TODO: We overscrolled... show loading...?
    }
  }

  /**
   Don't start in the middle like a regular binary search, instead start at the current page index.
   Usually we stay on the same page or we scroll from one page to the next, so most of the time we
   don't need to perform a search at all and if we have to search we know the starting direction.
   */
  getPageForPosition(arr: number[], pixelPosition: number, startingPoint: number) {
    let low = 0
    let high: number = Array.isArray(arr) ? arr.length - 1 : Object.keys(arr).length - 1
    let mid: number = startingPoint

    if (arr[mid] === pixelPosition || (arr[mid] > pixelPosition && (mid === 0 || arr[mid - 1] < pixelPosition))) {
      return mid
    } else if (arr[mid] < pixelPosition && (mid === high || arr[mid + 1] > pixelPosition)) {
      return mid + 1
    } else if (mid > 0 && arr[mid - 1] > pixelPosition && (mid === 1 || arr[mid - 2] < pixelPosition)) {
      return mid - 1
    } else if (arr[mid] > pixelPosition) {
      high = mid - 1
      mid = Math.max(high - Math.max(0.3 * (high - low), 1), low)
    } else {
      low = mid + 1
      mid = Math.min(low + Math.max(0.3 * (high - low), 1), high)
    }

    while (low < high) {
      if (arr[mid] === pixelPosition) {
        break
      } else if (arr[mid] > pixelPosition) {
        high = mid - 1
      } else {
        low = mid + 1
      }
      mid = Math.floor((high + low) / 2)
    }

    if (pixelPosition <= arr[mid]) {
      return mid
    } else {
      return mid + 1
    }
  }

  loadMore() {
    if (!this.loadingOrRefreshing && !!this.loadItems) {
      // Mark the loading status
      this.loadingOrRefreshing = true
      this.loadItems(this.pagePositions.length, this.pageSize).then((page: Page<any>) => {
        let totalItems: number | null = null
        if (typeof page.total === 'number') {
          totalItems = Math.max(page.total, this.items.length)
        } else if (typeof page.hasMore === 'boolean') {
          totalItems = (this.totalItems || 0) + Math.max((this.totalItems || 0) + 1, this.items.length + 1)
        } else if (this.totalItems === 0) {
          totalItems = Math.max(0, this.items.length)
        }
        if (totalItems !== null && this.totalItems !== totalItems) {
          this.totalItems = totalItems
          this.updateHeightEstimate()
        }
      }).finally(() => {
        this.loadingOrRefreshing = false
      })
    }
  }

  // This snippet is taken straight from https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
  // It will only work on browser so if you are using in an SSR environment, keep your eyes open
  doesBrowserSupportPassiveScroll() {
    if (this.browserSupportsPassiveScroll !== null) {
      return this.browserSupportsPassiveScroll
    } else {
      let passiveSupported = false

      try {
        const options = {
          get passive() {
            // This function will be called when the browser attempts to access the passive property.
            passiveSupported = true
            return false
          }
        }

        //@ts-ignore
        window.addEventListener("test", null, options)
        //@ts-ignore
        window.removeEventListener("test", null, options)
      } catch (err) {
        passiveSupported = false
      }
      this.browserSupportsPassiveScroll = passiveSupported
      return passiveSupported
    }
  }

  @Watch('getItemPage')
  watchGetItemPageFunction(/* oldFunction: any, newFunction: any */) {
    this.reset()
  }

  @Watch('items')
  watchForNewItems(/* oldItems: any, newItems: any */) {
    if (this.isAtStart && this.autoScrollAtStart){
      this.shouldScrolltoBottom = true
      void this.$nextTick(this.handleScroll)
    }
  }

  @Watch('isAtStart')
  watchAtStart(newVal: any, oldVal: any){
    if (newVal === true) {
      this.$emit("isatstart")
    }
  }

  @Watch('isAtEnd')
  watchAtEnd(newVal: any, oldVal: any){
    if (newVal === true) {
      this.$emit("isatend")
    }
  }


  @Watch('loadItems')
  watchLoadMoreFunction(/* oldFunction: any, newFunction: any */) {
    this.reset()
    this.loadMore()
  }

  reset() {
    this.root.scrollTop = 0
    this.resetData()
  }

  resetData() {
    this.totalItems = null
    this.initialTotalItems = 0
    this.loadingOrRefreshing = false
    this.currentPage = -1
    this.pagePositions = []
    this.translateY = 0
    this.listHeight = null
  }

  scrollCorrectionDistance: number = 0
  mounted() {
    this.loadMore()
    this.handleScroll()
    // Check if browser supports passive scroll and add scroll event listener
    let options = this.doesBrowserSupportPassiveScroll() ? { passive: true } : false
    this.root.addEventListener("scroll", this.handleScroll, options)
    try {
      this.resizeObserver = new ResizeObserver((elements: any[]) => {
        for (let element of elements) {
          const oldElementHeight = this.elementHeights.get(element.target)
          if (oldElementHeight && (Math.abs(element.target.offsetHeight - oldElementHeight) > 1)) {
            this.listHeight = this.listHeight + element.target.offsetHeight - oldElementHeight
            let pixelPosition: number
            if (this.scrollDirection < 0) {
              pixelPosition = this.listHeight - (this.pixelPosition || 0)
            } else {
              pixelPosition = this.pixelPosition || 0
            }
            let direction: number = 0
            if (this.scrollDirection < 0 && element.target.offsetTop < pixelPosition) {
              direction = 1 //Element is on or above the page
            } else if ((element.target.offsetTop + element.target.offsetHeight) < (pixelPosition - (this.root?.clientHeight || 0))) {
              direction = 1 //Element is above the page
            } else if (this.scrollDirection >= 0 && element.target.offsetTop > pixelPosition && oldElementHeight < element.target.offsetHeight) {
              direction = -1 //Element is below the page and its size increased
            }
            if (direction) void this.$nextTick(() => {
              this.scrollCorrectionDistance += (element.target.offsetHeight - oldElementHeight) * direction
              if (this.root && this.scrollCorrectionDistance) {
                this.root.scrollTo({
                  top: this.root.scrollTop + this.scrollCorrectionDistance,
                  left: 0,
                  behavior: 'auto'
                })
                this.scrollCorrectionDistance = 0
              }
            })
          }
        }
      })
    } catch (ignore) {}
  }

  beforeUnmount() {
    this.root.removeEventListener("scroll", this.handleScroll)
  }

  unmounted(){
    this.resetData()
  }

  beforeMount(){
    this.resetData()
  }
}
</script>

<style scoped lang="scss">

.list-loading {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

#list-wrapper {
  position: relative;
}

#scrollToStartButton {
  border-radius: 50%;
  background: white;
  width: 4rem;
  height: 4rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
