export default class File {
  file!: any | null;
  inputStream!: any | null;
  data!: any | null;
  url!: string | null;
  text!: string | null;
  language!: string | null;
  mediaTypeName!: string | null;
  mediaType!: string | null;
  extension!: string | null;

  constructor() {
    this.file = null;
    this.inputStream = null;
    this.data = null;
    this.url = null;
    this.text = null;
    this.language = null;
    this.mediaTypeName = null;
    this.mediaType = null;
    this.extension = null;
  }
}
