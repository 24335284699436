import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Query from '@/model/common/Query';


export default class GeneratedQueryStore {

    state: {
        querys: Map<string, Query>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let querys: Map<string, Query> | null = null
        try {
            const persistedState = localStorage.getItem('QueryStore')
            if (persistedState) {
                querys = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            querys: querys || new Map<string, Query>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('QueryStore', JSON.stringify(Array.from(this.state.querys)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.querys) this.state.querys.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('QueryStore')
    }

    addOrReplaceQuery(newQuery: Query, persist: boolean = true): true | undefined {
        if (newQuery.id) {
            this.state.querys.set(newQuery.id, newQuery)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceQuerys(newQuerys: Query[]) {
        let persist = false
        for (const newQuery of newQuerys) {
            const changed = !!this.addOrReplaceQuery(newQuery, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceQuerys(querys: Query[]) {
        const newQuerys = new Map<string, Query>()
        for (const newQuery of querys) {
            if (newQuery.id) {
                newQuerys.set(newQuery.id, newQuery)
                
            }
        }
        this.state.querys = newQuerys
        this.persistState()
    }

    removeQuery(id: string | undefined | null, persist: boolean = true): true | undefined {
        if (id !== undefined && id !== null) {
            if (this.state.querys.delete(id)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeQuerys(ids: (string | null)[] | undefined | null) {
        let persist = false
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                const changed = !!this.removeQuery(id, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}