
import Button from "primevue/button"
import {Options, Vue} from "vue-class-component"

@Options({
  components:  { Button },
  //@ts-ignore
  props: {
    variant: String,
    addClasses: String,
    action: Function,
    inline: Boolean,
    disabled: Boolean,
    arguments: Object
  },
  emits: ['loadingActionDone']
})
export default class LoadingButton extends Vue {
  variant!: string;
  addClasses!: string;
  action!: (any: any) => Promise<any> | any
  arguments!: any;

  loading = false;
  hideText = false;
  type = "button";

  get classes (): string {
    return this.variantClass + " " + (this.addClasses ? this.addClasses : '')
  }

  get variantClass(): string {
    switch (this.variant){
      case "primary":
        return ""
      case "secondary":
        return "p-button-secondary"
      case "success":
        return "p-button-success"
      case "info":
        return "p-button-info"
      case "warning":
        return "p-button-warning"
      case "help":
        return "p-button-help"
      case "danger":
        return "p-button-danger"
      default:
        return ""
    }
  }

  callRegisteredAction () {
    if (!this.loading) { //Prevent additional clicks!
      this.loading = true
      this.hideText = true
      let func = this.action
      let promise: Promise<any> | any = func(this.arguments)
      if (promise && promise instanceof Promise) {
        promise.then((successObject: any) => {
          this.loading = false
          setTimeout(() => {
            this.hideText = false
            this.$emit('loadingActionDone')
          }, 400)

          if (!successObject) {
            return
          }

          if (successObject.hasOwnProperty("redirect") && typeof successObject.redirect === "string") {
            //TODO void this.$router.push(successObject.redirect);
          }

          if (successObject.hasOwnProperty("callback") && typeof successObject.callback === "function") {
            successObject.callback()
          }
        }, (errorObject: any) => {
          this.loading = false
          setTimeout(() => {
            this.hideText = false
            this.$emit('loadingActionDone')
          }, 400)

          //Test if we caught a platform error:
          if (errorObject instanceof Error) {
            throw errorObject
          }

          if (!errorObject) {
            return
          }

          if (errorObject.hasOwnProperty("redirect") && typeof errorObject.redirect === "string") {
            //TODO $router.push(errorObject.redirect);
          }

          if (errorObject.hasOwnProperty("callback") && typeof errorObject.callback === "function") {
            errorObject.callback()
          }
        })
      } else {
        this.loading = false
        setTimeout(() => {
          this.hideText = false
          this.$emit('loadingActionDone')
        }, 400)
      }
    }
  }
}
