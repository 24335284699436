import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import LibraryHistoryData from '@/model/common/LibraryHistoryData';
import Page from '@/model/Page';
import { libraryHistoryDataStore } from '@/store/LibraryHistoryDataStore';

export default class GeneratedLibHistoryServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getLibHistory(repoId: string, page: number, max: number): Promise<Page<LibraryHistoryData>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getLibHistory', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let libraryHistoryDatas: LibraryHistoryData[] = data.items.map((libraryHistoryData: any) => Object.assign(new LibraryHistoryData(), libraryHistoryData))
                libraryHistoryDataStore.addOrReplaceLibraryHistoryDatas(libraryHistoryDatas)
                return Object.assign(new Page<LibraryHistoryData>(), data)
            } else return Promise.reject()
        })
    }

    getLibHistory(repoId: string, page: number, max: number, refresh?: boolean | number): SWR<LibraryHistoryData[], Page<string>> {
        //@ts-ignore
        const result: SWR<LibraryHistoryData[], Page<string>> = reactive(new SWR<LibraryHistoryData[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_getLibHistory' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getLibHistory[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const libraryHistoryDatas: LibraryHistoryData[] = []
                for (const id of data.items || []) {
                    const libraryHistoryData: LibraryHistoryData | undefined = libraryHistoryDataStore.state.libraryHistoryDatas.get(id)
                    if (libraryHistoryData) {
                        libraryHistoryDatas.push(libraryHistoryData)
                    }
                }
                result.data = libraryHistoryDatas
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getLibHistory(repoId, page, max).then((data: Page<LibraryHistoryData>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(libraryHistoryData => !!libraryHistoryData.historyId)?.map(libraryHistoryData => libraryHistoryData.historyId) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let libraryHistoryDatas: LibraryHistoryData[] = [...libraryHistoryDataStore.state.libraryHistoryDatas.values()]
        if (repoId) libraryHistoryDatas = SortAndFilterUtil.filter(libraryHistoryDatas, { repoId: repoId })
        
        if (max !== null && max !== undefined) {
            libraryHistoryDatas = libraryHistoryDatas.slice((page || 0) * max, ((page || 0) + 1) * max)
        }
        result.data = libraryHistoryDatas
        return result
    }

    getLibraryHistoryDatas(pageIndex?: number, pageSize?: number): LibraryHistoryData[] {
        let libraryHistoryDatas: LibraryHistoryData[] = [...libraryHistoryDataStore.state.libraryHistoryDatas.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            libraryHistoryDatas = libraryHistoryDatas.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return libraryHistoryDatas
    }

    getLibraryHistoryData(historyId: string): LibraryHistoryData | undefined {
        return libraryHistoryDataStore.state.libraryHistoryDatas.get(historyId)
    }
}
