<template>
  <nav id="navbar-top" class="navbar navbar-expand-lg navbar-dark">
    <slot name="start" />
    <button
      class="navbar-toggler mr-2"
      type="button"
      aria-label="Toggle navigation"
      @click="toggleNavBar()"
    >
      <span class="navbar-toggler-icon" />
    </button>

    <div id="navbar-list" class="collapse navbar-collapse" @click="toggleNavBar">
      <slot class="navbar-nav mr-auto" name="content" />
    </div>
  </nav>
</template>

<script lang="ts">
import {Vue} from "vue-class-component"

export default class MenuBar extends Vue {

  toggleNavBar() {
    const collapse: HTMLElement | null = this.$el.querySelector('#navbar-list')
    if (collapse && collapse.classList.contains("show")) {
      collapse.classList.remove("show")
    } else if (collapse) {
      collapse.classList.add("show")
    }
  }
}
</script>

<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";

#navbar-top {
  background-color: transparent !important;
  box-shadow: none;
}

.collapse {
  display: block !important;
  overflow: hidden;
  transition: max-height .5s ease-in-out;
}
.collapse.show {
  max-height: 99em;
}
@media screen and (max-width: 991px) {
  .collapse:not(.show) {
    display: none !important;
  }
}

</style>
