import {reactive} from '@vue/reactivity'

export class WindowDimensions {
    public height: number = window.innerHeight
    public width: number = window.innerWidth;
}

class BreakpointUtil {

    dimensions: WindowDimensions  = reactive(new WindowDimensions())

    constructor() {
        //Do NOT remove the arrow function below.
        // if you just pass a function, it will be bound to the global 'window' element
        window.addEventListener('resize', () => {
            this.onWindowResize()
        })
    }

    onWindowResize(): void {
        this.dimensions.width = window.innerWidth
        this.dimensions.height = window.innerHeight
    }

    public getWindowDimensions(): WindowDimensions {
        return this.dimensions
    }

    public isOnMobile(): boolean {
        return this.dimensions.width < 736
    }

    public isOnXl(): boolean {
        return this.dimensions.width < 1200
    }

}

const breakpointUtil: BreakpointUtil = new BreakpointUtil()
export default breakpointUtil
