export default class EmailAddress {
  address!: string | null;
  name!: string | null;
  types!: string[] | null;
  preference!: number | null;
  alternativeId!: string | null;

  constructor() {
    this.address = null;
    this.name = null;
    this.types = null;
    this.preference = null;
    this.alternativeId = null;
  }
}
