import TaskBoardList from '@/model/common/TaskBoardList';

export default class TaskBoardMetaInformation {
  taskLists!: TaskBoardList[] | null;
  swimlanes!: string[] | null;
  colors!: any | null;

  constructor() {
    this.taskLists = null;
    this.swimlanes = null;
    this.colors = null;
  }
}
