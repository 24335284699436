import GeneratedUserServiceApi from "./generated/GeneratedUserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import User from "@/model/User"

class UserServiceApi extends GeneratedUserServiceApi {

    get user(): User | null {
        return rpcClient.session?.user || null
    }

    get isAdmin(): boolean {
        return this.user?.posixGroupName === 'admin'
    }

    get userEmail(): string | null {
        return this.user?.email?.toLowerCase() || null
    }

    get userName(): string | null {
        return this.user?.userName || null
    }

    get userId(): string | null {
        return this.user?.uid?.toLowerCase() || null
    }
}

export const userServiceApi = new UserServiceApi()
