<template>
  <div v-if="!loading">
    <div
      v-if="success"
      class="manualdisplay"
      @click="handleLinkClick($event)"
      v-html="html"
    />
    <div v-else class="d-flex flex-row bg-light h-100" style="border-radius: 3px; overflow: hidden">
      <div class="d-flex flex-grow-1 flex-column justify-content-center h-100">
        <div class="text-center">
          <p class="h5 mb-2">
            <translate>Manual not available</translate>
          </p>
          <p><translate>Due to an error, the manual could not be loaded.</translate></p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <ProgressBar mode="indeterminate" style="width: 100%" />
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import axios from "axios"
import {replaceColonsWithUTF8} from "@/util/emojiFromStringConverter"
import insane from "insane"
import adminpanelHolder from "@/util/AdminpanelHolder"
import {rpcClient} from "@/api/WebsocketClient"
import {Watch} from "vue-property-decorator"
import ProgressBar from "primevue/progressbar"
import {useGettext} from "@jshmrtn/vue3-gettext"

const marked = require("marked")

const insaneOptions = {
  "allowedAttributes": {
    "a": ["href", "name", "target", "rel"],
    "iframe": ["allowfullscreen", "frameborder", "src"],
    "img": ["src"],
    "span": ["class"]
  },
  "allowedClasses": {},
  "allowedSchemes": ["http", "https", "mailto"],
  "allowedTags": [
    "a", "article", "b", "blockquote", "br", "caption", "code", "del", "details", "div", "em",
    "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "ins", "kbd", "li", "main", "ol",
    "p", "pre", "section", "span", "strike", "strong", "sub", "summary", "sup", "table",
    "tbody", "td", "th", "thead", "tr", "u", "ul"
  ],
  "filter": null,
  "transformText": null
}

@Options({
  components: { ProgressBar },
  //@ts-ignore
  props: {
    "markdownFile": String,
  },
  emits: [ 'switchManual' ]
})
export default class ManualDisplay extends Vue {

  i18n = useGettext()
  markdownFile: string = ""
  loading: boolean  = true
  success: boolean = true
  html: string = ""

  handleLinkClick(e: any) {
    if (typeof e.target?.href?.includes === 'function' && e.target.href.includes('/manual/')) {
      e.preventDefault()
      this.$emit('switchManual', e.target.href.split('/manual/')[1])
    }
  }

  injectVariables(md: string): string {
    if (!md) return ''

    let myDomain = adminpanelHolder?.instance?.store?.state?.status?.status?.domain //TODO
    let username = rpcClient.session.user?.userName
    let myEmail = rpcClient.session.user?.email || this.i18n.$gettext("your@email.address")

    if(!myDomain){
      return md
    }

    if (!username) {
      username = "benutzername@" + myDomain
    }

    let newMd: string = md.replace(/<MYDOMAIN>/g, myDomain)
    newMd = newMd.replace(/<USERNAME>/g, username)
    newMd = newMd.replace(/<EMAIL>/g, myEmail)

    let localIP =  adminpanelHolder?.instance?.store?.state?.system?.system?.localIp

    if (localIP) {
      newMd = newMd.replace(/<MYIP>/g, localIP)
    }

    return newMd
  }

  safeRenderText(text: string | null): string {
    if (!text) return ""
    let unsafeHtml: string
    const renderer = new marked.Renderer()
    const linkRenderer = renderer.link
    renderer.link = (href: any, title: any, text: any) => {
      const localLink = href.startsWith(`${location.protocol}//${location.hostname}`) || href.startsWith("/")
      const html = linkRenderer.call(renderer, href, title, text)
      return localLink ? html : html.replace(/^<a /, '<a target="_blank" rel="noreferrer noopener nofollow" ')
    }
    marked.setOptions({
      renderer
    })
    unsafeHtml = marked.parse(replaceColonsWithUTF8(text))
    return insane(unsafeHtml, insaneOptions)
  }

  async loadCurrentManualMarkdown(){
    if(this.markdownFile === "") return
    this.loading = true
    let resourcePath: string = '/manuals/markdown/' + this.markdownFile
    let res = await axios.get(resourcePath)
    let rawMd: string = res.data
    let markdown = this.injectVariables(rawMd)
    this.loading = false
    this.html = this.safeRenderText(markdown)
  }

  created() {
    void this.loadCurrentManualMarkdown()
  }

  @Watch('markdownFile')
  handleNewManual(){
    void this.loadCurrentManualMarkdown()
  }


}
</script>

<style lang="scss" scoped>




</style>
