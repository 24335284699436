import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import AddressBook from '@/model/directory/AddressBook';


export default class GeneratedAddressBookStore {

    state: {
        addressBooks: Map<string, AddressBook>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let addressBooks: Map<string, AddressBook> | null = null
        try {
            const persistedState = localStorage.getItem('AddressBookStore')
            if (persistedState) {
                addressBooks = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            addressBooks: addressBooks || new Map<string, AddressBook>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('AddressBookStore', JSON.stringify(Array.from(this.state.addressBooks)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.addressBooks) this.state.addressBooks.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('AddressBookStore')
    }

    addOrReplaceAddressBook(newAddressBook: AddressBook, persist: boolean = true): true | undefined {
        if (newAddressBook.originalId) {
            this.state.addressBooks.set(newAddressBook.originalId, newAddressBook)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceAddressBooks(newAddressBooks: AddressBook[]) {
        let persist = false
        for (const newAddressBook of newAddressBooks) {
            const changed = !!this.addOrReplaceAddressBook(newAddressBook, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceAddressBooks(addressBooks: AddressBook[]) {
        const newAddressBooks = new Map<string, AddressBook>()
        for (const newAddressBook of addressBooks) {
            if (newAddressBook.originalId) {
                newAddressBooks.set(newAddressBook.originalId, newAddressBook)
                
            }
        }
        this.state.addressBooks = newAddressBooks
        this.persistState()
    }

    removeAddressBook(originalId: string | undefined | null, persist: boolean = true): true | undefined {
        if (originalId !== undefined && originalId !== null) {
            if (this.state.addressBooks.delete(originalId)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeAddressBooks(originalIds: (string | null)[] | undefined | null) {
        let persist = false
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                const changed = !!this.removeAddressBook(originalId, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}