

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import useToast from "@/util/toasts"
import InputText from "primevue/inputtext"
import OutOfOffice from "@/model/settings/OutOfOffice"
import Button from "primevue/button"
import DatePicker from "@/components/common/DatePicker.vue"
import Editor from "@tinymce/tinymce-vue"
import { ref } from "@vue/reactivity"
import dayjs from "@/util/dayjs"
import insane from "insane"
import EmailUtil from "@/util/EmailUtil"
import Signature from "@/model/settings/Signature"
import Menu from "primevue/menu"

@Options({
  components: {
    InputText, DatePicker, Button, Editor, Menu
  },
  //@ts-ignore
  props: {
    filter: [ OutOfOffice, Object ],
    signatures: Array,
    createMode: Boolean
  },
  emits: [
    'save', 'delete', 'cancel'
  ]
})
export default class UserOutOfOfficeEditor extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  api = settingsServiceApi

  //@ts-ignore
  editor: Editor = ref<Editor | null>(null)

  signatures!: Signature[]
  filter!: OutOfOffice
  createMode!: boolean

  editActive: boolean = false
  saveLoading: boolean = false
  deleteLoading: boolean = true

  start: Date = new Date()
  end: Date = new Date(new Date().getTime() + 86400000)
  subject: string = ''
  htmlBody: string = '<html><body><br><br></body></html>'
  signaturesMenuItems: any[] = []
  //@ts-ignore
  signaturesMenu: Menu = ref(null)
  currentSignature: string = ''

  get editMode(): boolean {
    return Boolean(this.editActive || this.createMode)
  }

  get formattedTime(): string {
    if (this.filter?.start && this.filter?.end) {
      return dayjs(this.filter.start).format('dddd, LLL') + ' - ' + dayjs(this.filter.end).format('dddd, LLL')
    } else {
      return ''
    }
  }

  get invalidRules(): boolean {
    return !(this.start && this.end && (this.subject || this.htmlBody))
  }

  get sanitizedHTML(): string {
    return insane(this.filter.htmlBody, EmailUtil.insaneOptions)
  }

  save() {
    if (this.start && this.end && this.subject && this.htmlBody) {
      const outOfOffice: OutOfOffice = this.filter || new OutOfOffice()
      outOfOffice.start = this.start.toISOString()
      outOfOffice.end = this.end.toISOString()
      outOfOffice.subject = this.subject
      outOfOffice.htmlBody = this.htmlBody
      //@ts-ignore
      outOfOffice.textBody = window.tinyMCE.editors[0].getContent({format: 'text'})
      this.$emit('save', outOfOffice)
    }
  }

  startEdit() {
    this.start = this.filter?.start ? new Date(this.filter.start) : new Date()
    this.end = this.filter?.end ? new Date(this.filter.end) : new Date(new Date().getTime() + 86400000)
    this.subject = this.filter?.subject || ''
    this.htmlBody = this.filter?.htmlBody || '<html><body><br><br></body></html>'
    this.editActive = true
  }

  cancelEdit() {
    this.editActive = false
    this.$emit('cancel')
  }

  get defaultSignature(): Signature | null {
    return this.signatures?.find(s => s.isDefault) || null
  }

  openSignaturesMenu(e: Event) {
    if (this.signatures && this.signatures.length > 0) {
      this.signaturesMenuItems = this.signatures.map(s => {
        return {
          label: s.name,
          icon: 'fa fa-file-signature',
          command: () => {
            this.changeSignature(s.html || '')
          }
        }
      })
      void this.$nextTick(() => {
        this.signaturesMenu.show(e)
      })
    }
  }

  changeSignature(html: string) {
    if (this.htmlBody) {
      if (this.currentSignature && this.htmlBody.includes(this.currentSignature)) {
        this.htmlBody = this.htmlBody.replace(this.currentSignature, html)
      } else {
        this.htmlBody = this.htmlBody + html + '<br />'
      }
    } else {
      this.htmlBody = '<br />' + html + '<br />'
    }
    this.currentSignature = html
  }
}
