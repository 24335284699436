export default class Query {
  id!: string | null;
  all!: string | null;
  type!: string[] | null;
  fields!: string[] | null;
  collectionIds!: string[] | null;

  constructor() {
    this.id = null;
    this.all = null;
    this.type = null;
    this.fields = null;
    this.collectionIds = null;
  }
}
