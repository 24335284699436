<template>
  <div class="dash-widget d-flex flex-column card card-lite bg-white z-1 mr-md-4 mr-3 mt-6">
    <div class="dash-widget-count z-2">
      {{ unreadMessageCnt }}
    </div>
    <div class="w-100 d-flex align-items-center px-3 py-3">
      <p class="h5 mb-0">
        <translate>New Messages</translate>
      </p>
      <!-- this is basically in here to adjust the height. Just make it visible if you need a settings cog on this -->
      <a
        v-tooltip.left="i18n.$gettext('Settings')"
        class="nav-link flex-shrink-0"
        style="cursor: pointer; visibility: hidden"
      >
        <i class="cil-settings" style="font-size: 1.6rem" />
      </a>
    </div>
    <div class="flex-shrink-1 flex-grow-1 result-list" style="min-height: 22.8rem">
      <InfiniteList
        :get-all-items="allItems"
        id-property="originalId"
      >
        <template #element="{ item }">
          <div class="pt-2 pb-2 px-3 separator-top cursor-pointer bg-hover-light" @click="goToItem(item)">
            <div class="w-100 d-flex flex-row">
              <div class="strong flex-grow-1" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                {{ getChannelName(item) }}
              </div>
            </div>
            <div class="w-100 d-flex flex-row">
              <div class="flex-grow-1" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                <translate
                  v-if="item.type === 'Direct'"
                  :translate-n="item.unreadMsgCount"
                  translate-plural="%{ msgcount } unread Messages"
                  :translate-params="{msgcount: item.unreadMsgCount}"
                >
                  %{ msgcount } unread Message
                </translate>&nbsp;
                <translate
                  v-else
                  :translate-n="item.unreadMsgCount"
                  translate-plural="%{ msgcount } unread Messages"
                  :translate-params="{msgcount: item.unreadMsgCount}"
                >
                  %{ msgcount } unread Message
                </translate>&nbsp;
              </div>
            </div>
          </div>
        </template>
        <template #loading>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
        </template>
        <template #empty>
          <div class="d-flex flex-column justify-content-center" style="min-height: 22rem">
            <div class="text-center">
              <p><translate>No messages unread, champ!</translate></p>
            </div>
          </div>
        </template>
      </InfiniteList>
    </div>
  </div>
</template>


<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Channel from "@/model/directory/Channel"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import Skeleton from "primevue/skeleton"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import SWR from "@/api/SWR"
import Project from "@/model/directory/Project"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"

@Options({
  components: { Skeleton, InfiniteList  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class ChatWidget extends Vue {

  projectsAreLoading: boolean = false
  channelsAreLoading: boolean = false
  usersAreLoading: boolean = false
  channelApi = channelServiceApi
  i18n: Language = useGettext()

  get loading(): boolean {
    return this.channelsAreLoading || this.usersAreLoading || this.projectsAreLoading
  }

  goToItem(item: Channel): void {
    if (item.id) {
      void this.$router.push('/chat/' + encodeURIComponent(item.id))
    }
  }

  get projects() {
    const swr: SWR<Project[], string[]> = projectServiceApi.getProjects()
    if (swr.call?.loading && swr.call?.promise) {
      this.projectsAreLoading = true
      swr.call.promise.finally(() => { this.projectsAreLoading  = false })
    }
    return swr.data || []
  }

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  get channels(): Channel[] {
    const channels = []
    let swr1 = this.channelApi.getChannelsForTeam(this.currentProjectId || null, false)
    let swr2 = this.currentProjectId ? this.channelApi.getChannelsForTeam(null, false) : undefined
    this.channelsAreLoading = Boolean((swr1.call?.loading && swr1.call?.promise) || (swr2?.call?.loading && swr2?.call?.promise))
    if (swr1.call?.promise) {
      swr1.call.promise.finally(() => {
        if (!swr2?.call?.loading || !swr2?.call?.promise) {
          this.channelsAreLoading = false
        }
      })
    }
    if (swr2?.call?.promise) {
      swr2.call.promise.finally(() => {
        if (!swr1?.call?.loading || !swr1?.call?.promise) {
          this.channelsAreLoading = false
        }
      })
    }
    if (swr1.data && (!swr2 || swr2.data)) {
      channels.push(...swr1.data)
      if (swr2?.data) channels.push(...swr2.data.filter(c => c.isDirect))
    }
    return channels.filter(c => !c.deleted)
  }

  get allItems(): Channel[] {
    return this.channels.filter((ch: Channel) => {
      return ch.unreadMsgCount ? ch.unreadMsgCount > 0 : false
    })
  }

  get unreadMessageCnt(): number {
    let sum = 0
    this.allItems.forEach((ch: Channel) => {
      sum += ch.unreadMsgCount || 0
    })
    return sum
  }

  get users(): User[] {
    const swr = userServiceApi.getUsers()
    if (swr.call?.loading && swr.call?.promise) {
      this.usersAreLoading = true
      swr.call.promise.finally(() => {
        this.usersAreLoading = false
      })
    }
    return swr.data || []
  }

  getChannelName(channel: Channel): string {
    if (channel.type === "Direct") {
      return this.getChannelNameForDirectConversation(channel)
    } else {
      return channel.displayName || ""
    }
  }

  getChannelNameForDirectConversation(channel: Channel): string {
    const username = rpcClient.session.user?.userName
    const users: string[] = []
    channel.members?.forEach((member: string ) => {
      if (member !== username) {
        const user: User | undefined = userServiceApi.getUser(member)
        if (!user) {
          users.push(member)
        } else {
          users.push(user.displayName || member)
        }
      }
    })

    return users.join(", ")
  }


}
</script>

<style lang="scss" scoped>

@import "node_modules/elly-bs4/sass/variables";

.strong {
  font-weight: 700;
}

</style>
