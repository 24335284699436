<template>
  <div class="d-flex align-items-center">
    <div v-for="colorOption in colorOptions" :key="colorOption" style="display:inline-block;">
      <span class="d-flex" :style="{ background: colorOption }" @click="updateColor(colorOption)" />
    </div>
    <div style="display:inline-block;">
      <span class="d-flex flex-column align-content-center justify-content-center" @click="updateColor(null)"><i class="cil-trash" /> </span>
    </div>
    <!--
    <label>
      <span @click="openPalette" class="customColorPickerIcon"><i class="m-0 p-0" style="display:inline;"></i></span>
    </label> -->
    <!--
    <PrimeVueColorPicker v-model="color" class="customColorPickerIcon" />
    -->
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import PrimeVueColorPicker from "primevue/colorpicker"

@Options({
  //@ts-ignore
  props: {
    modelValue: String
  },
  components: {
    PrimeVueColorPicker
  }
})
export default class ColorPicker extends Vue {

  i18n: Language = useGettext()

  modelValue!: string

  color: string | null = null
  colorOptions: string[] = [
    '#00AAA0', '#ffb74d', '#32b865', '#505050', '#c62828', '#462066', '#31d6af', '#cb4d4d', '#34b27d', '#dbdb57', '#e09952', '#9933cc', '#4d77cb'
  ]

  updateColor(color: string) {
    this.$emit('update:modelValue', color)
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables.scss";

input[type=checkbox] {
  display: none;
}

label :checked + span {
  background: $uniki_primary;
  color: white;
}

div span {
  display: inline-block;
  background: $input-bg;
  height: 2em;
  width: 2em;
  line-height: 3em;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 2px;
}

.customColorPickerIcon {
  position: relative;

  &::before {
    font-family: 'CoreUI-Icons-Linear' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    line-height: 200%;
    text-align: center;
    content: "\eb2e";
    font-size: 1.5rem;
    transition: all 0.2s;
  }

  &:hover {
    cursor: pointer;
    &::before{
      font-size: 1.6rem;
    }
  }

}

.p-colorpicker {
  margin-bottom: 0.5rem;
}
</style>
