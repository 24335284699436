
const defaultLang = "de_DE"
const defaultShortLang = defaultLang.substr(0, 2)


export function getNormalizedBrowserLanguage(): string {
    //@ts-ignore
    const browser: string = navigator.language || navigator.userLanguage

    if(!browser){
        return defaultLang
    }

    if(browser.length <= 3){
        switch (browser){
            case "en":
                return "en_US"
            case "de":
                return "de_DE"
            default:
                return defaultLang
        }
    }

    //Since some browsers do work with de-de instead of de-DE, we need to handle this here for all supported languages
    const lowercase = browser.toLowerCase()
    switch (lowercase){
        case "de-de":
        case "de-at":
        case "de-ch":
            return "de_DE"
        default:
            return "en_US"
    }
}

export function getNormalizedShortFormLanguage(): string {
    const longForm = getNormalizedBrowserLanguage()

    if(longForm.length < 4){
        return longForm
    }
    const matchRes = longForm.match(/(\w{2,3})_(\w{2,3})/)

    if (!matchRes) {
        return defaultShortLang.toLowerCase()
    }

    if (matchRes.length === 3) {
        return matchRes[1].toLowerCase()
    } else {
        return defaultShortLang.toLowerCase()
    }

}

export function getBrowserSelectedLanguageForI18N(translations: any): string {
    const langs = Object.keys(translations)

    const normLang = getNormalizedBrowserLanguage()

    if (langs.indexOf(normLang) > -1) {
        return normLang
    }

    const prefix = normLang.split("_")[0]

    const matching = langs.find((lang: string) => {
        return lang.startsWith(prefix)
    })

    if (matching) {
        return matching
    } else {
        return defaultLang
    }
}
