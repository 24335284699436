<template>
  <InnerLayoutWithSidebar
    v-if="hasChatBooked"
    :header-label="i18n.$gettext('Channels')"
    :default-open="openMobileMenuDefault"
    @created-clicked="openNewChannelModal"
  >
    <template #side-panel-content>
      <div
        v-for="channel in notDirectChannels"
        :key="channel.id"
        class="side-panel-menu-item pl-3 pt-2 pb-2 d-flex align-items-center"
        :class="{'active': channelIsActive(channel)}"
        @click="navigateToChannel(channel)"
        @contextmenu="showChannelContextMenu(channel, $event)"
      >
        <i v-if="channel.type === 'Open'" class="cil-globe collection-icon mr-2" />
        <i v-if="channel.type === 'Private'" class="cil-lock-locked collection-icon mr-2" />
        <span class="pl-1 flex-grow-1">{{ channel.displayName }}</span>
        <Badge
          v-if="channel.unreadMsgCount > 0"
          class="mr-1"
          style="float: right"
          :value="channel.unreadMsgCount"
        />
        <Button icon="cil-menu" class="p-button-text p-button-sm context-menu-btn" @click.stop.prevent="showChannelMenu(channel, $event)" />
      </div>
      <div v-if="!notDirectChannels?.length && channelsAreLoading">
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
      </div>
      <div class="py-3 pl-3 pr-2 mb-2 d-flex align-items-center">
        <h6 class="mb-0 d-inline flex-grow-1" style="margin-top: 0.15rem">
          <translate>Direct</translate>
        </h6>
        <Button class="p-button-sm p-button-success p-button-raised" icon="cil-plus" @click="openNewPrivateConvoModal" />
      </div>
      <div
        v-for="channel in directChannels"
        :key="channel.id"
        class="side-panel-menu-item d-flex align-items-center"
        :class="{'active': channelIsActive(channel)}"
        @click="navigateToChannel(channel)"
        @contextmenu="showChannelContextMenu(channel, $event)"
      >
        <Avatar
          v-if="channel.members && channel.members.length > 1"
          :key="channel.id + '-avatar'"
          :username="channel.members.find(m => m !== rpcClient.session.user?.userName)"
          :label="getChannelNameForDirectConversation(channel)"
          :size="24"
          generate-initials
          class="ml-2 pl-1 mr-1"
        />
        <i v-else-if="channel.members && channel.members.length ===1" class="cil-save collection-icon ml-3 mt-2 mb-2 mr-2" />
        <i v-else class="cil-comment-square collection-icon pl-3 mt-2 mb-2 mr-2" />
        <span class="pl-1 py-2 mb-1 flex-grow-1">{{ getChannelNameForDirectConversation(channel) }}</span>
        <Badge
          v-if="channel.unreadMsgCount > 0"
          class="mr-1 my-2"
          style="float: right"
          :value="channel.unreadMsgCount"
        />
        <Button icon="cil-menu" class="p-button-text p-button-sm context-menu-btn my-2" @click.stop.prevent="showChannelMenu(channel, $event)" />
      </div>
      <div v-if="!directChannels?.length && channelsAreLoading">
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
      </div>
    </template>
    <template #main-content>
      <div class="flex-grow-1 d-flex flex-column h-100" style="min-height: 1px">
        <div v-if="channelId" class="flex-grow-1 h-100 bg-white">
          <ChatChannel
            :key="channelId"
            :channel-id="channelId"
            :project-id="currentProjectId"
            :search-query="searchQuery"
            @editChannelMembers="startChannelMemberEdit"
            @editChannelDisplay="startChannelDisplayEdit"
          />
        </div>
        <div v-else class="flex-grow-1 h-100 d-flex flex-row justify-content-center align-items-center">
          <div class="">
            <p class="h5">
              <translate>Please select a channel</translate>
            </p>
            <p><translate>You can select a channel on the left side or create a new one in the top bar.</translate></p>
          </div>
        </div>
      </div>

      <Dialog
        v-model:visible="showPrivateConvoDialog"
        :header="i18n.$gettext('Create private Conversation')"
        :breakpoints="{'960px': '75vw', '640px': '100vw'}"
        :style="{minWidth: '50vw'}"
        :modal="true"
        :draggable="false"
        @close="closeModal"
      >
        <div>
          <UserSharingInput
            v-model="privateConvoSharees"
            only-allow-suggestions
            :access-options="[]"
            class="pb-2"
          >
            <template #header>
              <slot name="header">
                <p>
                  <translate>With whom do you want to talk?</translate>
                </p>
              </slot>
            </template>
            <template #placeholder>
              <translate>Add users to chat with</translate>
            </template>
            <template #footer>
              <slot name="footer" />
              <!--<div class="py-4 w-100 d-flex justify-content-end">
                <Button class="p-button-success" :label="i18n.$gettext('Save')"  icon="cil-save" @click="saveSharees" v-tooltip="i18n.$gettext('Save')" ></Button>
              </div>-->
            </template>
          </UserSharingInput>
          <p class="text-muted">
            <translate>Add the users you want to talk to. Type to search and press Enter to add a user.</translate>
          </p>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-secondary p-button-text"
              :label="i18n.$gettext('Abort')"
              @click="showPrivateConvoDialog = false"
            />
            <Button
              icon="cil-send"
              class="p-button-raised p-button-success"
              :loading="createChannelIsLoading"
              :label="i18n.$gettext('Start')"
              @click="getUsersAndCreatePrivateChannel"
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        v-model:visible="showNewChannelModal"
        :header="i18n.$gettext('Create new Channel')"
        :breakpoints="{'960px': '75vw', '640px': '100vw'}"
        :style="{minWidth: '50vw'}"
        :modal="true"
        :draggable="false"
        @close="closeModal"
      >
        <div>
          <div>
            <Dropdown
              v-model="newChannelType"
              :options="channelTypes"
              option-label="name"
              option-value="option"
              :placeholder="i18n.$gettext('Choose type')"
            />
            <p class="text-muted">
              <translate>Decide whether everyone is able to join (Public) or only the selected people you add(Private)</translate>
            </p>
          </div>
          <div>
            <span class="p-float-label w-100">
              <InputText v-model="newChannelName" class="w-100" />
              <label><translate>Channel Name</translate></label>
            </span>
            <p class="text-muted">
              <translate>The Name will appear for everyone. Choose short and wisely.</translate>
            </p>
          </div>
          <div>
            <span class="p-float-label mt-2 w-100">
              <InputText v-model="newChannelHeading" class="w-100" />
              <label><translate>Channel Heading</translate></label>
            </span>
            <p class="text-muted">
              <translate>The description will appear above the messages in the channel and can be edited.</translate>
            </p>
          </div>

          <UserSharingInput
            v-model="privateConvoSharees"
            only-allow-suggestions
            :access-options="[]"
            class="pb-2"
          >
            <template #header>
              <slot name="header">
                <div />
              </slot>
            </template>
            <template #placeholder>
              <translate>Add users to chat with</translate>
            </template>
            <template #footer>
              <slot name="footer">
                <p class="text-muted">
                  <translate>Type to search for users you want to chat with.</translate>
                </p>
              </slot>
              <!--<div class="py-4 w-100 d-flex justify-content-end">
                <Button class="p-button-success" :label="i18n.$gettext('Save')"  icon="cil-save" @click="saveSharees" v-tooltip="i18n.$gettext('Save')" ></Button>
              </div>-->
            </template>
          </UserSharingInput>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-secondary p-button-text"
              :label="i18n.$gettext('Abort')"
              @click="showNewChannelModal = false"
            />
            <Button
              icon="cil-send"
              class="p-button-raised p-button-success"
              :loading="createChannelIsLoading"
              :label="i18n.$gettext('Start')"
              @click="getUsersAndCreateNewChannel"
            />
          </div>
        </div>
      </Dialog>

      <Dialog v-model:visible="showEditModal" :header="i18n.$gettext('Edit conversation')" @close="closeModal">
        <div>
          <span class="p-float-label">
            <InputText v-model="newChannelName" class="w-100" type="text" />
            <label><translate>New channel name</translate></label>
          </span>
          <span class="p-float-label mt-2">
            <InputText v-model="newChannelHeading" class="w-100" type="text" />
            <label><translate>New channel heading</translate></label>
          </span>
          <div class="d-flex flex-row justify-content-end w-100 mt-4">
            <Button class="p-button-secondary p-button-text" :label="i18n.$gettext('Abort')" @click="showEditModal = false" />
            <Button
                class="ml-2 p-button-success"
                icon="cil-save"
                :label="i18n.$gettext('Save')"
                :loading="saveChannelIsLoading"
                @click="saveChannel"
            />
          </div>
        </div>
      </Dialog>

      <Dialog v-model:visible="showMemberModal" :header="i18n.$gettext('Edit members')" @close="closeModal">
        <div>
          <div class="p-inputgroup">
            <span class="p-float-label">
              <AutoComplete
                v-model="userToAdd"
                :suggestions="selectableUsers"
                @complete="filterUsers"
                @item-select="addUser"
              />
              <label><translate>Select users</translate></label>
            </span>
            <Button
              v-tooltip="i18n.$gettext('Add')"
              icon="cil-plus"
              class="p-button-success"
              @click="addUser"
            />
          </div>
          <div
            v-for="member in newUsers"
            :key="member"
            class="d-flex flex-row align-items-center mt-2"
            style="min-width: 24rem"
          >
            <Avatar
                :key="member + '-avatar'"
                :username="member"
                :label="member"
                :size="24"
                generate-initials
                class="mr-2"
            />
            <p class="flex-grow-1 mb-0">
              {{ member }}
            </p>
            <Button
              v-if="channelToEdit?.name !== 'town-square'"
              v-tooltip="i18n.$gettext('Remove')"
              class="p-button-secondary"
              icon="cil-trash"
              @click="removeMember(member)"
            />
          </div>
          <div class="d-flex justify-content-between mt-4">
            <Button :label="i18n.$gettext('Abort')" class="p-button-secondary" @click="showMemberModal = false" />
            <Button
              :label="i18n.$gettext('Save')"
              icon="cil-plus"
              class="p-button-success"
              @click="saveChannelMembers"
            />
          </div>
        </div>
      </Dialog>

      <Menu ref="menu" :model="menuItems" :popup="true" />
      <ContextMenu ref="contextmenu" :key="JSON.stringify(menuItems)" :model="menuItems" />
      <teleport to="#menubarcontent">
        <SearchBar
          class="pt-2"
          collection-type="CHAT_MESSAGE"
          :collection-id="channelId"
          collection-icon="cil cil-chat-square"
          :collection-name="channelDisplayName"
          @search="searchQuery = $event"
        />
      </teleport>
    </template>
  </InnerLayoutWithSidebar>
  <div v-else class="d-flex flex-row bg-light h-100" style="border-radius: 3px; overflow: hidden">
    <div class="d-flex flex-grow-1 flex-column justify-content-center h-100">
      <div class="text-center">
        <p class="h5 mb-2">
          <translate>Chat is disabled</translate>
        </p>
        <p><translate>Please talk to your administrator to enable Chat for your account or purchase an upgrade to your subscription.</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import ChatChannel from "@/components/chat/ChatChannel.vue"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import Channel from "@/model/directory/Channel"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import OverlayPanel from "primevue/overlaypanel"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import { ref } from "@vue/reactivity"
import Dialog from "primevue/dialog"
import MenuBar from "@/components/common/MenuBar.vue"
import {rpcClient} from "@/api/WebsocketClient"
import Menu from "primevue/menu"
import {userServiceApi} from "@/api/UserServiceApi"
import RpcError from "@/api/RpcError"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import ProgressBar from "primevue/progressbar"
import Button from "primevue/button"
import Badge from 'primevue/badge'
import User from "@/model/User"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import Dropdown from "primevue/dropdown"
import InputText from "primevue/inputtext"
import ContextMenu from "primevue/contextmenu"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {useConfirm} from "primevue/useconfirm"
import AutoComplete from "@/components/common/AutoComplete.vue"
import ChatUtil from "@/util/ChatUtil"
import Project from "@/model/directory/Project"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import featureSubset from "@/util/FeatureSubsets"
import SWR from "@/api/SWR"
import desktopNotificationUtil from "@/util/DesktopNotificationUtil"
import Avatar from "@/components/common/Avatar.vue"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import {toChatChannel, toChatsPromise} from "@/router"

@Options({
  components: {
    //@ts-ignore
    InnerLayoutWithSidebar, ChatChannel, OverlayPanel, Dialog, MenuBar, Menu, LoadingButton, ProgressBar, Button,
    Badge, UserSharingInput, Dropdown, InputText, ContextMenu, AnimatedInput, Skeleton, AutoComplete, Avatar, SearchBar
  }
})
export default class ChatView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()
  rpcClient = rpcClient

  channelApi = channelServiceApi
  userApi = userServiceApi
  newChannelName = ""
  newChannelHeading = ""
  newChannelType = "Private"
  showNewChannelModal = false
  showEditModal = false
  showMemberModal = false
  newUsers: string[] = []
  userToAdd = ""
  selectableUsers: string[] = []
  channelToEdit: Channel | null = null
  searchQuery: Query | null = null

  //@ts-ignore
  newConversationMenu: Menu = ref(null)

  showPrivateConvoDialog = false
  privateConvoSharees: any[] = []

  createChannelIsLoading = false

  deleteIsLoading = false
  saveChannelIsLoading = false

  //@ts-ignore
  menu: Menu = ref(null)
  //@ts-ignore
  contextmenu: ContextMenu = ref<ContextMenu | null>(null)
  menuItems: any[] = []

  openMobileMenuDefault: boolean = false

  get channelTypes(): {option: string, name: string}[] {
    const types = []
    if (this.canCreatePublicChannel) {
      types.push({option: "Open", name: this.i18n.$gettext("Public Channel")})
    }
    if (this.canCreatePrivateChannel) {
      types.push({option: "Private", name: this.i18n.$gettext("Private Channel")})
    }
    return types
  }

  get channelId(): string{
    return this.$route.params["channel"] as string
  }

  get channelDisplayName(): string | null {
    if (this.channelId) {
      const channel: Channel | null = this.channelApi.getChannel(this.channelId, false).data
      return channel?.displayName || ChatUtil.getChannelNameForDirectConversation(channel) || ''
    } else {
      return null
    }
  }

  get users(): User[] {
    return this.userApi.getUsers().data || []
  }

  get canCreatePublicChannel(): boolean {
    return Boolean(this.currentProject?.permissions?.includes('create_public_channel'))
  }

  get canCreatePrivateChannel(): boolean {
    return Boolean(this.currentProject?.permissions?.includes('create_private_channel'))
  }

  getChannelNameForDirectConversation(channel: Channel): string {
    return ChatUtil.getChannelNameForDirectConversation(channel) || ''
  }

  channelIsActive(channel: Channel): boolean {
    return channel.id == this.channelId
  }

  navigateToChannel(channel: Channel) {
    if (channel?.id) this.navigateToChannelId(channel.id)
  }

  navigateToChannelId(channelId: string) {
    toChatChannel((channelId))
    void SettingsUtil.setLastViewedCollection('chatChannel', channelId)
  }

  get projects() {
    return projectServiceApi.getProjects().data || []
  }

  get currentProject(): Project | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0]
    } else {
      return null
    }
  }

  get currentProjectId(): string | null {
    if (this.currentProject) {
      return this.currentProject.id
    } else {
      return null
    }
  }

  getChannelById(id: string): Channel | null {
    if (!id) return null
    const channels: Channel[] = this.channels
    if (!channels) return null
    return channels.find((c: Channel) => c.id === id) || null
  }

  directChannelsSWR!: SWR<Channel[], string[]>
  projectChannelsSWR!: SWR<Channel[], string[]> | null
  get channelsAreLoading(): boolean {
    return Boolean(this.directChannelsSWR?.call?.loading || this.projectChannelsSWR?.call?.loading)
  }

  get channels(): Channel[] {
    const channels = []
    this.directChannelsSWR = this.channelApi.getChannelsForTeam(this.currentProjectId || null, false)
    this.projectChannelsSWR = this.currentProjectId ? this.channelApi.getChannelsForTeam(null, false) : null
    if (this.directChannelsSWR.data) {
      channels.push(...this.directChannelsSWR.data)
    }
    if (this.projectChannelsSWR?.data) {
      channels.push(...this.projectChannelsSWR.data.filter(c => c.isDirect))
    }
    return channels.filter(c => !c.deleted && !c.isHidden)
  }

  get notDirectChannels(): Channel[] {
    return this.channels.filter(c => !c.isDirect)
  }

  get directChannels(): Channel[] {
    return this.channels.filter((c: Channel) => {
      return c.isDirect && ((c.members || []).filter(m => !!m).length > 1 ||
        (c.members?.length === 1 && rpcClient.session.user?.userName && c.members[0] == rpcClient.session.user.userName))
    })
  }

  openNewChannelModal(): void {
    desktopNotificationUtil.updateNotificationPermission() //Just to do it sometimes
    this.newChannelName = ""
    this.newChannelHeading = ""
    this.newChannelType = "Private"
    this.privateConvoSharees = []
    this.showNewChannelModal = true
  }

  openNewPrivateConvoModal(): void {
    desktopNotificationUtil.updateNotificationPermission() //Just to do it sometimes
    this.privateConvoSharees = []
    this.showPrivateConvoDialog = true
  }

  getUsersAndCreatePrivateChannel(): Promise<any> {
    this.createChannelIsLoading = true
    const userSWR: SWR<User[], string[]> = this.userApi.getUsers()
    if (userSWR.call?.promise) {
      return userSWR.call?.promise.finally(() => {
        return this.createPrivateChannel()
      })
    } else {
      return this.createPrivateChannel()
    }
  }

  createPrivateChannel(): Promise<string | void> {
    const newUsers: string[] = []
    for (let sharee of this.privateConvoSharees) {
      if (!sharee.userName) {
        const user: User | undefined = this.users.find(u => u.email === sharee.commonName || u.userName === sharee.commonName)
        if (user) sharee.userName = user.userName
      }
      newUsers.push(sharee.userName || sharee.commonName || '')
    }
    return this.channelApi._createChannelWith(newUsers).then((channelId: string) => {
      this.toast.success(this.i18n.$gettext("Conversation created"))
      this.showPrivateConvoDialog = false
      this.navigateToChannelId(channelId)
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Creating a conversation failed"))
    }).finally(() => {
      this.createChannelIsLoading = false
    })
  }

  getUsersAndCreateNewChannel(): Promise<any> {
    this.createChannelIsLoading = true
    const userSWR: SWR<User[], string[]> = this.userApi.getUsers()
    if (userSWR.call?.promise) {
      return userSWR.call?.promise.finally(() => {
        return this.createNewChannel()
      })
    } else {
      return this.createNewChannel()
    }
  }

  createNewChannel(): Promise<void> {
    const teamId = this.currentProjectId
    if (!teamId) {
      this.createChannelIsLoading = false
      return Promise.reject()
    }
    const newUsers: string[] = []
    for (let sharee of this.privateConvoSharees) {
      if (!sharee.userName) {
        const user: User | undefined = this.users.find(u => u.email === sharee.commonName || u.userName === sharee.commonName)
        if (user) sharee.userName = user.userName
      }
      newUsers.push(sharee.userName || sharee.commonName || '')
    }
    let newChannel: Channel = new Channel()
    newChannel.displayName = this.newChannelName
    newChannel.header = this.newChannelHeading
    newChannel.type = this.newChannelType
    newChannel.teamId = teamId
    newChannel.members = newUsers
    newChannel.name = this.newChannelName.toLowerCase().replace(/s/g, "-").replace(/((?!([a-z0-9])).)/gi, "")
    return this.channelApi._createChannel(newChannel).then((channelId: string) => {
      this.toast.success(this.i18n.$gettext("Conversation created"))
      this.showNewChannelModal = false
      this.navigateToChannelId(channelId)
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Creating a conversation failed"))
    }).finally(() => {
      this.createChannelIsLoading = false
    })
  }

  showChannelContextMenu(channel: Channel, event: Event) {
    this.updateChannelMenuItems(channel)
    this.menu.hide()
    void this.$nextTick(() => {
      this.contextmenu.toggle(event)
    })
  }

  showChannelMenu(channel: Channel, event: Event) {
    this.updateChannelMenuItems(channel)
    this.contextmenu.hide()
    void this.$nextTick(() => {
      this.menu.toggle(event)
    })
  }

  updateChannelMenuItems(channel: Channel) {
    this.menuItems = []

    if (ChatUtil.canEditChannelProperties(channel)) {
      this.menuItems.push({
        label: this.i18n.$gettext('Edit Channel'),
        icon:'cil-pencil',
        command: () => {
          this.startChannelDisplayEdit(channel)
        }
      })
    }

    if (ChatUtil.canEditMembers(channel)) {
      this.menuItems.push({
        label: this.i18n.$gettext('Edit Members'),
        icon:'cil-group',
        command: () => {
          this.startChannelMemberEdit(channel)
        }
      })
    }

    this.menuItems.push({
      label: this.i18n.$gettext('Delete'),
      icon:'cil-trash',
      command: () => {
        if (channel.id) {
          this.confirm.require({
            message: this.i18n.$gettext("Do you want to delete this channel?"),
            header: this.i18n.$gettext("Are you sure?"),
            icon: 'cil-warning',
            accept: () => {
              let promise: Promise<any>
              const channelId = channel.id
              if (!channelId) {
                return
              } else if (channelId == this.channelId) {
                promise = toChatsPromise()
              } else {
                promise = Promise.resolve()
              }
              this.deleteIsLoading  = true
              if (['Direct', 'Group'].includes(channel.type || '')) {
                promise = promise.then(() => {
                  return SettingsUtil.setLastViewedCollection('chatChannel', '').then(() => {
                    return channelServiceApi._setChannelVisibility(channel, false)
                  })
                })
              } else {
                promise = promise.then(() => {
                  return channelServiceApi._deleteChannel(channelId)
                })
              }
              promise.then(() => {
                this.toast.success(this.i18n.$gettext("Channel deleted"))
              }).catch((e: RpcError) => {
                this.toast.error(e.message, this.i18n.$gettext("Channel could not be deleted"))
              }).finally(() => {
                this.deleteIsLoading = false
              })
              //callback to execute when user confirms the action
            },
            reject: () => {
              //callback to execute when user rejects the action
            }
          })
        }
      }
    })
  }

  closeModal(): void {
    this.newChannelHeading = ""
    this.newChannelName = ""
    this.newUsers = []
  }

  startChannelMemberEdit(channel: Channel): void {
    this.channelToEdit = channel
    this.newUsers = [...(channel?.members?.filter(m => !!m) || [])]
    this.showMemberModal = true
  }

  startChannelDisplayEdit(channel: Channel): void {
    this.channelToEdit = channel
    this.newChannelHeading = channel?.header || ""
    this.newChannelName = channel?.displayName || ""
    this.showEditModal = true
  }

  saveChannel(): Promise<void>{
    if (!this.channelToEdit) return Promise.reject()
    const newChannelObject : Channel = {...this.channelToEdit}
    newChannelObject.displayName = this.newChannelName
    newChannelObject.header = this.newChannelHeading
    return this.channelApi._updateChannel(newChannelObject).then(() => {
      this.showEditModal = false
      this.toast.info(this.i18n.$gettext("Conversation updated"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Conversation could not be updated"))
    })
  }

  saveChannelMembers(): Promise<void> {
    if (this.channelToEdit?.id) {
      return this.channelApi._updateChannelMemberList(this.channelToEdit.id, this.newUsers).then(() => {
        this.showMemberModal = false
        this.toast.success(this.i18n.$gettext("Users updated"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not update userslist"))
      })
    } else {
      return Promise.reject()
    }
  }

  filterUsers(event: any) {
    let users = this.users
    this.selectableUsers = users.filter((user: User) => {
      return user.userName &&
        (!this.currentProjectId || !!projectServiceApi.getProject(this.currentProjectId)?.members?.find(m => m.userId === user.userName)) &&
        user.userName.indexOf(event.query?.toLowerCase()) !== -1 && //if user exists and matches
        user.userName != this.rpcClient.session.user?.userName && this.newUsers.indexOf(user.userName) === -1 //if user is not this user and nor already selected
    }).map((user: User) => {
      return user.userName || ""
    }).filter((str: string) => {return str !== "" })
  }

  addUser() {
    this.newUsers.push(this.userToAdd)
  }

  removeMember(memberName: string) {
    let pos: number = this.newUsers.indexOf(memberName)
    if (pos === -1) return
    this.newUsers.splice(pos, 1)
  }

  get hasChatBooked(): boolean {
    return featureSubset.hasChat
  }

  mounted() {
    desktopNotificationUtil.updateNotificationPermission()
    if (!this.channelId) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('chatChannel')
      if (!this.channelsAreLoading && !this.getChannelById(lastViewed || '')) {
        //This chatChannel no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('chatChannel', '')
      }
      if (lastViewed) {
        this.navigateToChannelId(lastViewed)
      } else {
        this.openMobileMenuDefault = true
      }
    }
  }
}

</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.list-group-item.channel-item {
  border: none !important;
}

.chat-channel-panel {

  width: 18rem;

  .channel-menu-item {

  }

  .channel-menu-item:hover {
    background-color: $uniki_secondary;
    cursor: pointer;

    & > a {
      text-decoration: underline;
    }

  }

  .channel-menu-item.active {
    background-color: $uniki_primary;
    color: white;
  }

}

</style>
