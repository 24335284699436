import DataImportJob$Progress from "@/model/DataImportJob$Progress"
import Formats from "@/util/formats"
import dayjs from "@/util/dayjs"
import {Language} from "@jshmrtn/vue3-gettext"
import DataImportJob from "@/model/DataImportJob"

export default class DataImportUtil {
    static getStatsLineForJob(progress: DataImportJob$Progress, i18n: Language) : string {
        let result: string = ""
        if (progress?.transferStats) {
            if (progress.transferStats.elements) try {
                result += i18n.$gettext('Total elements:') + ' ' + Math.round(Number(progress.transferStats.elements)) + '<br>'
            } catch(ignore) {} else if (progress.transferStats.totalTransfers) try {
                const total: number = (progress.transferStats.checks || 0) + (progress.transferStats.totalTransfers || 0)
                result += i18n.$gettext('Total elements:') + ' ' + Math.round(total) + '<br>'
            } catch(ignore) {}
            if (progress.transferStats.checks) try {
                result += i18n.$gettext('Elements checked:') + ' ' + Math.round(Number(progress.transferStats.checks)) + '<br>'
            } catch(ignore) {}
            if (progress.transferStats.transfers) try {
                result += i18n.$gettext('Transferred elements:') + ' ' + Math.round(Number(progress.transferStats.transfers)) + '<br>'
            } catch(ignore) {}
            if (progress.transferStats.errors) try {
                result += i18n.$gettext('Failed elements:') + ' ' + Math.round(Number(progress.transferStats.errors)) + '<br>'
            } catch(ignore) {}
            if (progress.transferStats.speed) try {
                result += i18n.$gettext('Speed:') + ' ' + Formats.formatBytes(Number(progress.transferStats.speed)) + '/s' + '<br>'
            } catch(ignore) {} else if ((progress.transferStats.transfers || progress.transferStats.checks) && progress.transferStats.elapsedTime) try {
                const elements: number = Number(progress.transferStats.transfers || 0) + Number(progress.transferStats.checks || 0)
                result += i18n.$gettext('Speed:') + ' ' +
                    Math.round(6000 * elements / Number(progress.transferStats.elapsedTime)) / 100 +
                    ' Elements/min' +
                    '<br>'
            } catch(ignore) {} else if ((progress.transferStats.transfers || progress.transferStats.checks) && progress.status?.startTime) try {
                const elements: number = Number(progress.transferStats.transfers || 0) + Number(progress.transferStats.checks || 0)
                result += i18n.$gettext('Speed:') + ' ' +
                    Math.round(elements / (new Date().getTime() - dayjs(progress.status.startTime).toDate().getTime()) * 6000000) / 100 +
                    ' Elements/min' +
                    '<br>'
            } catch(ignore) {}
            if (progress.transferStats.eta) try {
                result += i18n.$gettext('Estimated completion:') + ' ' + dayjs().add(Number(progress.transferStats.eta), 'seconds').format('MMM D, HH:mm') + '<br>'
            } catch(ignore) {}
        }
        return result
    }

    static getJobStatusTranslation(job: DataImportJob, i18n: Language): string {
        switch (job.progress?.state) {
            case "READY":
                return i18n.$gettext("Ready")
            case "RUNNING":
                return i18n.$gettext("Currently running")
            case "FINISHED":
            case "ERRORS":
                return i18n.$gettext("Done")
            case "FAILED":
                return i18n.$gettext("Failed")
            default:
                return i18n.$gettext("Status unknown")
        }
    }

    static getJobStatusStyles(job: DataImportJob): string {
        switch (job.progress?.state) {
            case "READY":
                return "badge-primary"
            case "RUNNING":
                return "badge-info"
            case "FINISHED":
                return "badge-success"
            case "ERRORS":
                return "badge-warning"
            case "FAILED":
                return "badge-danger"
            default:
                return "badge-primary"
        }
    }
}
