<template>
  <div :class="{'dtfield-inline': inline}">
    <div v-if="date.text">
      <div v-if="!readOnly">
        <AnimatedInput v-model="date.text" type="text" :disabled="readOnly" :label="name" />
        <Button
          v-if="!readOnly"
          icon="cil-trash-alt"
          class="p-button-danger after-inline-label ml-2"
          @click="emitDelete"
        />
      </div>
      <div v-else class="d-flex align-items-center">
        <i class="cil-calendar-event" />&nbsp;&nbsp;<strong>{{ name }}:</strong>&emsp;{{ date.text }}
      </div>
    </div>
    <div v-else>
      <div v-if="!readOnly">
        <div class="p-float-label inline mt-4">
          <Calendar
            v-model="calendarDate"
            date-format="dd.mm.yy"
            :month-navigator="true"
            :year-navigator="true"
            year-range="1900:2030"
            :disabled="readOnly"
          />
          <label>{{ name }}</label>
        </div>
        <Button
          v-if="!readOnly"
          icon="cil-trash-alt"
          class="p-button-danger after-inline-label ml-2"
          @click="emitDelete"
        />
      </div>
      <div v-else class="d-flex align-items-center">
        <i class="cil-calendar-event" />&nbsp;&nbsp;<strong>{{ name }}:</strong>&emsp;{{ formattedCalendarDate }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import DateOrTime from "@/model/common/carddav/DateOrTime"
import Calendar from "primevue/calendar"
import Button from "primevue/button"
import dayjs from "dayjs"
import AnimatedInput from "@/components/common/AnimatedInput.vue"

/*
This component just supports calendar (which is default) and text properties. PartialDate Support is missing...
 */

@Options({
  components: {AnimatedInput, Calendar, Button},
  //@ts-ignore
  props: {
    date: [DateOrTime, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    name: String,
    inline: {
      type: Boolean,
      default: false
    },
  },
  emits: [ 'delete']
})
export default class DateOrTimeField extends Vue {

  date!: DateOrTime;

  get formattedCalendarDate(): string {
    return dayjs(this.calendarDate).format("dddd, D. MMMM, YYYY")
  }

  get calendarDate(): Date {
    return new Date(this.date.calendar)
  }

  set calendarDate(date: Date) {
    this.date.calendar = date.toISOString()
  }

  emitDelete(): void {
    this.$emit('delete')
  }
}
</script>

<style scoped lang="scss">

.dtfield-inline {
  display: inline-block !important;
}

</style>
