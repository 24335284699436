import Gender from '@/model/common/carddav/Gender';
import Member from '@/model/common/carddav/Member';
import Source from '@/model/common/carddav/Source';
import TextValue from '@/model/common/carddav/TextValue';
import StructuredName from '@/model/common/carddav/StructuredName';
import NickNames from '@/model/common/carddav/NickNames';
import File from '@/model/common/carddav/File';
import Place from '@/model/common/carddav/Place';
import DateOrTime from '@/model/common/carddav/DateOrTime';
import Address from '@/model/common/carddav/Address';
import EmailAddress from '@/model/common/EmailAddress';
import Telephone from '@/model/common/carddav/Telephone';
import Url from '@/model/common/carddav/Url';
import TimeZone from '@/model/common/carddav/TimeZone';
import GeoCoordinates from '@/model/common/carddav/GeoCoordinates';
import Organization from '@/model/common/carddav/Organization';
import Categories from '@/model/common/carddav/Categories';
import Agent from '@/model/common/carddav/Agent';
import IMAddress from '@/model/common/carddav/IMAddress';
import Related from '@/model/common/carddav/Related';
import Expertise from '@/model/common/carddav/Expertise';
import CustomProperty from '@/model/common/CustomProperty';

export default class Contact {
  originalId!: string | null;
  originalParentId!: string | null;
  uid!: string | null;
  syncTag!: string | null;
  vCardVersion!: string | null;
  kind!: string | null;
  gender!: Gender | null;
  member!: Member[] | null;
  classification!: string | null;
  sources!: Source[] | null;
  sourceDescription!: string | null;
  formattedName!: TextValue | null;
  structuredName!: StructuredName | null;
  nickNames!: NickNames | null;
  titles!: TextValue[] | null;
  roles!: TextValue[] | null;
  photos!: File[] | null;
  logos!: File[] | null;
  sounds!: File[] | null;
  birthPlace!: Place | null;
  deathPlace!: Place | null;
  deathDate!: DateOrTime | null;
  birthDay!: DateOrTime | null;
  anniversary!: DateOrTime | null;
  revision!: any | null;
  productId!: string | null;
  addresses!: Address[] | null;
  orphanedLabels!: TextValue[] | null;
  emailAddresses!: EmailAddress[] | null;
  phones!: Telephone[] | null;
  mailer!: string | null;
  urls!: Url[] | null;
  timeZones!: TimeZone[] | null;
  geos!: GeoCoordinates[] | null;
  organization!: Organization | null;
  categories!: Categories[] | null;
  agent!: Agent | null;
  notes!: TextValue[] | null;
  keys!: File[] | null;
  imAddresses!: IMAddress[] | null;
  relations!: Related[] | null;
  languages!: TextValue[] | null;
  calenderRequestUris!: TextValue[] | null;
  calendarUris!: TextValue[] | null;
  freeBusyUrls!: TextValue[] | null;
  expertise!: Expertise[] | null;
  hobbies!: Expertise[] | null;
  interests!: Expertise[] | null;
  orgDirectories!: TextValue[] | null;
  customProperties!: CustomProperty[] | null;
  queryId!: string | null;
  score!: number | null;
  fileTokens!: string[] | null;

  constructor() {
    this.originalId = null;
    this.originalParentId = null;
    this.uid = null;
    this.syncTag = null;
    this.vCardVersion = null;
    this.kind = null;
    this.gender = null;
    this.member = null;
    this.classification = null;
    this.sources = null;
    this.sourceDescription = null;
    this.formattedName = null;
    this.structuredName = null;
    this.nickNames = null;
    this.titles = null;
    this.roles = null;
    this.photos = null;
    this.logos = null;
    this.sounds = null;
    this.birthPlace = null;
    this.deathPlace = null;
    this.deathDate = null;
    this.birthDay = null;
    this.anniversary = null;
    this.revision = null;
    this.productId = null;
    this.addresses = null;
    this.orphanedLabels = null;
    this.emailAddresses = null;
    this.phones = null;
    this.mailer = null;
    this.urls = null;
    this.timeZones = null;
    this.geos = null;
    this.organization = null;
    this.categories = null;
    this.agent = null;
    this.notes = null;
    this.keys = null;
    this.imAddresses = null;
    this.relations = null;
    this.languages = null;
    this.calenderRequestUris = null;
    this.calendarUris = null;
    this.freeBusyUrls = null;
    this.expertise = null;
    this.hobbies = null;
    this.interests = null;
    this.orgDirectories = null;
    this.customProperties = null;
    this.queryId = null;
    this.score = null;
    this.fileTokens = null;
  }
}
