import Permission from '@/model/entry/Permission';

export default class MailFolder {
  originalId!: string | null;
  id!: string | null;
  name!: string | null;
  fullName!: string | null;
  numberOfMails!: number | null;
  numberOfUnread!: number | null;
  numberOfNew!: number | null;
  supportsUserFlags!: boolean | null;
  noSelect!: boolean | null;
  type!: string | null;
  backend!: string | null;
  writable!: boolean | null;
  subFolders!: MailFolder[] | null;
  permissions!: Permission[] | null;
  projects!: string[] | null;

  constructor() {
    this.originalId = null;
    this.id = null;
    this.name = null;
    this.fullName = null;
    this.numberOfMails = null;
    this.numberOfUnread = null;
    this.numberOfNew = null;
    this.supportsUserFlags = null;
    this.noSelect = null;
    this.type = null;
    this.backend = null;
    this.writable = null;
    this.subFolders = null;
    this.permissions = null;
    this.projects = null;
  }
}
