<template>
  <div class="mb-2 d-flex flex-column flex-grow-1">
    <slot name="header">
      <p class="m-0">
        <translate>Please select all users you want to share this resource with</translate>:
      </p>
    </slot>
    <div class="p-inputgroup">
      <span class="p-float-label">
        <AutoComplete
          v-model="newSharee"
          :suggestions="selectableUsers"
          @complete="filterUsers"
          @item-select="addSharee"
          @keyup.prevent.stop.enter="addShareeViaEnter"
        />
        <label><slot name="placeholder"><translate>Add User</translate></slot></label>
      </span>

      <Button
        v-if="!onlyAllowSuggestions"
        v-tooltip="i18n.$gettext('Add')"
        icon="cil-plus"
        class="p-button-success"
        @click="addSharee"
      />
    </div>
    <div class="w-100">
      <small><translate>Type to start searching</translate></small>
    </div>
    <div v-if="sharedTo.length > 0" class="flex-grow-1">
      <div v-for="sharee in sharedTo" :key="JSON.stringify(sharee)" class="d-flex flex-row align-items-center mt-2">
        <Avatar
            :key="(sharee.userName || sharee.commonName) + '-avatar'"
            :username="(sharee.userName || sharee.commonName)"
            :label="(sharee.userName || sharee.commonName)"
            :size="24"
            generate-initials
            class="mr-2"
        />
        <p class="flex-grow-1 mb-0 mr-2">
          {{ sharee.commonName }}
        </p>
        <Dropdown
          v-if="tAccessOptions.length > 0 && ((sharee.userName || sharee.commonName) !== myUsername || !ignoreSessionUser)"
          v-model="sharee.accessRight"
          small
          class="mb-0 mr-4"
          :options="tAccessOptions"
          option-label="name"
          option-value="id"
          style="flex-basis: 20%; min-width: 200px; flex-grow: 0"
          @change="updateSharee(sharee)"
        />
        <Button
          v-if="(sharee.userName || sharee.commonName) !== myUsername || !ignoreSessionUser"
          v-tooltip="i18n.$gettext('Remove')"
          class="p-button-secondary"
          icon="cil-trash"
          @click="removeSharee(sharee)"
        />
      </div>
    </div>
    <div v-else class="flex-grow-1">
      <p class="mb-0 mt-4 text-secondary">
        <translate>No Sharee chosen</translate>
      </p>
    </div>
    <slot name="footer" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import EmailUtil from "@/util/EmailUtil"
import {Watch} from "vue-property-decorator"
import {rpcClient} from "@/api/WebsocketClient"
import useToast from "@/util/toasts"
import Avatar from "@/components/common/Avatar.vue"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    accessOptions: {
      type: Array,
      default: null
    },
    onlyAllowSuggestions: {
      type: Boolean,
      default: false
    },
    ignoreSessionUser: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Avatar, Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class UserSharingInput extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  modelValue!: any[]
  accessOptions!: any[] | null
  onlyAllowSuggestions = false
  ignoreSessionUser = true

  sharedTo: any[] = []
  selectableUsers: string[] = []
  newSharee = ''
  myUsername = rpcClient.session.user?.userName

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      return user.userName &&
        (user.userName != rpcClient.session.user?.userName || !this.ignoreSessionUser) &&
        user.userName?.toLowerCase()?.indexOf(event.query?.toLowerCase()) !== -1 &&
        !this.sharedTo.find((a: any) => (!a.userName && a.sharee === ('mailto:' + user.email)) || a.userName === user.userName)
    }).map((user: User) => {
      return user.userName || ""
    }).filter((str: string) => {return str !== "" })
  }

  addSharee() {
    const email = this.newSharee
    if (email === "" || !!this.sharedTo.find(a => (!a.userName && a.sharee === ('mailto:' + email)) || (a.userName || a.commonName) === email)) {
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const sharee: any = {}
      const user: User | undefined = this.users.find(u => u.cn === email)
      sharee.sharee = 'mailto:' + email
      if (user) sharee.userName = user.userName
      sharee.commonName = (user && user.userName) ? user.userName : email
      if (this.tAccessOptions && this.tAccessOptions.length > 0) sharee.accessRight = this.tAccessOptions[0].id
      this.sharedTo.push(sharee)
      this.$emit('added', sharee)
      this.$emit('update:modelValue', this.sharedTo)
      this.newSharee = ""
    } else {
      this.toast.error(this.i18n.$gettext("Attendee must be an email address"))
    }
  }

  addShareeViaEnter() {
    if (!this.onlyAllowSuggestions) {
      this.addSharee()
    }
  }

  updateSharee(sharee: any) {
    this.$emit('updated', sharee)
  }

  removeSharee(sharee: any) {
    const removed: number = this.sharedTo.indexOf(sharee)
    if (removed >= 0) {
      const removedSharee = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedSharee)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged(sharedTo: any[]) {
    this.sharedTo = sharedTo
  }

  mounted() {
    this.onValueChanged(this.modelValue)
  }
}
</script>

<style scoped lang="scss">

</style>
