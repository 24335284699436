
import {Options, Vue} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import {ref} from "@vue/reactivity"
import InputText from "primevue/inputtext"

@Options({
  name: "AnimatedInput",
  //@ts-ignore
  props: {
    label: String,
    placeholder: String,
    modelValue: String,
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String,
    autofocus: Boolean,
    small: Boolean,
    noBackground: Boolean,
    required: Boolean
  },
  components: {
    InputText
  }
})
export default class AnimatedInput extends Vue {
  label!: string
  placeholder!: string
  modelValue!: string
  inline!: boolean
  autocomplete!: string
  error!: string
  type!: string
  disabled!: boolean
  name!: string
  autofocus!: boolean
  small!: boolean
  noBackground!: boolean
  required!: boolean

  focused = false
  internalValue: string = this.modelValue || ''

  //@ts-ignore
  myinput: HTMLInputElement = ref<HTMLInputElement | null>(null)

  get animationClass (): string {
    if (this.noBackground) {
      return 'bg-white' + this.sizeClass + this.inlineClass
    } else if (this.focused || (this.internalValue && this.internalValue !== '')) {
      return 'ai-filled' + this.errorClass + this.sizeClass + this.inlineClass
    } else {
      return '' + this.errorClass + this.sizeClass + this.inlineClass
    }
  }

  get inlineClass (): string {
    return (this.inline ? ' animated-input-inline' : '')
  }

  get sizeClass(): string {
    return (this.small ? ' animated-input-sm' : '')
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger'
      } else {
        return ' border-danger danger'
      }
    } else {
      return ''
    }
  }

  handleFocus () {
    this.focused = true
    this.$emit('focus')
  }

  handleBlur () {
    this.focused = false
    this.$emit('blur')
  }

  handleKeypress (event: KeyboardEvent) {
    if ('Enter' === event.code) {
      this.$emit('submit', this.internalValue || '')
    }
  }

  checkAutofill(e : AnimationEvent) {
    console.log(e)
    if (e.animationName === "onAutoFillStart") this.$emit("autofill", true)
    else if (e.animationName === "onAutoFillCancel") this.$emit("autofill", false)
  }

  @Watch('internalValue')
  onInternalValueChanged (val: string, /* oldVal: string */) {
    this.$emit('update:modelValue', this.internalValue || '')
  }

  @Watch('modelValue')
  onModelValueChanged (val: string, /* oldVal: string */) {
    this.internalValue = val || ''
  }

  //Do things when we're in the dom:
  mounted () {
    if (this.autofocus) {
      //TODO this.myinput.focus()
    }
  }
}
