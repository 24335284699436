import Calendar from '@/model/directory/Calendar';
import TaskBoardMetaInformation from '@/model/common/TaskBoardMetaInformation';

export default class TaskBoard extends Calendar {
  originalId!: string | null;
  id!: string | null;
  meta!: TaskBoardMetaInformation | null;
  projects!: string[] | null;

  constructor() {
    super();
    this.originalId = null;
    this.id = null;
    this.meta = null;
    this.projects = null;
  }
}
