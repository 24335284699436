
import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {rpcClient} from "@/api/WebsocketClient"
import useToast from "@/util/toasts"

@Options({
  components: {
    AnimatedInput, LoadingButton
  }
})
export default class LoginForm extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  username = "";
  password = "";
  loading = false;
  autofillOverwrite = false; //Overrides validation if chrome autofill does input something directly after page load. See: https://bugs.chromium.org/p/chromium/issues/detail?id=669724

  login () {
    this.loading = true
    rpcClient.login(this.username, this.password).catch(e => {
      this.toast.error(this.i18n.$gettext('Login failed.'))
    }).finally(() => {
      this.loading = false
    })
  }

  handleAutofill(args: boolean) {
    this.autofillOverwrite = args
  }
}
