

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import Button from "primevue/button"
import { ref } from "@vue/reactivity"
import VirtualMailboxesPasswordManagerDialog from "@/components/settings/virtualmailboxes/VirtualMailboxesPasswordManagerDialog.vue"
import RpcError from "@/api/RpcError"
import {useConfirm} from "primevue/useconfirm"
import Skeleton from "primevue/skeleton"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import VirtualMailboxesUserManagerDialog from "@/components/settings/virtualmailboxes/VirtualMailboxesUserManagerDialog.vue"
import VirtualMailbox from "@/model/settings/VirtualMailbox"
import {Router, useRouter} from "vue-router"
import {toVirtualMailboxMailFilter, toVirtualMailboxOutOfOffice, toVirtualMailboxSignatures} from "@/router"

@Options({
  //@ts-ignore
  props: {
    mailbox: VirtualMailbox
  },
  components: {
    VirtualMailboxesPasswordManagerDialog, VirtualMailboxesUserManagerDialog, Button, Skeleton
  }
})
export default class VirtualMailboxGridItem extends Vue {

  mailbox!: VirtualMailbox | null

  router: Router = useRouter()
  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()
  loading = false

  //@ts-ignore
  pwManagerDialog: Menu = ref<Menu | null>(null)

  showPasswordDialog: boolean = false
  showUserDialog: boolean = false

  showManagePasswordDialog() {
    this.showPasswordDialog = true
  }

  hideManagePasswordDialog() {
    this.showPasswordDialog = false
  }

  showManageUserDialog() {
    this.showUserDialog = true
  }

  goToMailFilters() {
    if (!this.mailbox?.address) return
    toVirtualMailboxMailFilter(this.mailbox.address)
  }

  goToMailOOO() {
    if (!this.mailbox?.address) return
    toVirtualMailboxOutOfOffice(this.mailbox.address)
  }

  goToMailSignatures() {
    if (!this.mailbox?.address) return
    toVirtualMailboxSignatures(this.mailbox.address)
  }

  hideManageUserDialog() {
    this.showUserDialog = false
  }

  confirmDelete(event: Event | undefined): void {
    this.confirm.require({
      //@ts-ignore
      target: event?.currentTarget || undefined,
      message: this.i18n.$gettext("Do you really want to delete this mailbox?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-exclamation',
      accept: () => {
        this.deleteMailbox()
      },
      reject: () => {}
    })
  }

  deleteMailbox() {
    if (!this.mailbox || !this.mailbox.address) {
      this.toast.error(this.i18n.$gettext("Could not delete mailbox."))
      return
    }
    this.loading = true
    void virtualMailboxServiceApi._deleteVirtualMailbox(this.mailbox.address).then(() => {
      this.toast.success(this.i18n.$gettext("Deleted mailbox."))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete mailbox."))
      this.loading = false
    })
  }
}
