export default class AddressBook {
  originalId!: string | null;
  id!: string | null;
  name!: string | null;
  description!: string | null;
  ownerId!: string | null;
  isDefault!: boolean | null;
  isShared!: boolean | null;
  shareAccess!: string | null;
  canBeShared!: boolean | null;
  privileges!: string[] | null;
  sharedTo!: any[] | null;
  backend!: string | null;
  syncTag!: string | null;
  contacts!: string[] | null;
  projects!: string[] | null;

  constructor() {
    this.originalId = null;
    this.id = null;
    this.name = null;
    this.description = null;
    this.ownerId = null;
    this.isDefault = null;
    this.isShared = null;
    this.shareAccess = null;
    this.canBeShared = null;
    this.privileges = null;
    this.sharedTo = null;
    this.backend = null;
    this.syncTag = null;
    this.contacts = null;
    this.projects = null;
  }
}
