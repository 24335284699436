import axios from "axios"
import {reactive} from "@vue/reactivity"
import {rpcClient} from "@/api/WebsocketClient"

export type DemoDescriptor = {
    isDemoInstance: boolean
    enablePipedriveLivechat: boolean
    demoNaggingEnabled: boolean
    demoNaggingLink: string
}

const INTERVAL: number = 60000
const MAX_INTERVAL_TIMES: number = 60
const LOCALSTORAGE_INTERVAL_KEY: string = "demoMinutesUsed"

class DemoService {

    demoData: DemoDescriptor = reactive({
        isDemoInstance: false,
        enablePipedriveLivechat: false,
        demoNaggingEnabled: false,
        demoNaggingLink: ""
    })

    callbacks: Function[] = []
    intervalCallback: Function = () => { console.warn("Demo usage interval reached, but no callback registered")}
    timer: number = 0
    usageMinutes: number = 0

    constructor(){
        const savedNum = window.localStorage.getItem(LOCALSTORAGE_INTERVAL_KEY)
        if(savedNum != null){
            this.usageMinutes = Number.parseInt(savedNum)
        }
    }

    addCallback(toAdd: Function): void {
        this.callbacks.push(toAdd)
    }

    setIntervalCallback(callback: Function): void {
        this.intervalCallback = callback
    }

    get isDemo(){
        return this.demoData.isDemoInstance
    }

    refreshDemoStatus() {
        void axios.get('/groupware-api/v1/demo').then(response => {
            Object.assign(this.demoData, response.data)
            if(this.demoData.isDemoInstance){
                if(this.timer === 0){
                    this.timer = window.setInterval(this.runTimer, INTERVAL, this)
                }
                window.setTimeout(() => {
                    this.callbacks.forEach((callback: Function) => {
                        callback.call(null, this.demoData)
                    })
                }, 0)  //Run  callbacks after completing current stack
            }
        }).catch(error => {
            //For now: Do nothing
        })
    }

    runTimer(thisInstance: DemoService){
        if (window.document.hidden || !rpcClient.fullyLoggedIn){  //If tab is not active or not logged In
            return
        }

        thisInstance.usageMinutes += 1

        if (thisInstance.demoData.isDemoInstance && thisInstance.demoData.demoNaggingEnabled && thisInstance.usageMinutes > MAX_INTERVAL_TIMES) {
            const link = thisInstance.demoData.demoNaggingLink
            thisInstance.intervalCallback.call(null, link)
            thisInstance.usageMinutes = 0
        }

        window.localStorage.setItem(LOCALSTORAGE_INTERVAL_KEY, thisInstance.usageMinutes.toString())
    }
}

const demoService = new DemoService()

export default demoService
