<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="h-100 container-fluid container-md p-4 p-md-6 ml-md-0 d-flex flex-column">
        <h2>
          <translate translate-context="title">
            Share Link Administration
          </translate>
        </h2>
        <!--ya<p>
          <b><translate>What you can do here:</translate></b>&nbsp;<translate>
            See all created Share-Links for Persons outside of your Organization and change the password or delete those.
          </translate>
        </p>
        <p><b><translate>What you can't do here:</translate></b>&nbsp;<translate>Create new share links or change the URL of a link.</translate></p>-->
        <Dropdown
            class="mb-4"
            v-model="linkType"
            :options="linkTypeOptions"
            option-label="label"
            option-value="value"
            :placeholder="i18n.$gettext('Select Link Type')">
        </Dropdown>
        <div class="d-flex align-items-center separator-bottom py-2 font-weight-bold text-secondary">
          <p class="m-0 flex-grow-1"><translate>Folder / File</translate></p>
          <p class="m-0"><translate>Views</translate></p>
          <p class="m-0 text-right" style="width: 180px"><translate>Creator</translate></p>
          <p class="m-0 text-right" style="width: 90px"><translate>Expiry</translate></p>
          <p class="m-0 text-right ml-4 pr-4" style="width: 188px"><translate>Action</translate></p>
        </div>
        <div class="flex-grow-1" style="min-height: 1px">
          <InfiniteList
              :get-all-items="getAllItems"
              :get-item-page="getItemPage"
              :page-size="pageSize"
              id-property="token"
              class="flex-shrink-1 h-100"
              show-scroll-to-start-button
              auto-scroll-at-start
          >
            <template #element="props">
              <div class="d-flex align-items-center separator-bottom py-2" style="min-width: 0">
                <div class="flex-grow-1 d-flex align-items-center" style="min-width: 0">
                  <a
                      class="d-flex align-items-center"
                      :href="props.item.link || ''"
                      target="_blank"
                      v-tooltip.top="props.item.repoName + props.item.path + (props.item.name || '')"
                      style="min-width: 0"
                  >
                  <span
                      v-if="props.item.repoName && props.item.path"
                      class="flex-shrink-1"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                    {{ props.item.repoName + props.item.path }}
                  </span>
                    <span v-if="props.item.name" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                    {{ props.item.name }}
                  </span>
                  </a>
                </div>
                <span class="flex-shrink-0 text-right pl-3">
                  {{ props.item.viewCnt || '0' }}
                </span>
                <span class="flex-shrink-0 text-right pl-3"
                      style="width: 180px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                      v-tooltip.top="props.item.creatorName || props.item.userName"
                >
                  {{ props.item.creatorName || props.item.userName }}
                </span>
                <span class="flex-shrink-0 text-right pl-3" :class="{ 'text-danger': props.item.isExpired }" style="width: 90px">
                  {{ getDateString(props.item.expireDate || '') }}
                </span>
                <div class="flex-shrink-0 d-flex align-items-end ml-4">
                  <Button
                      class="p-button-raised p-button-primary mr-2"
                      icon="cil-copy"
                      v-tooltip="i18n.$gettext('Copy Link to Clipboard')"
                      @click="copyShareLink(props.item.link)"
                      :disabled="!props.item.link"
                  />
                  <Button
                      class="p-button-raised p-button-primary mr-2"
                      icon="cil-key"
                      v-tooltip="i18n.$gettext('Change Password')"
                      @click="openShareLinkPasswordModal(props.item)"
                  />
                  <Button
                      class="p-button-raised p-button-primary mr-2"
                      icon="cil-clock"
                      v-tooltip="i18n.$gettext('Change Expiry')"
                      @click="openShareLinkExpiryModal(props.item)"
                  />
                  <Button
                      class="p-button-raised p-button-danger mr-2"
                      icon="cil-trash"
                      v-tooltip="i18n.$gettext('Delete')"
                      @click="deleteShareLink(props.item)"
                  />
                </div>
              </div>
            </template>
            <template #skeleton>
              <div class="w-100 d-flex flex-row p-2">
                <Skeleton
                    height="36px"
                    width="36px"
                    class="mr-2 Avatar"
                />
                <div class="flex-grow-1">
                  <Skeleton
                      height="1rem"
                      width="100%"
                      class="mb-1"
                      style="max-width: 200px"
                  />
                  <Skeleton height="1rem" width="100%" class="mb-1" />
                  <Skeleton height="1rem" width="100%" style="max-width: 80%" />
                </div>
              </div>
            </template>
            <template #empty>
              <div class="d-flex flex-column justify-content-center" style="min-height: 30rem">
                <div class="text-center">
                  <p class="h5 mb-2">
                    <translate>No share links created</translate>
                  </p>
                </div>
              </div>
            </template>
          </InfiniteList>
          <Dialog :header="i18n.$gettext('Change Link Password')" v-model:visible="showShareLinkPasswordModal" modal style="max-width: 100%">
            <form class="p-3" @submit.prevent.stop="changeShareLinkPassword">
              <p class="m-0"><translate>Please enter the new password for the Share Link:</translate></p>
              <a style="word-break: break-all" v-if="shareLinkToEdit.link" :href="shareLinkToEdit.link">{{ shareLinkToEdit.link }}</a>
              <p class="mb-0 mt-2"><translate>Link target:</translate></p>
              <p class="text-secondary font-weight-bold mb-4" style="word-break: break-all">{{ shareLinkToEdit.repoName + shareLinkToEdit.path + (shareLinkToEdit.name || '') }}</p>
              <AnimatedInput v-model="newPassword" type="password" :label="i18n.$gettext('Password')" class="w-100 mb-4" />
              <div class="d-flex flex-row-reverse pt-4">
                <Button class="p-button p-button-raised" :label="i18n.$gettext('Save')" type="submit" />
                <Button class="p-button p-button-text" :label="i18n.$pgettext('Abbrechen', 'Cancel')" @click="shareLinkToEdit = null" />
              </div>
            </form>
          </Dialog>
          <Dialog :header="i18n.$gettext('Change Link Expiry')" v-model:visible="showShareLinkExpiryModal" modal style="max-width: 100%">
            <form class="p-3" @submit.prevent.stop="changeShareLinkExpiry">
              <p class="m-0"><translate>Please enter the new expiry date for the Share Link:</translate></p>
              <a style="word-break: break-all" v-if="shareLinkToEdit.link" :href="shareLinkToEdit.link">{{ shareLinkToEdit.link }}</a>
              <p class="mb-0 mt-2"><translate>Link target:</translate></p>
              <p class="text-secondary font-weight-bold mb-4" style="word-break: break-all">{{ shareLinkToEdit.repoName + shareLinkToEdit.path + (shareLinkToEdit.name || '') }}</p>
              <DatePicker
                  v-model="newExpiry"
                  inline
                  :label="i18n.$gettext('Expiry')"
                  :show-time="true"
                  month-navigator
                  year-navigator
                  year-range="2000:2030"
                  show-button-bar
              />
              <div class="d-flex flex-row-reverse pt-4">
                <Button class="p-button p-button-raised" :label="i18n.$gettext('Save')" type="submit" />
                <Button class="p-button p-button-text" :label="i18n.$pgettext('Abbrechen', 'Cancel')" @click="shareLinkToEdit = null" />
              </div>
            </form>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import Dialog from "primevue/dialog"
import Skeleton from "primevue/skeleton"
import useToast from "@/util/toasts"
import ShareLink from "@/model/common/ShareLink"
import {shareLinkServiceApi} from "@/api/ShareLinkServiceApi"
import InfiniteList from "@/components/common/InfiniteList.vue"
import ChatMessage from "@/components/chat/subcomponents/ChatMessage.vue"
import SWR from "@/api/SWR"
import Page from "@/model/Page"
import {shareLinkStore} from "@/store/ShareLinkStore"
import {useConfirm} from "primevue/useconfirm"
import RpcError from "@/api/RpcError"
import dayjs from "dayjs"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import DatePicker from "@/components/common/DatePicker.vue"
import UploadLink from "@/model/common/UploadLink"
import {uploadLinkServiceApi} from "@/api/UploadLinkServiceApi"
import {uploadLinkStore} from "@/store/UploadLinkStore"
import Dropdown from "@/components/common/Dropdown.vue"

@Options({
  components: {
    AnimatedInput, ChatMessage, InfiniteList, Button, Dialog, Skeleton, DatePicker, Dropdown
  },
  //@ts-ignore
  props: {},
  emits: [],
  name: "ShareLinkAdministration"
})
export default class GlobalSignatureSettings extends Vue {

  toast = useToast()
  i18n: Language = useGettext()
  confirm = useConfirm()

  pageSize: number = 100

  shareLinkToEdit: ShareLink | UploadLink | null = null
  newPassword: string | null = null
  newExpiry: Date | null = null
  showShareLinkPasswordModal: boolean = false
  showShareLinkExpiryModal: boolean = false

  settingsLoading:  boolean = false
  linkType: string = 'DOWNLOAD'
  linkTypeOptions = [
    {
      value: 'DOWNLOAD',
      label: this.i18n.$gettext('Download-Links')
    }, {
      value: 'UPLOAD',
      label: this.i18n.$gettext('Upload-Links')
    }
  ]

  mounted() {
    this.settingsLoading = true
  }

  getAllItems(): (ShareLink | UploadLink)[] {
    if (this.linkType === 'DOWNLOAD') {
      return [...shareLinkStore.state.shareLinks.values()]
    } else {
      return [...uploadLinkStore.state.uploadLinks.values()]
    }
  }

  getItemPage(pageIndex: number, pageSize: number): SWR<ShareLink[], Page<string>> | SWR<UploadLink[], Page<string>> {
    if (this.linkType === 'DOWNLOAD') {
      return shareLinkServiceApi.getShareLinks(null, pageIndex, pageSize)
    } else {
      return uploadLinkServiceApi.getUploadLinks(null, pageIndex, pageSize)
    }
  }

  openShareLinkPasswordModal(shareLink: ShareLink | UploadLink){
    this.shareLinkToEdit = shareLink
    this.newPassword = ""
    this.showShareLinkPasswordModal = true
  }

  openShareLinkExpiryModal(shareLink: ShareLink | UploadLink){
    this.shareLinkToEdit = shareLink
    this.newExpiry = shareLink.expireDate ? dayjs(shareLink.expireDate).toDate() : null
    this.showShareLinkExpiryModal = true
  }

  getDateString(expireDate: string): string {
    if (expireDate) try {
      return dayjs(expireDate).format("DD.MM.YYYY")
    } catch (e) {}
    return ''
  }

  copyShareLink(link: string | null | undefined) {
    if (link) {
      const el = document.createElement('textarea')
      el.value = link
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    }
  }

  changeShareLinkPassword(): Promise<void> {
    if (!this.shareLinkToEdit?.token) {
      return Promise.reject<void>()
    }
    if (this.linkType === 'DOWNLOAD') {
      return shareLinkServiceApi._setShareLinkPassword(this.shareLinkToEdit.token, this.newPassword || '').then(() => {
        this.shareLinkToEdit = null
        this.newPassword = null
        this.showShareLinkPasswordModal = false
        this.toast.success(this.i18n.$gettext('Link password changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link password.', e.message))
      })
    } else {
      return uploadLinkServiceApi._setUploadLinkPassword(this.shareLinkToEdit.token, this.newPassword || '').then(() => {
        this.shareLinkToEdit = null
        this.newPassword = null
        this.showShareLinkPasswordModal = false
        this.toast.success(this.i18n.$gettext('Link password changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link password.', e.message))
      })
    }
  }

  changeShareLinkExpiry(): Promise<void> {
    if (!this.shareLinkToEdit?.token) {
      return Promise.reject<void>()
    }
    if (this.linkType === 'DOWNLOAD') {
      return shareLinkServiceApi._setShareLinkExpiry(this.shareLinkToEdit.token, this.newExpiry?.toISOString() || '').then(() => {
        this.shareLinkToEdit = null
        this.newExpiry = null
        this.showShareLinkExpiryModal = false
        this.toast.success(this.i18n.$gettext('Link expiry changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link expiry.', e.message))
      })
    } else {
      return uploadLinkServiceApi._setUploadLinkExpiry(this.shareLinkToEdit.token, this.newExpiry?.toISOString() || '').then(() => {
        this.shareLinkToEdit = null
        this.newExpiry = null
        this.showShareLinkExpiryModal = false
        this.toast.success(this.i18n.$gettext('Link expiry changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link expiry.', e.message))
      })
    }
  }

  deleteShareLink(shareLink: ShareLink): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.confirm.require({
        message: this.i18n.$gettext('Do you really want to delete this share link?'),
        header: this.i18n.$gettext('Confirmation'),
        icon: 'cil-warning',
        accept: () => {
          if (shareLink.token) {
            if (this.linkType === 'DOWNLOAD') {
              resolve(shareLinkServiceApi._deleteShareLink(shareLink.token).then(() => {
                this.toast.success(this.i18n.$gettext('Link deleted.'))
              }).catch((e: RpcError) => {
                this.toast.error(this.i18n.$gettext('Failed to delete link.', e.message))
              }))
            } else {
              resolve(uploadLinkServiceApi._deleteUploadLink(shareLink.token).then(() => {
                this.toast.success(this.i18n.$gettext('Link deleted.'))
              }).catch((e: RpcError) => {
                this.toast.error(this.i18n.$gettext('Failed to delete link.', e.message))
              }))
            }
          } else {
            reject()
          }
        },
        reject: () => {
          reject()
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";



</style>
