import EmailAddress from '@/model/common/EmailAddress';

export default class Attendee extends EmailAddress {
  name!: string | null;
  email!: string | null;
  uri!: string | null;
  participationLevel!: string | null;
  status!: string | null;
  rsvp!: boolean | null;

  constructor() {
    super();
    this.name = null;
    this.email = null;
    this.uri = null;
    this.participationLevel = null;
    this.status = null;
    this.rsvp = null;
  }
}
