
import {Options, Vue} from "vue-class-component"
import Attachment from "@/model/common/caldav/Attachment"
import CalDavFile from "@/model/common/caldav/File"
import Button from "primevue/button"
import Image from "primevue/image"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService";

@Options({
  //@ts-ignore
  props: {
    attachment: Object,
    editMode: Boolean
  },
  components: {Image, Button},
  emits: ['delete']
})
export default class AttachmentItem extends Vue {

  attachment!: Attachment
  editMode: boolean = false

  get isImage(): boolean {
    if(!this.attachment.file || !this.attachment.file.uri || !this.attachment.file.formatType) return false
    return this.attachment.file.formatType?.startsWith('image/')
  }

  get image(): CachedImage | null {
    if(!this.attachment?.file) return null
    return imageLoadingService.getCachedImage(this.attachment.file?.uri || '')
  }

  downloadAttachment (){
    const file = this.attachment.file
    if(!file) return
    if (file.uri) {
      window.open(file.uri, '_blank')
    } else if (file.fileName) {
      window.open('/groupware-api/v1/uploads/' + encodeURIComponent(file.fileName), '_blank')
    }
  }

  get shortName(): string {
    if(!this.attachment.file?.fileName) return ""
    return this.attachment.file?.fileName?.length > 15 ? this.attachment.file?.fileName.substring(0,15) + "..." : ""
  }

  sendDelete(){
    this.$emit("delete", this.attachment)
  }
}
