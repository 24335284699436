
import {Options, Vue} from 'vue-class-component'
import EmailList from './EmailList.vue'
import DetailView from './DetailView.vue'
import {rpcClient} from "@/api/WebsocketClient"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import MailFolder from "@/model/directory/MailFolder"
import SWR from "@/api/SWR"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import Email from "@/model/entry/Email"
import Tree from "@/components/common/Tree.vue"
import ContextMenu from "primevue/contextmenu"
import Dialog from "primevue/dialog"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Avatar from "@/components/common/Avatar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import SideBar from "@/components/SideBar.vue"
import Button from "primevue/button"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import EmailUtil from "@/util/EmailUtil"
import {mailServiceApi} from "@/api/MailServiceApi"
import featureSubset from "@/util/FeatureSubsets"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import breakpointUtil from "@/util/BreakpointUtil"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import DetailViewDialog from "@/components/email/DetailViewDialog.vue"
import {useConfirm} from "primevue/useconfirm"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Badge from 'primevue/badge'
import InputText from "primevue/inputtext"
import ResourceShareDialog from "@/components/common/resourceShare/ResourceShareDialog.vue"

@Options({
  components: {
    DetailViewDialog,
    UserSharingInput,
    //@ts-ignore
    InnerLayoutWithSidebar, EmailList, DetailView, Tree, MenuBar, InputText, LoadingButton, EmailComposer,
    ContextMenu, Dialog, Avatar, SideBar, Button, Skeleton, SearchBar, Badge, ResourceShareDialog
  }
})
export default class EmailInterface extends Vue {

  i18n: Language = useGettext()
  rpcClient = rpcClient
  toast = useToast()
  confirm = useConfirm()
  searchQuery: Query | null = null

  projects: any[] = []
  menuItems: any[] = []

  groupShares: any[] = []

  permissionsActiveTab: number = 0

  //@ts-ignore
  menu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  folderOrder: string[] = [ 'inbox', 'sent messages', 'drafts', 'junk', 'trash' ]
  resourceToShare: string = ''

  showNewFolderModal = false
  newFolderName = ''
  newFolderLoading = false
  newFolderLocation: string | null = null
  renameFolderName: string = ""
  showRenameFolderModal: boolean = false
  folderToRename: string = ""

  openMobileMenuDefault: boolean = false

  get foldersAreLoading() {
    return Boolean(this.folderSWR?.call?.loading)
  }

  folderSWR!: SWR<MailFolder[], string[]>
  get folders(): any[] {
    this.folderSWR = mailFolderServiceApi.getFolders()
    const folders = this.folderSWR.data ? [...this.folderSWR.data] : []
    const treeNodes: any[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('mailFolder')
    if (order) {
      for (let id of order) {
        const folderIndex: number = folders.findIndex(b => b.originalId === id || b.id === id)
        if (folderIndex >= 0) {
          const node: any | undefined = EmailUtil.treeNodeFromFolder(folders[folderIndex], order, this.i18n)
          if (node) {
            treeNodes.push(node)
            folders.splice(folderIndex, 1)
          }
        }
      }
    }
    for (const folderName of this.folderOrder) {
      const folderIndex: number = folders.findIndex(f => f.name?.toLowerCase() === folderName)
      if (folderIndex >= 0) {
        const node: any | undefined = EmailUtil.treeNodeFromFolder(folders[folderIndex], order, this.i18n)
        if (node) {
          treeNodes.push(node)
          folders.splice(folderIndex, 1)
        }
      }
    }
    folders.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name))
    for (const folder of folders) {
      const node: any | undefined = EmailUtil.treeNodeFromFolder(folder, order, this.i18n)
      if (node) {
        treeNodes.push(node)
      }
    }
    const newOrder: string[] = []
    this.getOrderRecursive(newOrder, treeNodes)
    void SettingsUtil.setLastViewedCollectionOrder('mailFolder', newOrder)
    return treeNodes
  }

  getOrderRecursive(order: string[], treeNodes: any[] | null | undefined) {
    for (const node of (treeNodes || [])) {
      if (node.key) {
        order.push(node.key)
      }
      if (node.children) {
        this.getOrderRecursive(order, node.children)
      }
    }
  }

  isActive(node: any): boolean {
    const routedId = this.folderId
    if (routedId) {
      return node.key === routedId
    } else {
      return false
    }
  }

  get folderId(): string | null {
    if (this.$route?.params?.hasOwnProperty("folder")) {
      return this.$route.params["folder"] as string
    } else {
      return null
    }
  }

  get folderName(): string | null {
    if (this.folderId) {
      const folder: MailFolder | undefined = mailFolderServiceApi.getMailFolder(this.folderId)
      if (folder) {
        const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
        return mapping?.name || folder.name
      }
    }
    return null
  }

  get emailId(): string | null {
    if (this.$route?.params?.hasOwnProperty("email")) {
      return this.$route.params["email"] as string
    } else {
      return null
    }
  }

  closeEmail(): void {
    this.goToFolderId(this.folderId || "")
  }

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  get width() {
    return window.innerWidth
  }

  goToFolder(folder: any) {
    if (folder.key && !folder.noSelect) {
      this.goToFolderId(folder.key)
    }
  }

  goToFolderId(folderId: string) {
    void this.$router.push('/mail/' + encodeURIComponent(folderId))
    if (folderId !== "") {
      void SettingsUtil.setLastViewedCollection('mailFolder', folderId)
    }
  }

  newMessage(): void {
    this.composer.show(new Email(), null, this.folderId, null, null)
  }

  get isOnMobile() {
    return breakpointUtil.isOnXl()
  }

  showContextMenu(item: any, event: Event) {
    if (this.menu) {
      this.updateMenuItems(item)
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  menuItemFromFolder(source: MailFolder, target: MailFolder, copy: boolean): any {
    const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(target, this.i18n)
    const item: any = {
      label: mapping?.name || target.name,
      icon: mapping?.icon || 'cil-inbox',
      command: () => {
        if (source.originalId && target.originalId) {
          mailFolderServiceApi._move(source.originalId, target.originalId, copy).then(() => {
            this.toast.success(this.i18n.$gettext("Folder moved"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not move folder"))
          })
        }
      }
    }

    const otherFolders: MailFolder[] | undefined = target.subFolders ? target.subFolders.filter(other => other.originalId !== source.originalId) : undefined
    if (otherFolders && otherFolders.length > 0) {
      item['items'] = otherFolders.map(subFolder => this.menuItemFromFolder(source, subFolder, copy))
    }

    return item
  }

  renameFolder() {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to move this folder? Moving big folders may result in reindexing!'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.folderToRename !== "" && this.renameFolderName.trim() !== "") {
          mailFolderServiceApi._renameFolder(this.folderToRename, this.renameFolderName.trim()).then(() => {
            this.toast.success(this.i18n.$gettext("Folder renamed"))
            this.$nextTick(() => {
              mailFolderServiceApi.getFolders(true)
            })
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not rename Folder"))
          }).finally(() => {
            this.showRenameFolderModal = false
            this.renameFolderName = ""
          })
        } else {
          this.toast.error(this.i18n.$gettext("Could not determine new folder or new name."))
        }
      },
      reject: () => {
        this.folderToRename = ""
        //callback to execute when user rejects the action
      }
    })
  }

  updateMenuItems(treeItem: any) {
    const folders: MailFolder[] | null = mailFolderServiceApi.getFolders().data
    const folder: MailFolder | undefined = folders ? this.findFolder(treeItem.key, folders) : undefined

    if (!folder || folder?.noSelect) {
      this.menuItems = [{
        label: this.i18n.$gettext("This folder is shared. You cannot edit it."),
        disabled: true
      }]
      return
    }

    const myUsername: string = rpcClient.session.user?.userName || ""
    const canWrite = folder.permissions?.find(perm =>  perm.userName === myUsername )?.mode === "WRITE"
    const owner = folder.permissions?.find(perm =>  perm.userName === myUsername )?.mode === "OWNER"

    const items: any[] = []

    if (canWrite || owner) {
      items.push({
            label: this.i18n.$gettext('Mark all read'),
            icon: 'fa fa-eye',
            command: () => {
              if (folder.originalId) {
                void mailServiceApi._markAllRead(folder.originalId)
              }
            }
          },
          {
            label: this.i18n.$gettext('Create Subfolder'),
            icon: 'fa fa-folder',
            command: () => {
              this.newFolderLocation = folder.id
              this.showNewFolderModal = true
            }
          })
    }

    if (owner || this.isAdmin) {
      items.push({
        label: this.i18n.$gettext('Set Permissions'),
        icon: 'cil-security',
        command: () => {
          if (folder) {
            this.openFolderPermissionsModal(folder)
          }
        }
      })
    }

    if (!folder.type && owner) {
      items.push({
        label: this.i18n.$gettext('Rename Folder'),
        icon: 'cil-pencil',
        command: () => {
          if (folder) {
            this.showRenameFolderModal = true
            this.folderToRename = folder.originalId || ""
          }
        }
      })
    }

    if (!folder.type && owner) {
      items.push({
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteFolder(folder)
        }
      })
    }

    const otherFolders: MailFolder[] | undefined = folders ? folders.filter(other => other.originalId !== folder.originalId) : undefined
    if (otherFolders && otherFolders.length > 0 && owner) {
      items.push({
        label: this.i18n.$gettext('Move to...'),
        icon: 'cil-folder-move',
        items: otherFolders.map(target => this.menuItemFromFolder(folder, target, false))
      })
      /*items.push({
        label: this.i18n.$gettext('Copy to...'),
        icon: '',
        items: folders.map(folder => this.menuItemFromFolder(folder, false))
      })*/
    }

    if (items.length == 0) {
      items.push({
        label: this.i18n.$gettext("No actions available."),
        disabled: true
      })
    }

    this.menuItems = items
  }

  findFolder(id: string, folders: MailFolder[]): MailFolder | undefined {
    for (const folder of folders) {
      if (folder.originalId === id) {
        return folder
      } else if (folder.subFolders) {
        const subFolder: MailFolder | undefined = this.findFolder(id, folder.subFolders)
        if (subFolder) {
          return subFolder
        }
      }
    }
    return undefined
  }

  deleteFolder(folder: MailFolder) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this folder?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (folder.originalId) {
          mailFolderServiceApi._deleteFolder(folder.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Folder deleted"))
            if (this.folderId == folder.originalId) {
              this.goToFolderId("")
            }
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not delete Folder"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  createFolder() {
    if (this.newFolderName === "") return
    let newFolderName = this.newFolderName.trim()
    if (newFolderName.indexOf(".") > -1) {
      this.toast.error(this.i18n.$gettext("Folder names are not allowed to contain dots."))
      return Promise.reject()
    }
    this.newFolderLoading = true
    return mailFolderServiceApi._createFolder(this.newFolderName, this.newFolderLocation).then(() => {
      this.toast.success(this.i18n.$gettext("Mail folder created"))
      this.newFolderName = ""
      this.showNewFolderModal = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create mail folder"))
    }).finally(() => {
      this.newFolderLoading = false
    }) //TODO subfolder
  }

  get hasEmailBooked(): boolean {
    return featureSubset.hasMail
  }

  get flexListStyle(): Object {
    if (breakpointUtil.isOnMobile()) {
      return { "width": "100%"}
    } else if (breakpointUtil.isOnXl()) {
      return { "width": "100%"}
    } else {
      return {
        "flex-basis": "50%"
      }
    }
  }

  openFolderPermissionsModal(folder: MailFolder) {
    if (!folder?.originalId) {
      this.toast.error(this.i18n.$gettext("Could not determine folder to change permissions for"))
      this.resourceToShare = ''
    } else {
      this.resourceToShare = folder.originalId
    }
  }

  mounted() {
    if (!this.folderId) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('mailFolder')
      const folder = this.folders?.find((a: any) => !a.noSelect && (a.key === lastViewed || a.legacyId === lastViewed))
      if (!this.foldersAreLoading && !folder) {
        //This mailFolder no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('mailFolder', '')
      }
      if (folder) {
        this.goToFolderId(folder.key)
      } else {
        this.openMobileMenuDefault = true
      }
    }
  }
}
