export default class Duration {
  weeks!: number | null;
  days!: number | null;
  hours!: number | null;
  minutes!: number | null;
  seconds!: number | null;
  prior!: boolean | null;

  constructor() {
    this.weeks = null;
    this.days = null;
    this.hours = null;
    this.minutes = null;
    this.seconds = null;
    this.prior = null;
  }
}
