<template>
  <div
    class="d-flex flex-row align-items-center p-2 contacts-list-item separator-bottom bg-hover-light"
    :class="{'selected': isSelected}"
    @click="handleClick"
    @contextmenu="showCtxMenu"
  >
    <div class="flex-shrink-1 pr-3">
      <Avatar
        :display-online-status="false"
        :size="48"
        :cached-image="photoSrc"
        :label="contact.formattedName.text"
        generate-initials
      />
    </div>
    <div>
      <p :class="organization ? 'mb-0' : 'mb-1'">
        {{ name }}
      </p>
      <p v-if="organization" class="small mb-0">
        {{ organization }}
      </p>
    </div>
    <ContextMenu ref="ctxmenu" :global="false" :model="ctxMenuItems" />
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Contact from "@/model/entry/Contact"
import Avatar from "@/components/common/Avatar.vue"
import ContextMenu from "primevue/contextmenu"
import { ref } from "@vue/reactivity"
import {useGettext} from "@jshmrtn/vue3-gettext"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import {contactServiceApi} from "@/api/ContactServiceApi"
import RpcError from "@/api/RpcError"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"

@Options({
  components: {Avatar, ContextMenu, Dialog, Button},
  //@ts-ignore
  props: {
    contact: [Contact, Object]
  },
  emits: [ 'select', 'move' ]
})
export default class ContactListItem extends Vue {
  contact!: Contact

  i18n = useGettext()
  toast = useToast()
  confirm = useConfirm()

  ctxMenuItems: any = [
    {
      label: this.i18n.$gettext('Delete'),
      icon:'cil-trash',
      command: () => {
        this.deleteContact()
      }
    },
    {
      label: this.i18n.$gettext("Move"), icon: 'cil-folder-move', command:() => {
        this.$emit('move', this.contact)
      }
    }
  ];

  //@ts-ignore
  ctxmenu: ContextMenu = ref<ContextMenu | null>(null)

  get name(): string {
    if (this.contact.formattedName?.text) {
      return this.contact.formattedName.text
    } else if (this.contact.structuredName && (this.contact.structuredName.given || this.contact.structuredName.family)) {
      return (this.contact.structuredName.given || '') + (this.contact.structuredName.family ? (' ' + this.contact.structuredName.family) : '')
    } else if (this.contact.nickNames?.names && this.contact.nickNames.names.length > 0) {
      return this.contact.nickNames.names[0]
    } else if (this.contact.emailAddresses && this.contact.emailAddresses.find(a => a.name || a.address)) {
      return this.contact.emailAddresses.find(a => a.name)?.name || this.contact.emailAddresses.find(a => a.address)?.address || ''
    } else {
      return ''
    }
  }

  get organization(): string | null {
    if (this.contact.organization && this.contact.organization.titles && this.contact.organization.titles.length > 0) {
      return this.contact.organization.titles[0]
    } else {
      return null
    }
  }

  showCtxMenu(e: Event): void {
    this.ctxmenu.show(e)
  }

  get isSelected(): boolean {
    const selectedId = this.$route.params.contact
    if (!selectedId) return false
    return selectedId == this.contact?.originalId
  }

  handleClick(): void {
    this.$emit("select", this.contact)
  }

  get photoSrc(): CachedImage | null {
    if (this.contact?.photos && this.contact.photos.length > 0){
      if (this.contact.photos[0].data == null){
        return imageLoadingService.getCachedImage(`/groupware-api/v1/contacts/${this.contact.originalId}/image`)
      }
      if (this.contact.photos[0].url !== null && this.contact.photos[0].url !== ""){
        return imageLoadingService.getCachedImage(this.contact.photos[0].url)
      }
    }
    return null
  }

  deleteContact() {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this contact?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.contact?.originalId) {
          contactServiceApi._deleteContact(this.contact.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Contact deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Contact could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

}
</script>

<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables";

.contacts-list-item {

  cursor: pointer;

  &.selected {
    border-left: 2px solid $uniki_primary;
    color: $uniki_primary_dark;
    background-color: $uniki_secondary;

    & > p:first-child {
      font-weight: 700;
    }
  }
}

</style>
