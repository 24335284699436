import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Task from '@/model/entry/Task';


export default class GeneratedTaskStore {

    state = reactive({
        tasks: new Map<string, Task>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.tasks) this.state.tasks.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceTask(newTask: Task) {
        if (newTask.originalId) {
            this.state.tasks.set(newTask.originalId, newTask)
            
        }
    }

    addOrReplaceTasks(newTasks: Task[]) {
        for (const newTask of newTasks) {
            this.addOrReplaceTask(newTask)
        }
    }

    replaceTasks(tasks: Task[]) {
        const newTasks = new Map<string, Task>()
        for (const newTask of tasks) {
            if (newTask.originalId) {
                newTasks.set(newTask.originalId, newTask)
                
            }
        }
        this.state.tasks = newTasks
    }

    removeTask(originalId: string | undefined | null) {
        if (originalId !== undefined && originalId !== null) {
            this.state.tasks.delete(originalId)
        }
    }

    removeTasks(originalIds: (string | null)[] | undefined | null) {
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                this.removeTask(originalId)
            }
        }
    }
}