import GeoCoordinates from '@/model/common/carddav/GeoCoordinates';

export default class Address {
  types!: string[] | null;
  poBox!: string | null;
  extendedAddress!: string | null;
  streetAddress!: string | null;
  streetAddressFallback!: string | null;
  extendedAddressFallback!: string | null;
  locality!: string | null;
  region!: string | null;
  postalCode!: string | null;
  country!: string | null;
  label!: string | null;
  coordinate!: GeoCoordinates | null;
  timeZone!: string | null;

  constructor() {
    this.types = null;
    this.poBox = null;
    this.extendedAddress = null;
    this.streetAddress = null;
    this.streetAddressFallback = null;
    this.extendedAddressFallback = null;
    this.locality = null;
    this.region = null;
    this.postalCode = null;
    this.country = null;
    this.label = null;
    this.coordinate = null;
    this.timeZone = null;
  }
}
