import GeneratedEventServiceApi from "./generated/GeneratedEventServiceApi"
import SWR, { Call } from "@/api/SWR"
import Event from "@/model/entry/Event"
import {eventStore} from "@/store/EventStore"

class EventServiceApi extends GeneratedEventServiceApi {
    getEvent(originalId: string, refresh?: boolean | number): SWR<Event | null, string> {
        const oldEvent: Event | undefined = eventStore.state.events.get(originalId)
        if (oldEvent && !refresh) {
            const result: SWR<Event | null, string> = new SWR<Event | null, string>()
            const call = new Call<string>()
            call.loading = false
            call.refreshing = false
            call.promise = Promise.resolve(originalId)
            result.call = call
            result.data = oldEvent
            return result
        }
        const swr: SWR<Event | null, string> = super.getEvent(originalId, refresh)
        swr.call?.promise?.finally(() => {
            if (swr.data && !swr.data?.instanceStart && oldEvent?.instanceStart) {
                swr.data.instanceStart = oldEvent?.instanceStart
            }
        })
        return swr
    }
}

export const eventServiceApi = new EventServiceApi()
