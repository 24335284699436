import {EmojiButton} from "@joeattardi/emoji-button"
import {DirectiveBinding} from "vue"
import emojiButton from "@/util/emojiPickerInstance"

class Picker {

    static currentElement: HTMLElement | null = null

    //Do Setup:
    created(el: HTMLElement, binding: DirectiveBinding) {
        const picker: EmojiButton = emojiButton
        let callback = (selection: {emoji: string}) => { console.warn("Emoji" + selection.emoji + "selected but no callback registered...")}
        if(typeof binding.value === "function") {
            callback = (selection: {emoji: string}) => { binding.value(selection.emoji) }
        }
        picker.on('emoji', (selection: {emoji: string}) => {
            if (Picker.currentElement === el) {
                callback(selection)
            }
        })
        el.addEventListener('click', () => {
            Picker.currentElement = el
            picker.togglePicker(el)
        })
    }
}

export default new Picker()