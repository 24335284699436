import LibraryHistoryData from '@/model/common/LibraryHistoryData';
import FileHistoryData from '@/model/common/FileHistoryData';

export default class INodeHistory {
  isDirectory!: boolean | null;
  hasMore!: boolean | null;
  nextStartCommit!: string | null;
  libData!: LibraryHistoryData[] | null;
  fileData!: FileHistoryData[] | null;

  constructor() {
    this.isDirectory = null;
    this.hasMore = null;
    this.nextStartCommit = null;
    this.libData = null;
    this.fileData = null;
  }
}
