import EmailAddress from '@/model/common/EmailAddress';

export default class ToAddress {
  id!: string | null;
  address!: EmailAddress | null;

  constructor() {
    this.id = null;
    this.address = null;
  }
}
