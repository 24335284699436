

import {Options, Vue} from "vue-class-component"

@Options({
  components: {},
  //@ts-ignore
  props: {
    setting: Object
  },
  emits: []
})
export default class SettingsListItem extends Vue {

  setting!: {
    name: string,
    icon: string,
    linkTo: string
  }

}
