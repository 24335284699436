
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ProgressBar from "primevue/progressbar"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {fileServiceApi} from "@/api/FileServiceApi"
import RpcError from "@/api/RpcError"
import {Watch} from "vue-property-decorator"
import INodeHistory from "@/model/common/INodeHistory"
import RpcCallStatus from "@/api/RpcCallStatus"
import FileHistoryData from "@/model/common/FileHistoryData"
import dayjs from "@/util/dayjs"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Button from "primevue/button"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Avatar from "@/components/common/Avatar.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import InputText from "primevue/inputtext"

@Options({
  components: {
    LoadingButton, Dialog, TabView, TabPanel, Avatar, ProgressBar, Button, InfiniteList, InputText
  },
  //@ts-ignore
  props: {
    inode: [ INode, Object ],
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide']
})
export default class HistoryModal extends Vue {

  api = fileServiceApi
  toast = useToast()
  confirm = useConfirm()
  i18n: Language = useGettext()

  inode!: INode
  readOnly!: boolean

  show = false
  rpcStatus: RpcCallStatus = RpcCallStatus.UNINITIALIZED
  fileHistory: INodeHistory | null = null
  historySearch: string = ''

  historicDownloadLoading = false
  historicReverseLoading = false

  @Watch('show')
  loadDataOnShow(): Promise<any> {
    return this.show ? this.loadData() : Promise.reject()
  }

  loadData(): Promise<any> {
    if (this.inode.path && !this.fileHistory) {
      this.rpcStatus = RpcCallStatus.LOADING
      const sharePromise = this.api._listINodeHistory(this.inode.path).then((history: INodeHistory) => {
        this.fileHistory = history
        this.rpcStatus = RpcCallStatus.SUCCESS
      }).catch((error: RpcError) => {
        this.toast.error(error.message)
        this.rpcStatus = RpcCallStatus.ERROR
      })
      return Promise.all([sharePromise])
    } else {
      return Promise.reject()
    }
  }

  hide(): void {
    this.fileHistory = null
    this.$emit('hide')
  }

  get hasHistory(): boolean {
    return this.historyData.length > 0
  }

  get isLoading(): boolean {
    return this.rpcStatus === RpcCallStatus.LOADING
  }

  get requestFailed(): boolean {
    return this.rpcStatus === RpcCallStatus.ERROR
  }

  get requestSuccess(): boolean {
    return this.rpcStatus === RpcCallStatus.SUCCESS
  }

  toggle(): void {
    this.show = !this.show
  }

  get historyData(): FileHistoryData[] {
    let data = this.fileHistory?.fileData || []
    if (this.historySearch && this.historySearch !== '') {
      const lowerSearch = this.historySearch.toLowerCase()
      data = data.filter(d => {
        return d.description?.toLowerCase()?.includes(lowerSearch) ||
          d.creatorEmail?.toLowerCase()?.includes(lowerSearch) ||
          d.creatorName?.toLowerCase()?.includes(lowerSearch) ||
          this.formatTimestamp(d.ctime).toLowerCase()?.includes(lowerSearch)
      })
    }
    return data
  }

  formatTimestamp(isoString: string | null) {
    return isoString ? dayjs(isoString).format("HH:mm DD.MM.YYYY") : ''
  }

  revertFileToPointInHistory(hist: FileHistoryData) {
    this.confirm.require({
      message: this.i18n.$gettext("Are you sure you want to revert this file to" ) + ' ' + this.formatTimestamp(hist.ctime) + '?',
      header: this.i18n.$gettext("Revert file"),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.i18n.$gettext("Yes"),
      rejectLabel: this.i18n.$gettext("No"),
      accept: () => {
        return this._revertFileToPointInHistory(hist)
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  _revertFileToPointInHistory(hist: FileHistoryData): Promise<void> {
    if (this.inode.path && hist.historyId) {
      this.historicReverseLoading = true
      return this.api._restoreFile(this.libraryPath + '/' + hist.path, hist.historyId).then(() => {
        this.toast.success(this.i18n.$gettext("File reverted"))
        this.loadData().catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Failed to load files"))
        })
      }).catch((error: RpcError) => {
        this.toast.error(error.message, this.i18n.$gettext("File could not be reverted"))
      }).finally(() => { this.historicReverseLoading = false })
    } else {
      return Promise.reject()
    }
  }

  downloadHistoricFile(hist: FileHistoryData) {
    if (hist.path && hist.historyId) {
      this.historicDownloadLoading = true
      const historicPath = (this.libraryPath || '') + hist.path
      return this.api._getDownloadLinkFromHistory(historicPath, hist.historyId).then((uri: string) => {
        let link = document.createElement("a")
        link.setAttribute('download', '')
        link.href = uri
        document.body.appendChild(link)
        link.click()
        link.remove()
      }).catch((error: RpcError) => {
        this.toast.error(this.i18n.$gettext("Could not access file in history") + ":" + error.message)
      }).finally(() => { this.historicDownloadLoading = false })
    } else {
      return Promise.reject()
    }
  }

  get libraryPath(): string | null {
    const pathParts: string[] = this.inode.parentPath?.split("/").filter(elem => elem !== "") || []
    if (!pathParts || pathParts.length === 0) return null
    return "/" + pathParts[0]
  }

}
