<template>
  <div class="p-3 card">
    <div class="d-flex justify-content-between">
      <div v-if="i.event.requestFor">
        <div v-for="(so, index) in i.messages" :key="so.id">
          <p class="d-flex align-items-center mx-2 mb-1">
            <span>{{ humanreadableDate(so) }}</span>:&nbsp;
            <span v-if="so.method === ITIP_REQUEST && isAccepted(i.event.requestFor)">
              <translate>
                You have accepted this invite
              </translate>.
            </span>
            <span v-else-if="so.method === ITIP_REQUEST && isDeclined(i.event.requestFor)">
              <translate>
                You have declined this invite
              </translate>.
            </span>
            <span v-else-if="so.method === ITIP_REQUEST && isTentative(i.event.requestFor)">
              <translate>
                You have tentatively accepted this invite
              </translate>.
            </span>
            <span v-else-if="so.method === ITIP_REQUEST">
              <translate v-if="isInvitation(index, so.method)">
                You have been invited to this event
              </translate>
              <translate v-else>
                Attendee status changes
              </translate>.
            </span>
            <span v-else-if="so.method === ITIP_ADD">
              <translate>
                New instances were added to this event
              </translate>.
              <!-- TODO show instance here -->
            </span>
            <span v-else-if="so.method === ITIP_CANCEL">
              <translate>
                This event has been canceled
              </translate>.
            </span>
            <Chip
              v-else-if="so.method === ITIP_REPLY && so.eventsFromRequest && so.eventsFromRequest.length && so.eventsFromRequest[0].attendees && so.eventsFromRequest[0].attendees.length"
              :key="so.eventsFromRequest[0].attendees[0].uri || so.eventsFromRequest[0].attendees[0].email"
              v-tooltip="getAttendeeStatusTooltip(so.eventsFromRequest[0].attendees[0])"
              :label="attendeeName(so.eventsFromRequest[0].attendees[0].email, so.eventsFromRequest[0].attendees[0].name) + ' ' + getReplyText(so.eventsFromRequest[0])"
              :image="attendeeImage(so.eventsFromRequest[0].attendees[0].email)"
              :class="attendeeStatusColor(so.eventsFromRequest[0].attendees[0].status)"
            />
            <span v-else-if="so.method === ITIP_REPLY">
              {{ getReplyText(null) }}
            </span>
            <span v-else-if="so.method === ITIP_COUNTER">
              <translate>
                You have been sent a counter event
              </translate>.
              <!-- TODO show instance here -->
            </span>
            <span v-else-if="so.method === ITIP_DECLINECOUNTER">
              <translate>
                Your counter event has been declined
              </translate>.
              <!-- TODO show instance here -->
            </span>
            <span v-else-if="so.method === ITIP_REFRESH">
              <translate>
                A refresh has been requested for this event
              </translate>.
            </span>
          </p>
          <div v-if="index === 0 && so.method === ITIP_REQUEST">
            <Button
              class="p-button-raised p-button-danger mt-2 mr-2 mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('invite:decline', so)"
            >
              <translate>Decline</translate>
            </Button>
            <Button
              class="p-button-raised p-button-info mr-2 mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('invite:tentative', so)"
            >
              <translate>Tentative</translate>
            </Button>
            <Button
              class="p-button-raised p-button-success mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('invite:accept', so)"
            >
              <translate>Accept</translate>
            </Button>
          </div>
          <div v-else-if="index === 0 && so.method === ITIP_CANCEL">
            <Button
              class="p-button-raised p-button-danger mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('event:delete', so)"
            >
              <translate>Delete</translate>
            </Button>
          </div>
          <div v-else-if="index === 0 && so.method === ITIP_COUNTER">
            <div class="d-flex">
              <Button
                class="p-button-raised p-button-success mb-2"
                :loading="handlingIMIPLoading"
                @click="$emit('counter:decline', so)"
              >
                <translate>Decline</translate>
              </Button>
              <Button
                class="p-button-raised p-button-success mb-2"
                :loading="handlingIMIPLoading"
                @click="$emit('counter:accept', so)"
              >
                <translate>Accept</translate>
              </Button>
            </div>
          </div>
          <div v-else-if="index === 0 && so.method === ITIP_REFRESH && getAttendeeInvitedHimselfAndNeedsAccept(so, i.event.requestFor)">
            <p class="text-danger mb-2">
              <translate>You have not invited this attendee!</translate>
            </p>
            <Button
              class="p-button-raised p-button-success mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('attendee:accept', so)"
            >
              <translate>Accept new attendee</translate>
            </Button>
            <Button
              v-if="getAttendeeCanBeCanceled(i.event)"
              class="p-button-raised p-button-danger mx-2 mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('attendee:decline', so)"
            >
              <translate>Cancel attendee</translate>
            </Button>
          </div>
          <div v-else-if="index === 0 && so.method === ITIP_REFRESH">
            <Button
              class="p-button-raised p-button-success mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('event:resend', so)"
            >
              <translate>Resend Event</translate>
            </Button>
          </div>
          <div v-else-if="index === 0 && showCancel && isOrganizer">
            <Button
              class="p-button-raised p-button-success mb-2"
              :loading="handlingIMIPLoading"
              @click="$emit('event:cancel', so)"
            >
              <translate>Cancel Event</translate>
            </Button>
          </div>
        </div>
      </div>
      <div v-else-if="i.messages && i.messages.find(m => m.method === ITIP_CANCEL)">
        <p class="ml-2 mb-1">
          <translate>This event has been canceled</translate>.
        </p>
      </div>
      <div v-else>
        <p class="ml-2 mb-1">
          <translate>This event has been canceled or deleted</translate>.
        </p>
      </div>
      <div v-if="showDismiss" class="d-flex flex-shrink-0 align-self-start justify-content-end">
        <Button
          icon="cil-x"
          class="p-button-text p-button-danger"
          :label="i18n.$gettext('Dismiss')"
          @click="$emit('message:dismiss', i)"
        />
      </div>
    </div>
    <div v-if="!i.event.requestFor" class="d-flex align-items-center mx-2">
      <i class="cil-warning lead font-weight-bold mr-3" />
      <strong v-if="i.messages && i.messages.find(m => m.method === ITIP_CANCEL)" class="mb-0 text-danger"><translate>This event has been canceled</translate>.</strong>
      <strong v-else class="mb-0 text-danger"><translate>This event has been canceled or deleted</translate>.</strong>
    </div>
    <div class="d-flex align-items-center mx-2 mb-2">
      <div>
        <i class="cil-short-text lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Summary</translate>:&emsp;</strong>
        <strong class="mb-0">
          {{ i.event.summary }}
        </strong>
      </div>
      <div v-if="i.event.requestFor && canGoToEvent(i.event.requestFor)" class="ml-auto">
        <Button
          class="p-button-raised p-button-primary"
          :label="i18n.$gettext('Go to event')"
          @click="$emit('event:goto', i.event.requestFor)"
        />
      </div>
    </div>
    <div class="d-flex align-items-center mx-2 mb-2">
      <i class="cil-clock lead font-weight-bold mr-3" />
      <strong class="mb-0"><translate>Time</translate>:&emsp;</strong>
      <strong class="mb-0">
        {{ getFormattedEventTime(i.event) }}
      </strong>
    </div>
    <div v-if="i.event.location" class="d-flex align-items-center mx-2 mb-2">
      <i class="cil-location-pin lead font-weight-bold mr-3" />
      <strong class="mb-0"><translate>Location</translate>:&emsp;</strong>
      <strong class="mb-0">
        {{ i.event.location }}
      </strong>
    </div>
    <div v-if="i.event.recurrenceRule" class="d-flex align-items-center mx-2 mb-2">
      <i class="cil-sync lead font-weight-bold mr-3" />
      <strong class="mb-0"><translate>Recurrence</translate>:&emsp;</strong>
      <strong class="mb-0">
        {{ getRecurrenceText(i.event) }}
      </strong>
    </div>
    <div v-if="i.event.organizer" class="d-flex align-items-center mx-2 mb-2">
      <i class="cil-user lead font-weight-bold mr-3" />
      <strong class="mb-0"><translate>Organizer</translate>:&emsp;</strong>
      <Chip
        :key="i.event.organizer.uri || i.event.organizer.email"
        v-tooltip="i.event.organizer.email"
        :label="attendeeName(i.event.organizer.email, i.event.organizer.name)"
        :image="attendeeImage(i.event.organizer.email)"
      />
    </div>
    <div v-if="i.event.attendees && i.event.attendees.length > 0" class="d-flex align-items-center mx-2 mb-2">
      <i class="cil-group lead font-weight-bold mr-3" />
      <strong class="mb-0"><translate>Attendees</translate>:&emsp;</strong>
      <div class="d-flex flex-wrap">
        <Chip
          v-for="attendee in getAttendees(i.event)"
          :key="attendee.uri || attendee.email"
          v-tooltip="getAttendeeStatusTooltip(attendee)"
          :label="attendeeName(attendee.email, attendee.name)"
          :image="attendeeImage(attendee.email)"
          :class="attendeeStatusColor(attendee.status)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import CalendarEvent from "../../model/entry/Event"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Attendee from "@/model/common/caldav/Attendee"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import Button from "primevue/button"
import useToast from "@/util/toasts"
import DateTimeUtil from "@/util/DateTimeUtil"
import Chip from 'primevue/chip'
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import dayjs from "@/util/dayjs"
import SchedulingObject from "@/model/SchedulingObject"
import {Router, useRouter} from "vue-router"

@Options({
  components: {
    //@ts-ignore
    Button, Chip
  },
  //@ts-ignore
  props: {
    i: {
      type: Object,
      default: ""
    },
    showDismiss: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: false
    }
  },
  //@ts-ignore
  emits: [
    'message:dismiss', 'event:delete', 'event:goto', 'invite:decline', 'invite:tentative', 'invite:accept', 'event:cancel', 'counter:accept', 'counter:decline', 'invite:resend', 'attendee:accept', 'attendee:decline'
  ]
})
export default class InboxItem extends Vue {

  rpcClient = rpcClient

  i!: { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }
  showDismiss!: boolean
  showCancel!: boolean

  i18n: Language = useGettext()
  toast = useToast()
  router: Router = useRouter()

  handlingIMIPLoading: boolean = false

  isInvitation(index: number, method: string): boolean {
    if (method != this.ITIP_REQUEST) return false
    return !this.isOrganizer && (index == (this.i.messages.length - 1))
  }

  get userEmail(): string | null {
    return rpcClient.session?.user?.email || null
  }

  attendeeStatusColor(status: string): string {
    let classString: string = "mr-2 "
    if (status === this.Participation_ACCEPTED) classString += " text-success"
    else if (status === this.Participation_TENTATIVE) classString += " text-info"
    else classString += " text-danger"
    return classString
  }

  getAttendeeStatus(status: string | null): string {
    let participation: any | null = this.participationOptions.find(o => o.id === status)
    if (!participation) {
      participation = this.participationOptions.find(o => o.id === this.Participation_NEEDS_ACTION)
    }
    return participation.name
  }

  ITIP_REQUEST: string = "REQUEST"
  ITIP_REPLY: string = "REPLY"
  ITIP_ADD: string = "ADD"
  ITIP_CANCEL: string = "CANCEL"
  ITIP_REFRESH: string = "REFRESH"
  ITIP_COUNTER: string = "COUNTER"
  ITIP_DECLINECOUNTER: string = "DECLINECOUNTER"

  Participation_NEEDS_ACTION: string = "NEEDS_ACTION"
  Participation_ACCEPTED: string = "ACCEPTED"
  Participation_DECLINED: string = "DECLINED"
  Participation_TENTATIVE: string = "TENTATIVE"
  Participation_DELEGATED: string = "DELEGATED"

  participationOptions: any[] = [
    {
      id: this.Participation_NEEDS_ACTION,
      name: this.i18n.$gettext('Needs Action')
    },
    {
      id: this.Participation_ACCEPTED,
      name: this.i18n.$gettext('Accepted')
    },
    {
      id: this.Participation_DECLINED,
      name: this.i18n.$gettext('Declined')
    },
    {
      id: this.Participation_TENTATIVE,
      name: this.i18n.$gettext('Tentative')
    },
    {
      id: this.Participation_DELEGATED,
      name: this.i18n.$gettext('Delegated')
    }
  ]

  getAttendees(request: CalendarEvent | null): Attendee[] {
    if (!request) return []
    if (request.requestFor && request.requestFor.attendees && request.requestFor.attendees.length > 0) {
      return request.requestFor.attendees
    }
    return request.attendees || []
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  getFormattedEventTime(event: CalendarEvent): string {
    if (!event) return ""
    if (event?.start) {
      const start = new Date(event.start)
      let end: Date | null = null
      if (event.duration) {
        end = new Date(start.getTime() + DateTimeUtil.getMillisFromDuration(event.duration))
      } else if (event.end) {
        end = new Date(event.end)
      }
      const startString = event.allDay ? dayjs(start).format('dddd, L') : dayjs(start).format('dddd, LLL')
      const allDayEnd = end ? dayjs(end).add(-1, 'day').toDate(): null
      const sameDay: boolean = DateTimeUtil.isSameDay(start, event.allDay ? allDayEnd : end)
      if (!end || (sameDay && event.allDay)) {
        return startString
      } else if (end && sameDay) {
        return startString + ' - ' + dayjs(end).format('LT')
      } else if (end && event.allDay) {
        return startString + ' - ' + dayjs(end).add(-1, 'day').format('dddd, L')
      } else {
        return startString + ' - ' + dayjs(end).format('dddd, LLL')
      }
    } else {
      return ''
    }
  }

  getAttendeeInvitedHimselfAndNeedsAccept(so: SchedulingObject, requestFor: CalendarEvent | null): boolean {
    const request: CalendarEvent | undefined = (so.eventsFromRequest && so.eventsFromRequest.length > 0) ? so.eventsFromRequest[0] : undefined
    if (!request || !requestFor) return false
    if (!request.attendees || request.attendees.length != 1) return false
    if (!request.attendees[0].email) return false
    if (!requestFor.attendees || requestFor.attendees.length == 0) return true
    let attendeeMail: string = request.attendees[0].email || ""
    return Boolean(!requestFor.attendees.find(attendee => attendee.email === attendeeMail))
  }

  getAttendeeCanBeCanceled(request: CalendarEvent | null): boolean {
    if (!request || !request.requestFor) return false
    if (!request.attendees || request.attendees.length != 1) return false
    if (!request.attendees[0].email) return false
    if (!request.requestFor.attendees || request.requestFor.attendees.length == 0) return false
    let attendeeMail: string = request.attendees[0].email || ""
    let status: string = request.requestFor.attendees.find(attendee => attendee.email === attendeeMail)?.status || this.Participation_DECLINED
    return status != this.Participation_DECLINED
  }

  getRecurrenceText(event: CalendarEvent): string {
    if (event?.recurrenceRule) {
      try {
        return DateTimeUtil.getRRule(event, event.recurrenceRule)?.toText() || this.i18n.$gettext("Could not determine recurrence")
      } catch (ignore) {
        console.log(ignore)
        return ''
      }
    } else {
      return ''
    }
  }

  getReplyText(request: CalendarEvent | null): string {
    let text
    if (request && request.attendees && request.attendees.length > 0) {
      if (request.attendees[0].status === this.Participation_ACCEPTED) {
        text = this.i18n.$gettext('accepted this invite')
      } else if (request.attendees[0].status === this.Participation_DECLINED) {
        text = this.i18n.$gettext('declined this invite')
      } else if (request.attendees[0].status === this.Participation_TENTATIVE) {
        text = this.i18n.$gettext('accepted this invite tentatively')
      } else {
        text = this.i18n.$gettext('replied to this invite')
      }
    } else {
      text = this.i18n.$gettext('An attendee replied to this invite')
    }
    const message: string = this.getReplyMessage(request)
    if (message && message !== '') {
      text += ' ' + this.i18n.$gettext('with message') + ': ' + message
    } else {
      text += '.'
    }
    return text
  }

  getReplyMessage(request: CalendarEvent | null): string {
    if (request && request.comments && request.comments[0]) {
      return request.comments[0]
    }
    return ""
  }

  attendeeImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  attendeeName(email: string, name: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return name ? name : email
    }
  }

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Optional')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Required')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Fyi')
    }
  ]

  getAttendeeStatusTooltip(attendee: Attendee | null): string {
    if (!attendee) return ''
    let tooltip: string = this.attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || ''
    if (tooltip)
      tooltip += ': '
    tooltip += this.getAttendeeStatus(attendee.status)

    return tooltip
  }

  humanreadableDate(s: SchedulingObject): string {
    if (s.eventsFromRequest && s.eventsFromRequest.length > 0 && s.eventsFromRequest[0].stamp) try {
      return dayjs(s.eventsFromRequest[0].stamp).format("DD.MM.YYYY, HH:mm")
    } catch (e) {
      return ''
    } else {
      return ''
    }
  }

  get isOrganizer(): boolean {
    return !!(this.userEmail && this.i?.event?.requestFor?.organizer?.email?.toLowerCase() === this.userEmail?.toLowerCase())
  }

  needsAction(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_NEEDS_ACTION))
  }

  isAccepted(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_ACCEPTED))
  }

  isDeclined(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_DECLINED))
  }

  isTentative(event: CalendarEvent): boolean {
    return !!(event.attendees && event.attendees.find(a => a.email === this.userEmail && a.status === this.Participation_TENTATIVE))
  }

  canGoToEvent(event: CalendarEvent): boolean {
    return !!(event && event.originalParentId && event.originalId)
  }
}
</script>

<style scoped lang="scss">

</style>
