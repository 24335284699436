<template>
  <Dialog
    v-model:visible="visibleInternal"
    position="right"
    :style="modalStyle"
    :draggable="false"
    :close-on-escape="false"
    :closable="false"
    @hide="hide"
  >
    <template #header>
      <div v-if="editMode" class="d-flex flex-shrink-0 flex-grow-1 align-self-start">
        <Button
          class="p-button-raised mr-2 p-button-success"
          icon="cil-save"
          :label="i18n.$gettext('Save')"
          :loading="saveLoading"
          @click="save"
        />
        <Button
          v-tooltip="i18n.$gettext('Add attachment')"
          class="p-button-raised p-button-secondary mr-2"
          icon="cil-paperclip"
          @click="attachMenu.toggle($event)"
        />
        <!--<Button
          v-tooltip="i18n.$gettext('Recurrence')"
          class="p-button-raised p-button-secondary mr-2"
          icon="cil-clock"
          @click="showRecurrencePicker = true"
        />-->
        <Button
          v-tooltip="i18n.$gettext('Color')"
          class="p-button-raised p-button-secondary mr-2"
          icon="cil-color-palette"
          :style="{ 'background-color': color, 'border': '1px solid ' + color}"
          @click="colorPicker.toggle($event)"
        />
        <Button
          v-tooltip="i18n.$gettext('Conference')"
          class="p-button-raised mr-2"
          :class="(conferences && conferences.length > 0) ? 'p-button-success' : 'p-button-secondary'"
          icon="cil-video"
          :style="{ 'background-color': color, 'border': '1px solid ' + color}"
          :disabled="!featureSubset.conferenceUrl"
          @click="addOrRemoveVideoConference"
        />
      </div>
      <div v-else-if="task" class="h-100 d-flex flex-wrap flex-shrink-0 flex-grow-1 align-self-start align-items-center">
        <h3 class="mb-0 mr-6 flex-grow-1" style="text-transform: initial; width: 0; max-width: 100%">
          {{ task.summary || '' }}
        </h3>
      </div>
      <div class="d-flex flex-shrink-0 align-self-start justify-content-end">
        <Button
          v-if="canEdit"
          class="p-button-raised mr-2"
          :icon="editMode ? 'cil-x' : 'cil-pencil'"
          :label="editMode ? i18n.$pgettext('Abbrechen', 'Cancel') : i18n.$gettext('Edit')"
          :loading="saveLoading"
          @click="editMode = !editMode"
        />
        <Button
          icon="cil-x"
          class="p-button-text p-button-secondary"
          @click="confirmClose"
        />
      </div>
    </template>
    <div v-if="editMode && visibleInternal && task" class="d-flex flex-column w-100 h-100">
      <div class="mb-4">
        <span class="p-float-label w-100 mt-2">
          <InputText v-model="summary" class="w-100" type="text" />
          <label><translate>Summary</translate></label>
        </span>
        <span class="p-float-label w-100 mt-2">
          <InputText v-model="location" class="w-100" type="text" />
          <label><translate>Location</translate></label>
        </span>
      </div>
      <div class="mb-3">
        <span class="p-float-label w-100 mt-2">
          <InputText v-model="percentCompleted" class="w-100" type="number" />
          <Slider
            v-model="percentCompleted"
            :step="1"
            :min="0"
            :max="100"
          />
          <label><translate>Completed</translate></label>
        </span>
      </div>
      <div class="p-inputgroup mb-4 mt-2">
        <!--<Dropdown show-clear inline v-model="taskBoardId" :options="taskBoards" option-label="name" option-value="originalId" :label="i18n.$gettext('Board')"></Dropdown>-->
        <span class="p-float-label">
          <Tags
            v-model="categories"
            class="flex-grow-1"
            :forbidden-tags="forbiddenLabels"
            :get-autocomplete-items="filterCategoriesOptions"
          />
          <label><translate>Labels</translate></label>
        </span>
      </div>
      <!--<div class="mb-4 d-inline-flex align-items-center">
        <InputSwitch v-model="allDay"></InputSwitch><label class="my-0 mx-2"><translate>All Day</translate></label>
      </div>-->
      <div class="row mb-4">
        <div class="col col-md-6">
          <DatePicker
            v-model="start"
            inline
            :label="i18n.$gettext('Start')"
            :show-time="true"
            month-navigator
            year-navigator
            year-range="2000:2030"
            show-button-bar
          />
        </div>
        <div class="col col-md-6">
          <DatePicker
            v-model="due"
            inline
            :label="i18n.$gettext('Due')"
            :show-time="true"
            month-navigator
            year-navigator
            year-range="2000:2030"
            show-button-bar
          />
        </div>
      </div>
      <!--<div class="mb-4 d-inline-flex align-items-center">
        <InputSwitch v-model="recurring"></InputSwitch><label class="my-0 mx-2"><translate>Repeat Event</translate></label>
      </div>
      <div class="mb-4" v-if="recurring">
        <RecurrencePicker :value="rrule"></RecurrencePicker>
      </div>-->
      <div class="row mb-4">
        <div class="col col-md-6">
          <Dropdown
            v-model="classification"
            inline
            small
            :options="classificationOptions"
            :label="i18n.$gettext('Visibility')"
            option-label="label"
            option-value="value"
          />
        </div>
        <div class="col col-md-6">
          <Dropdown
            v-model="priority"
            inline
            small
            :options="priorityOptions"
            :label="i18n.$gettext('Priority')"
            option-label="label"
            option-value="value"
          />
        </div>
      </div>
      <div class="mb-4">
        <div class="p-inputgroup">
          <span class="p-float-label">
            <AutoComplete
              v-model="newParticipant"
              :suggestions="selectableUsers"
              @complete="filterUsers"
              @item-select="addAttendee"
            />
            <label><translate>Add Participants</translate></label>
          </span>
          <Button
            v-tooltip="i18n.$gettext('Add')"
            icon="cil-plus"
            class="p-button-success h-100"
            @click="addAttendee"
          />
        </div>

        <div v-for="attendee in attendees" :key="attendee.attendee.email" class="d-flex flex-row align-items-center mt-2">
          <Avatar
            :username="attendee.user?.userName"
            :label="attendee.attendee.email"
            generate-initials
            class="mr-3"
          />
          <strong class="flex-grow-1 mb-0" style="width: 1px">
            {{ attendee.user?.displayName || attendee.attendee.name || attendee.attendee.email }}
          </strong>
          <Dropdown
            v-model="attendee.attendee.participationLevel"
            small
            class="mb-0 mr-4"
            :options="attendeesTypeOptions"
            option-label="name"
            option-value="id"
            style="flex-basis: 20%; min-width: 200px; flex-grow: 0"
          />
          <Button
            v-tooltip="i18n.$gettext('Remove')"
            class="p-button-secondary"
            icon="cil-trash"
            @click="removeAttendee(attendee.attendee.email)"
          />
        </div>
      </div>
      <div v-if="conferences && conferences.length > 0" class="d-flex align-items-center mb-4">
        <i class="cil cil-video lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Conference</translate>:&emsp;</strong>
        <div class="d-flex flex-wrap">
          <Chip
            v-for="conference in conferences"
            :key="conference.uri || conference.text || conference.label"
            :label="conference.label || conference.text || conference.uri"
            removable
            @remove="removeConference(conference)"
          />
        </div>
      </div>
      <div class="d-flex flex-wrap" :class="{ 'mb-2': attachments && attachments.length > 0 }">
        <TokenAttachmentList
          ref="attachmentcontrol"
          v-model="attachments"
          :mimic-big-attachments="true"
        />
        <div v-if="task.attachments && task.attachments.length > 0" class="d-flex flex-wrap align-items-center mb-2">
          <AttachmentItem
            v-for="a in attachmentsWithFileName"
            :key="JSON.stringify(a)"
            :edit-mode="true"
            :attachment="a"
            @delete="removeAttachment"
          />
        </div>
      </div>
      <div class="flex-shrink-0 flex-grow-1 pb-4" style="min-height: 200px">
        <TipTapTextArea ref="editor" class="w-100 h-100" />
      </div>

      <Dialog
        v-model:visible="showRecurrencePicker"
        position="right"
        style="min-width: 50%; margin: 0 2rem; height: 100vh"
      >
        <!--<template v-slot:header>
          <h5 class="modal-title"><translate>Recurrence</translate></h5>
        </template>-->
        <div class="w-100 h-100">
          <div class="mt-4 d-inline-flex align-items-center">
            <InputSwitch v-model="recurring" /><label class="my-0 mx-2"><translate>Repeat Event</translate></label>
          </div>
          <div v-if="recurring" class="mt-4">
            <RecurrencePicker v-model="recurrenceRule" />
          </div>
        </div>
      </Dialog>

      <OverlayPanel ref="colorPicker">
        <ColorPicker v-model="color" @update:modelValue="colorPicker.toggle($event)" />
      </OverlayPanel>
    </div>
    <div v-else-if="visibleInternal && task" class="d-flex flex-column w-100 h-100">
      <div
        v-if="task.color"
        class="w-100 mb-4"
        style="height: .5rem; border-bottom-left-radius: 3px; border-bottom-right-radius: 3px"
        :style="'background:' + task.color"
      />
      <div v-else class="mb-4" />
      <div v-if="task.start" class="d-flex align-items-center mb-4">
        <i class="cil-media-play lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Start</translate>:&emsp;</strong>
        <strong class="mb-0">
          {{ startDate }}
        </strong>
      </div>
      <div v-if="task.due" class="d-flex align-items-center mb-4">
        <i class="cil-exclamation lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Due</translate>:&emsp;</strong>
        <strong class="mb-0">
          {{ dueDate }}
        </strong>
      </div>
      <div v-if="task.completed" class="d-flex align-items-center mb-4">
        <i class="fa fa-flag-checkered lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Completed at</translate>:&emsp;</strong>
        <strong class="mb-0">
          {{ completedDate }}
        </strong>
      </div>
      <div v-if="task.location" class="d-flex align-items-center mb-4">
        <i class="cil-location-pin lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Location</translate>:&emsp;</strong>
        <p class="mb-0">
          {{ task.location }}
        </p>
      </div>
      <div v-if="taskBoardName" class="d-flex align-items-center mb-4">
        <i class="cil-task lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Task Board</translate>:&emsp;</strong>
        <p class="mb-0">
          {{ taskBoardName }}
        </p>
      </div>
      <div v-if="task.categories && task.categories.length > 0" class="d-flex align-items-center mb-4">
        <i class="cil-tags lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Labels</translate>:&emsp;</strong>
        <div class="d-flex flex-wrap">
          <Chip
            v-for="category in task.categories"
            :key="category"
            :label="category"
            class="mr-2"
          />
        </div>
      </div>
      <div v-if="assignees.length > 0" class="d-flex align-items-center mb-4">
        <i class="cil-group lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Assigned To</translate>:&emsp;</strong>
        <div class="d-flex flex-wrap">
          <Chip
            v-for="attendee in assignees"
            :key="attendee.uri || attendee.email"
            v-tooltip="attendee.email + ' (' + (attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || '') + ')'"
            :label="attendeeName(attendee.email)"
            :image="attendeeImage(attendee.email)"
            class="mr-2"
          />
        </div>
      </div>
      <div v-if="stakeholders.length > 0" class="d-flex align-items-center mb-4">
        <i class="cil-group lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Stakeholders</translate>:&emsp;</strong>
        <div class="d-flex flex-wrap">
          <Chip
            v-for="attendee in stakeholders"
            :key="attendee.uri || attendee.email"
            v-tooltip="attendee.email + ' (' + (attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || '') + ')'"
            :label="attendeeName(attendee.email)"
            :image="attendeeImage(attendee.email)"
            class="mr-2"
          />
        </div>
      </div>
      <div v-if="members.length > 0" class="d-flex align-items-center mb-4">
        <i class="cil-group lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Members</translate>:&emsp;</strong>
        <div class="d-flex flex-wrap">
          <Chip
            v-for="attendee in members"
            :key="attendee.uri || attendee.email"
            v-tooltip="attendee.email + ' (' + (attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || '') + ')'"
            :label="attendeeName(attendee.email)"
            :image="attendeeImage(attendee.email)"
            class="mr-2"
          />
        </div>
      </div>
      <div v-if="task.classification" class="d-flex align-items-center mb-4">
        <i
          class="lead font-weight-bold mr-3"
          :class="{
            'cil-low-vision': task.classification === 'CONFIDENTIAL',
            'cil-eye-slash': task.classification === 'PRIVATE',
            'cil-eye': task.classification === 'PUBLIC'
          }"
        />
        <strong class="mb-0"><translate>Visibility</translate>:&emsp;</strong>
        <p class="mb-0">
          {{
            classificationOptions.find(o => o.value === task.classification)?.label || ''
          }}
        </p>
      </div>
      <div v-if="task.organizer" class="d-flex align-items-center mb-4">
        <i class="cil-user lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Created by</translate>:&emsp;</strong>
        <Chip
          :key="task.organizer.uri || task.organizer.email"
          v-tooltip="task.organizer.email"
          :label="organizerName(task.organizer.email, task.organizer.email)"
          :image="organizerImage(task.organizer.email)"
        />
      </div>
      <div v-if="task.conferences && task.conferences.length > 0" class="d-flex align-items-center mb-4">
        <i class="cil cil-video lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Conference</translate>:&emsp;</strong>
        <div class="d-flex flex-wrap">
          <Chip
            v-for="conference in task.conferences"
            :key="conference.uri || conference.text || conference.label"
            :label="conference.label || conference.text"
            icon=""
          >
            <a v-if="conference.uri" :href="conference.uri" target="_blank">{{ conference.uri }}</a>
          </Chip>
        </div>
      </div>
      <div v-if="task.attachments && task.attachments.length > 0" class="d-flex align-items-center mb-2">
        <i class="cil-file lead font-weight-bold mr-3" />
        <strong class="mb-0"><translate>Files</translate>:&emsp;</strong>
      </div>
      <div v-if="task.attachments && task.attachments.length > 0" class="d-flex flex-wrap align-items-center mb-2">
        <AttachmentItem v-for="a in attachmentsWithFileName" :key="JSON.stringify(a)" :attachment="a" />
      </div>
      <div v-if="hasLink">
        <div v-for="entry in entryLinks" :key="entry.id" class="d-flex align-items-center mb-4">
          <i class="cil-mail lead font-weight-bold mr-3" />
          <strong class="mb-0"><translate>Linked Mail</translate>:&emsp;</strong>
          <div class="cursor-pointer bg-hover-light" @click="goToEmail(entry)">
            {{ entry.description }}
          </div>
        </div>
      </div>
      <div v-if="task.description" class="mb-0">
        <p>
          <i class="cil-info lead font-weight-bold mr-3" />
          <strong><translate>Description</translate>:&emsp;</strong>
        </p>
      </div>
      <div v-if="task.description" class="flex-shrink-0 flex-grow-1 pb-4" style="min-height: 200px">
        <TipTapTextArea
          ref="editor"
          class="w-100 h-100"
          :editable="false"
          :show-tool-bar="false"
          :preview-content="task.description"
          @changed="saveTaskListItemChanges()"
        />
      </div>
    </div>
    <Menu ref="attachMenu" :model="attachMenuItems" :popup="true" />
  </Dialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Task from "../../model/entry/Task"
import AnimatedInput from "../common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {Watch} from "vue-property-decorator"
import CalendarPicker from "primevue/calendar"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Attendee from "@/model/common/caldav/Attendee"
import Alarm from "@/model/common/caldav/Alarm"
import Conference from "@/model/common/caldav/Conference"
import {taskServiceApi} from "@/api/TaskServiceApi"
import InputSwitch from "primevue/inputswitch"
import {ref} from "@vue/reactivity"
import InfiniteList from "@/components/common/InfiniteList.vue"
import User from "@/model/User"
import TaskBoard from "@/model/directory/TaskBoard"
import Avatar from "@/components/common/Avatar.vue"
import {userServiceApi} from "@/api/UserServiceApi"
import UserPicker from "@/components/common/UserPicker.vue"
import Dropdown from "@/components/common/Dropdown.vue"
import RecurrencePicker from "@/components/common/RecurrencePicker.vue"
import Organizer from "@/model/common/caldav/Organizer"
import {rpcClient} from "@/api/WebsocketClient"
import RecurrenceRule from "@/model/common/caldav/RecurrenceRule"
import ColorPicker from "@/components/common/ColorPicker.vue"
import OverlayPanel from "primevue/overlaypanel"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import DatePicker from "@/components/common/DatePicker.vue"
import EmailUtil from "@/util/EmailUtil"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Textarea from "primevue/textarea"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import Attachment from "@/model/common/caldav/Attachment"
import Chip from "primevue/chip"
import dayjs from "dayjs"
import Slider from 'primevue/slider'
import breakpointUtil from "@/util/BreakpointUtil"
import TipTapTextArea from "@/components/common/TipTapTextArea.vue"
import Menu from "primevue/menu"
import featureSubset from "@/util/FeatureSubsets"
import Tags from "@/components/common/Tags.vue"
import AttachmentItem from "@/components/common/AttachmentItem.vue"
import {entryLinkServiceApi} from "@/api/EntryLinkServiceApi"
import EntryLink from "@/model/EntryLink"
import {toEmail} from "@/router"
import AttachmentUpload from "@/util/AttachmentUpload"

@Options({
  components: {
    //@ts-ignore
    AnimatedInput, LoadingButton, InputText, Dialog, CalendarPicker, InputSwitch, InfiniteList, Avatar,
    UserPicker, AutoComplete, Dropdown, RecurrencePicker, ColorPicker, OverlayPanel, Button, TokenAttachmentList,
    DatePicker, Textarea, Chip, Slider, TipTapTextArea, Menu, Tags, AttachmentItem
  },
  //@ts-ignore
  props: {
    task: [Task, Object],
    boardId: String,
    forbiddenLabels: {
      type: Array,
      default: []
    }
  },
  emits: [
    'hide'
  ]
})
export default class TaskDetails extends Vue {

  featureSubset = featureSubset

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()
  forbiddenLabels: string[] = []
  loadingEntryLink: boolean = true
  entryLinks: { type: string, parent: string, id: string, description: string }[] = []

  //@ts-ignore
  colorPicker: OverlayPanel = ref<OverlayPanel>(null)
  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)
  //@ts-ignore
  description: HTMLElement = ref<HTMLElement>(null)

  saveLoading = false

  task!: Task | null
  visibleInternal = false
  editMode = false

  showRecurrencePicker = false

  newParticipant = ""
  selectableUsers: string[] = []

  start: Date | null = null
  due: Date | null = null
  summary: string | null = null
  completed: Date | null = null
  percentCompleted: number | null = null
  location: string | null = null
  taskBoardId: string | null = null
  classification = 'PRIVATE'
  priority = 'UNDEFINED'
  organizer: Organizer | null = null
  categories: string[] = []
  list: string | null = null
  attendees: { attendee: Attendee, user: User | undefined }[] = []
  contacts: string[] = []
  alarms: Alarm[] = []
  color: string | null = null
  conferences: Conference[] = []
  recurring = false
  transparency = 'OPAQUE'
  recurrenceRule: RecurrenceRule = Object.assign(new RecurrenceRule(), {
    frequency: 'WEEKLY',
    interval: '1'
  })

  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  //@ts-ignore
  attachMenu: Menu = ref(null)
  attachments: AttachmentUpload[] = []

  attachMenuItems = [
    {
      label: this.i18n.$gettext('Upload from your computer'),
      icon: 'cil-data-transfer-up',
      command: () => {
        this.attachmentcontrol.openNativeFileChooser()
      }
    },
    {
      label: this.i18n.$gettext('Choose from files'),
      icon: 'cil-inbox-out',
      command: () => {
        this.attachmentcontrol.openInodeChooser()
      }
    },
  ]

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      const query: string = event.query.toLowerCase()
      return user.userName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.displayName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.email?.toLowerCase()?.indexOf(query) !== -1 ||
        user.uid?.toLowerCase()?.indexOf(query) !== -1
    }).map((user: User) => {
      let userTag: string = ""
      if (user.email) userTag = user.email
      if (user.displayName) userTag = user.displayName + " <" + userTag + ">"
      return userTag
    }).filter((str: string) => {return str !== "" })
  }

  saveTaskListItemChanges() {
    if (this.task?.originalId && !this.saveLoading && this.canEdit && this.editor?.hasChanges()) {
      this.task.description = this.editor.getMarkdown()
      this.saveLoading = true
      return taskServiceApi._updateTask(this.task).then((id: string) => {
        return id
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Updating task failed"))
      }).finally(() => {
        this.saveLoading = false
      })
    }
  }

  organizerImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  organizerName(email: string, name: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return name ? name : email
    }
  }

  save(): Promise<string | void> {
    if (this.validate && this.taskBoardId) {
      let hasIncompleteUploads: boolean = this.attachmentcontrol.checkForIncompleteUploads()
      if (hasIncompleteUploads){
        this.toast.error(this.i18n.$gettext("You cannot send the message while attachments are still uploading"))
        return Promise.reject()
      }

      if (this.start && this.due && this.due.getTime() < this.start.getTime()){
        this.toast.error(this.i18n.$gettext("Due date must be after start date"))
        return Promise.reject()
      }

      const task: Task = this.task ? Object.assign(new Task(), this.task) : new Task()

      if (this.percentCompleted === 100 && task.percentCompleted !== 100) {
        this.completed = new Date()
      }

      task.start = this.start?.toISOString() || null
      task.due = this.due?.toISOString() || null
      task.completed = this.completed?.toISOString() || null
      task.percentCompleted = this.percentCompleted
      task.summary = this.summary
      task.location = this.location
      task.originalParentId = this.taskBoardId
      task.classification = this.classification
      task.description = this.editor?.getMarkdown()
      task.priority = this.priority
      task.organizer = this.organizer ? this.organizer : (rpcClient.session.user ? Object.assign(new Organizer(), {
        email: rpcClient.session.user.email,
        name: rpcClient.session.user.userName, //TODO
      }) : null)
      task.categories = [...this.categories]
      if (this.list && task.categories.indexOf(this.list) < 0) {
        task.categories.push(this.list)
      }
      task.attendees = (this.attendees || []).map(a => a.attendee)
      for (let attendee of task.attendees) {
        attendee.rsvp = true //TODO
      }
      task.contacts = this.contacts
      task.alarms = this.alarms
      task.color = this.color
      task.conferences = this.conferences
      task.recurrenceRule = this.recurring ? this.recurrenceRule : null
      task.fileTokens = this.attachmentcontrol.getFileTokens()

      this.saveLoading = true

      if (this.task?.originalId) {
        return taskServiceApi._updateTask(task).then((id: string) => {
          this.editMode = false
          this.attachments = []
          this.saveLoading = false
          return id
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Updating task failed"))
        }).finally(() => {
          this.saveLoading = false
        })
      } else {
        return taskServiceApi._addTask(task).then((id: string) => {
          this.editMode = false
          this.attachments = []
          const created: Task | undefined = taskServiceApi.getTask(id)
          if (created && this.task) {
            Object.assign(this.task, created)
          } else if (created) {
            this.watchEvent(task, null)
          }
          return id
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Creating task failed"))
        }).finally(() => {
          this.saveLoading = false
        })
      }
    } else {
      return Promise.reject()
    }
  }

  get validate(): boolean {
    return Boolean(this.summary)
  }

  classificationOptions: {label: string, value: string}[] = [
    {label: this.i18n.$gettext('Public'), value: 'PUBLIC'},
    {label: this.i18n.$gettext('Private'), value: 'PRIVATE'},
    {label: this.i18n.$gettext('Confidential'), value: 'CONFIDENTIAL'}
  ]

  priorityOptions: {label: string, value: string}[] = [
    {label: this.i18n.$gettext('High'), value: 'HIGH'},
    {label: this.i18n.$gettext('Medium'), value: 'MEDIUM'},
    {label: this.i18n.$gettext('Low'), value: 'LOW'},
    {label: this.i18n.$gettext('Not set'), value: 'UNDEFINED'}
  ]

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Member')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Assignee')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Stakeholder')
    }
  ]

  filterCategoriesOptions(currentInput: string): string[] {
    const options: string[] = taskServiceApi.getCategoriesOptions(this.taskBoardId || "")
    if (currentInput) {
      const lowerQuery = currentInput.toLowerCase()
      return options.filter(option => option.toLowerCase().includes(lowerQuery)).sort((o1: string, o2: string) => {
        let index1: number = o1.toLowerCase().indexOf(lowerQuery)
        let index2: number = o2.toLowerCase().indexOf(lowerQuery)
        return index1 - index2
      })
    } else {
      return options
    }
  }

  hide() {
    this.attachments = []
    this.$emit('hide')
  }

  confirmClose() {
    if (this.editMode && this.hasChanges) {
      this.confirm.require({
        message: this.i18n.$gettext('Do you want to save the task?'),
        header: this.i18n.$gettext('Save & Close'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.save().then(() => {
            this.hide()
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext('Could not save task'))
            this.hide()
          })
        },
        reject: () => {
          this.hide()
        }
      })
    } else {
      this.hide()
    }
  }

  get taskBoards(): TaskBoard[] | null {
    return taskBoardServiceApi.getTaskBoards().data
  }

  get canEdit(): boolean {
    const canWrite: string[] = ['WRITE', 'OWNER']
    const board: TaskBoard | undefined = this.taskBoards?.find(b => b.originalId === this.task?.originalParentId)
    return Boolean( board && canWrite.includes(board.shareAccess || ''))
  }

  get users(): User[] {
    return userServiceApi.getUsers( ).data || []
  }

  get taskBoardName(): string {
    if (this.task?.originalParentId) {
      return this.taskBoards?.find(b => b.originalId === this.task?.originalParentId)?.name || ''
    } else {
      return ''
    }
  }

  get attachmentsWithFileName(): Attachment[] {
    return this.task?.attachments?.filter(a => a.file && a.file.fileName) || []
  }

  get startDate(): string {
    return this.task?.start ? dayjs(this.task.start).format('dddd, LLL') : ''
  }

  get dueDate(): string {
    return this.task?.due ? dayjs(this.task.due).format('dddd, LLL') : ''
  }

  get completedDate(): string {
    return this.task?.completed ? dayjs(this.task.completed).format('dddd, LLL') : ''
  }

  get members(): Attendee[] {
    return this.task?.attendees?.filter(a => a.participationLevel === 'OPTIONAL') || []
  }

  get assignees(): Attendee[] {
    return this.task?.attendees?.filter(a => a.participationLevel === 'REQUIRED') || []
  }

  get stakeholders(): Attendee[] {
    return this.task?.attendees?.filter(a => a.participationLevel === 'FYI') || []
  }

  attendeeImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  attendeeName(email: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return email
    }
  }

  addAttendee() {
    const parts: string[] = this.newParticipant.split(" <")
    if (parts.length < 1) return
    const email = parts[parts.length - 1].replace('>', '')

    if (email === "") {
      return
    } else if (!!this.attendees.find(a => a.attendee.email === email)) {
      this.newParticipant = ""
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const attendee: Attendee = new Attendee()
      const user: User | undefined = this.users.find(u => u.email === email)
      attendee.email = (user && user.email) ? user.email : email
      attendee.name = (user && user.displayName) ? user.displayName : email
      attendee.participationLevel = 'REQUIRED'
      this.attendees.push({
        attendee: attendee,
        user: user
      })
      this.newParticipant = ""
    } else {
      this.toast.error(this.i18n.$gettext("Member must be an email address"))
    }
  }

  addOrRemoveVideoConference() {
    if (this.conferences && this.conferences.length > 0) {
      this.removeConference(this.conferences[0])
    } else if (this.featureSubset.conferenceUrl) {
      if (!this.conferences) {
        this.conferences = []
      }
      let uri = this.featureSubset.conferenceUrl
      if (!uri.endsWith('/')) {
        uri += '/'
      }
      for (let i = 0; i < 3; i++) {
        uri += Math.random().toString(36).substr(2)
      }
      const conference: Conference = new Conference()
      conference.uri = uri
      this.conferences.push(conference)
      this.editor?.insertContent('<p>Meeting-Link: <a href="' + uri + '"></a>' + uri + '</p>')
    }
  }

  removeConference(conference: any) {
    const i: number = this.conferences.indexOf(conference)
    if (i >= 0) {
      this.conferences.splice(i, 1)
      let html = this.editor?.getHTML() || ''
      html = html.replace(/<p>Meeting-Link:.*<\/p>/, '')
      if (html && html.trim() !== '') {
        this.editor.setContent(html)
      } else if (this.editor) {
        this.editor?.clearContent()
      }
    }
  }

  removeAttendee(email: string) {
    this.attendees = this.attendees.filter(attendee => attendee.attendee.email !== email)
  }

  removeAttachment(attachment: any) {
    if (this.task?.attachments) {
      const index: number = this.task.attachments.indexOf(attachment)
      if (index >= 0) {
        this.task.attachments.splice(index, 1)
      }
    }
  }

  get hasChanges(): boolean {
    if (this.task?.originalId) {
      let taskBoard: TaskBoard | undefined =  this.taskBoards?.find(board => board.originalId === this.taskBoardId)
      return Boolean(((!this.task?.start && this.start) || (this.start && this.start.getTime() !== new Date(this.task?.start || 0).getTime())) ||
        ((!this.task?.due && this.due) || (this.due && this.due.getTime() !== new Date(this.task?.due || 0).getTime())) ||
        (this.taskBoardId !== this.task?.originalParentId) ||
        (this.percentCompleted !== this.task?.percentCompleted) ||
        (this.completed !== this.task?.completed) ||
        (this.summary !== this.task?.summary) ||
        (this.location !== this.task?.location) ||
        (this.classification !== (this.task?.classification || 'PRIVATE')) ||
        //TODO (this.description !== this.task?.description) ||
        (this.priority !== (this.task?.priority || 'UNDEFINED')) ||
        (this.color !== this.task?.color) ||
        //(this.recurrenceRule !== this.task?.recurrenceRule) ||
        (this.organizer !== (this.task?.organizer || null)) ||
        //(this.transparency !== (this.task?.transparency || 'OPAQUE')) ||
        !SortAndFilterUtil.arrayEquals(this.categories, (this.task?.categories || []).filter((category: string) => {
          return !taskBoard || !taskBoard.meta?.taskLists?.map(list => list.name).includes(category)
        })) ||
        !SortAndFilterUtil.arrayEquals((this.attendees || []).map(a => a.attendee), this.task?.attendees || []) ||
        !SortAndFilterUtil.arrayEquals(this.contacts, this.task?.contacts || []) ||
        !SortAndFilterUtil.arrayEquals(this.alarms, this.task?.alarms || []) ||
        !SortAndFilterUtil.arrayEquals(this.conferences, this.task?.conferences || []))
    } else {
      return Boolean(this.task && this.validate)
    }
  }

  @Watch('editor')
  watchEditor(newEditor: TipTapTextArea, oldEditor: TipTapTextArea) {
    if (this.task?.description && this.task.description !== '' && this.editor) {
      this.editor.setContent(this.task.description)
    } else if (this.editor) {
      this.editor.clearContent()
    }
  }

  @Watch('task')
  watchEvent(task: Task | null, oldValue: Task | null) {
    if (!task) {
      this.visibleInternal = false
      this.editMode = false
      this.start = null
      this.due = null
      this.summary = null
      this.completed = null
      this.percentCompleted = null
      this.location = null
      this.taskBoardId = null
      this.classification = 'PRIVATE'
      if (this.editor) {
        this.editor.clearContent()
      }
      this.priority = 'UNDEFINED'
      this.organizer = null
      this.categories = []
      this.attendees = []
      this.contacts = []
      this.alarms = []
      this.color = null
      this.conferences = []
      this.recurring = false
      this.transparency = 'OPAQUE'
      this.recurrenceRule = Object.assign(new RecurrenceRule(), {
        frequency: 'WEEKLY',
        interval: '1'
      })
    } else if (!this.editMode) {
      this.editMode = !task.originalId
      this.visibleInternal = true
      let taskBoard: TaskBoard | undefined = undefined
      this.start = task.start ? new Date(task.start) : null
      this.due = task.due ? new Date(task.due) : null
      this.completed = task.completed ? new Date(task.completed) : null
      this.percentCompleted = task.percentCompleted
      this.summary = task.summary
      this.location = task.location
      if (task.originalParentId) {
        this.taskBoardId = task.originalParentId
        if (this.taskBoards) taskBoard = this.taskBoards.find(board => board.originalId === this.taskBoardId)
      } else {
        this.taskBoardId = this.taskBoards ? (this.taskBoards[0]?.originalId || null) : null
      }
      this.classification = task.classification || 'PRIVATE'
      if (task.description && this.editor) {
        this.editor.setContent(task.description)
      }
      this.priority = task.priority || 'UNDEFINED'
      if (task.organizer) {
        this.organizer = task.organizer
      } else {
        this.organizer = null
      }
      if (task.categories) {
        this.categories = task.categories.filter((category: string) => {
          return !taskBoard || !taskBoard.meta?.taskLists?.map(list => list.name?.toLowerCase()).includes(category.toLowerCase())
        })
        this.list = task.categories.find((category: string) => {
          return taskBoard && taskBoard.meta?.taskLists?.map(list => list.name?.toLowerCase()).includes(category.toLowerCase())
        }) || null
      } else {
        this.categories = []
      }
      if (task.attendees) {
        this.attendees = task.attendees.map(a => {
          return {
            attendee: a,
            user: this.users.find(u => u.email === a.email)
          }
        })
      } else {
        this.attendees = []
      }
      if (task.contacts) {
        this.contacts = task.contacts
      } else {
        this.contacts = []
      }
      if (task.alarms) {
        this.alarms = task.alarms
      } else {
        this.alarms = []
      }
      this.color = task.color
      if (task.conferences) {
        this.conferences = task.conferences
      } else {
        this.conferences = []
      }
      if (task.recurrenceRule) {
        this.recurring = true
        this.recurrenceRule = task.recurrenceRule
      } else {
        this.recurring = false
      }
    }
    if (task) {
      this.checkForEntryLinks()
    }
  }

  checkForEntryLinks() {
    this.entryLinks = []
    if (this.task && this.task.originalId) {
      entryLinkServiceApi._getLinksByTypeAndBackendId(
        "TASK", this.task.originalId
      ).then((entryLinks: EntryLink[]) => {
        if (entryLinks && entryLinks.length > 0) {
          let linkedId: string | null
          let linkedParentId: string | null
          let linkedType: string | null
          let linkedDescription: string | null
          entryLinks.forEach((entry: EntryLink) => {
            if (this.task && entry.meta) {
              linkedDescription = entry.meta.description
              if (entry.leftBackendId == this.task.originalId) {
                linkedId = entry.rightBackendId
                linkedParentId = entry.meta.rightOriginalParentId
                linkedType = entry.rightType
              } else {
                linkedId = entry.leftBackendId
                linkedParentId = entry.meta.leftOriginalParentId
                linkedType = entry.leftType
              }
              if (linkedId && linkedType == 'EMAIL' && linkedParentId && linkedDescription) {
                this.entryLinks.push({
                  type: linkedType,
                  parent: linkedParentId,
                  id: linkedId,
                  description: linkedDescription
                })
              }
            }
          })
        }
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get linked entries"))
      }).finally(() => {
        this.loadingEntryLink = false
      })
    }
  }

  get modalStyle(){
    if(breakpointUtil.isOnMobile()){
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { width: "50%", margin: "1rem", height: "100%" }
    }
  }

  get hasLink(): boolean {
    if (this.loadingEntryLink) return false
    return this.entryLinks.length > 0
  }

  goToEmail(entry: {type: string, parent: string, id: string}): void {
    toEmail(entry.parent, entry.id)
  }

  mounted() {
    this.watchEvent(this.task, null)
  }

  openNativeFileChooser(){
    this.attachmentcontrol.openNativeFileChooser()
  }
}
</script>

<style scoped lang="scss">

</style>
