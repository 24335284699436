export default class Channel {
  id!: string | null;
  displayName!: string | null;
  teamId!: string | null;
  name!: string | null;
  creatorId!: string | null;
  isDirect!: boolean | null;
  isHidden!: boolean | null;
  lastPosted!: string | null;
  created!: string | null;
  deleted!: string | null;
  updated!: string | null;
  extraUpdated!: string | null;
  members!: string[] | null;
  permissions!: string[] | null;
  unreadMsgCount!: number | null;
  mentionCount!: number | null;
  memberCount!: number | null;
  guestCount!: number | null;
  pinnnedPostCount!: number | null;
  type!: string | null;
  header!: string | null;
  purpose!: string | null;
  schemeId!: string | null;
  props!: any | null;
  totalMsgCount!: number | null;
  groupConstrained!: boolean | null;
  backend!: string | null;

  constructor() {
    this.id = null;
    this.displayName = null;
    this.teamId = null;
    this.name = null;
    this.creatorId = null;
    this.isDirect = null;
    this.isHidden = null;
    this.lastPosted = null;
    this.created = null;
    this.deleted = null;
    this.updated = null;
    this.extraUpdated = null;
    this.members = null;
    this.permissions = null;
    this.unreadMsgCount = null;
    this.mentionCount = null;
    this.memberCount = null;
    this.guestCount = null;
    this.pinnnedPostCount = null;
    this.type = null;
    this.header = null;
    this.purpose = null;
    this.schemeId = null;
    this.props = null;
    this.totalMsgCount = null;
    this.groupConstrained = null;
    this.backend = null;
  }
}
