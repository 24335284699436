import MessageEmbed from '@/model/entry/MessageEmbed';
import MessageFileInfo from '@/model/entry/MessageFileInfo';
import MessageImage from '@/model/entry/MessageImage';
import MessageReaction from '@/model/entry/MessageReaction';

export default class Message {
  id!: string | null;
  text!: string | null;
  created!: string | null;
  channelId!: string | null;
  userId!: string | null;
  forwardedFromId!: string | null;
  deleted!: string | null;
  updated!: string | null;
  edited!: string | null;
  props!: any | null;
  embeds!: MessageEmbed[] | null;
  files!: MessageFileInfo[] | null;
  images!: MessageImage[] | null;
  reactions!: MessageReaction[] | null;
  fileIds!: string[] | null;
  rootId!: string | null;
  parentId!: string | null;
  originalId!: string | null;
  type!: string | null;
  hashtags!: string | null;
  pendingPostId!: string | null;
  hasReactions!: boolean | null;
  replyCount!: number | null;
  parentMessage!: Message | null;
  backend!: string | null;
  renderingFieldsSet!: boolean | null;
  isFollowup!: boolean | null;
  isFirstMessageOfTheDay!: boolean | null;
  shouldCiteParent!: boolean | null;
  isFollowUpInThreadLevel!: boolean | null;
  isForwarded!: boolean | null;
  queryId!: string | null;
  score!: number | null;
  forwardedMessage!: Message | null;
  fileTokens!: string[] | null;

  constructor() {
    this.id = null;
    this.text = null;
    this.created = null;
    this.channelId = null;
    this.userId = null;
    this.forwardedFromId = null;
    this.deleted = null;
    this.updated = null;
    this.edited = null;
    this.props = null;
    this.embeds = null;
    this.files = null;
    this.images = null;
    this.reactions = null;
    this.fileIds = null;
    this.rootId = null;
    this.parentId = null;
    this.originalId = null;
    this.type = null;
    this.hashtags = null;
    this.pendingPostId = null;
    this.hasReactions = null;
    this.replyCount = null;
    this.parentMessage = null;
    this.backend = null;
    this.renderingFieldsSet = null;
    this.isFollowup = null;
    this.isFirstMessageOfTheDay = null;
    this.shouldCiteParent = null;
    this.isFollowUpInThreadLevel = null;
    this.isForwarded = null;
    this.queryId = null;
    this.score = null;
    this.forwardedMessage = null;
    this.fileTokens = null;
  }
}
