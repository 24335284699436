
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/entry/Email"
import {mailServiceApi} from "@/api/MailServiceApi"
import SWR from "@/api/SWR"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import DetailBody from "@/components/email/DetailBody.vue"
import DetailButtons from "@/components/email/DetailButtons.vue"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import EmailUtil from "@/util/EmailUtil"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import breakpointUtil from "@/util/BreakpointUtil"
import {Watch} from "vue-property-decorator"
import Dialog from "primevue/dialog"
import {rpcClient} from "@/api/WebsocketClient"
import {emailStore} from "@/store/EmailStore"

@Options({
  components: {
    //@ts-ignore
    Dialog, LoadingButton, DetailBody, DetailButtons, MenuBar, EmailComposer
  },
  //@ts-ignore
  props: {
    folderId: String,
    emailId: {type: String, default: null},
    queryId: {type: String, default: null}
  },
  emits: ['email:close']
})
export default class DetailViewDialog extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  showDetailView: boolean  = false

  folderId!: string
  emailId!: string | null
  queryId!: string | null

  mode = 'rich'
  showingConversation: boolean = false

  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  closeDetailView() {
    void this.$router.push('/mail/' + (this.$route.params['folder'] || ''))
  }

  detailBodyId(emailId: string): string {
    return "detailview-" + emailId.replaceAll('=', '-')
  }

  iframeId(emailId: string): string {
    return "iframeId-" + emailId.replaceAll('=', '-')
  }

  get hasThread(): boolean {
    if (this.thread?.length) {
      return this.thread?.length > 1
    }
    return false
  }

  toggleConversation(): void {
    if (this.showingConversation) {
      this.showingConversation = false
    } else {
      this.showingConversation = this.hasThread
    }
  }

  get threadSubject(): string | null {
    let emails: Email[] | null = this.thread
    if (emails && emails.length > 0) {
      let subject: string | null = emails[emails.length - 1].subject
      if (subject) {
        if (subject.length > 50) {
          return subject.substr(0, 49) + '...'
        } else {
          return subject
        }
      }
    }
    return null
  }

  get threadLatestMail(): Email | null {
    let emails: Email[] | null = this.thread
    if (emails && emails.length > 0) {
      return emails[0]
    }
    return null
  }

  get thread(): Email[] | null {
    if (this.emailId) {
      let email: Email | null = this.selectedEmail
      if (email?.threadId) {
        let swrThread: SWR<Email[] | null, string[]> = mailServiceApi.getEmailThread(email.threadId, -1)
        let emails: Email[] | null = swrThread.data
        if (!emails || emails.find(m => m.isPreview)) {
          swrThread = mailServiceApi.getEmailThread(email.threadId, true)
          emails = swrThread.data
        }
        if (this.queryId && emails) {
          emails.forEach(m => m.queryId = this.queryId)
        }
        if (emails) {
          return emails
        }
      }
      return email ? [email] : null
    } else {
      return null
    }
  }

  get emails(): Email[] | null {
    if (this.showingConversation && this.hasThread) {
      return this.thread
    } else {
      if (this.selectedEmail) {
        return [this.selectedEmail]
      }
    }
    return null
  }

  get selectedEmail(): Email | null {
    if (this.emailId) {
      const email: Email | null = emailStore.state.emails.get(this.emailId) || null
      if (!email || email.isPreview) {
        mailServiceApi.getFullMail(this.emailId).catch(e => {
          if (e.data && (
              e.data['exceptionTypeName']?.endsWith('MessageRemovedException') ||
              e.data['exceptionTypeName']?.endsWith('NotFoundException')
          )) {
            if (this.emailId) {
              emailStore.state.emails.delete(this.emailId)
              this.handleDeleted({ id: this.emailId })
            }
            this.toast.error(this.i18n.$gettext('The requested email does not exist.'))
          } else {
            this.toast.error(this.i18n.$gettext('Failed to load email details.'))
          }
        })
      }
      return email
    } else {
      return null
    }
  }

  switchToSource(){
    this.mode = 'source'
  }

  switchToRichText(){
    this.mode = 'rich'
  }

  handleDeleted(event: {id: string}) {
    if (this.selectedEmail?.originalId) {
      if (this.selectedEmail.originalId === event.id) {
        this.$emit('email:close')
      }
    }
  }

  moveToFolder(event: {id: string, sourceFolderId: string, targetFolderId: string}) {
    mailServiceApi._move([ event.id ], event.sourceFolderId, event.targetFolderId, false).then(() => {
      this.toast.success(this.i18n.$gettext("Email moved"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not move email"))
    })
  }

  getFromAddress(folderId: string | null): string {
    let fromAddress: string = ''
    if (folderId) {
      let decodedId: string = atob(folderId)
      if (decodedId?.startsWith('imap://') && decodedId.includes('%40') && decodedId.includes('@')) {
        decodedId = decodedId.substr(7)
        fromAddress = decodedId.split('@')[0].replace('%40', '@')
      }
    }
    if (!fromAddress && rpcClient.session.user?.email) {
      return rpcClient.session.user.email
    }
    return fromAddress
  }

  replyMessage(event: {id: string, folderId: string, replyAll: boolean}) {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const reply: Email = EmailUtil.createReply(email, event.replyAll)
        reply.from = [ this.getFromAddress(email.originalParentId) ]
        this.composer.show(reply, null, event.folderId, event.id, null)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  forwardMessage(event: {id: string,folderId: string,  replyAll: boolean}) {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const forward: Email = EmailUtil.createForward(email)
        forward.from = [ this.getFromAddress(email.originalParentId) ]
        this.composer.show(forward, null, event.folderId, null, event.id)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  print(event: {id: string}) {
    if (!this.threadLatestMail) return
    let contentId: string = this.detailBodyId(event.id)
    let frameId: string = this.iframeId(event.id)
    const content: HTMLElement | undefined = document.querySelector('#' + contentId) as HTMLElement
    const frame: HTMLIFrameElement | undefined | null = document.querySelector('#' + frameId) as HTMLIFrameElement
    if (content) {
      const height: number = (frame && frame.contentWindow) ? frame.contentWindow.document.body.scrollHeight : content.clientHeight
      const width: number = (frame && frame.contentWindow) ? frame.contentWindow.document.body.scrollWidth : content.clientWidth
      const printWin: Window | null = window.open('', 'PRINT', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + (window.screen?.width || width) + 'px,height=' + (window.screen?.height || height) + 'px')
      if (printWin) {
        printWin.document.write('<html><head>' + document.head.innerHTML  + '</head><body><div style="page-break-inside: avoid; position: relative; width: 100%">')
        printWin.document.write('<iframe id="' + frameId + '" style="page-break-inside: avoid; border: none; box-shadow: none; width: 100%" src="' + '/groupware-api/v1/emails/' + event.id + '/html?allowImages=true&header=true" sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox" />')
        printWin.document.write('</div></body></html>')

        printWin.document.close() // necessary for IE >= 10
        printWin.focus() // necessary for IE >= 10*/

        this.printFrame(printWin, frameId, width, height)
      }
    }
  }

  printFrame(printWin: Window, frameId: string, width: number, height: number) {
    const printFrame: HTMLElement | null = printWin.document.getElementById(frameId)
    if (printFrame) {
      printFrame.style.height = '100%'
      printFrame.style.width = '100%'
      printFrame.style.minHeight = height + 'px'
      printFrame.style.minWidth = width + 'px'
    }
    //@ts-ignore
    if (printFrame && printFrame.attachEvent) {
      //@ts-ignore
      printFrame.attachEvent('onload', function() {
        printWin.print()
        printWin.close()
      })
    } else if (printFrame) {
      printFrame.onload = function() {
        printWin.print()
        printWin.close()
      }
    } else {
      printWin.setTimeout(() => {
        this.printFrame(printWin, frameId, width, height)
      })
    }
  }

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }

  get modalStyle(){
    if (breakpointUtil.isOnMobile()){
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { minWidth: "50%", margin: "0 2rem", height: "100%" }
    }
  }

  @Watch('emailId')
  updateModalState(){
    this.showingConversation = false
    this.showDetailView = !!this.emailId
  }

  mounted(){
    this.showDetailView = !!this.emailId
  }
}
