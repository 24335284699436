import GeneratedEventStore from "./generated/GeneratedEventStore"
import Event from "@/model/entry/Event"

class EventStore extends GeneratedEventStore {

    addOrReplaceEvent(newEvent: Event) {
        if (newEvent.originalId) {
            const oldEvent: Event | undefined = this.state.events.get(newEvent.originalId)
            //It is a recurring event, but instanceDates have not been set. Keep the old instanceDates.
            if (oldEvent?.instanceDates && newEvent && !newEvent.instanceDates &&
                JSON.stringify(oldEvent.recurrenceRule) == JSON.stringify(newEvent.recurrenceRule)) {
                newEvent.instanceDates = oldEvent.instanceDates
            }
            this.state.events.set(newEvent.originalId, newEvent)
        }
    }
}

export const eventStore = new EventStore()