

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"
import ScrollPanel from "primevue/scrollpanel"
import Dialog from "primevue/dialog"
import Listbox from "primevue/listbox"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import useToast, {ToastAPI} from "@/util/toasts"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Badge from "primevue/badge";
import InputText from "primevue/inputtext"
import ProgressBar from "primevue/progressbar";
import TreeSelect from "primevue/treeselect"
import InfiniteList from "@/components/common/InfiniteList.vue"
import DataImportWizard from "@/components/settings/dataimportwizard/DataImportWizard.vue"
import DataImportJob from "@/model/DataImportJob"
import SWR from "@/api/SWR"
import {useConfirm} from "primevue/useconfirm"
import SettingsUtil from "@/util/SettingsUtil"
import DataImportJobListItem from "@/components/settings/dataimport/DataImportJobListItem.vue"

@Options({
  components: {
    DataImportWizard, Button, Badge, Skeleton, ScrollPanel, ProgressBar, InfiniteList,
    Dialog, Listbox, LoadingButton, InputText, TreeSelect, DataImportJobListItem
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DataImportWidget extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()

  showMainDialog: boolean = false

  get importJobs(): SWR<DataImportJob[], number[]> {
    return dataImportServiceApi.getDataImportJobs()
  }

  ack(): void {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to hide the data import widget? You will still be able use the data import feature inside System Settings.'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        SettingsUtil.setShowDataImportWidget(false)
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }
}
