import {reactive} from "@vue/reactivity"
import App from "@/model/adminpanel/App"

class FeatureSubsets {

    apps: App[] | null = null

    constructor() {
    }

    updateAppsFromAdminpanel(newApps: App[]) {
        this.apps = newApps
    }

    get hasFiles(): boolean {
        return this.hasAppWithId("de.uniki.seafile") || this.hasAppWithId("de.uniki.seafilepro") || this.hasAppWithId("de.uniki.files")
    }

    get hasChat(): boolean {
        return this.hasAppWithId("de.uniki.chat")
    }

    get hasDAV(): boolean {
        return this.hasAppWithId("de.uniki.dav")
    }

    get hasMail(): boolean {
        return this.hasAppWithId("de.uniki.mail")
    }

    get isInBasicFeatureSet(): boolean {
        return this.hasFiles && this.hasChat
    }

    get isInAdvancedFeatureSet(): boolean {
        return this.isInBasicFeatureSet && this.hasDAV
    }

    get isInCompleteFeatureSet(): boolean {
        return this.isInAdvancedFeatureSet && this.hasMail
    }

    get conferenceUrl(): string | null {
        const app: App | undefined = this.getAppWithId('de.uniki.jitsimeet')
        return app ? 'https://' + app.host : null
    }

    private hasAppWithId(storeId: string): boolean {
        return this.apps === null || this.apps.findIndex(( value: App, index: number) => {
            return value.storeId === storeId
        }) > -1
    }

    private getAppWithId(storeId: string): App | undefined {
        return this.apps ? this.apps.find(( value: App, index: number) => {
            return value.storeId === storeId
        }) : undefined
    }
}

const featureSubset = reactive(new FeatureSubsets())
export default featureSubset
