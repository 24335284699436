<template>
  <div class="mt-4">
    <div class="d-flex flex-row justify-content-end">
      <Button
        v-if="editMode"
        v-tooltip="i18n.$gettext('Save Filter')"
        icon="cil-save"
        class="p-button p-button-success btn-raised mr-3"
        :loading="saveLoading"
        :disabled="invalidRules"
        @click="$emit('save')"
      />
      <Button
        v-if="editMode"
        v-tooltip="i18n.$gettext('Cancel')"
        icon="cil-x"
        class="p-button p-button-secondary btn-raised"
        :class="{ 'mr-3': !createMode }"
        @click="cancelEdit"
      />
      <Button
        v-else
        v-tooltip="i18n.$gettext('Edit Filter')"
        icon="cil-pencil"
        class="p-button p-button-primary btn-raised mr-3"
        @click="startEdit"
      />
      <Button
        v-if="!createMode"
        v-tooltip="i18n.$gettext('Remove Filter')"
        icon="cil-trash"
        class="p-button p-button-danger btn-raised"
        @click="$emit('delete', $event)"
      />
    </div>
    <p class="font-weight-bold text-dark w-100 text-center my-2">
      <translate>Execute this Action</translate>
    </p>
    <div class="d-flex flex-row flex-wrap mt-2">
      <Dropdown
        v-model="filter.action"
        :options="actionOptions"
        option-label="label"
        option-value="value"
        :can-deselect="false"
        :disabled="!editMode"
        class="m-1"
      />
      <span v-if="[ 'REDIRECT', 'REJECT', 'COPY' ].includes(filter.action)" class="p-float-label m-1" style="flex-grow: 2">
        <InputText v-model="filter.target" :disabled="!editMode" class="w-100" />
        <label><translate>Value</translate></label>
      </span>
      <Dropdown
        v-else-if="filter.action === 'FILEINTO'"
        v-model="filter.target"
        :options="fileOptions"
        option-label="label"
        option-value="value"
        :can-deselect="false"
        :disabled="!editMode"
        class="m-1"
      />
    </div>
    <p class="font-weight-bold text-dark w-100 text-center my-2">
      <translate>if</translate>
    </p>
    <div v-for="(rule, i) in filter.rules" :key="'rule-' + i" class="d-flex flex-row flex-wrap mt-2">
      <Button
        v-if="i > 0 && editMode"
        icon="cil-trash"
        class="p-button p-button-danger btn-raised m-1"
        @click="filter.rules.splice(i, 1)"
      />
      <Dropdown
        v-if="i > 0"
        v-model="rule.prefix"
        :options="prefixOptions"
        option-label="label"
        option-value="value"
        :can-deselect="false"
        :disabled="!editMode"
        class="m-1"
      />
      <span class="p-float-label flex-grow-1 m-1">
        <Dropdown
          v-model="rule.field"
          :options="fieldOptions"
          option-label="label"
          option-value="value"
          :disabled="!rule.prefix || !editMode"
          :can-deselect="false"
          class="w-100"
        />
        <label><translate>Field</translate></label>
      </span>
      <span v-if="rule.field === 'HEADER'" class="p-float-label flex-grow-1 m-1">
        <InputText v-model="rule.name" :disabled="!editMode" />
        <label><translate>Header Name</translate></label>
      </span>
      <span class="p-float-label m-1">
        <Dropdown
          v-model="rule.operator"
          :options="operatorOptions"
          option-label="label"
          option-value="value"
          :disabled="!editMode || (rule.field === 'HEADER' ? !rule.name : !rule.field)"
          :can-deselect="false"
          class="w-100"
        />
        <label><translate>Condition</translate></label>
      </span>
      <span v-if="rule.field !== 'ATTACHMENT_TYPE'" class="p-float-label m-1" style="flex-grow: 2">
        <InputText v-model="rule.value" class="w-100" :disabled="!rule.operator || !editMode" />
        <label><translate>Value</translate></label>
      </span>
      <span v-else class="p-float-label m-1">
        <Dropdown
          v-model="rule.value"
          :options="attachmentTypeOptions"
          option-label="label"
          option-value="value"
          :disabled="!rule.operator || !editMode"
          :can-deselect="false"
          class="w-100"
        />
        <label><translate>File Type</translate></label>
      </span>
    </div>
    <div>
      <Button
        v-if="editMode"
        class="p-button p-button-primary btn-raised text-left my-3 mx-1"
        type="button"
        :disabled="invalidRules"
        @click="addRule"
      >
        <i class="fas fa-plus" />
        <span>&emsp;<translate>Add criterium</translate></span>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import Rule from "@/model/settings/Rule"
import useToast from "@/util/toasts"
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import MailFilter from "@/model/settings/MailFilter"
import Button from "primevue/button"
import EmailUtil from "@/util/EmailUtil"

@Options({
  components: {
    InputText, Dropdown, Button
  },
  //@ts-ignore
  props: {
    filter: [ MailFilter, Object ],
    fileOptions: Array,
    createMode: Boolean
  },
  emits: [
    'save', 'delete', 'cancel'
  ]
})
export default class UserMailFilterEditor extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  api = settingsServiceApi

  fileOptions: { label: string, value: string }[] = [];
  filter!: MailFilter
  originalFilter: string | null = null
  createMode!: boolean

  editActive: boolean = false
  saveLoading: boolean = false
  deleteLoading: boolean = true
  foldersLoading: boolean = true

  prefixOptions: { value: string, label: string }[] = [
    { value: 'AND', label: this.i18n.$gettext('AND') },
    { value: 'OR', label: this.i18n.$gettext('OR') }
  ]

  fieldOptions: { value: string, label: string }[] = [
    { value: 'TEXT', label: this.i18n.$gettext('Text (body or attachment)') },
    { value: 'SENDER', label: this.i18n.$gettext('Sender') },
    { value: 'RECEIVER', label: this.i18n.$gettext('Recipient (To / Cc /Bcc)') },
    { value: 'TO', label: this.i18n.$gettext('Recipient (To)') },
    { value: 'CC', label: this.i18n.$gettext('Recipient (Cc)') },
    { value: 'SUBJECT', label: this.i18n.$gettext('Subject') },
    //{ value: 'ATTACHMENT', label: this.i18n.$gettext('Attachment (filename or metadata)') },
    //{ value: 'ATTACHMENT_TYPE', label: this.i18n.$gettext('Attachment type') },
    { value: 'HEADER', label: this.i18n.$gettext('Message Header') },
    //{ value: 'HAS_ATTACHMENT', label: this.i18n.$gettext('Has Attachment(s)') },
    //{ value: 'HAS_NO_ATTACHMENT', label: this.i18n.$gettext('Has no Attachment(s)') },
    { value: 'IS_SPAM', label: this.i18n.$gettext('Classified as Spam') },
    { value: 'IS_NOT_SPAM', label: this.i18n.$gettext('Not classified as Spam') }
  ]

  operatorOptions: { value: string, label: string }[] = [
    { value: 'CONTAINS_ALL', label: this.i18n.$gettext('contains all of') },
    { value: 'CONTAINS_ONE', label: this.i18n.$gettext('contains one of') },
    { value: 'CONTAINS_NONE', label: this.i18n.$gettext('contains none of') },
    { value: 'MATCHES_REGEX', label: this.i18n.$gettext('matches regex') }
  ]

  attachmentTypeOptions: { value: string, label: string }[] = [
    { value: 'PDF', label: this.i18n.$gettext('PDF Document') },
    { value: 'Office', label: this.i18n.$gettext('Office Document') },
    { value: 'Image', label: this.i18n.$gettext('Image / Video') }
  ]

  actionOptions: { value: string, label: string }[] = [
    { value: 'FILEINTO', label: this.i18n.$gettext('File Into') },
    { value: 'REDIRECT', label: this.i18n.$gettext('Redirect To Address(es)') },
    { value: 'COPY', label: this.i18n.$gettext('Send Copy To Address(es)') },
    { value: 'DISCARD', label: this.i18n.$gettext('Discard') },
    { value: 'REJECT', label: this.i18n.$gettext('Reject') }
  ]

  spamThresholdOptions: number[] = [...Array(10).keys()].map(n => n + 1)

  get editMode(): boolean {
    return Boolean(this.editActive || this.createMode)
  }

  addRule() {
    let rule: Rule = new Rule()
    rule.prefix = 'AND'
    if (!this.filter.rules) {
      this.filter.rules = []
    }
    this.filter.rules.push(rule)
  }

  get invalidRules(): boolean {
    return !EmailUtil.isValid(this.filter)
  }

  startEdit() {
    this.originalFilter = JSON.stringify(this.filter)
    this.editActive = true
  }

  cancelEdit() {
    if (this.originalFilter) {
      Object.assign(this.filter, JSON.parse(this.originalFilter))
    }
    this.editActive = false
    this.$emit('cancel')
  }

  /*mounted() {
    this.api._getFilterRules().then((rules: Rule[]) => {
      this.rules = rules.map(rule => Object.assign(new Rule(), rule))
      for (let rule of this.rules) {
        if (rule.field === 'HAS_ATTACHMENT' && rule.value === 'false') {
          rule.field = 'HAS_NO_ATTACHMENT'
        } else if (rule.field === 'IS_SPAM' && rule.value === 'false') {
          rule.field = 'IS_NOT_SPAM'
        }
      }
    }).catch(e => {
      this.toast.error(this.i18n.$gettext('Failed to load archiving rules.'))
    }).finally(() => {
      this.rulesLoading = false
    })
  }*/

}
</script>

<style lang="scss" scoped>

</style>
