import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import LibraryHistoryData from '@/model/common/LibraryHistoryData';


export default class GeneratedLibraryHistoryDataStore {

    state: {
        libraryHistoryDatas: Map<string, LibraryHistoryData>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let libraryHistoryDatas: Map<string, LibraryHistoryData> | null = null
        try {
            const persistedState = localStorage.getItem('LibraryHistoryDataStore')
            if (persistedState) {
                libraryHistoryDatas = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            libraryHistoryDatas: libraryHistoryDatas || new Map<string, LibraryHistoryData>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('LibraryHistoryDataStore', JSON.stringify(Array.from(this.state.libraryHistoryDatas)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.libraryHistoryDatas) this.state.libraryHistoryDatas.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('LibraryHistoryDataStore')
    }

    addOrReplaceLibraryHistoryData(newLibraryHistoryData: LibraryHistoryData, persist: boolean = true): true | undefined {
        if (newLibraryHistoryData.historyId) {
            this.state.libraryHistoryDatas.set(newLibraryHistoryData.historyId, newLibraryHistoryData)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceLibraryHistoryDatas(newLibraryHistoryDatas: LibraryHistoryData[]) {
        let persist = false
        for (const newLibraryHistoryData of newLibraryHistoryDatas) {
            const changed = !!this.addOrReplaceLibraryHistoryData(newLibraryHistoryData, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceLibraryHistoryDatas(libraryHistoryDatas: LibraryHistoryData[]) {
        const newLibraryHistoryDatas = new Map<string, LibraryHistoryData>()
        for (const newLibraryHistoryData of libraryHistoryDatas) {
            if (newLibraryHistoryData.historyId) {
                newLibraryHistoryDatas.set(newLibraryHistoryData.historyId, newLibraryHistoryData)
                
            }
        }
        this.state.libraryHistoryDatas = newLibraryHistoryDatas
        this.persistState()
    }

    removeLibraryHistoryData(historyId: string | undefined | null, persist: boolean = true): true | undefined {
        if (historyId !== undefined && historyId !== null) {
            if (this.state.libraryHistoryDatas.delete(historyId)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeLibraryHistoryDatas(historyIds: (string | null)[] | undefined | null) {
        let persist = false
        if (historyIds !== undefined && historyIds !== null) {
            for (const historyId of historyIds) {
                const changed = !!this.removeLibraryHistoryData(historyId, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}