<template>
  <div class="dash-widget d-flex flex-column card card-lite bg-white z-1 mr-md-4 mr-3 mt-6">
    <div class="dash-widget-count z-2">
      {{ unreadEmailCnt }}
    </div>
    <div class="w-100 d-flex align-items-center px-3 py-3">
      <p class="h5 flex-grow-1 mb-0">
        <translate>New Emails</translate>
      </p>
      <!-- this is basically in here to adjust the height. Just make it visible if you need a settings cog on this -->
      <a
        v-tooltip.left="i18n.$gettext('Settings')"
        class="nav-link flex-shrink-0"
        style="cursor: pointer; visibility: hidden"
        @click.stop=""
      >
        <i class="cil-settings" style="font-size: 1.6rem" />
      </a>
    </div>
    <div class="flex-shrink-1 flex-grow-1 result-list" style="min-height: 22.8rem">
      <InfiniteList
        :get-all-items="allItems"
        :get-item-page="itemPage"
        :page-size="pageSize"
        id-property="originalId"
      >
        <template #element="{ item }">
          <div class="pt-2 pb-2 px-3 separator-top cursor-pointer bg-hover-light" @click="goToItem(item)">
            <div class="w-100 d-flex flex-row">
              <div class="strong flex-grow-1" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                {{ item.subject }}
              </div>
            </div>
            <div class="w-100 d-flex flex-row">
              <div class="flex-grow-1" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                {{ formatEmailDate(item) }} - {{ getSender(item) }}
              </div>
            </div>
          </div>
        </template>
        <template #loading>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
          <div class="mt-1 mb-1 px-3 separator-top">
            <Skeleton class="mt-1" height="18px" style="width: 80%" />
            <div class="w-100 d-flex flex-row">
              <Skeleton class="mt-1" height="18px" style="width: 100%" />
            </div>
          </div>
        </template>
        <template #empty>
          <div class="d-flex flex-column justify-content-center" style="min-height: 22rem">
            <div class="text-center">
              <p><translate>No unread mail in your inbox.</translate></p>
            </div>
          </div>
        </template>
      </InfiniteList>
    </div>
  </div>
</template>


<script lang="ts">

import {Options, Vue} from "vue-class-component"
import SWR from "@/api/SWR"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import Skeleton from "primevue/skeleton"
import dayjs from "dayjs"
import Email from "@/model/entry/Email"
import {mailServiceApi} from "@/api/MailServiceApi"
import Page from "@/model/Page"
import RpcError from "@/api/RpcError"
import InfiniteList from "@/components/common/InfiniteList.vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import useToast from "@/util/toasts"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"

@Options({
  components: { Skeleton, InfiniteList },
  //@ts-ignore
  props: {},
  emits: []
})
export default class EmailWidget extends Vue {

  isLoading: boolean = false
  toast = useToast()
  pageSize = 50
  i18n: Language = useGettext()

  goToItem(item: Email): void {
    if (item.originalParentId && item.originalId) {
      void this.$router.push('/mail/' + encodeURIComponent(item.originalParentId) + '/' + encodeURIComponent(item.originalId))
    }
  }

  getSenderEmails(email: Email): string {
    const sender = email.senderEmail ? email.senderEmail : ''
    if (sender) return sender
    if (email.fromEmail) return email.fromEmail.join(', ')
    if (email.from) return email.from.join(', ')
    if (email.fromName) return email.fromName.join(', ')
    return email.sender ? email.sender : email.senderName || ''
  }

  getSender(email: Email): string {
    const sender = email.senderName ? email.senderName : (email.senderEmail ? email.senderEmail : email.sender) || ''
    if (sender) return sender
    if (email.fromName) return email.fromName.join(', ')
    if (email.fromEmail) return email.fromEmail.join(', ')
    if (email.from) return email.from.join(', ')
    return ''
  }

  formatEmailDate(email: Email): string {
    if (!email.receivedDate) return ""
    const d: Date = new Date(email.receivedDate)
    return dayjs(d).format('L')
  }

  get inboxFolder(): MailFolder | null {
    const swr: SWR<MailFolder[], string[]> = mailFolderServiceApi.getFolders()
    if (swr.call?.loading && swr.call?.promise) {
      this.isLoading = true
      swr.call.promise.finally(() => {
        this.isLoading = false
      })
    }
    return swr.data?.find((f: MailFolder) => {
      return f.name === "INBOX"
    }) || null
  }

  get inboxLink(): string {
    const id = this.inboxFolder?.originalId
    let link = "/mail"
    if (id) link += "/" + encodeURIComponent(id)
    return link
  }

  get allItems(): Email[] {
    if (!this.inboxFolder?.originalId) return []
    let emails: Email[] = mailServiceApi.getEmailsFilterByOriginalParentId(this.inboxFolder?.originalId, "receivedDate:desc")
    let systemFlagsContainsOne: string[] = ['UNSEEN']
    return SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
  }

  errorMessageInterval: number = 30000
  lastErrorMessage: number = Date.now() + this.errorMessageInterval
  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], Page<string>>) | null {
    if (this.inboxFolder?.originalId) {
      const originalFolderId: string = this.inboxFolder.originalId
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], Page<string>> = mailServiceApi.getEmailPreviews(originalFolderId, "receivedDate:desc", null, [ 'SEEN' ], pageIndex, pageSize, 120000)
        if (swr.call?.promise) swr.call?.promise.then((page: Page<string>) => {
          if (typeof page.total === 'number') {
            mailServiceApi.state.total = page.total
            return page.total
          } else {
            return page.hasMore
          }
        }).catch((e: RpcError) => {
          if (Date.now() > this.lastErrorMessage) {
            this.toast.error(e.message, this.i18n.$gettext('Failed to load results.'))
            this.lastErrorMessage = Date.now() + this.errorMessageInterval
          }
        })
        return swr
      }

    } else {
      return null
    }
  }

  get unreadEmailCnt(): number {
    if (!this.inboxFolder?.numberOfUnread) return 0
    return this.inboxFolder?.numberOfUnread
  }

}
</script>

<style lang="scss" scoped>

@import "node_modules/elly-bs4/sass/variables";

.strong {
  font-weight: 700;
}

.email-shortview:last-child {
  border: none !important;
}



</style>
