
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import {groupServiceApi} from "@/api/GroupServiceApi"
import Group from "@/model/directory/Group"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    accessOptions: {
      type: Array,
      default: null
    }
  },
  components: {
    Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class GroupSharingInput extends Vue {

  i18n: Language = useGettext()

  modelValue!: any[]
  accessOptions!: any[] | null

  sharedTo: any[] = []
  selectableGroups: string[] = []
  newSharee = ''

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  get seafileGroups(): string[] {
    return this.groups?.filter((group: Group) => {
      return group.syncedWithSeafile && !this.sharedTo.find((sharee: any) => {return sharee.commonName == group.description})
    }).map((group: Group) => {
      return group.description || ""
    }).filter((str: string) => {
      return str !== ""
    }) || []
  }

  get groups(): Group[] {
    return groupServiceApi.getGroupsForCurrentUser().data || []
  }

  addSharee() {
    const name = this.newSharee
    if (name === "" || !!this.sharedTo.find(g => g.commonName === name)) {
      return
    } else {
      const sharee: any = {}
      const group: Group | undefined = this.groups.find(u => u.description === name)
      if (group) {
        sharee.sharee = group
        sharee.commonName = name
        if (this.tAccessOptions && this.tAccessOptions.length > 0) sharee.accessRight = this.tAccessOptions[0].id
        this.sharedTo.push(sharee)
        this.$emit('added', sharee)
        this.$emit('update:modelValue', this.sharedTo)
        this.newSharee = ""
      }
    }
  }

  updateSharee(sharee: any) {
    this.$emit('updated', sharee)
  }

  removeSharee(sharee: any) {
    const removed: number = this.sharedTo.indexOf(sharee)
    if (removed >= 0) {
      const removedSharee = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedSharee)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged(sharedTo: any[]) {
    this.sharedTo = sharedTo
  }

  mounted() {
    this.onValueChanged(this.modelValue)
  }
}
