import UserSettings from "@/model/settings/UserSettings"
import {rpcClient} from "@/api/WebsocketClient"
import ProjectCollectionOrder from "@/model/settings/ProjectCollectionOrder"
import LastViewedCollections from "@/model/settings/LastViewedCollections"
import LastViewedCollectionOrder from "@/model/settings/LastViewedCollectionOrder"
import {userServiceApi} from "@/api/UserServiceApi"
import DashboardSettings from "@/model/settings/DashboardSettings"
import CalendarSettings from "@/model/settings/dashboard/CalendarSettings"
import TaskSettings from "@/model/settings/dashboard/TaskSettings"
import ChatSettings from "@/model/settings/dashboard/ChatSettings"

export default class SettingsUtil {

    static getSettings(): UserSettings | undefined {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            return settings
        }
    }

    static setLastViewedCollection(type: string, collectionId: string): Promise<void> {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings) {
            if (!settings.projectCollections) {
                settings.projectCollections = new ProjectCollectionOrder()
            }
            if (!settings.projectCollections.lastViewed) {
                settings.projectCollections.lastViewed = new LastViewedCollections()
            }
            if ((settings.projectCollections.lastViewed as any)[type] !== collectionId) {
                (settings.projectCollections.lastViewed as any)[type] = collectionId
                return userServiceApi._saveSettings('projectCollections.lastViewed', settings)
            }
        }
        return Promise.resolve()
    }

    static getLastViewedCollection(type: string): string | undefined | null {
        const collections: LastViewedCollections = rpcClient.session.user?.settings?.projectCollections?.lastViewed
        return collections ? (collections as any)[type] : undefined
    }

    static setLastViewedCollectionOrder(type: string, collectionOrder: string[]): Promise<void> {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings) {
            if (!settings.projectCollections) {
                settings.projectCollections = new ProjectCollectionOrder()
            }
            if (!settings.projectCollections.order) {
                settings.projectCollections.order = new LastViewedCollectionOrder()
            }
            const oldOrder = (settings.projectCollections.order as any)[type]
            if (!oldOrder || JSON.stringify(oldOrder) !== JSON.stringify(collectionOrder)) {
                (settings.projectCollections.order as any)[type] = collectionOrder
                return userServiceApi._saveSettings('projectCollections.order', settings)
            }
        }
        return Promise.resolve()
    }

    static getLastViewedCollectionOrder(type: string): string[] | undefined | null {
        const order: LastViewedCollectionOrder | undefined = rpcClient.session.user?.settings?.projectCollections?.order
        return order ? ((order as any)[type] || []) : undefined
    }

    static setLastSetCalendarView(view: string): void {
        if (view !== 'dayGridMonth' && view !== 'timeGridWeek' && view !== 'timeGridDay'
            && view !== 'listMonth' && view !== 'listWeek' && view !== 'listDay') return

        const settings: UserSettings | undefined = this.getSettings()
        if (settings) {
            if (settings.lastSetCalendarView !== view) {
                settings.lastSetCalendarView = view
                void userServiceApi._saveSettings('lastSetCalendarView', settings)
            }
        }
    }

    static getLastSetCalendarView(): string {
        const view: string = rpcClient.session.user?.settings?.lastSetCalendarView || 'dayGridMonth'
        if (view !== 'dayGridMonth' && view !== 'timeGridWeek' && view !== 'timeGridDay'
            && view !== 'listMonth' && view !== 'listWeek' && view !== 'listDay') return 'dayGridMonth'
        return view
    }

    static setHelpCenterSeen(): void {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings && !settings.helpCenterSeen) {
            settings.helpCenterSeen = true
            void userServiceApi._saveSettings('helpCenterSeen', settings)
        }
    }

    static getHelpCenterSeen(): boolean {
        return rpcClient.session.user?.settings?.helpCenterSeen || false
    }

    static showDataImportWidget(): boolean {
        return !rpcClient.session.user?.settings?.hideDataImportWidget
    }

    static setShowDataImportWidget(show: boolean): void {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings && !settings.hideDataImportWidget === !show) {
            settings.hideDataImportWidget = !show
            void userServiceApi._saveSettings('hideDataImportWidget', settings)
        }
    }

    static getIntroVideoSeen(): boolean {
        return rpcClient.session.user?.settings?.introVideoSeen || false
    }

    static setIntroVideoSeen(): void {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings && !settings.introVideoSeen) {
            settings.introVideoSeen = true
            void userServiceApi._saveSettings('introVideoSeen', settings)
        }
    }

    static setIntroVideoUnSeen(): void {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings && !!settings.introVideoSeen) {
            settings.introVideoSeen = false
            void userServiceApi._saveSettings('introVideoSeen', settings)
        }
    }

    static setHideNoBackupJobsWarning(): void {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings && !settings.hideNoBackupJobsWarning) {
            settings.hideNoBackupJobsWarning = true
            void userServiceApi._saveSettings('hideNoBackupJobsWarning', settings)
        }
    }

    static getHideNoBackupJobsWarning(): boolean {
        if (rpcClient.session.user?.settings) {
            return rpcClient.session.user?.settings?.hideNoBackupJobsWarning || false
        } else {
            return true
        }
    }

    static getLoadImagesBySender(sender: string): boolean {
        const senders: string[] | null = rpcClient.session.user?.settings?.loadImagesBySender
        if (senders) {
            return senders.includes('*') || senders.includes(sender)
        } else {
            return false
        }
    }

    static addSenderToLoadImagesBySender(sender: string): Promise<any> {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings) {
            const senders: string[] = settings.loadImagesBySender || []
            if (senders.includes(sender)) {
                return Promise.resolve()
            } else {
                senders.push(sender)
                settings.loadImagesBySender = senders
                return userServiceApi._saveSettings('loadImagesBySender', settings)
            }
        } else {
            return Promise.reject('No user!')
        }
    }

    static showLoadImagesDialog(): boolean {
        return !rpcClient.session.user?.settings?.disableLoadImagesDialog
    }

    static disableLoadImagesDialog(): Promise<any> {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings && !settings.disableLoadImagesDialog) {
            settings.disableLoadImagesDialog = true
            return userServiceApi._saveSettings('disableLoadImagesDialog', settings)
        } else if (settings) {
            return Promise.resolve(settings)
        } else {
            return Promise.reject('No user!')
        }
    }

    static getDashboardSettings(): DashboardSettings | undefined {
        const settings: UserSettings | undefined = this.getSettings()
        if (settings) {
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }
            return settings.dashboardSettings
        }
    }

    static setDashboardCalendarSettings(calendarSettings: CalendarSettings): void {
        const dashboardSettings: DashboardSettings | undefined = this.getDashboardSettings()
        if (dashboardSettings) {
            dashboardSettings.calendar = calendarSettings
            void userServiceApi._saveSettings('dashboardSettings.calendar', Object.assign(new UserSettings(), { dashboardSettings }))
        }
    }

    static setDashboardTaskSettings(taskSettings: TaskSettings): void {
        const dashboardSettings: DashboardSettings | undefined = this.getDashboardSettings()
        if (dashboardSettings) {
            dashboardSettings.tasks = taskSettings
            void userServiceApi._saveSettings('dashboardSettings.tasks', Object.assign(new UserSettings(), { dashboardSettings }))
        }
    }

    static setDashboardChatSettings(chatSettings: ChatSettings): void {
        const dashboardSettings: DashboardSettings | undefined = this.getDashboardSettings()
        if (dashboardSettings) {
            dashboardSettings.chat = chatSettings
            void userServiceApi._saveSettings('dashboardSettings.chat', Object.assign(new UserSettings(), { dashboardSettings }))
        }
    }
}

//@ts-ignore
window.settings = SettingsUtil
