import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import ShareLink from '@/model/common/ShareLink';
import Page from '@/model/Page';
import { shareLinkStore } from '@/store/ShareLinkStore';

export default class GeneratedShareLinkServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getShareLinks(path: string | null, page: number | null, perPage: number | null): Promise<Page<ShareLink>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getShareLinks', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let shareLinks: ShareLink[] = data.items.map((shareLink: any) => Object.assign(new ShareLink(), shareLink))
                shareLinkStore.addOrReplaceShareLinks(shareLinks)
                return Object.assign(new Page<ShareLink>(), data)
            } else return Promise.reject()
        })
    }

    _createShareLink(path: string, password: string | null, expireDays: number | null, canPreview: boolean | null, canDownload: boolean | null, canEdit: boolean | null, unchecked: boolean | null, internal: boolean | null): Promise<ShareLink> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createShareLink', rpcParams, false).then((data: any) => {
            return Object.assign(new ShareLink(), data)
        })
    }

    _deleteShareLink(token: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteShareLink', rpcParams, false).then(() => {
            shareLinkStore.removeShareLink(token)
        })
    }

    _setShareLinkPassword(token: string, password: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setShareLinkPassword', rpcParams, false) as Promise<void>
    }

    _setShareLinkExpiry(token: string, expiry: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setShareLinkExpiry', rpcParams, false).then((data: any) => {
            const model = Object.assign(new ShareLink(), data)
            shareLinkStore.removeShareLink(token)
            shareLinkStore.addOrReplaceShareLink(model)
            return model.token
        })
    }

    getShareLinks(path: string | null, page: number | null, perPage: number | null, refresh?: boolean | number): SWR<ShareLink[], Page<string>> {
        //@ts-ignore
        const result: SWR<ShareLink[], Page<string>> = reactive(new SWR<ShareLink[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_getShareLinks' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getShareLinks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const shareLinks: ShareLink[] = []
                for (const id of data.items || []) {
                    const shareLink: ShareLink | undefined = shareLinkStore.state.shareLinks.get(id)
                    if (shareLink) {
                        shareLinks.push(shareLink)
                    }
                }
                result.data = shareLinks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getShareLinks(path, page, perPage).then((data: Page<ShareLink>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(shareLink => !!shareLink.token)?.map(shareLink => shareLink.token) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const shareLinks: ShareLink[] = []
            for (const id of cached.data.items || []) {
                const shareLink: ShareLink | undefined = shareLinkStore.state.shareLinks.get(id)
                if (shareLink) {
                    shareLinks.push(shareLink)
                }
            }
            result.data = shareLinks
        }
        return result
    }

    getShareLink(token: string): ShareLink | undefined {
        return shareLinkStore.state.shareLinks.get(token)
    }
}
