export default class Page<ItemType> {
  items!: ItemType[] | null;
  hasMore!: boolean | null;
  total!: number | null;
  nextId!: string | null;
  prevId!: string | null;

  constructor() {
    this.items = null;
    this.hasMore = null;
    this.total = null;
    this.nextId = null;
    this.prevId = null;
  }
}
