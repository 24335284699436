

import {Options, Vue} from "vue-class-component"
import DateOrTime from "@/model/common/carddav/DateOrTime"
import Calendar from "primevue/calendar"
import Button from "primevue/button"
import dayjs from "dayjs"
import AnimatedInput from "@/components/common/AnimatedInput.vue"

/*
This component just supports calendar (which is default) and text properties. PartialDate Support is missing...
 */

@Options({
  components: {AnimatedInput, Calendar, Button},
  //@ts-ignore
  props: {
    date: [DateOrTime, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    name: String,
    inline: {
      type: Boolean,
      default: false
    },
  },
  emits: [ 'delete']
})
export default class DateOrTimeField extends Vue {

  date!: DateOrTime;

  get formattedCalendarDate(): string {
    return dayjs(this.calendarDate).format("dddd, D. MMMM, YYYY")
  }

  get calendarDate(): Date {
    return new Date(this.date.calendar)
  }

  set calendarDate(date: Date) {
    this.date.calendar = date.toISOString()
  }

  emitDelete(): void {
    this.$emit('delete')
  }
}
