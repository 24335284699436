import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Email from '@/model/entry/Email';


export default class GeneratedEmailStore {

    state = reactive({
        emails: new Map<string, Email>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.emails) this.state.emails.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceEmail(newEmail: Email) {
        if (newEmail.originalId) {
            this.state.emails.set(newEmail.originalId, newEmail)
            
        }
    }

    addOrReplaceEmails(newEmails: Email[]) {
        for (const newEmail of newEmails) {
            this.addOrReplaceEmail(newEmail)
        }
    }

    replaceEmails(emails: Email[]) {
        const newEmails = new Map<string, Email>()
        for (const newEmail of emails) {
            if (newEmail.originalId) {
                newEmails.set(newEmail.originalId, newEmail)
                
            }
        }
        this.state.emails = newEmails
    }

    removeEmail(originalId: string | undefined | null) {
        if (originalId !== undefined && originalId !== null) {
            this.state.emails.delete(originalId)
        }
    }

    removeEmails(originalIds: (string | null)[] | undefined | null) {
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                this.removeEmail(originalId)
            }
        }
    }
}