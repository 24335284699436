
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import {groupServiceApi} from "@/api/GroupServiceApi"
import Group from "@/model/directory/Group"
import ResourceShare from "@/model/common/ResourceShare"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    resource: String,
    type: String,
    accessOptions: {
      type: Array,
      default: null
    }
  },
  components: {
    Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class ResourceShareGroupInput extends Vue {

  i18n: Language = useGettext()

  modelValue!: ResourceShare[]
  resource!: string
  type!: string
  accessOptions!: any[] | null

  sharedTo: ResourceShare[] = []
  newShare: string = ''

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  get availableGroups(): Group[] {
    return this.groups?.filter((group: Group) => {
      return !this.sharedTo.find((share: ResourceShare) => {
        return share.targetId?.toLowerCase() == group.cn?.toLowerCase()
      })
    })
  }

  get groups(): Group[] {
    return (groupServiceApi.getGroupsForCurrentUser().data || []).map((group: Group) => {
      const clonedGroup = Object.assign(new Group(), group)
      clonedGroup.description = clonedGroup.description || clonedGroup.cn
      return clonedGroup
    })
  }

  addShare() {
    const targetId = this.newShare
    if (targetId === "" || !!this.sharedTo.find(g => g.targetId === targetId)) {
      return
    } else {
      const share: ResourceShare = new ResourceShare()
      share.resource = this.resource
      share.targetId = targetId
      share.type = this.type
      share.targetType = 'GROUP'
      if (this.tAccessOptions && this.tAccessOptions.length > 0) share.permission = this.tAccessOptions[0].id
      this.sharedTo.push(share)
      this.$emit('added', share)
      this.$emit('update:modelValue', this.sharedTo)
      this.newShare = ""
    }
  }

  findGroupByCN(cn: string): Group | undefined {
    return this.groups.find(group => group.cn?.toLowerCase() === cn.toLowerCase())
  }

  updateShare(share: ResourceShare) {
    this.$emit('updated', share)
  }

  removeShare(share: ResourceShare) {
    const removed: number = this.sharedTo.indexOf(share)
    if (removed >= 0) {
      const removedShare: ResourceShare = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedShare)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged() {
    this.sharedTo = this.modelValue
  }

  mounted() {
    this.onValueChanged()
  }
}
