<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-xl p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Email Filter Settings
          </translate>
        </h2>
        <h2>{{ virtualMailbox }}</h2>
        <p>
          <b><translate>What you can do here:</translate></b>&nbsp;<translate>
            Set filters for incoming mails to distribute, reject or sort mails into folders automatically on the server.
          </translate>
        </p>
        <p><b><translate>What you can't do here:</translate></b>&nbsp;<translate>Set Filters for your mobile phone or email client.</translate></p>
        <div v-if="loading || settingsLoading">
          <Skeleton class="mt-6" height="40px" style="width: 80%" />
        </div>
        <div v-else class="row mt-6 mb-5">
          <div class="col-auto">
            <Button
              :label="i18n.$gettext('Add New Filter')"
              icon="cil-plus"
              :disabled="mailBoxNotExists"
              class="p-button btn-raised mr-3"
              @click="createNewFilter"
            />
          </div>
        </div>
        <UserMailFilterEditor
          v-if="newMailFilter"
          :filter="newMailFilter"
          :file-options="fileOptions"
          create-mode
          class="p-4 d-flex my-1 card separator-bottom"
          @save="saveAll"
          @cancel="newMailFilter = null"
        />
        <div v-if="filters !== null">
          <UserMailFilterEditor
            v-for="filter in filters"
            :key="'mf' + filter.id"
            :filter="filter"
            :file-options="fileOptions"
            class="p-4 d-flex my-1 card separator-bottom"
            @save="saveAll"
            @delete="confirmDelete(filter, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import MailFilter from "@/model/settings/MailFilter"
import Button from "primevue/button"
import UserMailFilterEditor from "@/components/settings/UserMailFilterEditor.vue"
import Rule from "@/model/settings/Rule"
import {mailFilterServiceApi} from "@/api/MailFilterServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import RpcError from "@/api/RpcError"
import SWR from "@/api/SWR"
import EmailUtil from "@/util/EmailUtil"
import {useConfirm} from "primevue/useconfirm"
import Skeleton from "primevue/skeleton"
import {mailFilterStore} from "@/store/MailFilterStore"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import VirtualMailbox from "@/model/settings/VirtualMailbox"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"

@Options({
  //@ts-ignore
  props: {
  },
  components: {
    UserMailFilterEditor, Button, Skeleton
  }
})
export default class UserMailFilterSettings extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  virtualMailboxfileOptions: { label: string, value: string }[] = []
  mailBoxNotExists: boolean = false
  virtualMailbox: string = ""
  newMailFilter: MailFilter | null  = null
  loading: boolean = false
  settingsLoading:  boolean = false

  mounted() {
    this.virtualMailbox =  this.$route?.params?.hasOwnProperty("virtual_mailbox") ?
      this.$route.params["virtual_mailbox"] as string : ""
    if (!this.virtualMailbox) {
      this.settingsLoading = true
      void rpcClient.refreshSettings().then(() => {
        this.settingsLoading = false
      })
    }
  }

  get filters(): MailFilter[] {
    if (!this.virtualMailbox) {
      const swr: SWR<MailFilter[], number[]> = mailFilterServiceApi.getMailFilters()
      if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
        this.loading = true
        swr.call.promise.catch(() => {
          this.toast.error(this.i18n.$gettext("Error while fetching the Mail Rules"))
        })
        swr.call.promise.finally(() => {
          this.loading = false
        })
      }
      return swr.data || rpcClient.session.user?.settings?.mailFilters || []
    } else {
      const swr: SWR<VirtualMailbox[], string[]> = virtualMailboxServiceApi.getVirtualMailboxes()
      if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
        this.loading = true
        swr.call.promise.catch(() => {
          this.toast.error(this.i18n.$gettext("Error while fetching the Mail Rules"))
        })
        swr.call.promise.finally(() => {
          this.loading = false
        })
      }
      const virtualMailboxData: VirtualMailbox | null = swr.data?.find((vmb: VirtualMailbox) => vmb.address === this.virtualMailbox) || null
      if (!virtualMailboxData) {
        this.mailBoxNotExists = true
        this.toast.error(this.i18n.$gettext("Mailbox does not exist"))
      }
      this.convertFilesIntoOptions
      return virtualMailboxData?.mailFilters || []
    }
  }

  get fileOptions(): { label: string, value: string }[] {
    if (!this.virtualMailbox) {
      return this.convertFilesIntoOptions
    }
    else {
      return this.virtualMailboxfileOptions
    }
  }

  get convertFilesIntoOptions(): { label: string, value: string }[] {
    let folders: MailFolder[] = []
    this.foldersLoading = true
    if (!this.virtualMailbox) {
      const swr: SWR<MailFolder[], string[]> = mailFolderServiceApi.getFolders()
      this.foldersLoading = Boolean(swr.call?.loading && swr.call?.promise)
      if (swr.call?.promise) {
        swr.call.promise.finally(() => {
          this.foldersLoading = false
        })
      } else {
        this.foldersLoading = false
      }
      folders = swr.data || folders
    } else {
      virtualMailboxServiceApi._getFoldersFor(this.virtualMailbox).then((list: MailFolder[]) => {
        const result: { label: string, value: string }[] = []
        for (const folder of list) {
          this.fileIntoOptionFromFolder(folder, result)
        }
        this.virtualMailboxfileOptions = result
      }).finally(() => this.foldersLoading = false)
    }

    const result: { label: string, value: string }[] = []
    for (const folder of folders) {
      this.fileIntoOptionFromFolder(folder, result)
    }
    this.virtualMailboxfileOptions = result
    return result
  }

  fileIntoOptionFromFolder(folder: MailFolder, result: { label: string, value: string }[]): void {
    if (folder.fullName && folder.name) {
      const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
      if (mapping && mapping.name) {
        result.push({ label: mapping.name, value: folder.fullName })
      } else {
        result.push({ label: folder.name, value: folder.fullName })
      }
      if (folder.subFolders) {
        for (const subFolder of folder.subFolders) {
          this.fileIntoOptionFromFolder(subFolder, result)
        }
      }
    }
  }

  createNewFilter() {
    let newOne = new MailFilter()
    newOne.rules = []
    newOne.action = 'REDIRECT'
    newOne.target = ''
    let rule: Rule = new Rule()
    rule.prefix = 'AND'
    newOne.rules.push(rule)
    this.newMailFilter = newOne
  }

  saveAll(): void {
    const filters: MailFilter[] = [...this.filters]
    if (this.newMailFilter && EmailUtil.isValid(this.newMailFilter)) {
      filters.splice(0, 0, this.newMailFilter)
    }
    this.saveFilters(filters)
  }

  confirmDelete(filter: MailFilter, event: Event | undefined): void {
    this.confirm.require({
      //@ts-ignore
      target: event?.currentTarget || undefined,
      message: this.i18n.$gettext("Do you really want to delete this filter?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-exclamation',
      accept: () => {
        const filters: MailFilter[] = [...this.filters.filter(f => f !== filter)]
        this.saveFilters(filters)
      },
      reject: () => {}
    })
  }

  saveFilters(filters: MailFilter[]) {
    this.loading = true
    if (!this.virtualMailbox) {
      void mailFilterServiceApi._saveMailFilters(filters).then((newFilters: MailFilter[]) => {
        this.newMailFilter = null
        mailFilterStore.replaceMailFilters(newFilters)
        this.toast.success(this.i18n.$gettext("Saved mail filters."))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not save mail filters."))
      }).finally(() => {
        this.loading = false
      })
    } else {
      void virtualMailboxServiceApi._setMailfilters(this.virtualMailbox, filters).then((address: string) => {
        this.newMailFilter = null
        this.toast.success(this.i18n.$gettext("Saved mail filters."))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not save mail filters."))
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">

</style>
