

import {Options, Vue} from "vue-class-component"
import DataSource from "@/model/DataSource"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import Dropdown from "@/components/common/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast, {ToastAPI} from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import {Router, useRouter} from "vue-router"
import LoadingButton from "@/components/common/LoadingButton.vue"
import DataSourceInstance from "@/model/DataSourceInstance"
import InputText from "primevue/inputtext"
import ProgressBar from "primevue/progressbar"
import Skeleton from "primevue/skeleton"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import DataSourceInstanceForm from "@/components/settings/dataimportwizard/DataSourceInstanceForm.vue"

@Options({
  components: { LoadingButton, Dropdown, InputText, ProgressBar, Skeleton, DataSourceInstanceForm },
  //@ts-ignore
  props: {},
  emits: []
})
export default class NewSource extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()
  router: Router = useRouter()

  selectedDataSource: DataSource | null = null
  possibleSourceLoading: boolean = false
  possibleSources: DataSource[] = [] //We load these in the background, should be instant

  displayName: string = ""

  async loadData() {
    try {
      this.possibleSourceLoading = true
      this.possibleSources = (await dataSourceServiceApi._getAvailableDataSources()).filter(s => s.name !== 'usb')
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not fetch importable sources"))
    } finally {
      this.possibleSourceLoading = false
    }
  }

  mounted() {
    void this.loadData()
  }
}
