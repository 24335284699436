import Organizer from '@/model/common/caldav/Organizer';
import RecurrenceId from '@/model/common/caldav/RecurrenceId';
import RecurrenceRule from '@/model/common/caldav/RecurrenceRule';
import Duration from '@/model/common/caldav/Duration';
import Attachment from '@/model/common/caldav/Attachment';
import Attendee from '@/model/common/caldav/Attendee';
import RequestStatus from '@/model/common/caldav/RequestStatus';
import RelatedTo from '@/model/common/caldav/RelatedTo';
import Resources from '@/model/common/caldav/Resources';
import RecurrenceDates from '@/model/common/caldav/RecurrenceDates';
import Alarm from '@/model/common/caldav/Alarm';
import Image from '@/model/common/caldav/Image';
import Conference from '@/model/common/caldav/Conference';

export default class Task {
  originalId!: string | null;
  originalParentId!: string | null;
  uid!: string | null;
  syncTag!: string | null;
  timeZoneId!: string | null;
  stamp!: string | null;
  start!: string | null;
  due!: string | null;
  created!: string | null;
  lastModified!: string | null;
  classification!: string | null;
  description!: string | null;
  geo!: any | null;
  location!: string | null;
  priority!: string | null;
  status!: string | null;
  summary!: string | null;
  organizer!: Organizer | null;
  recurrenceId!: RecurrenceId | null;
  url!: string | null;
  recurrenceRule!: RecurrenceRule | null;
  duration!: Duration | null;
  sequence!: number | null;
  attachments!: Attachment[] | null;
  attendees!: Attendee[] | null;
  categories!: string[] | null;
  comments!: string[] | null;
  contacts!: string[] | null;
  exceptionDates!: string[] | null;
  requestStatus!: RequestStatus | null;
  relatedTo!: RelatedTo[] | null;
  resources!: Resources[] | null;
  recurrenceDates!: RecurrenceDates[] | null;
  alarms!: Alarm[] | null;
  percentCompleted!: number | null;
  completed!: string | null;
  exceptionRules!: RecurrenceRule[] | null;
  color!: string | null;
  images!: Image[] | null;
  conferences!: Conference[] | null;
  queryId!: string | null;
  score!: number | null;
  fileTokens!: string[] | null;

  constructor() {
    this.originalId = null;
    this.originalParentId = null;
    this.uid = null;
    this.syncTag = null;
    this.timeZoneId = null;
    this.stamp = null;
    this.start = null;
    this.due = null;
    this.created = null;
    this.lastModified = null;
    this.classification = null;
    this.description = null;
    this.geo = null;
    this.location = null;
    this.priority = null;
    this.status = null;
    this.summary = null;
    this.organizer = null;
    this.recurrenceId = null;
    this.url = null;
    this.recurrenceRule = null;
    this.duration = null;
    this.sequence = null;
    this.attachments = null;
    this.attendees = null;
    this.categories = null;
    this.comments = null;
    this.contacts = null;
    this.exceptionDates = null;
    this.requestStatus = null;
    this.relatedTo = null;
    this.resources = null;
    this.recurrenceDates = null;
    this.alarms = null;
    this.percentCompleted = null;
    this.completed = null;
    this.exceptionRules = null;
    this.color = null;
    this.images = null;
    this.conferences = null;
    this.queryId = null;
    this.score = null;
    this.fileTokens = null;
  }
}
