

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import RpcError from "@/api/RpcError"
import SWR from "@/api/SWR"
import {useConfirm} from "primevue/useconfirm"
import Skeleton from "primevue/skeleton"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import MailAlias from "@/model/settings/MailAlias"
import {mailAliasServiceApi} from "@/api/MailAliasServiceApi"
import MailDomain from "@/model/settings/MailDomain"
import {mailDomainServiceApi} from "@/api/MailDomainServiceApi"
import VirtualMailbox from "@/model/settings/VirtualMailbox"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import Dropdown from "primevue/dropdown"
import InputText from "primevue/inputtext"
import VirtualMailboxGridItem from "@/components/settings/virtualmailboxes/VirtualMailboxGridItem.vue"

@Options({
  //@ts-ignore
  props: {
  },
  components: {
    Button, Skeleton, Dropdown, InputText, VirtualMailboxGridItem
  }
})
export default class VirtualMailboxSettings extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  creating: boolean = false
  loadingVirtualMailboxes: boolean = false
  loadingUsers: boolean = false
  loadingMailAliases: boolean = false
  loadingMailDomains: boolean = false

  emailRegex: RegExp = new RegExp("^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*(\\.\\w{2,})+$")
  selectedDomain: string = ''
  newUsername: string = ''

  get newAddress(): string {
    return (this.newUsername + '@' + this.selectedDomain).toLowerCase()
  }

  get canCreate(): boolean {
    return Boolean(this.isValidAddress && this.selectedDomain && this.isMailboxAvailable())
  }

  get isValidAddress(): boolean {
    return this.emailRegex.test(this.newAddress)
  }

  isMailboxAvailable(): boolean {
    for (let user of this.users as User[]) {
      if (user.internEmail && user.internEmail === this.newAddress) {
        return false
      }
    }
    for (let vMailbox of this.virtualMailboxes as VirtualMailbox[]) {
      if (vMailbox.address && vMailbox.address === this.newAddress) {
        return false
      }
    }
    for (let alias of this.mailAliases as MailAlias[]) {
      if (alias.name && alias.name === this.newAddress) {
        return false
      }
    }
    return true
  }

  get users(): User[] {
    const swr: SWR<User[], string[]> = userServiceApi.getUsers()
    if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
      swr.call.promise.finally(() => {
        this.loadingUsers = false
      })
    }
    return swr.data || rpcClient.session.user?.settings?.mailFilters || []
  }

  get mailAliases(): MailAlias[] {
    const swr: SWR<MailAlias[], string[]> = mailAliasServiceApi.getMailAliases()
    if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
      swr.call.promise.finally(() => {
        this.loadingMailAliases = false
      })
    }
    return swr.data || rpcClient.session.user?.settings?.mailFilters || []
  }

  get domainOptions(): any[] {
    let options: any[] = []

    this.mailDomains?.forEach((domain: MailDomain) => {
      options.push({
        name: '@' + domain.name,
        value: domain.name
      })
    })
    if (options.length > 0) {
      this.selectedDomain = options[0].value
    }
    return options
  }

  get mailDomains(): MailDomain[] {
    const swr: SWR<MailDomain[], string[]> = mailDomainServiceApi.getMailDomains()
    if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
      swr.call.promise.finally(() => {
        this.loadingMailDomains = false
      })
    }
    return swr.data || rpcClient.session.user?.settings?.mailFilters || []
  }

  get virtualMailboxes(): VirtualMailbox[] {
    const swr: SWR<VirtualMailbox[], string[]> = virtualMailboxServiceApi.getVirtualMailboxes()
    if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
      swr.call.promise.finally(() => {
        this.loadingVirtualMailboxes = false
      })
    }
    return swr.data || rpcClient.session.user?.settings?.mailFilters || []
  }

  createMailbox() {
    if (!this.is)
      this.creating = true
    void virtualMailboxServiceApi._createVirtualMailbox(this.newAddress).then(() => {
      this.newUsername = ''
      this.toast.success(this.i18n.$gettext("Created new mailbox."))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not creat new mailbox."))
    }).finally(() => {
      this.creating = false
    })
  }
}
