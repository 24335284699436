

import {Options, Vue} from "vue-class-component"
import VueTagsInput, {createTags, TagAutocompleteItem, TagInputItem} from "@sipec/vue3-tags-input"
import {Watch} from "vue-property-decorator"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"

@Options({
  components: { VueTagsInput },
  //@ts-ignore
  props: {
    modelValue: Array,
    label: String,
    getAutocompleteItems: [Function, Object],
    forbiddenTags: {
        type: Array,
        default: []
    }
  },
  emits: ["update:modelValue", "input"]
})
export default class Tags extends Vue {

  toast = useToast()
  i18n: Language = useGettext()
  modelValue: string[] = []
  currentTag: string = ""
  allTags: TagInputItem[] = []
  forbiddenTags: string[] = []
  autocompleteItems: TagAutocompleteItem[] = []
  getAutocompleteItems: (currentTag: string) => string[] = () => { return [] }

  onTagsChanged(newTags: TagInputItem[]){
    //Check for forbidden values:
    //Set tags and emit new value to outside:
    this.allTags = newTags.filter((elem: TagInputItem) => {
      return !this.forbiddenTags.includes(elem.text)
    })
    if(this.allTags.length != newTags.length){ //Should be by only max. 1, but this is safe so there are not multiple toasts
      this.toast.error(this.i18n.$gettext("You cannot add this as item"))
    }
    //Emit only values:
    let result: string[] = []
    this.allTags.forEach((tag: TagInputItem) => {
      result.push(tag.text)
    })
    this.$emit("update:modelValue", result)
  }

  @Watch('currentTag')
  searchForAutocompleteOptions(): void {
    const strings = this.getAutocompleteItems(this.currentTag)
    const result: TagAutocompleteItem[] = []
    strings.forEach((item: string) => {
      result.push({text: item})
    })
    this.autocompleteItems = result
  }

  @Watch('modelValue')
  updateTagsFromExternal(){
    this.allTags = createTags(this.modelValue)
  }

  mounted(){
    this.updateTagsFromExternal()
  }

}
