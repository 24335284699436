<template>
  <div class="d-flex flex-column h-100">
    <div class=" d-flex flex-row m-2 flex-grow-1 card card-lite z-2" style="overflow: auto">
      <div class="flex-shrink-0 h-100 p-0 side-panel separator-right">
        <div class="p-3">
          <h1 class="mb-2 h5 d-inline">
            <translate>Search Details</translate>
          </h1>
          <div class="pt-4">
            <p class="h6 mb-1">
              <translate>Filter result type</translate>
            </p>
            <div class="p-field-checkbox">
              <Checkbox id="fileselect" v-model="allowedTypes" value="INODE" />
              <label for="fileselect"><translate>Files</translate></label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="chatselect" v-model="allowedTypes" value="CHAT_MESSAGE" />
              <label for="chatselect"><translate>Messages</translate></label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="emailselect" v-model="allowedTypes" value="EMAIL" />
              <label for="emailselect"><translate>Emails</translate></label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="contactselect" v-model="allowedTypes" value="CONTACT" />
              <label for="contactselect"><translate>Contacts</translate></label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="eventselect" v-model="allowedTypes" value="EVENT" />
              <label for="eventselect"><translate>Events</translate></label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox id="taskselect" v-model="allowedTypes" value="TASK" />
              <label for="taskselect"><translate>Tasks</translate></label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 h-100 p-3">
        <!--
        <span class="p-input-icon-left">
          <i class="cil-search" style="z-index: 10;"></i>
          <AutoComplete type="text" v-model="searchQuery" :placeholder="i18n.$gettext('Type and press enter to search')" input-class="w-100 input-padding-left-for-icon" scroll-height="400px" :suggestions="[]" @complete="" class="w-100" @keyup.enter="" @item-select=""/>
        </span>
        <br> -->
        <h1 class="mb-2 h5 d-inline">
          <translate>Search Results for</translate> "{{ queryFromParams }}"
        </h1>
        <!-- Content -->
        <InfiniteList
          class="w-100 h-100"
          :get-all-items="allItems"
          :get-item-page="searchPage"
          :page-size="pageSize"
          id-property="id"
        >
          <template #element="{ item }">
            <div class="search-item d-flex flex-row separator-bottom" @click="goToEntry(item)">
              <div class="p-2 flex-grow-0 flex-shrink-0" style="padding-top: 1rem !important;">
                <i :class="getClassForSearchEntryIcon(item)" style="font-size: 3rem; line-height: 100%" />
              </div>
              <div class="p-2">
                <a class="mb-1" href="#" @click.prevent.stop="goToEntry(item)">
                  {{ item.title }}
                </a>
                <p class="text-muted mb-1 " v-html="safeRenderDescription(item.description)" />
                <p class="mb-1">
                  <translate>Letzte Änderung</translate>: {{ item.mtime }}
                </p>
              </div>
            </div>
          </template>
        </InfiniteList>
      </div>
    </div>
    <teleport to="#menubarcontent">
      <SearchBar class="pt-2" />
    </teleport>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Query from "@/model/common/Query"
import {queryResultServiceApi} from "@/api/QueryResultServiceApi"
import QueryResult from "@/model/QueryResult"
import {queryServiceApi} from "@/api/QueryServiceApi"
import InfiniteList from "@/components/common/InfiniteList.vue"
import SWR from "@/api/SWR"
import insane from "insane"
import dayjs from "@/util/dayjs"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import {Watch} from "vue-property-decorator"
import Checkbox from "primevue/checkbox"
import Page from "@/model/Page"
import SearchBar from "@/components/common/SearchBar.vue"

const marked = require("marked")

const resultTypes: string[] = ['INODE', 'CHAT_MESSAGE', 'EMAIL', 'CONTACT', 'EVENT', 'TASK']

@Options({
  components: {
    AutoComplete, InfiniteList, Checkbox, SearchBar
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class SearchView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  allowedTypes: string[] = [...resultTypes]
  queryId = ""

  pageSize = 50

  mounted() {
    void this.$nextTick(() => {
      this.searchIfQueryChanges()
    })
  }

  getClassForSearchEntryIcon(qr: QueryResult): string {
    switch (qr.type) {
      case "CHAT_MESSAGE":
        return "cil-chat-square"
      case "INODE":
        return "cil-file"
      case "EMAIL":
        return "cil-mail"
      case "CONTACT":
        return "cil-user"
      case "EVENT":
        return "cil-calendar"
      case "TASK":
        return "cil-task"
      default:
        return "cil-find-in-page"
    }
  }

  get queryFromParams(): string | null {
    const queryParams = this.$route.query
    if (queryParams.hasOwnProperty("term")) {
      return queryParams["term"] as string
    } else {
      return null
    }
  }

  @Watch('$route.query')
  searchIfQueryChanges(): void {
    const queryParams = this.$route.query
    if (queryParams.hasOwnProperty("type")) {
      //This calls searchIfTypeChanges
      this.allowedTypes = [ queryParams["type"] as string ]
    } else {
      void this.$nextTick(() => {
        this.startSearch()
      })
    }
  }

  @Watch('allowedTypes')
  searchIfTypeChanges(): void {
    void this.$nextTick(() => {
      this.startSearch()
    })
  }

  get queryObject(): Query{
    const query = new Query()
    query.all = this.queryFromParams
    query.type = this.allowedTypes
    return query
  }

  startSearch() {
    queryServiceApi._startQuery(this.queryObject).then((queryId : string) => {
      this.queryId = queryId
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Could not start search'))
    })
  }

  get allItems(): QueryResult[] {
    return queryResultServiceApi.getQueryResults()
  }

  get searchPage(): ((pageIndex: number, pageSize: number) => SWR<QueryResult[], Page<string>>) | null {
    if (!this.queryId || this.queryId === "") return null

    return (pageIndex: number, pageSize: number) => {
      return queryResultServiceApi.query(this.queryId, pageIndex, pageSize, '')
    }
  }

  safeRenderDescription(text: string | null): string {
    return text ? insane(marked.parseInline(text)) : ''
  }

  getFullDateTime(isoDate: string): string {
    return dayjs(isoDate).format("dddd, D. MMMM, YYYY HH:mm")
  }

  goToEntry(res: QueryResult): void {
    let link = "/"
    switch (res.type) {
      case "INODE":
        const parts = res.backendId?.split("/") || []
        const path = parts.slice(0, parts.length - 1).join("/")
        const filename = parts[parts.length - 1]
        link += "files" +  path
        void this.$router.push({path: link, query: {highlight: filename}})
        break
      case "CHAT_MESSAGE":
        //TODO highlight the message
        void this.$router.push({path: "/chat/" +  res.backendId}) //Setting backend to containerId so the frontend can open the chat containing the message
        break
      case "EMAIL":
        link += "mail/" +  res.backendId
        void this.$router.push({path: link})
        break
      case "CONTACT":
        link += "contacts/" +  res.backendId
        void this.$router.push({path: link})
        break
      case "EVENT":
        link += "calendar/" +  res.backendId
        void this.$router.push({path: link})
        break
      case "TASK":
        link += "tasks/" +  res.backendId
        void this.$router.push({path: link})
        break
    }
  }

}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.search-item em {
  color: $uniki_primary;
  font-style: normal;
}

.search-item:hover {
  background-color: $uniki_secondary;
  cursor: pointer;
}


</style>
