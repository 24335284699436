import GeneratedEmailStore from "./generated/GeneratedEmailStore"
import Email from "@/model/entry/Email"

class EmailStore extends GeneratedEmailStore {

    addOrReplaceEmail(newEmail: Email) {
        if (newEmail.originalId) {
            const oldEmail: Email | undefined = this.state.emails.get(newEmail.originalId)
            if (oldEmail && !newEmail.userName) {
                newEmail.userName = oldEmail.userName
            }
            if (oldEmail && !newEmail.toUserName) {
                newEmail.toUserName = oldEmail.toUserName
            }
            if (oldEmail && !oldEmail.isPreview && newEmail.isPreview) { //Only update flags
                oldEmail.seen = newEmail.seen
                oldEmail.systemFlags = newEmail.systemFlags
                oldEmail.userFlags = newEmail.userFlags
                oldEmail.userName = newEmail.userName
                oldEmail.toUserName = newEmail.toUserName
            } else {
                this.state.emails.set(newEmail.originalId, newEmail)
            }
        }
    }
}

export const emailStore = new EmailStore()
