import {createGettext} from "@jshmrtn/vue3-gettext"
import {getNormalizedBrowserLanguage} from "@/util/language"
import translations from "@/locale/generated/translations.json"
import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"
import { english } from "flatpickr/dist/l10n/default.js"

const flatPickrAvailableLanguages = {
     en_US: english,
     de_DE: German
}

//@ts-ignore
flatpickr.localize(flatPickrAvailableLanguages[getNormalizedBrowserLanguage()])


const i18n = createGettext({
    availableLanguages: {
        en_US: 'American English',
        de_DE: 'Deutsch'
    },
    silent: true,
    defaultLanguage: getNormalizedBrowserLanguage(),
    translations
})
export default i18n

export const localeForPrimeVue: any = {
    startsWith: i18n.$gettext('Starts with'),
    contains: i18n.$gettext('Contains'),
    notContains: i18n.$gettext('Not contains'),
    endsWith: i18n.$gettext('Ends with'),
    equals: i18n.$gettext('Equals'),
    notEquals: i18n.$gettext('Not equals'),
    noFilter: i18n.$gettext('No Filter'),
    lt: i18n.$gettext('Less than'),
    lte: i18n.$gettext('Less than or equal to'),
    gt: i18n.$gettext('Greater than'),
    gte: i18n.$gettext('Greater than or equal to'),
    dateIs: i18n.$gettext('Date is'),
    dateIsNot: i18n.$gettext('Date is not'),
    dateBefore: i18n.$gettext('Date is before'),
    dateAfter: i18n.$gettext('Date is after'),
    clear: i18n.$gettext('Clear'),
    apply: i18n.$gettext('Apply'),
    matchAll: i18n.$gettext('Match All'),
    matchAny: i18n.$gettext('Match Any'),
    addRule: i18n.$gettext('Add Rule'),
    removeRule: i18n.$gettext('Remove Rule'),
    accept: i18n.$gettext('Yes'),
    reject: i18n.$gettext('No'),
    choose: i18n.$gettext('Choose'),
    upload: i18n.$gettext('Upload'),
    cancel: i18n.$pgettext('Abbrechen', 'Cancel'),
    dayNames: [
        i18n.$gettext("Sunday"),
        i18n.$gettext("Monday"),
        i18n.$gettext("Tuesday"),
        i18n.$gettext("Wednesday"),
        i18n.$gettext("Thursday"),
        i18n.$gettext("Friday"),
        i18n.$gettext("Saturday")
    ],
    dayNamesShort: [
        i18n.$gettext("Sun"),
        i18n.$gettext("Mon"),
        i18n.$gettext("Tue"),
        i18n.$gettext("Wed"),
        i18n.$gettext("Thu"),
        i18n.$gettext("Fri"),
        i18n.$gettext("Sat")
    ],
    dayNamesMin: [
        i18n.$gettext("Sun"),
        i18n.$gettext("Mon"),
        i18n.$gettext("Tue"),
        i18n.$gettext("Wed"),
        i18n.$gettext("Thu"),
        i18n.$gettext("Fri"),
        i18n.$gettext("Sat")
    ],
    monthNames: [
        i18n.$gettext("January"),
        i18n.$gettext("February"),
        i18n.$gettext("March"),
        i18n.$gettext("April"),
        i18n.$gettext("May"),
        i18n.$gettext("June"),
        i18n.$gettext("July"),
        i18n.$gettext("August"),
        i18n.$gettext("September"),
        i18n.$gettext("October"),
        i18n.$gettext("November"),
        i18n.$gettext("December")
    ],
    monthNamesShort: [
        i18n.$gettext("Jan"),
        i18n.$gettext("Feb"),
        i18n.$gettext("Mar"),
        i18n.$gettext("Apr"),
        i18n.$gettext("May"),
        i18n.$gettext("Jun"),
        i18n.$gettext("Jul"),
        i18n.$gettext("Aug"),
        i18n.$gettext("Sep"),
        i18n.$gettext("Oct"),
        i18n.$gettext("Nov"),
        i18n.$gettext("Dec")
    ],
    today: i18n.$gettext('Today'),
    weekHeader: i18n.$gettext('Week'),
    firstDayOfWeek: 1,
    dateFormat:'dd.mm.yy',
    weak: i18n.$gettext('Weak'),
    medium: i18n.$gettext('Medium'),
    strong: i18n.$gettext('Strong'),
    passwordPrompt: i18n.$gettext('Enter a password'),
    emptyFilterMessage: i18n.$gettext('No results found'),
    emptyMessage: i18n.$gettext('No available options')
}
