import GeneratedMessageStore from "./generated/GeneratedMessageStore"
import Message from "@/model/entry/Message"
import {reactive} from "@vue/reactivity"

class MessageStore extends GeneratedMessageStore {

    tempMessagesByChannel: Map<string, Map<string, Message>> = reactive(new Map<string, Map<string, Message>>())

    getTempMessagesInChannel(channelId: string) {
        let messagesInChannel: Map<string, Message> | undefined = this.tempMessagesByChannel.get(channelId)
        if (!messagesInChannel) {
            messagesInChannel = reactive(new Map<string, Message>())
            this.tempMessagesByChannel.set(channelId, messagesInChannel)
        }
        return messagesInChannel
    }

    addTempMessage(newMessage: Message) {
        if (newMessage.channelId && newMessage.id) {
            this.addOrReplaceMessage(newMessage)
            const messagesInChannel: Map<string, Message> = this.getTempMessagesInChannel(newMessage.channelId)
            messagesInChannel.set(newMessage.id, newMessage)
        }
    }

    removeTempMessage(newMessage: Message) {
        if (newMessage.channelId && newMessage.id) {
            this.removeMessage(newMessage.id)
            const messagesInChannel: Map<string, Message> = this.getTempMessagesInChannel(newMessage.channelId)
            messagesInChannel.delete(newMessage.id)
        }
    }

    addOrReplaceMessage(newMessage: Message) {
        console.dir(newMessage)
        if (newMessage.channelId && newMessage.id) {
            const messagesInChannel: Map<string, Message> = this.getTempMessagesInChannel(newMessage.channelId)
            const tempMessage: Message | undefined = [...messagesInChannel.values()].find(m => m.text === newMessage.text)
            if (newMessage && tempMessage?.id && newMessage !== tempMessage) {
                this.removeMessage(tempMessage.id)
                messagesInChannel.delete(tempMessage.id)
            }
        }
        super.addOrReplaceMessage(newMessage)
    }

}

export const messageStore = new MessageStore()
