export default class Recaptcha {

    static loadIntoSite(){
        if(!window.hasOwnProperty("grecaptcha")){
            //Load the script:
            const tag = document.createElement("script")
            tag.src = "https://www.google.com/recaptcha/api.js"
            document.getElementsByTagName("head")[0].appendChild(tag)
        }
    }
}
