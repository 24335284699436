import {reactive} from '@vue/reactivity'
import {rpcClient} from "@/api/WebsocketClient"

export class CachedImage {

    constructor(url: string) {
        this.url = url
    }

    readonly url: string
    cached = ""
    loaded = false
    loading = false
    lastError: number = 0

    get error(): boolean {
        return !!this.lastError
    }
}

class ImageLoadingService {

    images = new Map<string, CachedImage>()

    private doImageRequest(url: string) {
        const currentObject = this.safeGetCachedImageInstance(url)
        if (currentObject.loading) {
            return
        } else {
            currentObject.loading = true
        }
        //Prepare the XHR2:
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        //Authenticate on own origin:
        const me = window.location.origin
        const target = new URL(url).origin
        if (me == target) {
            const token = rpcClient.session.token
            if (token) {
                xhr.setRequestHeader("X-Auth-Token", token)
            }
        }

        xhr.onload = () => {
            const response: Blob = xhr.response

            const currentObject = this.safeGetCachedImageInstance(url)
            const oldUrl: string = currentObject.cached

            currentObject.cached = URL.createObjectURL(response)
            currentObject.loaded = true
            currentObject.loading = false

            //We need content and okay. so only 200 is okay
            if (response.size === 0 || xhr.status !== 200) {
                currentObject.lastError = new Date().getTime()
            }

            //Cleanup if needed:
            if (oldUrl && oldUrl !== "") URL.revokeObjectURL(oldUrl)
        }

        //Send the request:
        xhr.send()
    }

    private safeGetCachedImageInstance(url: string): CachedImage {
        if (this.images.has(url)) {
            return this.images.get(url) as CachedImage
        } else {
            const newOne = reactive(new CachedImage(url))
            this.images.set(url, newOne)
            return newOne
        }
    }

    private checkUrl(url: string) {
        return url.startsWith("/") ? window.location.origin + url : url
    }

    refreshImage(img: CachedImage) {
        this.refreshUrl(img.url)
    }

    refreshUrl(url: string): void {
        url = this.checkUrl(url)
        this.doImageRequest(url)
    }

    getCachedImage(url: string, refresh = false): CachedImage {
        url = this.checkUrl(url)
        const image: CachedImage | undefined = this.images.get(url)
        if (refresh || !image || (image.error && (image.lastError < (new Date().getTime() - 10000)))) {
            this.refreshUrl(url)
        }
        return this.safeGetCachedImageInstance(url)
    }

    evictCachedImage(img: CachedImage): void{
        this.evictUrl(img.url)
    }

    evictUrl(url: string): void{
        url = this.checkUrl(url)
        const img = this.images.get(url)
        if (!img) return
        URL.revokeObjectURL(img.cached)
        this.images.delete(url)
    }
}

export const imageLoadingService: ImageLoadingService = new ImageLoadingService()
