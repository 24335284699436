

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import Dialog from "primevue/dialog"
import Skeleton from "primevue/skeleton"
import useToast from "@/util/toasts"
import ShareLink from "@/model/common/ShareLink"
import {shareLinkServiceApi} from "@/api/ShareLinkServiceApi"
import InfiniteList from "@/components/common/InfiniteList.vue"
import ChatMessage from "@/components/chat/subcomponents/ChatMessage.vue"
import SWR from "@/api/SWR"
import Page from "@/model/Page"
import {shareLinkStore} from "@/store/ShareLinkStore"
import {useConfirm} from "primevue/useconfirm"
import RpcError from "@/api/RpcError"
import dayjs from "dayjs"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import DatePicker from "@/components/common/DatePicker.vue"
import UploadLink from "@/model/common/UploadLink"
import {uploadLinkServiceApi} from "@/api/UploadLinkServiceApi"
import {uploadLinkStore} from "@/store/UploadLinkStore"
import Dropdown from "@/components/common/Dropdown.vue"

@Options({
  components: {
    AnimatedInput, ChatMessage, InfiniteList, Button, Dialog, Skeleton, DatePicker, Dropdown
  },
  //@ts-ignore
  props: {},
  emits: [],
  name: "ShareLinkAdministration"
})
export default class GlobalSignatureSettings extends Vue {

  toast = useToast()
  i18n: Language = useGettext()
  confirm = useConfirm()

  pageSize: number = 100

  shareLinkToEdit: ShareLink | UploadLink | null = null
  newPassword: string | null = null
  newExpiry: Date | null = null
  showShareLinkPasswordModal: boolean = false
  showShareLinkExpiryModal: boolean = false

  settingsLoading:  boolean = false
  linkType: string = 'DOWNLOAD'
  linkTypeOptions = [
    {
      value: 'DOWNLOAD',
      label: this.i18n.$gettext('Download-Links')
    }, {
      value: 'UPLOAD',
      label: this.i18n.$gettext('Upload-Links')
    }
  ]

  mounted() {
    this.settingsLoading = true
  }

  getAllItems(): (ShareLink | UploadLink)[] {
    if (this.linkType === 'DOWNLOAD') {
      return [...shareLinkStore.state.shareLinks.values()]
    } else {
      return [...uploadLinkStore.state.uploadLinks.values()]
    }
  }

  getItemPage(pageIndex: number, pageSize: number): SWR<ShareLink[], Page<string>> | SWR<UploadLink[], Page<string>> {
    if (this.linkType === 'DOWNLOAD') {
      return shareLinkServiceApi.getShareLinks(null, pageIndex, pageSize)
    } else {
      return uploadLinkServiceApi.getUploadLinks(null, pageIndex, pageSize)
    }
  }

  openShareLinkPasswordModal(shareLink: ShareLink | UploadLink){
    this.shareLinkToEdit = shareLink
    this.newPassword = ""
    this.showShareLinkPasswordModal = true
  }

  openShareLinkExpiryModal(shareLink: ShareLink | UploadLink){
    this.shareLinkToEdit = shareLink
    this.newExpiry = shareLink.expireDate ? dayjs(shareLink.expireDate).toDate() : null
    this.showShareLinkExpiryModal = true
  }

  getDateString(expireDate: string): string {
    if (expireDate) try {
      return dayjs(expireDate).format("DD.MM.YYYY")
    } catch (e) {}
    return ''
  }

  copyShareLink(link: string | null | undefined) {
    if (link) {
      const el = document.createElement('textarea')
      el.value = link
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    }
  }

  changeShareLinkPassword(): Promise<void> {
    if (!this.shareLinkToEdit?.token) {
      return Promise.reject<void>()
    }
    if (this.linkType === 'DOWNLOAD') {
      return shareLinkServiceApi._setShareLinkPassword(this.shareLinkToEdit.token, this.newPassword || '').then(() => {
        this.shareLinkToEdit = null
        this.newPassword = null
        this.showShareLinkPasswordModal = false
        this.toast.success(this.i18n.$gettext('Link password changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link password.', e.message))
      })
    } else {
      return uploadLinkServiceApi._setUploadLinkPassword(this.shareLinkToEdit.token, this.newPassword || '').then(() => {
        this.shareLinkToEdit = null
        this.newPassword = null
        this.showShareLinkPasswordModal = false
        this.toast.success(this.i18n.$gettext('Link password changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link password.', e.message))
      })
    }
  }

  changeShareLinkExpiry(): Promise<void> {
    if (!this.shareLinkToEdit?.token) {
      return Promise.reject<void>()
    }
    if (this.linkType === 'DOWNLOAD') {
      return shareLinkServiceApi._setShareLinkExpiry(this.shareLinkToEdit.token, this.newExpiry?.toISOString() || '').then(() => {
        this.shareLinkToEdit = null
        this.newExpiry = null
        this.showShareLinkExpiryModal = false
        this.toast.success(this.i18n.$gettext('Link expiry changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link expiry.', e.message))
      })
    } else {
      return uploadLinkServiceApi._setUploadLinkExpiry(this.shareLinkToEdit.token, this.newExpiry?.toISOString() || '').then(() => {
        this.shareLinkToEdit = null
        this.newExpiry = null
        this.showShareLinkExpiryModal = false
        this.toast.success(this.i18n.$gettext('Link expiry changed.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Failed to change link expiry.', e.message))
      })
    }
  }

  deleteShareLink(shareLink: ShareLink): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.confirm.require({
        message: this.i18n.$gettext('Do you really want to delete this share link?'),
        header: this.i18n.$gettext('Confirmation'),
        icon: 'cil-warning',
        accept: () => {
          if (shareLink.token) {
            if (this.linkType === 'DOWNLOAD') {
              resolve(shareLinkServiceApi._deleteShareLink(shareLink.token).then(() => {
                this.toast.success(this.i18n.$gettext('Link deleted.'))
              }).catch((e: RpcError) => {
                this.toast.error(this.i18n.$gettext('Failed to delete link.', e.message))
              }))
            } else {
              resolve(uploadLinkServiceApi._deleteUploadLink(shareLink.token).then(() => {
                this.toast.success(this.i18n.$gettext('Link deleted.'))
              }).catch((e: RpcError) => {
                this.toast.error(this.i18n.$gettext('Failed to delete link.', e.message))
              }))
            }
          } else {
            reject()
          }
        },
        reject: () => {
          reject()
        }
      })
    })
  }
}
