import GeneratedMessageServiceApi from "./generated/GeneratedMessageServiceApi"
import {reactive} from "@vue/reactivity"
import {messageStore} from "@/store/MessageStore"
import Message from "@/model/entry/Message"
import i18n from "@/util/i18n"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import {getWindowNotificationPermission} from "@/util/DesktopNotificationUtil"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import {isViewingChatChannel, isViewingMailFolder} from "@/router"

class MessageServiceApi extends GeneratedMessageServiceApi {

    state: {total: number | null} = reactive({
        total: null
    })

    lastSeenMessages: Map<string, Date> //Maps channelId to last message

    constructor() {
        super()
        this.lastSeenMessages = new Map()
    }

    initNotificationWatcher(): void {
        rpcClient.registerHandlerMethod("notify_message", (msg: Message | null) => {
            if (msg) {
                this.toastNewMessage(msg)
                if (msg.channelId) {
                    channelServiceApi.getChannel(msg.channelId, true)
                }
            }
        })
    }

    resetState(): void {
        messageStore.state.messages = new Map<string, Message>()
    }

    toastNewMessage(msg: Message, title: string = "") {
        if (msg.channelId && isViewingChatChannel(msg.channelId)) return
        const toast = useToast()
        if(title === "") {
            title = i18n.$gettext("New Chat message from") + " " + msg.userId
        }
        const ellipsis = (msg.text?.length || 0) > 25
        const preview = msg.text?.substring(0, 25) + (ellipsis ? "..." : "")
        if (getWindowNotificationPermission() === "granted") {
            this.createNativeNotificationWithTimeout(title, preview, 10000)
        } else {
            toast.info(title)
        }
    }

    createNativeNotificationWithTimeout(header: string, body: string, timeout: number): void {
        const n: Notification = new Notification(header, {body, icon: "/images/elly_logo.png"})
        n.onclick = function(){
            window.focus()
            n.close()
        }
        window.setTimeout(() => {
            n.close()
        }, timeout)

    }

}

export const messageServiceApi = new MessageServiceApi()
