import GeneratedVirtualMailboxServiceApi from "./generated/GeneratedVirtualMailboxServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import VirtualMailbox from "@/model/settings/VirtualMailbox"
import {virtualMailboxStore} from "@/store/VirtualMailboxStore"

class VirtualMailboxServiceApi extends GeneratedVirtualMailboxServiceApi {

    generateVirtualMailboxPassword(address: string, accessDescription: string): Promise<string> {
        const rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addVirtualMailboxPassword', rpcParams).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            const password = model.addedPassword
            model.addedPassword = ''
            return password
        })
    }

}

export const virtualMailboxServiceApi = new VirtualMailboxServiceApi()
