import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import ToAddress from '@/model/settings/ToAddress';
import Page from '@/model/Page';
import { toAddressStore } from '@/store/ToAddressStore';

export default class GeneratedToAddressesApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getToAddresses(): Promise<ToAddress[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getToAddresses', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const toAddresss: ToAddress[] = data.map(toAddress => Object.assign(new ToAddress(), toAddress))
                toAddressStore.replaceToAddresss(toAddresss)
                return toAddresss
            } else return Promise.reject()
        })
    }


    getToAddresses(refresh?: boolean | number): SWR<ToAddress[], string[]> {
        //@ts-ignore
        const result: SWR<ToAddress[], string[]> = reactive(new SWR<ToAddress[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getToAddresses' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getToAddresses[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const toAddresss: ToAddress[] = []
                for (const id of data) {
                    const toAddress: ToAddress | undefined = toAddressStore.state.toAddresss.get(id)
                    if (toAddress) {
                        toAddresss.push(toAddress)
                    }
                }
                result.data = toAddresss
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getToAddresses().then((data: ToAddress[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(toAddress => toAddress.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let toAddresss: ToAddress[] = [...toAddressStore.state.toAddresss.values()]
        
        
        
        result.data = toAddresss
        return result
    }

    getToAddress(id: string): ToAddress | undefined {
        return toAddressStore.state.toAddresss.get(id)
    }
}
