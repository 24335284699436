export default class StructuredName {
  family!: string | null;
  given!: string | null;
  additional!: string[] | null;
  prefixes!: string[] | null;
  suffixes!: string[] | null;
  sortAs!: string[] | null;
  language!: string | null;
  alternativeId!: string | null;

  constructor() {
    this.family = null;
    this.given = null;
    this.additional = null;
    this.prefixes = null;
    this.suffixes = null;
    this.sortAs = null;
    this.language = null;
    this.alternativeId = null;
  }
}
