export default class Rule {
  prefix!: string | null;
  field!: string | null;
  operator!: string | null;
  name!: string | null;
  value!: string | null;

  constructor() {
    this.prefix = null;
    this.field = null;
    this.operator = null;
    this.name = null;
    this.value = null;
  }
}
