export default function fileSizeString(size: number): string {
    if (size < 1000) {
        return `${size} Bytes`
    }
    if (size < 1000000) {
        return `${Math.round(size / 1000)} KB`
    }
    if( size < 1000000000) {
        return `${Math.round(size / 1000000)} MB`
    }
    return `${Math.round(size / 1000000000)} GB`
}
