export default class ShareLink {
  token!: string | null;
  userName!: string | null;
  ctime!: string | null;
  expireDate!: string | null;
  viewCnt!: number | null;
  link!: string | null;
  name!: string | null;
  path!: string | null;
  isDir!: boolean | null;
  isExpired!: boolean | null;
  repoName!: string | null;
  creatorName!: string | null;
  creatorEmail!: string | null;
  creatorContactEmail!: string | null;
  canPreview!: boolean | null;
  canEdit!: boolean | null;
  canDownload!: boolean | null;
  internal!: boolean | null;

  constructor() {
    this.token = null;
    this.userName = null;
    this.ctime = null;
    this.expireDate = null;
    this.viewCnt = null;
    this.link = null;
    this.name = null;
    this.path = null;
    this.isDir = null;
    this.isExpired = null;
    this.repoName = null;
    this.creatorName = null;
    this.creatorEmail = null;
    this.creatorContactEmail = null;
    this.canPreview = null;
    this.canEdit = null;
    this.canDownload = null;
    this.internal = null;
  }
}
