import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import User from '@/model/User';


export default class GeneratedUserStore {

    state: {
        users: Map<string, User>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let users: Map<string, User> | null = null
        try {
            const persistedState = localStorage.getItem('UserStore')
            if (persistedState) {
                users = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            users: users || new Map<string, User>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('UserStore', JSON.stringify(Array.from(this.state.users)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.users) this.state.users.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('UserStore')
    }

    addOrReplaceUser(newUser: User, persist: boolean = true): true | undefined {
        if (newUser.userName) {
            this.state.users.set(newUser.userName, newUser)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceUsers(newUsers: User[]) {
        let persist = false
        for (const newUser of newUsers) {
            const changed = !!this.addOrReplaceUser(newUser, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceUsers(users: User[]) {
        const newUsers = new Map<string, User>()
        for (const newUser of users) {
            if (newUser.userName) {
                newUsers.set(newUser.userName, newUser)
                
            }
        }
        this.state.users = newUsers
        this.persistState()
    }

    removeUser(userName: string | undefined | null, persist: boolean = true): true | undefined {
        if (userName !== undefined && userName !== null) {
            if (this.state.users.delete(userName)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeUsers(userNames: (string | null)[] | undefined | null) {
        let persist = false
        if (userNames !== undefined && userNames !== null) {
            for (const userName of userNames) {
                const changed = !!this.removeUser(userName, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}