<template>
  <div class="row">
    <div class="col">
      <p class="lead mb-0">
        <span v-html="job.displayName" /> <span class="badge ml-2" :class="getJobStatusStyles">{{ getJobStatusTranslation }}</span>
      </p>
      <p class="text-muted mb-0">
        {{ (getDataSourceInstanceForJob?.displayName || i18n.$gettext("Unknown type")) }}, <translate>Created at:</translate> {{ formatCreatedDate }}
      </p>
      <p v-if="job.progress?.state === 'RUNNING'" v-html="getStatsLineForJob" class="mb-0" />
      <p v-if="[ 'FAILED', 'ERRORS' ].includes(job.progress?.state)" class="mb-0">
        {{ job.lastRunMessage || i18n.$gettext("No error message available. Please contact Support.") }}
      </p>
    </div>
    <div class="col-auto d-flex align-items-center">
      <Button
          v-if="canStart"
          v-tooltip="i18n.$gettext('Start import')"
          icon="cil-media-play"
          class="p-button p-button-success"
          @click="startJob"
      />
      <Button
          v-if="canStop"
          v-tooltip="i18n.$gettext('Stop')"
          icon="cil-media-stop-circle"
          class="p-button p-button-secondary"
          @click="confirmJobStop"
      />
      <Button
          v-if="job.progress?.state !== 'RUNNING'"
          v-tooltip="i18n.$gettext('Delete')"
          icon="cil-trash"
          class="p-button p-button-danger ml-2"
          @click="confirmJobDeletion"
      />
      <Button
          v-if="job.logFile"
          v-tooltip="i18n.$gettext('Show Log')"
          icon="cil-short-text"
          class="p-button p-button-secondary ml-2"
          @click="showLog"
      />
    </div>
    <Dialog
        :header="i18n.$gettext('Data Import Log')"
        v-model:visible="showLogDialog"
        :draggable="false"
        :close-on-escape="false"
        :closable="true"
        :modal="true"
        @hide="logText = null">
      <div class="h-100 container" style="overflow: auto">
        <pre>{{ logText }}</pre>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast, {ToastAPI} from "@/util/toasts"

import {useConfirm} from "primevue/useconfirm"
import Button from "primevue/button"
import DataSourceInstance from "@/model/DataSourceInstance"
import DataImportJob from "@/model/DataImportJob"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import Skeleton from "primevue/skeleton"
import {Router, useRouter} from "vue-router"
import dayjs from "@/util/dayjs"
import RpcError from "@/api/RpcError"
import ScrollPanel from "primevue/scrollpanel"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import Checkbox from "primevue/checkbox"
import DataImportUtil from "@/util/DataImportUtil"
import DataImportWizard from "@/components/settings/dataimportwizard/DataImportWizard.vue"
import Dialog from "primevue/dialog"

@Options({
  components: { Button, Skeleton, ScrollPanel, Dialog, Checkbox, DataImportWizard },
  //@ts-ignore
  props: {
    job: [ DataImportJob, Object ]
  },
  emits: []
})
export default class DataImportJobListItem extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()
  router: Router = useRouter()

  job!: DataImportJob

  logText: string | null = null

  get showLogDialog() {
    return this.logText != null
  }

  set showLogDialog(show: boolean) {
    if (!show) this.logText = null
  }

  get getDataSourceInstanceForJob(): DataSourceInstance | null {
    let result: DataSourceInstance | null = null
    dataSourceServiceApi.getDataSourceInstances().data?.forEach((source: DataSourceInstance) => {
      if (source.id == this.job.dataSourceInstanceId) {
        result = source
      }
    })
    return result
  }

  get formatCreatedDate(): string {
    if (this.job?.created) {
      return dayjs(this.job.created).format("HH:mm DD.MM.YYYY")
    } else {
      return this.i18n.$gettext("Unknown creation date")
    }
  }

  get getStatsLineForJob(): string {
    return this.job.progress ? DataImportUtil.getStatsLineForJob(this.job.progress, this.i18n) : ''
  }

  get getJobStatusTranslation(): string{
    return DataImportUtil.getJobStatusTranslation(this.job, this.i18n)
  }

  get getJobStatusStyles() {
    return DataImportUtil.getJobStatusStyles(this.job)
  }

  get canStart() {
    return Boolean(this.job.progress?.state !== "RUNNING" && this.job.canStart)
  }

  async startJob() {
    if (this.job?.id) {
      try {
        await dataImportServiceApi._startDataImportJob(this.job.id)
      } catch (e) {
        this.toast.error(this.i18n.$gettext("Starting job failed"))
      }
    }
  }

  get canStop(): boolean {
    return Boolean(this.job.progress?.state === 'RUNNING' && this.job.canStop)
  }

  confirmJobStop() {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to stop this job?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.job?.id) {
          dataImportServiceApi._stopDataImportJob(this.job.id).then(() => {
            this.toast.success(this.i18n.$gettext("Job stopped"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Job could not be stopped"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  confirmJobDeletion() {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this job?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.job?.id) {
          dataImportServiceApi._removeDataImportJob(this.job.id).then(() => {
            this.toast.success(this.i18n.$gettext("Job deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Job could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  showLog() {
    if (this.job?.id) dataImportServiceApi._getDataImportLog(this.job.id).then((log: string) => {
      this.logText = log
    })
  }
}
</script>
