import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import MailFolder from '@/model/directory/MailFolder';


export default class GeneratedMailFolderStore {

    state: {
        mailFolders: Map<string, MailFolder>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let mailFolders: Map<string, MailFolder> | null = null
        try {
            const persistedState = localStorage.getItem('MailFolderStore')
            if (persistedState) {
                mailFolders = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            mailFolders: mailFolders || new Map<string, MailFolder>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('MailFolderStore', JSON.stringify(Array.from(this.state.mailFolders)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.mailFolders) this.state.mailFolders.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('MailFolderStore')
    }

    addOrReplaceMailFolder(newMailFolder: MailFolder, persist: boolean = true): true | undefined {
        if (newMailFolder.originalId) {
            this.state.mailFolders.set(newMailFolder.originalId, newMailFolder)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceMailFolders(newMailFolders: MailFolder[]) {
        let persist = false
        for (const newMailFolder of newMailFolders) {
            const changed = !!this.addOrReplaceMailFolder(newMailFolder, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceMailFolders(mailFolders: MailFolder[]) {
        const newMailFolders = new Map<string, MailFolder>()
        for (const newMailFolder of mailFolders) {
            if (newMailFolder.originalId) {
                newMailFolders.set(newMailFolder.originalId, newMailFolder)
                
            }
        }
        this.state.mailFolders = newMailFolders
        this.persistState()
    }

    removeMailFolder(originalId: string | undefined | null, persist: boolean = true): true | undefined {
        if (originalId !== undefined && originalId !== null) {
            if (this.state.mailFolders.delete(originalId)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeMailFolders(originalIds: (string | null)[] | undefined | null) {
        let persist = false
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                const changed = !!this.removeMailFolder(originalId, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}