
//Simple wrapper to make attaching/removing listener easier
export default class FocusListener {

    private readonly onShow: () => void

    constructor(callback: () => void) {
        this.onShow = () => {
            if (document.readyState === 'complete') {
                callback()
            }
        }
        this.add()
    }

    add() {
        window.addEventListener("focus", this.onShow)
    }

    remove() {
        window.removeEventListener("focus", this.onShow)
    }

}
