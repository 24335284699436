<template>
  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-row">
      <Chips
        v-model="selectedUserEmails"
        class="w-100"
        :placeholder="placeholder"
        :allow-duplicate="false"
        @input="filterUsers"
        @update:modelValue="updateSelection"
      />
      <Button v-tooltip="i18n.$gettext('Save')" class="p-button-success" @click="emitSave">
        <i class="fa fa-save" />&emsp;<translate>Save</translate>
      </Button>
    </div>
    <div class="flex-grow-1" style="height: 1px">
      <InfiniteList
        v-if="selectableUsers.length > 0"
        :get-all-items="selectableUsers"
        :page-size="50"
      >
        <template #element="{ item }">
          <slot name="item">
            <div class="d-flex flex-row py-2 w-100 align-items-center">
              <Checkbox v-model="item.selected" :binary="true" @update:modelValue="toggleUser(item.user, $event)" />
              <Avatar v-if="item.user.image" class="ml-3" :src="item.user.image" />
              <div class="flex-grow-1 ml-3">
                <p class="text-dark font-weight-bold mb-1">
                  {{ item.user.name || item.user.email }}
                </p>
                <p v-if="item.user.name" class="mb-0">
                  {{ item.user.email }}
                </p>
              </div>
            </div>
          </slot>
        </template>
      </InfiniteList>
      <slot v-else-if="allowAdd" name="hint">
        <label>{{ hint || i18n.$gettext("Input valid email address and press 'Enter'.") }}</label>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Avatar from "@/components/common/Avatar.vue"
import Checkbox from "primevue/checkbox"
import {Watch} from "vue-property-decorator"
import Chips from "primevue/chips"

@Options({
  //@ts-ignore
  props: {
    users: Array,
    selection: Array,
    multiSelect: Boolean,
    allowAdd: Boolean,
    placeholder: String,
    hint: String
  },
  components: {
    //@ts-ignore
    InfiniteList, Checkbox, Avatar, Chips
  },
  emits : [
    'update', 'save'
  ]
})
export default class UserPicker extends Vue {

  i18n: Language = useGettext()

  users!: {id: string, name: string | undefined, email: string | undefined, image: string | undefined}[] | null
  selection!: string[]
  multiSelect!: boolean
  allowAdd!: boolean
  placeholder!: string
  hint!: string
  filter = ''

  selectableUsers: {user: any, selected: boolean}[] = []
  selectedUserEmails: string[] = []

  emitSave() {
    this.$emit('save', this.selectedUserEmails)
  }

  updateSelection() {
    this.filterUsers('')
    this.$emit('update', this.selectedUserEmails)
  }

  toggleUser(user: any, selected: boolean) {
    if (selected && user.email) {
      this.selectedUserEmails.push(user.email)
    } else if (!selected) {
      this.selectedUserEmails = this.selectedUserEmails.filter((u: string) => {
        return u !== user.email
      })
    }
  }

  filterUsers(input: string) {
    this.filter = input.toLowerCase()
    this.watchUsers((this.users || []))
  }

  @Watch('selection')
  watchSelection(newSelection: string[], /* oldSelection: string[] */) {
    this.selectedUserEmails = newSelection
  }

  @Watch('users')
  watchUsers(newUsers: any[], /* oldUsers: any[] */) {
    this.selectableUsers = newUsers.filter((user: any) => {
      return !this.filter ||
        (user.email && user.email.toLowerCase().includes(this.filter)) ||
        (user.userName && user.userName.toLowerCase().includes(this.filter))
    }).map((user: any) => {
      const selected: string | undefined = this.selectedUserEmails.find((u: string) => {
        return u === user.email
      })
      return {user: user, selected: !!selected}
    })
  }

  mounted() {
    this.watchSelection((this.selection || []))
    this.watchUsers((this.users || []))
  }

}
</script>

<style scoped lang="scss">

</style>
