
import {Options, Vue} from "vue-class-component"
import manuals from "@/components/helpcenter/manuals"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import ManualDisplay from "@/components/common/ManualDisplay.vue"
import SettingsUtil from "@/util/SettingsUtil"
import Constants from "@/util/Constants"

@Options({
  components: { ManualDisplay },
  //@ts-ignore
  props: {},
  emits: ['close']
})
export default class HelpCenter extends Vue {

  manualSubpath: string = ""
  currentManual: string = ""

  i18n: Language = useGettext()
  toast = useToast()

  get manualSections(){
    return manuals()
  }

  get manualsForSelectedSection(){
    const section = this.manualSections.find((s: { name: string, path: string, manuals: any[] }) => {
      return this.manualSubpath === s.path
    })
    return section ? section.manuals : []
  }

  get currentManualObject(){
    const section = this.manualSections.find((s: { name: string, path: string, manuals: any[] }) => {
      return this.manualSubpath === s.path
    })

    if(!section) return null
    return section.manuals.find((m: { title: string, url: string | null , file: string }) => {
      return this.currentManual == m.file
    })
  }

  switchManual(file: string) {
    this.currentManual = file
  }

  get hasSeenIntroVideo(): boolean {
    return SettingsUtil.getIntroVideoSeen()
  }

  get introVideoUrl(): string {
    return Constants.introductionVideoUrl
  }

  isActiveSection(section: string) {
    return section === this.manualSubpath
  }

  isActiveManual(manual: string) {
    return this.currentManual === manual
  }

  handleClose(){
    this.$emit('close')
  }

}
