import LastViewedCollections from '@/model/settings/LastViewedCollections';
import LastViewedCollectionOrder from '@/model/settings/LastViewedCollectionOrder';

export default class ProjectCollectionOrder {
  projectId!: string | null;
  lastViewed!: LastViewedCollections | null;
  order!: LastViewedCollectionOrder | null;

  constructor() {
    this.projectId = null;
    this.lastViewed = null;
    this.order = null;
  }
}
