import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import MailFilter from '@/model/settings/MailFilter';


export default class GeneratedMailFilterStore {

    state: {
        mailFilters: Map<number, MailFilter>,
        calls: Map<string, number | number[]>
    }
    timer: number | null = null

    constructor() {
        let mailFilters: Map<number, MailFilter> | null = null
        try {
            const persistedState = localStorage.getItem('MailFilterStore')
            if (persistedState) {
                mailFilters = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            mailFilters: mailFilters || new Map<number, MailFilter>(),
            calls: new Map<string, number | number[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('MailFilterStore', JSON.stringify(Array.from(this.state.mailFilters)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.mailFilters) this.state.mailFilters.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('MailFilterStore')
    }

    addOrReplaceMailFilter(newMailFilter: MailFilter, persist: boolean = true): true | undefined {
        if (newMailFilter.id) {
            this.state.mailFilters.set(newMailFilter.id, newMailFilter)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceMailFilters(newMailFilters: MailFilter[]) {
        let persist = false
        for (const newMailFilter of newMailFilters) {
            const changed = !!this.addOrReplaceMailFilter(newMailFilter, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceMailFilters(mailFilters: MailFilter[]) {
        const newMailFilters = new Map<number, MailFilter>()
        for (const newMailFilter of mailFilters) {
            if (newMailFilter.id) {
                newMailFilters.set(newMailFilter.id, newMailFilter)
                
            }
        }
        this.state.mailFilters = newMailFilters
        this.persistState()
    }

    removeMailFilter(id: number | undefined | null, persist: boolean = true): true | undefined {
        if (id !== undefined && id !== null) {
            if (this.state.mailFilters.delete(id)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeMailFilters(ids: (number | null)[] | undefined | null) {
        let persist = false
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                const changed = !!this.removeMailFilter(id, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}