<template>
  <InnerLayoutWithSidebar
    v-if="hasFilesBooked"
    :header-label="i18n.$gettext('Libraries')"
    :default-open="openMobileMenuDefault"
    @created-clicked="openNewLibraryModal"
  >
    <template #side-panel-content>
      <div
        v-for="library in (librarySWR.data || [])"
        :key="library.path"
        class="side-panel-menu-item pl-3 pt-2 pb-2 d-flex"
        :class="{'active': libraryIsSelected(library)}"
        @click="goToLibrary(library.path)"
        @contextmenu="openLibraryContextMenu($event, library)"
      >
        <div v-if="libraryToEditId !== library.path || library.sharedFrom" class="d-flex w-100 align-items-center">
          <img class="collection-icon my-1 mr-2" :src="library.thumbNailSmall">
          <span class="flex-grow-1">{{ library.name }}</span>
          <i
            v-if="library.sharedFrom"
            v-tooltip="i18n.$gettext('This Library is shared to you by ') + library.sharedFrom + (library.permission === 'READ' ? (' ' + i18n.$gettext('(read only)')) : '')"
            class="cil-share-alt"
          />
          <Button icon="cil-menu" class="p-button-text p-button-sm context-menu-btn" @click.stop.prevent="toggleDropdown($event, library)" />
        </div>
        <div v-else class="d-flex w-100 align-items-center">
          <InputText
            v-model="libraryToEditName"
            class="p-inputtext-sm w-100 flex-grow-1"
            @click.stop
            @keyup.enter="renameLibrary"
          />
          <Button
            icon="cil-check-alt"
            :loading="renameLibraryLoading"
            class="p-button-text p-button-success p-button-sm"
            @click.stop="renameLibrary"
          />
          <Button icon="cil-x" class="p-button-text p-button-danger p-button-sm" @click.stop="libraryToEditId = ''" />
        </div>
      </div>
      <div v-if="librarySWR.call?.loading">
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
      </div>
    </template>
    <template #main-content>
      <div v-if="isTopLevel" class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="">
          <p class="h5">
            <translate>Please select a library</translate>
          </p>
          <p><translate>You can select a library on the left side or create a new one in the top bar.</translate></p>
        </div>
      </div>
      <div v-else class="flex-grow p-0 bg-white h-100">
        <INodeList
          :project-id="currentProjectId"
          :current-path="currentPath"
          :current-i-node="currentINode"
          class="flex-grow-1 h-100"
          @inode-chosen="goToInode"
          @upper-chosen="goToUpperDir"
        />
      </div>
      <Dialog
        v-model:visible="showNewModal"
        :header="i18n.$gettext('Create Library')"
        :modal="true"
        :draggable="false"
      >
        <div>
          <p><translate>Please specify a name for the new Library:</translate></p>
          <span class="p-float-label w-100">
            <InputText v-model="newLibraryName" class="w-100" @keyup.stop.prevent.enter="createLibrary" />
            <label><translate>Name</translate></label>
          </span>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-raised p-button-secondary"
              :label="i18n.$gettext('Abort')"
              @click="showNewModal = false"
            />
            <Button
              icon="cil-plus"
              class="p-button p-button-raised p-button-success"
              :loading="newLibraryLoading"
              :label="i18n.$gettext('Create')"
              @click="createLibrary"
            />
          </div>
        </div>
      </Dialog>
      <Menu ref="libraryMenu" :model="menuItems" :popup="true" />
      <ContextMenu ref="libraryContextMenu" :model="menuItems" />
      <ShareModal ref="shareModal" :inode="contextSelectedInode" />
    </template>
  </InnerLayoutWithSidebar>
  <div v-else class="d-flex flex-row bg-light h-100" style="border-radius: 3px; overflow: hidden">
    <div class="d-flex flex-grow-1 flex-column justify-content-center h-100">
      <div class="text-center">
        <p class="h5 mb-2">
          <translate>Files are disabled</translate>
        </p>
        <p><translate>Please talk to your administrator to enable Files for your account or purchase an upgrade to your subscription.</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import INode from '@/model/entry/INode'
import INodeList from './INodeList.vue'
import {Options, Vue} from "vue-class-component"
import MenuBar from "@/components/common/MenuBar.vue"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import {fileServiceApi} from "@/api/FileServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dialog from "primevue/dialog"
import ShareModal from "@/components/filemanger/subcomponents/ShareModal.vue"
import { ref } from "@vue/reactivity"
import ContextMenu from 'primevue/contextmenu'
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import ProgressBar from "primevue/progressbar"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import {useConfirm} from "primevue/useconfirm"
import Menu from "primevue/menu"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import featureSubset from "@/util/FeatureSubsets"
import {iNodeStore} from "@/store/INodeStore"
import SWR from "@/api/SWR"

@Options({
  components: {
    InnerLayoutWithSidebar, INodeList, MenuBar, AnimatedInput, Button, InputText, Dialog, ContextMenu, ShareModal, ProgressBar, Skeleton, Menu
  },
  //@ts-ignore
  props: {}
})
export default class FileView extends Vue {

  i18n: Language = useGettext()
  api = fileServiceApi
  toast = useToast()
  confirm = useConfirm()

  showNewModal = false
  newLibraryName = ""
  newLibraryLoading = false
  libraryToEditId: string = ''
  libraryToEditName: string = ''
  renameLibraryLoading = false

  //@ts-ignore
  shareModal: ShareModal = ref(null)
  //@ts-ignore
  libraryMenu: Menu = ref(null)
  //@ts-ignore
  libraryContextMenu: ContextMenu = ref(null)
  contextSelectedInode: INode | null = null

  openMobileMenuDefault: boolean = false

  menuItems: { label: string, icon: string, command: () => any }[] = []

  openLibraryContextMenu(e: Event, library: INode) {
    this.contextSelectedInode = library
    this.updateMenuItems(library)
    this.libraryMenu.hide()
    void this.$nextTick(() => {
      this.libraryContextMenu.toggle(e)
    })
  }

  toggleDropdown(e: Event, library: INode): void {
    this.contextSelectedInode = library
    this.updateMenuItems(library)
    this.libraryContextMenu.hide()
    void this.$nextTick(() => {
      this.libraryMenu.toggle(e)
    })
  }

  updateMenuItems(library: INode) {
    this.menuItems = [
      {
        label: this.i18n.$pgettext('File Context Menu', 'Share'),
        icon: 'cil-share',
        command: () => {
          this.shareModal.toggle()
        }
      }
    ]

    if (!library.sharedFrom) {
      this.menuItems.push({
        label: this.i18n.$pgettext('File Context Menu', 'Rename'),
        icon: 'cil-pencil',
        command: () => {
          this.libraryToEditId = this.contextSelectedInode?.path || ''
          this.libraryToEditName = this.contextSelectedInode?.name || ''
        }
      })
      this.menuItems.push({
        label: this.i18n.$pgettext('File Context Menu', 'Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteLibrary()
        }
      })
    }
    this.menuItems.push({
      label: this.i18n.$pgettext('File Context Menu', 'View Trash'),
      icon: 'cil-recycle',
      command: () => {
        if (this.contextSelectedInode?.path) {
          this.goToLibraryTrash(this.contextSelectedInode?.path)
        }
      }
    })
  }

  deleteLibrary() {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this library?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.contextSelectedInode?.path) {
          fileServiceApi._deleteINode(this.contextSelectedInode.path).then(() => {
            this.toast.success(this.i18n.$gettext("Library deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Library could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  renameLibrary() {
    this.renameLibraryLoading = true
    fileServiceApi._renameINode(this.libraryToEditId, this.libraryToEditName.trim()).then(() => {
      this.libraryToEditId = ''
      this.libraryToEditName = ''
      this.toast.success(this.i18n.$gettext("Rename successful"))
    }).catch((error: RpcError) => {
      this.toast.error(error.message)
    }).finally(() => {
      this.renameLibraryLoading = false
    })
  }

  goToUpperDir(): void {
    if (this.currentPath !== "/") {
      const pathElements: string[] = (this.currentPath?.split("/") || ["files"]).filter(elem => elem !== "")
      pathElements.pop()
      const topLevelPath: string = "/files/" + pathElements.join("/")
      void this.$router.push(topLevelPath)
    }
  }

  goToInode(inode: INode): void {
    if (inode.isDirectory) {
      const pathParts = inode.path?.split("/").filter((part: string) => { return part.trim() !== ""}) || []
      const encodedParts = pathParts.map((part: string) => { return encodeURIComponent(part.trim())})
      const path: string = "/files" + "/" + encodedParts.join("/")
      void this.$router.push(path)
    } else if (inode.directLink) {
      window.open(inode.directLink, '_blank')?.focus()
    }
  }

  get currentINode(): INode | null {
    const slashCount: number = (this.currentPath?.match(/\//g) || []).length
    if (this.currentPath && slashCount > 2) {
      const segments: string[] = this.currentPath.split('/').filter(elem => elem !== "")
      segments.pop()
      const topLevelPath: string = segments.join("/")
      const swr = fileServiceApi.getINodesByPath(topLevelPath, false)
      return (swr.data || []).find(i => i.path === this.currentPath) || null
    } else if (this.currentPath && slashCount === 2) {
      return this.getLibraryByPath(this.currentPath)
    } else {
      return null
    }
  }

  getLibraryByPath(path: string): INode | null {
    if (!path) return null
    const iNodes: INode[] = this.librarySWR.data || []
    if (!iNodes) return null
    return iNodes.find(iNode => iNode.path && path.startsWith(iNode.path)) || null
  }

  get librarySWR(): SWR<INode[], string[]> {
    let swr = fileServiceApi.getINodesByPath("/", undefined,["name:asc", "isDirectory:desc"])
    if (swr.call?.promise && swr.call?.loading) {
      void swr.call.promise.then((ids: string[]) => {
        //Replace the state, to make sure no deleted libraries remain in it
        const inodes = [...iNodeStore.state.iNodes.values()]
        iNodeStore.replaceINodes(inodes.filter(inode => (inode.parentPath !== '/') || ids.includes(inode.path || '')) || [])
      })
    }
    return swr
  }

  get currentPath(): string | null {
    if (this.$route?.params.hasOwnProperty("path")) { //We only have a path as soon as the route knows it!
      const currentPath: string[] = (Array.isArray(this.$route.params["path"]) ? this.$route.params["path"] : [this.$route.params["path"]])
      if (currentPath.length > 0 && !!currentPath[0]) {
        return "/" + currentPath.join("/") + "/"
      } else {
        return "/"
      }
    } else {
      return null
    }
  }

  get isTopLevel(): boolean {
    return this.currentPath === "/"
  }

  get projects() {
    return projectServiceApi.getProjects().data || []
  }

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  goToLibrary(path: string) {
    void this.$router.push("/files" + path)
    void SettingsUtil.setLastViewedCollection('files', path)
  }

  goToLibraryTrash(path: string) {
    void this.$router.push("/files/trash" + path)
  }

  libraryIsSelected(library: INode) {
    if (library.path) {
      return this.currentPath?.startsWith(library.path)
    } else {
      return false
    }
  }

  openNewLibraryModal() {
    this.newLibraryName = ""
    this.newLibraryLoading = false
    this.showNewModal = true
  }

  createLibrary() {
    this.newLibraryLoading = true
    return this.api._createDirectory(  "/" + this.newLibraryName , null).then(() => {
      this.showNewModal = false
      this.toast.success(this.i18n.$gettext("Directory created"))
      fileServiceApi.getINodesByPath("/", true,["name:asc", "isDirectory:desc"])
    }).catch((error: RpcError) => {
      this.toast.error(error.message, this.i18n.$gettext("Directory could not be created"))
    }).finally(() => { this.newLibraryLoading = false })
  }

  get hasFilesBooked(): boolean {
    return featureSubset.hasFiles
  }

  mounted() {
    if (!this.currentPath || this.currentPath === '/') {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('files')
      if (!this.librarySWR.call?.loading && this.getLibraryByPath(lastViewed || '') === null) {
        //This path no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('files', '')
      }
      if (lastViewed) {
        this.goToLibrary(lastViewed)
      } else {
        this.openMobileMenuDefault = true
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
