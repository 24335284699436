<template>
  <div>
    <div class="d-flex flex-row mb-4 align-items-center">
      <label class="mt-2 mr-2"><translate translate-context="recurrence">Repeat</translate></label>
      <Dropdown
        v-model="endType"
        :options="endTypeOptions"
        option-label="name"
        option-value="id"
        @update:modelValue="updateRule"
      />
      <DatePicker
        v-if="endType === 'UNTIL'"
        v-model="until"
        :label="i18n.$gettext('Date')"
        inline
        month-navigator
        year-navigator
        year-range="1980:2050"
        :min-date="minDate"
        @update:modelValue="updateRule"
      />
      <InputNumber
        v-if="endType === 'COUNT'"
        v-model="count"
        type="number"
        @update:modelValue="updateRule"
      />

      <label class="mt-2 mr-2 ml-2"><translate translate-context="recurrence">every</translate></label>
      <InputText v-model="interval" type="number" @update:modelValue="updateRule" />
      <Dropdown
        v-model="frequency"
        :options="frequencyOptions"
        option-label="name"
        option-value="id"
        @update:modelValue="updateRule"
      />
    </div>
    <div v-if="isWeekly" class="d-flex flex-row mb-4 align-items-center">
      <!--<label><translate>Days of the Week</translate>:&emsp;</label>-->
      <label class="mr-2"><translate translate-context="days-of-week">On Day(s)</translate></label>
      <label v-for="dayOption in weekDayOptions" :key="dayOption.value" class="dayselector">
        <input
          v-model="weekDays"
          type="checkbox"
          :value="dayOption.value"
          @change="updateWeekDayOption"
          @update:modelValue="updateRule"
        >
        <span>{{ i18n.$gettext(dayOption.name) }}</span>
      </label>
    </div>
    <div v-else-if="isMonthly">
      <div class="row mb-4">
        <div class="col-auto d-flex align-items-center">
          <radio-button
            v-model="rbMonthly"
            input-id="monthly1"
            name="Monthly"
            value="BYMONTHDAY"
          />
          <label class="mt-2 ml-2" for="monthly1"><translate translate-context="day-of-month">on Date</translate></label>
        </div>
        <div class="col d-flex flex-wrap">
          <label v-for="dayOfMonthOption in daysOfMonthOptions" :key="dayOfMonthOption" class="dayselector">
            <input
              v-model="byMonthDays"
              type="checkbox"
              :value="dayOfMonthOption"
              @change="updateDayOfMonthOption"
              @update:modelValue="updateRule"
            >
            <span>{{ dayOfMonthOption }}</span>
          </label>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-auto d-flex align-items-center">
          <radio-button
            v-model="rbMonthly"
            input-id="monthly2"
            name="Monthly"
            value="BYDAY"
          />
          <label class="mt-2 ml-2" for="monthly1"><translate translate-context="day-of-month">On the</translate></label>
        </div>
        <div class="col">
          <Dropdown
            v-model="byDayPosition"
            :options="relativeCounter"
            option-label="name"
            option-value="value"
            @update:modelValue="updateRule"
          />
        </div>
        <div class="col">
          <Dropdown
            v-model="weekDay"
            :options="weekDayOptions"
            option-label="full_name"
            option-value="id"
            @update:modelValue="updateRule"
          />
        </div>
      </div>
    </div>
    <div v-else-if="isYearly">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <radio-button
            v-model="yearlySpecific"
            input-id="yearly1"
            name="Yearly"
            value="YEARLY"
          />
          <label class="mt-2 ml-2" for="monthly1"><translate>on</translate> {{ startString }}</label>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-auto d-flex align-items-center">
          <radio-button
            v-model="yearlySpecific"
            input-id="yearly2"
            name="Yearly"
            value="SPECIFIC"
          />
          <label class="ml-2 mt-2 mr-2"><translate>On the</translate></label>
          <Dropdown
            v-model="byDayPosition"
            :options="relativeCounter"
            option-label="name"
            option-value="value"
            @update:modelValue="updateRule"
          />
          <Dropdown
            v-model="weekDay"
            :options="weekDayOptions"
            option-label="full_name"
            option-value="id"
            @update:modelValue="updateRule"
          />
          <label class="mt-2 ml-2"><translate translate-context="month-chooser">in</translate></label>
        </div>
        <div class="col">
          <label v-for="monthOption in monthOptions" :key="monthOption.id" class="dayselector">
            <input
              v-model="months"
              type="checkbox"
              :value="monthOption.value"
              @change="updateMonthOption"
              @update:modelValue="updateRule"
            >
            <span>{{ monthOption.name }}</span>
          </label>
        </div>
      </div>
    </div>
    <div v-if="tooManyDatesSelectedForOutlook" class="alert alert-danger">
      <translate>You have selected multiple occurences, this may not be compatible with Microsoft Outlook or ActiveSync devices. Proceed at your own risk.</translate>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import RecurrenceRule from "@/model/common/caldav/RecurrenceRule"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/common/Dropdown.vue"
import DatePicker from "@/components/common/DatePicker.vue"
import {Watch} from "vue-property-decorator"
import InputText from "primevue/inputtext"
import InputNumber from "primevue/inputnumber"
import RadioButton from "primevue/radiobutton"
import dayjs from "@/util/dayjs"

@Options({
  components: {
    AnimatedInput, Dropdown, DatePicker, InputText, InputNumber, RadioButton
  },
  //@ts-ignore
  props: {
    modelValue: Object,
    start: Date
  }
})
export default class RecurrencePicker extends Vue {

  i18n: Language = useGettext()

  modelValue!: RecurrenceRule
  start!: Date

  rbMonthly: string = "BYMONTHDAY";
  yearlySpecific: string = "YEARLY"
  minDate: Date = new Date()

  relativeCounter: any[] = [
    {
      id: 1,
      value: [1],
      name: this.i18n.$gettext('First')
    },
    {
      id: 2,
      value: [2],
      name: this.i18n.$gettext('Second')
    },
    {
      id: 3,
      value: [3],
      name: this.i18n.$gettext('Third')
    },
    {
      id: 4,
      value: [4],
      name: this.i18n.$gettext('Fourth')
    },
    {
      id: 5,
      value: [-1],
      name: this.i18n.$gettext('Last')
    }
  ]

  frequencyOptions: any[] = [
    {
      id: 'DAILY',
      name: this.i18n.$gettext('Day(s)')
    },
    {
      id: 'WEEKLY',
      name: this.i18n.$gettext('Week(s)')
    },
    {
      id: 'MONTHLY',
      name: this.i18n.$gettext('Month(s)')
    },
    {
      id: 'YEARLY',
      name: this.i18n.$gettext('Year(s)')
    }
  ]

  endTypeOptions: any[] = [
    {
      id: 'INDEFINITELY',
      name: this.i18n.$gettext('Indefinitely')
    },
    {
      id: 'UNTIL',
      name: this.i18n.$gettext('Until')
    },
    {
      id: 'COUNT',
      name: this.i18n.$gettext('Count')
    }
  ]

  monthTypeOptions: string[] = [

  ]

  weekDayOptions: {name: string, value: string, full_name: string, id: string}[] = [
    {
      name: this.i18n.$gettext('Mon'),
      value: '1',
      full_name: this.i18n.$gettext('Monday'),
      id: 'MONDAY'
    },
    {
      name: this.i18n.$gettext('Tue'),
      value: '2',
      full_name: this.i18n.$gettext('Tuesday'),
      id: 'TUESDAY'
    },
    {
      name: this.i18n.$gettext('Wed'),
      value: '3',
      full_name: this.i18n.$gettext('Wednesday'),
      id: 'WEDNESDAY'
    },
    {
      name: this.i18n.$gettext('Thu'),
      value: '4',
      full_name: this.i18n.$gettext('Thursday'),
      id: 'THURSDAY'
    },
    {
      name: this.i18n.$gettext('Fri'),
      value: '5',
      full_name: this.i18n.$gettext('Friday'),
      id: 'FRIDAY'
    }
    , {
      name: this.i18n.$gettext('Sat'),
      value: '6',
      full_name: this.i18n.$gettext('Saturday'),
      id: 'SATURDAY'
    },
    {
      name: this.i18n.$gettext('Sun'),
      value: '7',
      full_name: this.i18n.$gettext('Sunday'),
      id: 'SUNDAY'
    },
  ]

  extendedWeekDayOptions: {name: string, value: string, full_name: string, id: string}[] = [
    {
      name: this.i18n.$gettext('Mon'),
      value: '1',
      full_name: this.i18n.$gettext('Monday'),
      id: 'MONDAY'
    },
    {
      name: this.i18n.$gettext('Tue'),
      value: '2',
      full_name: this.i18n.$gettext('Tuesday'),
      id: 'TUESDAY'
    },
    {
      name: this.i18n.$gettext('Wed'),
      value: '3',
      full_name: this.i18n.$gettext('Wednesday'),
      id: 'WEDNESDAY'
    },
    {
      name: this.i18n.$gettext('Thu'),
      value: '4',
      full_name: this.i18n.$gettext('Thursday'),
      id: 'THURSDAY'
    },
    {
      name: this.i18n.$gettext('Fri'),
      value: '5',
      full_name: this.i18n.$gettext('Friday'),
      id: 'FRIDAY'
    }
    , {
      name: this.i18n.$gettext('Sat'),
      value: '6',
      full_name: this.i18n.$gettext('Saturday'),
      id: 'SATURDAY'
    },
    {
      name: this.i18n.$gettext('Sun'),
      value: '7',
      full_name: this.i18n.$gettext('Sunday'),
      id: 'SUNDAY'
    },
    {
      name: this.i18n.$gettext('Week'),
      value: '8',
      full_name: this.i18n.$gettext('Weekday'),
      id: 'WEEKDAY'
    },
    {
      name: this.i18n.$gettext('Weekend'),
      value: '9',
      full_name: this.i18n.$gettext('Weekend'),
      id: 'WEEKEND'
    }
  ]

  daysOfMonthOptions: number[] = [
    1,  2,  3,  4,  5,  6,  7,  8,  9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31
  ]

  monthOptions: {name: string, value: number, full_name: string, id: string}[] = [
    {
      name: this.i18n.$gettext('Jan'),
      value: 1,
      full_name: this.i18n.$gettext('January'),
      id: 'JANUARY'
    },
    {
      name: this.i18n.$gettext('Feb'),
      value: 2,
      full_name: this.i18n.$gettext('February'),
      id: 'FEBRUARY'
    },
    {
      name: this.i18n.$gettext('Mar'),
      value: 3,
      full_name: this.i18n.$gettext('March'),
      id: 'MARCH'
    },
    {
      name: this.i18n.$gettext('Apr'),
      value: 4,
      full_name: this.i18n.$gettext('April'),
      id: 'APRIL'
    },
    {
      name: this.i18n.$gettext('May'),
      value: 5,
      full_name: this.i18n.$gettext('May'),
      id: 'MAY'
    }
    , {
      name: this.i18n.$gettext('Jun'),
      value: 6,
      full_name: this.i18n.$gettext('June'),
      id: 'JUNE'
    },
    {
      name: this.i18n.$gettext('Jul'),
      value: 7,
      full_name: this.i18n.$gettext('July'),
      id: 'JULY'
    },
    {
      name: this.i18n.$gettext('Aug'),
      value: 8,
      full_name: this.i18n.$gettext('August'),
      id: 'AUGUST'
    },
    {
      name: this.i18n.$gettext('Sep'),
      value: 9,
      full_name: this.i18n.$gettext('September'),
      id: 'SEPTEMBER'
    },
    {
      name: this.i18n.$gettext('Oct'),
      value: 10,
      full_name: this.i18n.$gettext('October'),
      id: 'OCTOBER'
    },
    {
      name: this.i18n.$gettext('Nov'),
      value: 11,
      full_name: this.i18n.$gettext('November'),
      id: 'NOVEMBER'
    },
    {
      name: this.i18n.$gettext('Dec'),
      value: 12,
      full_name: this.i18n.$gettext('December'),
      id: 'DECEMBER'
    },
  ]

  interval = '1'
  frequency: string = this.i18n.$gettext('DAILY')
  weekDays: string[] = []
  byMonthDays: number[] = [1]
  months: number[] = [1]
  endType: string = 'INDEFINITELY'
  until: Date | null = null
  count = '1'
  monthType = ''
  byDayPosition: number[] = [1];
  weekDay: string = 'MONDAY'
  firstUpdateDone: boolean = false

  @Watch('rbMonthly')
  watchRbMonthly(){
    this.updateRule()
  }

  @Watch('yearlySpecific')
  watchYearlySpecific(){
    this.updateRule()
  }

  get isDaily(): boolean {
    return this.frequency === 'DAILY'
  }
  get isWeekly(): boolean {
    return this.frequency === 'WEEKLY'
  }

  get isMonthly(): boolean {
    return this.frequency === 'MONTHLY'
  }

  get isYearly(): boolean {
    return this.frequency === 'YEARLY'
  }

  get isByMonthDays(): boolean {
    return this.isMonthly && this.rbMonthly === 'BYMONTHDAY'
  }

  get isBySetPosition(): boolean {
    return (this.isYearly && this.everyYearSpecific) || (this.isMonthly && this.rbMonthly === 'BYDAY')
  }

  get countLabel(): string {
    return this.frequencyOptions.find(o => o.id == this.frequency).name
  }

  updateWeekDayOption() {
    this.weekDays.sort()
  }

  updateMonthOption() {
    this.months.sort()
  }

  updateDayOfMonthOption() {
    this.byMonthDays.sort()
  }

  updateRule() {
    this.$emit('update:modelValue', this.rule)
  }

  get rule(): RecurrenceRule {
    return Object.assign(new RecurrenceRule(), {
      frequency: this.frequency,
      interval: Number(this.interval),
      until: this.endType === 'UNTIL' ? this.until : undefined,
      count: this.endType === 'COUNT' ? Number(this.count) : undefined,
      byDay: this.isWeekly ? this.byWeekDay : (this.isBySetPosition ? this.byYearMonthDay : null),
      byMonthDay: this.isByMonthDays ? this.byMonthDays : null,
      bySetPosition: this.isBySetPosition ? this.byDayPosition : null,
      byMonth: this.isYearly && this.everyYearSpecific ? this.months : null
    })
  }

  get startString(): string {
    if (this.start) {
      return dayjs(this.start).format('LL').split(',')[0].replace(String(this.start.getFullYear()), '')
    } else {
      return this.i18n.$gettext('this day.')
    }
  }

  get tooManyDatesSelectedForOutlook(){
    return (this.isMonthly && this.isByMonthDays && this.byMonthDays.length > 1) || (this.isYearly && this.everyYearSpecific && this.months.length > 1)
  }

  get byYearMonthDay(): any {
    const byDay: any = {}
    byDay[this.weekDay] = null
    return byDay
  }

  get everyYearSpecific(): boolean {
    return this.yearlySpecific !== "YEARLY"
  }

  get byWeekDay(): any {
    const byDay: any = {}
    for (const dayNumber of this.weekDays) {
      const dayName: string | undefined = this.weekDayOptions.find(o => o.value === dayNumber)?.id
      if (dayName) {
        byDay[dayName] = null //TODO: If month or year, add day number: Number(this.interval)
      }
    }
    return byDay
  }

  @Watch('modelValue')
  updateModelValue(newValue: RecurrenceRule) {
    if (newValue.frequency) this.frequency = newValue.frequency
    if (newValue.interval) this.interval = String(newValue.interval)
    if (newValue.until && !this.until) {
      this.endType = 'UNTIL'
      this.until = new Date(newValue.until)
    }
    if (newValue.count) {
      this.endType = 'COUNT'
      this.count = String(newValue.count)
    }
    const weekDays: string[] = []
    if (newValue.byDay) {
      if (this.isWeekly) {
        for (const dayName in newValue.byDay) {
          const dayNumber: string | undefined = this.weekDayOptions.find(o => o.id === dayName)?.value
          if (dayNumber) {
            weekDays.push(dayNumber)
          }
        }
        this.weekDays = weekDays
        this.updateWeekDayOption()
      } else {
        for (const dayName in newValue.byDay) {
          this.weekDay = dayName
        }
      }
    }
    if (newValue.byMonthDay) this.byMonthDays = newValue.byMonthDay
    let isBySetPosition = true
    if (Array.isArray(newValue.bySetPosition) && newValue.bySetPosition.length > 0) {
      this.byDayPosition = newValue.bySetPosition
    } else if (typeof newValue.bySetPosition === 'number') {
      this.byDayPosition = [newValue.bySetPosition]
    } else {
      isBySetPosition = false
    }
    if (newValue.byMonth) this.months = newValue.byMonth
    if (!this.firstUpdateDone) {
      if (newValue.frequency == "MONTHLY" && isBySetPosition) {
        this.rbMonthly = "BYDAY"
      } else {
        this.rbMonthly = "BYMONTHDAY"
      }

      if (newValue.frequency == "YEARLY" && newValue.byMonth && newValue.byMonth.length > 0) {
        this.yearlySpecific = "SPECIFIC"
      } else {
        this.yearlySpecific = "YEARLY"
      }
      this.firstUpdateDone = true //set these values only the first time from the loaded recurrence
    }

  }

  mounted() {
    this.updateModelValue(this.modelValue)
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables.scss";

input[type=checkbox] {
  display: none;
}

label.dayselector span {
  display: inline-block;
  background: $input-bg;
  height: 3em;
  width: 3em;
  line-height: 3em;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 2px;
}

label :checked + span {
  background: $uniki_primary;
  color: white;
}
</style>
