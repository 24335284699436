import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import UploadLink from '@/model/common/UploadLink';
import Page from '@/model/Page';
import { uploadLinkStore } from '@/store/UploadLinkStore';

export default class GeneratedUploadLinkServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getUploadLinks(path: string | null, page: number | null, perPage: number | null): Promise<Page<UploadLink>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUploadLinks', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let uploadLinks: UploadLink[] = data.items.map((uploadLink: any) => Object.assign(new UploadLink(), uploadLink))
                uploadLinkStore.addOrReplaceUploadLinks(uploadLinks)
                return Object.assign(new Page<UploadLink>(), data)
            } else return Promise.reject()
        })
    }

    _deleteUploadLink(token: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteUploadLink', rpcParams, false).then(() => {
            uploadLinkStore.removeUploadLink(token)
        })
    }

    _setUploadLinkPassword(token: string, password: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setUploadLinkPassword', rpcParams, false) as Promise<void>
    }

    _setUploadLinkExpiry(token: string, expiry: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setUploadLinkExpiry', rpcParams, false).then((data: any) => {
            const model = Object.assign(new UploadLink(), data)
            uploadLinkStore.removeUploadLink(token)
            uploadLinkStore.addOrReplaceUploadLink(model)
            return model.token
        })
    }

    getUploadLinks(path: string | null, page: number | null, perPage: number | null, refresh?: boolean | number): SWR<UploadLink[], Page<string>> {
        //@ts-ignore
        const result: SWR<UploadLink[], Page<string>> = reactive(new SWR<UploadLink[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_getUploadLinks' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getUploadLinks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const uploadLinks: UploadLink[] = []
                for (const id of data.items || []) {
                    const uploadLink: UploadLink | undefined = uploadLinkStore.state.uploadLinks.get(id)
                    if (uploadLink) {
                        uploadLinks.push(uploadLink)
                    }
                }
                result.data = uploadLinks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getUploadLinks(path, page, perPage).then((data: Page<UploadLink>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(uploadLink => !!uploadLink.token)?.map(uploadLink => uploadLink.token) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const uploadLinks: UploadLink[] = []
            for (const id of cached.data.items || []) {
                const uploadLink: UploadLink | undefined = uploadLinkStore.state.uploadLinks.get(id)
                if (uploadLink) {
                    uploadLinks.push(uploadLink)
                }
            }
            result.data = uploadLinks
        }
        return result
    }

    getUploadLink(token: string): UploadLink | undefined {
        return uploadLinkStore.state.uploadLinks.get(token)
    }
}
