import EntryLinkMeta from '@/model/EntryLinkMeta';

export default class EntryLink {
  id!: number | null;
  leftBackendId!: string | null;
  rightBackendId!: string | null;
  leftBackend!: string | null;
  rightBackend!: string | null;
  leftType!: string | null;
  rightType!: string | null;
  timestamp!: number | null;
  meta!: EntryLinkMeta | null;

  constructor() {
    this.id = null;
    this.leftBackendId = null;
    this.rightBackendId = null;
    this.leftBackend = null;
    this.rightBackend = null;
    this.leftType = null;
    this.rightType = null;
    this.timestamp = null;
    this.meta = null;
  }
}
