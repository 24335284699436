import GeneratedTaskServiceApi from "./generated/GeneratedTaskServiceApi"
import { taskStore } from '@/store/TaskStore'
import {taskBoardStore} from "@/store/TaskBoardStore"
import TaskBoard from "@/model/directory/TaskBoard"
import Task from "@/model/entry/Task"
import {userServiceApi} from "@/api/UserServiceApi"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import dayjs, {Dayjs} from "dayjs"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"

class TaskServiceApi extends GeneratedTaskServiceApi {

    get overDueCount(): number {
        return this.overDueTasks.length
    }

    get overDueTasks(): Task[] {
        const overdue: Task[] = []
        this.tasks.forEach((t: Task) => {
            if ((t.percentCompleted || 0) < 100 && t.due) {
                const dueDate = dayjs(t.due)
                if (dueDate.isBefore(this.endOfDay)){
                    overdue.push(t)
                }
            }
        })

        SortAndFilterUtil.sort(overdue, 'due:asc')
        return overdue
    }

    get endOfDay(): Dayjs {
        return dayjs().endOf('day')
    }

    get tasks(): Task[] {
        const promises: Promise<string[]>[] = []
        const myTasks: Task[] = []

        taskBoardServiceApi.taskBoards.forEach((board: TaskBoard) => {
            if (!board.originalId) return
            const swr = taskServiceApi.getTasks(board.originalId, false)
            if (swr.call?.loading && swr.call?.promise){
                promises.push(swr.call.promise)
            }
            const tasks: Task[] = swr.data ? [...swr.data] : []
            tasks.forEach((t: Task) => {
                if (taskServiceApi.isMyTask(t))
                    myTasks.push(t)
            })
        })

        return myTasks
    }

    isMyTask(t: Task): boolean {
        return this.isAttendee(t)
    }

    isAttendee(t: Task): boolean {
        return Boolean(t.attendees?.find(attendee =>
            attendee.email && (attendee.email.toLowerCase() === userServiceApi.userId || attendee.email.toLowerCase() === userServiceApi.userEmail)
        ))
    }

    getCategoriesOptions(boardId: string = ""): string[] {
        const columns: string[] = []
        for (const taskBoard of taskBoardStore.state.taskBoards.values()) {
            if (taskBoard.meta?.taskLists) {
                for (const list of taskBoard.meta?.taskLists) {
                    if (list.name && !columns.includes(list.name)) {
                        columns.push(list.name)
                    }
                }
            }
        }

        const categories: string[] = []
        for (const task of taskStore.state.tasks.values()) {
            if (task.categories) {
                for (const category of task.categories) {
                    if (!columns.includes(category) && !categories.includes(category)) {
                        if(boardId === "" || (boardId === task.originalParentId)){
                            categories.push(category)
                        }
                    }
                }
            }
        }
        return categories
    }
}

export const taskServiceApi = new TaskServiceApi()
