

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import DataSource from "@/model/DataSource"
import Skeleton from "primevue/skeleton"
import ScrollPanel from "primevue/scrollpanel"
import Dialog from "primevue/dialog"
import Listbox from "primevue/listbox"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import useToast, {ToastAPI} from "@/util/toasts"
import LoadingButton from "@/components/common/LoadingButton.vue"
import DataSourceInstance from "@/model/DataSourceInstance"
import {Watch} from "vue-property-decorator"
import DataImportJob from "@/model/DataImportJob"
import Badge from "primevue/badge";
import InputText from "primevue/inputtext"
import ProgressBar from "primevue/progressbar";
import Tree from "primevue/tree"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import SWR from "@/api/SWR"
import JsUiTreeModel from "@/model/JsUiTreeModel"
import RpcError from "@/api/RpcError"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import DataSourceInstanceForm from "@/components/settings/dataimportwizard/DataSourceInstanceForm.vue"
import {useConfirm} from "primevue/useconfirm"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import Dropdown from "@/components/common/Dropdown.vue"
import Steps from "primevue/steps"

@Options({
  components: {
    AnimatedInput, Button, Badge, Skeleton, ScrollPanel, ProgressBar, InfiniteList, Steps,
    Dialog, Listbox, LoadingButton, InputText, Tree, DataSourceInstanceForm, Dropdown
  },
  //@ts-ignore
  props: {
    modelValue: Boolean
  },
  emits: [
    'update:modelValue'
  ]
})
export default class DataImportWizard extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()

  currentTabIndex: number = 1

  possibleSources: DataSource[] | null = null
  selectedDataSource: DataSource | null = null
  selectedDataSourceInstance: DataSourceInstance | null = null

  sourceTree: any[] | null = null
  sourceTreeLoading: boolean = false
  selectedSourceObject: Object = {}

  selectedUser: string | null = rpcClient.session.user?.userName || null
  targetTree: any[] | null =null
  targetTreeLoading: boolean = false
  selectedTargetObject: Object = {}

  modelValue: boolean = false

  newFolderParent: string | null = null
  newFolderName: string = ''
  showNewDataSourceDialog: boolean = false

  get visible() {
    return this.modelValue
  }

  set visible(visible: boolean) {
    this.$emit('update:modelValue', visible)
  }

  get showNewFolderDialog() {
    return !!this.newFolderParent
  }

  set showNewFolderDialog(show: boolean) {
    if (!show) {
      this.newFolderParent = null
    }
  }

  get steps() {
    return [
      {
        label: '',
        icon: '',
        active: this.currentTabIndex == 0,
        disabled: false
      },
      {
        label: '',
        icon: '',
        active: this.currentTabIndex == 1,
        disabled: false
      },
      {
        label: '',
        icon: '',
        active: this.currentTabIndex == 2,
        disabled: false
      },
      {
        label: '',
        icon: '',
        active: this.currentTabIndex == 3,
        disabled: false
      }
    ]
  }

  get canGoNext(): boolean {
    if (this.currentTabIndex === 0) {
      return !!this.selectedDataSource
    } else if (this.currentTabIndex === 1) {
      return !!this.selectedDataSourceInstance
    } else if (this.currentTabIndex === 2) {
      return !!this.selectedSourcePath
    } else if (this.currentTabIndex === 3) {
      return Boolean(this.selectedSourcePath && this.selectedTargetPath)
    }
    return true
  }

  get possibleDataSourceInstances(): DataSourceInstance[] | null {
    if (this.selectedDataSource?.name) {
      const name: string = this.selectedDataSource.name
      const allInstancesSWR: SWR<DataSourceInstance[], number[]> = dataSourceServiceApi.getDataSourceInstances()
      if (allInstancesSWR.data !== null) {
        const instances: DataSourceInstance[] = [
            Object.assign(new DataSourceInstance(), {
              displayName: '&plus; ' + this.i18n.$gettext('Add Account')
            })
        ]
        instances.push(...allInstancesSWR.data.filter(instance => instance.datasourceName === name))
        return instances
      }
    }
    return null
  }

  get selectedSourcePath(): string | undefined {
    if (this.selectedSourceObject) {
      for (const [key, value] of Object.entries(this.selectedSourceObject)) {
        if (value === true) {
          return key
        }
      }
    }
  }

  get selectedTargetPath(): string | undefined {
    if (this.selectedTargetObject) {
      for (const [key, value] of Object.entries(this.selectedTargetObject)) {
        if (value === true) {
          return key
        }
      }
    }
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  get folderTypeTranslated() {
    const typesTranslated: string[] = []
    for (const scope of (this.selectedDataSource?.scopes || [])) {
      const translated = this.scopeTranslated(scope)
      if (translated) typesTranslated.push(translated)
    }
    return typesTranslated.join(', ')
  }

  scopeTranslated(scope: string): string | undefined {
    if (scope === 'FILES') {
      return this.i18n.$gettext('Folder')
    } else if (scope === 'EMAILS') {
      return this.i18n.$gettext('Folder')
    } else if (scope === 'CONTACTS') {
      return this.i18n.$gettext('Address Book')
    } else if (scope === 'EVENTS') {
      return this.i18n.$gettext('Calendar')
    } else if (scope === 'TASKS') {
      return this.i18n.$gettext('Task Board')
    }
  }

  get entryTypeTranslated() {
    const typesTranslated: string[] = []
    for (const scope of (this.selectedDataSource?.scopes || [])) {
      if (scope === 'FILES') {
        typesTranslated.push(this.i18n.$pgettext('Dateien', 'Files'))
      } else if (scope === 'EMAILS') {
        typesTranslated.push(this.i18n.$gettext('Emails'))
      } else if (scope === 'CONTACTS') {
        typesTranslated.push(this.i18n.$gettext('Contacts'))
      } else if (scope === 'EVENTS') {
        typesTranslated.push(this.i18n.$gettext('Events'))
      } else if (scope === 'TASKS') {
        typesTranslated.push(this.i18n.$gettext('Tasks'))
      }
    }
    return typesTranslated.join(', ')
  }

  @Watch("selectedDataSourceInstance")
  async loadSourceTree() {
    if (this.selectedDataSourceInstance?.id) try {
      this.sourceTree = null
      this.sourceTree = await dataSourceServiceApi._getDirectoryTreeOfSourceInstance(this.selectedDataSourceInstance.id, '/')
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not fetch source directories"))
    }
  }

  @Watch("selectedUser")
  @Watch("selectedDataSourceInstance")
  @Watch("selectedSourcePath")
  async loadTargetTree() {
    if (this.selectedDataSourceInstance?.id) try {
      this.targetTree = null
      const targetTree = await dataSourceServiceApi._getTargetDirectoryTreeOfSourceInstance(this.selectedDataSourceInstance.id, this.selectedUser, '', this.scopesOfSelection(this.selectedSourcePath, this.sourceTree || []))
      this.addNewButtons(targetTree)
      this.targetTree = targetTree
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not fetch target directories"))
    }
  }

  async onSourceNodeExpand(node: JsUiTreeModel) {
    if (this.selectedDataSourceInstance?.id && node.key) {
      node.children = await dataSourceServiceApi._getDirectoryTreeOfSourceInstance(this.selectedDataSourceInstance.id, node.key)
    }
  }

  async onTargetNodeExpand(node: JsUiTreeModel) {
    if (this.selectedDataSourceInstance?.id && node.key) {
      const children = await dataSourceServiceApi._getTargetDirectoryTreeOfSourceInstance(this.selectedDataSourceInstance.id, this.selectedUser, node.key, this.scopesOfSelection(this.selectedSourcePath, this.sourceTree || []))
      this.addNewButtons(children, node)
      node.children = children
    }
  }

  addNewButtons(tree: JsUiTreeModel[], parent: JsUiTreeModel | null = null): void {
    const possibleScopes = this.scopesOfSelection(this.selectedSourcePath, this.sourceTree || [])
    if (tree.length) {
      const addedScopes: string[] = []
      for (const leaf of [...tree]) {
        if (leaf.scopes?.length) {
          for (const scope of leaf.scopes) {
            if (!addedScopes.includes(scope) && (scope !== 'EMAILS' || parent) && (possibleScopes == null || possibleScopes.includes(scope))) {
              addedScopes.push(scope)
              const type = this.scopeTranslated(scope)
              if (type) tree.unshift(Object.assign(new JsUiTreeModel(), {
                key: 'CREATE-NEW:' + scope + ':' + (parent?.key || ''),
                label: '&plus; ' + this.i18n.interpolate(this.i18n.$gettext('Add %{ type }'), { type: type })
              }))
            }
            if (leaf.key && [ 'FILES', 'EMAILS' ].includes(scope) && Array.isArray(leaf.children)) {
              if (leaf.children.length) {
                this.addNewButtons(leaf.children, leaf)
              } else if (possibleScopes == null || possibleScopes.includes(scope)) {
                const type = this.scopeTranslated(scope)
                if (type) leaf.children.unshift(Object.assign(new JsUiTreeModel(), {
                  key: 'CREATE-NEW:' + scope + ':' + leaf.key,
                  label: '&plus; ' + this.i18n.interpolate(this.i18n.$gettext('Add %{ type }'), { type: type })
                }))
              }
            }
          }
        }
      }
    } else if (parent?.scopes?.length) {
      for (const scope of parent.scopes) {
        if (possibleScopes == null || possibleScopes.includes(scope)) {
          const type = this.scopeTranslated(scope)
          if (type) tree.unshift(Object.assign(new JsUiTreeModel(), {
            key: 'CREATE-NEW:' + scope + ':' + (parent?.key || ''),
            label: '&plus; ' + this.i18n.interpolate(this.i18n.$gettext('Add %{ type }'), { type: type })
          }))
        }
      }
    }
  }

  async loadData() {
    try {
      this.possibleSources = await dataSourceServiceApi._getAvailableDataSources()
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not fetch importable sources"))
    }
  }

  scopesOfSelection(key: string | undefined, tree: JsUiTreeModel[]): string[] | null {
    for (const leaf of tree) {
      if (leaf.key == key) {
        return leaf.scopes
      }
      const childScopes = this.scopesOfSelection(key, leaf.children || [])
      if (childScopes !== null) {
        return childScopes
      }
    }
    return null
  }

  labelOfSelection(key: string | undefined, tree: JsUiTreeModel[]): string | undefined {
    for (const leaf of tree) {
      if (leaf.key == key) {
        return leaf.label || leaf.key
      }
      const childLabel = this.labelOfSelection(key, leaf.children || [])
      if (childLabel) {
        return childLabel
      }
    }
  }

  async createFolder() {
    const id = this.selectedDataSourceInstance?.id
    if (id && this.newFolderName && this.newFolderParent && this.newFolderParent.includes(':')) {
      const scope = this.newFolderParent.substring(0, this.newFolderParent.indexOf(':'))
      const parent = this.newFolderParent.substring(this.newFolderParent.indexOf(':') + 1)
      let name = parent === '/' ? this.newFolderName : (parent + '/' + this.newFolderName)
      if (name.startsWith('/')) name = name.substring(1)
      this.targetTree = null
      await dataSourceServiceApi._addTargetDirectoryForSourceInstance(id, this.selectedUser, name, scope)
      await this.loadTargetTree()
      this.newFolderParent = null
    }
  }

  createImportJob(): Promise<number> | undefined {
    if (this.selectedDataSourceInstance?.id && this.selectedSourcePath && this.selectedTargetPath) {
      const importJob: DataImportJob = new DataImportJob()
      const sourceName: string = this.labelOfSelection(this.selectedSourcePath, this.sourceTree || []) || ''
      const targetName: string = this.labelOfSelection(this.selectedTargetPath, this.targetTree || []) || ''
      importJob.displayName = sourceName + ' &rArr; ' + targetName
      importJob.dataSourceInstanceId = this.selectedDataSourceInstance.id
      importJob.targetUser = this.selectedUser
      importJob.targetPath = this.selectedTargetPath
      importJob.sourcePath = this.selectedSourcePath
      return new Promise((resolve, reject) => {
        const context = { type: this.entryTypeTranslated, sourceName, targetName }
        this.confirm.require({
          message: this.i18n.interpolate(this.i18n.$gettext('The %{ type } in %{ sourceName } will be imported into %{ targetName }.'), context),
          header: this.i18n.$gettext('Confirmation'),
          acceptLabel: this.i18n.$gettext('Start'),
          rejectLabel: this.i18n.$pgettext('Abbrechen', 'Cancel'),
          acceptIcon: 'cil-data-transfer-up',
          acceptClass: 'p-button-success',
          accept: () => {
            return dataImportServiceApi._createDataImportJob(importJob).then((jobId: number) => {
              return dataImportServiceApi._startDataImportJob(jobId).then((jobId: number) => {
                resolve(jobId)
              }).catch((e: RpcError) => {
                this.toast.error(e.message, this.i18n.$gettext("Could not start import job."))
                reject()
              })
            }).catch((e: RpcError) => {
              this.toast.error(e.message, this.i18n.$gettext("Could not create import job."))
              reject()
            })
          },
          reject: resolve
        })
      })
    } else {
      this.toast.error(this.i18n.$gettext("Could not determine source or target path."))
    }
  }

  handleDataSourceInstanceSelection(target: DataSourceInstance) {
    if (target?.displayName?.startsWith('&' + 'plus;')) {
      this.showNewDataSourceDialog = true
      this.selectedDataSourceInstance = null
    } else {
      this.selectedDataSourceInstance = target
    }
  }

  handleTargetSelection(target: any) {
    if (target) {
      for (const [key, value] of Object.entries(target)) {
        if (value === true) {
          if (key.startsWith('CREATE-NEW:')) {
            this.newFolderParent = key.substring(11)
          } else {
            this.selectedTargetObject = target
          }
        }
      }
    } else {
      this.selectedTargetObject = target
    }
  }

  selectDataSourceInstance(newSource: DataSourceInstance) {
    this.selectedDataSourceInstance = newSource
    this.showNewDataSourceDialog = false
  }

  startImport() {
    this.createImportJob()?.then(() => {
      this.visible = false
      this.reset()
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext("Could not start import job"))
    })
  }

  reset() {
    void this.loadData()
    this.currentTabIndex = 0
    this.selectedDataSourceInstance = null
    this.selectedDataSource = null
    this.selectedUser = rpcClient.session.user?.userName || null
  }

  mounted() {
    this.reset()
  }
}
