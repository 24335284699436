import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import DataImportJob from '@/model/DataImportJob';


export default class GeneratedDataImportJobStore {

    state: {
        dataImportJobs: Map<number, DataImportJob>,
        calls: Map<string, number | number[]>
    }
    timer: number | null = null

    constructor() {
        let dataImportJobs: Map<number, DataImportJob> | null = null
        try {
            const persistedState = localStorage.getItem('DataImportJobStore')
            if (persistedState) {
                dataImportJobs = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            dataImportJobs: dataImportJobs || new Map<number, DataImportJob>(),
            calls: new Map<string, number | number[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('DataImportJobStore', JSON.stringify(Array.from(this.state.dataImportJobs)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.dataImportJobs) this.state.dataImportJobs.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('DataImportJobStore')
    }

    addOrReplaceDataImportJob(newDataImportJob: DataImportJob, persist: boolean = true): true | undefined {
        if (newDataImportJob.id) {
            this.state.dataImportJobs.set(newDataImportJob.id, newDataImportJob)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceDataImportJobs(newDataImportJobs: DataImportJob[]) {
        let persist = false
        for (const newDataImportJob of newDataImportJobs) {
            const changed = !!this.addOrReplaceDataImportJob(newDataImportJob, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceDataImportJobs(dataImportJobs: DataImportJob[]) {
        const newDataImportJobs = new Map<number, DataImportJob>()
        for (const newDataImportJob of dataImportJobs) {
            if (newDataImportJob.id) {
                newDataImportJobs.set(newDataImportJob.id, newDataImportJob)
                
            }
        }
        this.state.dataImportJobs = newDataImportJobs
        this.persistState()
    }

    removeDataImportJob(id: number | undefined | null, persist: boolean = true): true | undefined {
        if (id !== undefined && id !== null) {
            if (this.state.dataImportJobs.delete(id)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeDataImportJobs(ids: (number | null)[] | undefined | null) {
        let persist = false
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                const changed = !!this.removeDataImportJob(id, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}