<template>
  <Dialog
    v-model:visible="show"
    :modal="true"
    :header="i18n.$gettext('Share this file')"
    :draggable="false"
    :breakpoints="{'960px': '80vw', '640px': '100vw'}"
    :style="{minWidth: '60vw'}"
    :content-style="{minHeight: '25rem'}"
    @hide="$emit('hide')"
  >
    <TabView lazy>
      <TabPanel :header="i18n.$gettext('Download Link')">
        <DownloadLinkForm :inode="inode" @should-close="toggle()" />
      </TabPanel>
      <TabPanel v-if="inode.isDirectory && (inode.canShare || inode.permission === 'WRITE')" :header="i18n.$gettext('Upload Link')">
        <UploadLinkFrom :inode="inode" @should-close="toggle()" />
      </TabPanel>
      <TabPanel v-if="inode.isDirectory && inode.canShare" :header="i18n.$gettext('Share with users')">
        <ShareWithUsersForm :inode="inode" @should-close="toggle()" />
      </TabPanel>
      <TabPanel v-if="inode.isDirectory && inode.canShare" :header="i18n.$gettext('Share with groups')">
        <ShareWithGroupsForm :inode="inode" @should-close="toggle()" />
      </TabPanel>
    </TabView>
  </Dialog>
</template>

<script lang="ts">
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import DownloadLinkForm from "@/components/filemanger/subcomponents/DownloadLinkForm.vue"
import UploadLinkFrom from "@/components/filemanger/subcomponents/UploadLinkForm.vue"
import ShareWithUsersForm from "@/components/filemanger/subcomponents/ShareWithUsersForm.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ShareWithGroupsForm from "@/components/filemanger/subcomponents/ShareWithGroupsForm.vue"

@Options({
  components: {
    Dialog, TabView, TabPanel, DownloadLinkForm, UploadLinkFrom, ShareWithUsersForm, ShareWithGroupsForm
  },
  //@ts-ignore
  props: {
    inode: [ INode, Object ],
  },
  emits: ['hide']
})
export default class ShareModal extends Vue {

  i18n: Language = useGettext()

  inode!: INode

  show = false

  toggle(): void {
    this.show = !this.show
  }

}
</script>

<style scoped lang="scss">

</style>
