export default class User {
  userName!: string | null;
  email!: string | null;
  roles!: string[] | null;
  features!: any | null;
  password!: string | null;
  settings!: any | null;
  created!: string | null;
  updated!: string | null;
  version!: number | null;
  adminpanelUser!: any | null;
  status!: string | null;
  lastTypingChannel!: string | null;
  lastTypingActivity!: number | null;
  objectClass!: any | null;
  dn!: string | null;
  uid!: string | null;
  cn!: string | null;
  externEmail!: string | null;
  internEmail!: string | null;
  mobile!: string | null;
  title!: string | null;
  userPassword!: string | null;
  surname!: string | null;
  givenname!: string | null;
  posixUid!: string | null;
  posixGid!: string | null;
  homeDirectory!: string | null;
  loginShell!: string | null;
  posixGroupName!: string | null;
  passwordResetPin!: string | null;
  twoFactorEnabled!: boolean | null;
  kopanoAliases!: string[] | null;
  kopanoAccount!: string | null;
  kopanoAdmin!: string | null;
  kopanoQuotaOverride!: string | null;
  kopanoEnabledFeatures!: string[] | null;
  kopanoDisabledFeatures!: string[] | null;
  kopanoQuotaWarn!: string | null;
  kopanoQuotaSoft!: string | null;
  kopanoQuotaHard!: string | null;
  kopanoSendAsPrivilege!: string[] | null;
  description!: string | null;
  seeAlso!: string | null;
  telephoneNumber!: string | null;
  audio!: any | null;
  businessCategory!: string | null;
  carLicense!: string | null;
  departmentNumber!: string | null;
  employeeNumber!: string | null;
  employeeType!: string | null;
  homePhone!: string | null;
  homePostalAddress!: string | null;
  initials!: string | null;
  jpegPhoto!: any | null;
  labeledURI!: string | null;
  manager!: string | null;
  o!: string | null;
  pager!: string | null;
  preferredLanguage!: string | null;
  roomNumber!: string | null;
  secretary!: string | null;
  userCertificate!: any | null;
  displayName!: string | null;
  memberOf!: string[] | null;
  destinationIndicator!: string | null;
  facsimileTelephoneNumber!: string | null;
  internationaliSDNNumber!: string | null;
  l!: string | null;
  ou!: string | null;
  physicalDeliveryOfficeName!: string | null;
  postalAddress!: string | null;
  postalCode!: string | null;
  postOfficeBox!: string | null;
  preferredDeliveryMethod!: string | null;
  registeredAddress!: string | null;
  st!: string | null;
  street!: string | null;
  teletexTerminalIdentifier!: string | null;
  telexNumber!: string | null;
  x121Address!: string | null;
  enableGroupware!: boolean | null;
  O!: string | null;
  L!: string | null;

  constructor() {
    this.userName = null;
    this.email = null;
    this.roles = null;
    this.features = null;
    this.password = null;
    this.settings = null;
    this.created = null;
    this.updated = null;
    this.version = null;
    this.adminpanelUser = null;
    this.status = null;
    this.lastTypingChannel = null;
    this.lastTypingActivity = null;
    this.objectClass = null;
    this.dn = null;
    this.uid = null;
    this.cn = null;
    this.externEmail = null;
    this.internEmail = null;
    this.mobile = null;
    this.title = null;
    this.userPassword = null;
    this.surname = null;
    this.givenname = null;
    this.posixUid = null;
    this.posixGid = null;
    this.homeDirectory = null;
    this.loginShell = null;
    this.posixGroupName = null;
    this.passwordResetPin = null;
    this.twoFactorEnabled = null;
    this.kopanoAliases = null;
    this.kopanoAccount = null;
    this.kopanoAdmin = null;
    this.kopanoQuotaOverride = null;
    this.kopanoEnabledFeatures = null;
    this.kopanoDisabledFeatures = null;
    this.kopanoQuotaWarn = null;
    this.kopanoQuotaSoft = null;
    this.kopanoQuotaHard = null;
    this.kopanoSendAsPrivilege = null;
    this.description = null;
    this.seeAlso = null;
    this.telephoneNumber = null;
    this.audio = null;
    this.businessCategory = null;
    this.carLicense = null;
    this.departmentNumber = null;
    this.employeeNumber = null;
    this.employeeType = null;
    this.homePhone = null;
    this.homePostalAddress = null;
    this.initials = null;
    this.jpegPhoto = null;
    this.labeledURI = null;
    this.manager = null;
    this.o = null;
    this.pager = null;
    this.preferredLanguage = null;
    this.roomNumber = null;
    this.secretary = null;
    this.userCertificate = null;
    this.displayName = null;
    this.memberOf = null;
    this.destinationIndicator = null;
    this.facsimileTelephoneNumber = null;
    this.internationaliSDNNumber = null;
    this.l = null;
    this.ou = null;
    this.physicalDeliveryOfficeName = null;
    this.postalAddress = null;
    this.postalCode = null;
    this.postOfficeBox = null;
    this.preferredDeliveryMethod = null;
    this.registeredAddress = null;
    this.st = null;
    this.street = null;
    this.teletexTerminalIdentifier = null;
    this.telexNumber = null;
    this.x121Address = null;
    this.enableGroupware = null;
    this.O = null;
    this.L = null;
  }
}
