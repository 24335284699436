import i18n from "@/util/i18n"
import {reactive} from "@vue/reactivity"

export function getWindowNotificationPermission(){
    if (!("Notification" in window)) {
        return "denied"
    } else {
        return window.Notification.permission
    }
}

class DesktopNotificationUtil {

    notificationPermission: string = getWindowNotificationPermission()

    constructor() {
        this.notificationPermission = getWindowNotificationPermission()
    }

    updateNotificationPermission () {
        this.notificationPermission = getWindowNotificationPermission()
    }

    get shouldAskForNotificationPermission() {
        return this.notificationPermission === "default"
    }

    checkNotificationPromise(): boolean {
        try {
            void Notification.requestPermission().then()
        } catch(e) {
            return false
        }
        return true
    }

    sendNotificationForSuccess(): void {
        const text = i18n.$gettext("You will now receive notifications when the tab is not visible")
        const n: Notification = new Notification(i18n.$gettext("Success"), {body: text, icon: "/images/elly_logo.png"})
        window.setTimeout(() => {
            n.close()
        }, 10000)

    }

    askForNotification() {
        if (this.checkNotificationPromise()) {
            void Notification.requestPermission()
                .then(() => {
                    this.sendNotificationForSuccess()
                    this.updateNotificationPermission()
                })
        } else { //Basically only for safari
            void Notification.requestPermission(() => {
                this.sendNotificationForSuccess()
                this.updateNotificationPermission()
            })
        }
    }
}

const desktopNotificationUtil = reactive(new DesktopNotificationUtil())
export default desktopNotificationUtil
