import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import OutOfOffice from '@/model/settings/OutOfOffice';


export default class GeneratedOutOfOfficeStore {

    state: {
        outOfOffices: Map<number, OutOfOffice>,
        calls: Map<string, number | number[]>
    }
    timer: number | null = null

    constructor() {
        let outOfOffices: Map<number, OutOfOffice> | null = null
        try {
            const persistedState = localStorage.getItem('OutOfOfficeStore')
            if (persistedState) {
                outOfOffices = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            outOfOffices: outOfOffices || new Map<number, OutOfOffice>(),
            calls: new Map<string, number | number[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('OutOfOfficeStore', JSON.stringify(Array.from(this.state.outOfOffices)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.outOfOffices) this.state.outOfOffices.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('OutOfOfficeStore')
    }

    addOrReplaceOutOfOffice(newOutOfOffice: OutOfOffice, persist: boolean = true): true | undefined {
        if (newOutOfOffice.id) {
            this.state.outOfOffices.set(newOutOfOffice.id, newOutOfOffice)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceOutOfOffices(newOutOfOffices: OutOfOffice[]) {
        let persist = false
        for (const newOutOfOffice of newOutOfOffices) {
            const changed = !!this.addOrReplaceOutOfOffice(newOutOfOffice, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceOutOfOffices(outOfOffices: OutOfOffice[]) {
        const newOutOfOffices = new Map<number, OutOfOffice>()
        for (const newOutOfOffice of outOfOffices) {
            if (newOutOfOffice.id) {
                newOutOfOffices.set(newOutOfOffice.id, newOutOfOffice)
                
            }
        }
        this.state.outOfOffices = newOutOfOffices
        this.persistState()
    }

    removeOutOfOffice(id: number | undefined | null, persist: boolean = true): true | undefined {
        if (id !== undefined && id !== null) {
            if (this.state.outOfOffices.delete(id)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeOutOfOffices(ids: (number | null)[] | undefined | null) {
        let persist = false
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                const changed = !!this.removeOutOfOffice(id, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}