

import {Options, Vue} from "vue-class-component"
import {useGettext} from "@jshmrtn/vue3-gettext"
import MultiSelect from "primevue/multiselect"
import Address from "@/model/common/carddav/Address"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"
import AnimatedInput from "@/components/common/AnimatedInput.vue"

@Options({
  components: {AnimatedInput, MultiSelect, Button, Dropdown},
  //@ts-ignore
  props: {
    address: [Address, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    isv4: {
      type: Boolean,
      default: false
    },
    showType: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  emits: [ 'delete' ]
})
export default class AddressField extends Vue {

  i18n = useGettext()
  index = 0
  isv4 = false
  address!: Address

  typeMapv3: { [index:string] : string }  = {
    dom: this.i18n.$gettext("Domestic"),
    intl: this.i18n.$gettext("International"),
    postal: this.i18n.$gettext("Postal"),
    parcel: this.i18n.$gettext("Parcel"),
    work: this.i18n.$gettext("Business"),
    pref: this.i18n.$gettext("Main"),
    home: this.i18n.$gettext("Private"),
  }

  typeMapv4: { [index:string] : string }  = {
    work: this.i18n.$gettext("Business"),
    home: this.i18n.$gettext("Private"),
    individual: this.i18n.$gettext("Both")
  }


  get typeString(): string {
    const result: string = this.i18n.$gettext("Address")
    const type: string = this.value
    if(this.typeMapv3.hasOwnProperty(type)){

      return this.typeMapv3[type]
    }

    if(this.typeMapv4.hasOwnProperty(type)) {
      return this.typeMapv4[type]
    }

    return result
  }

  get typeSelectOptions() {
    let result = []
    let typeMap = this.isv4 ? this.typeMapv4 : this.typeMapv3
    for (const [key, value] of Object.entries(typeMap)) {
      result.push({label: value, value: key})
    }
    return result
  }

  emitDelete(): void {
    this.$emit('delete')
  }

  get value(): string {
    if(!this.address) return ""

    if(!this.address.types){
      this.address.types = []
    }

    return this.address?.types[0] || ""
  }

  set value(val: string) {
    if(!this.address) return

    if(!this.address.types){
      this.address.types = []
    }

    this.address.types[0] = val
  }
}
