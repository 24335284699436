

import {Options, Vue} from "vue-class-component"
import Constants from "@/util/Constants"
import SettingsUtil from "@/util/SettingsUtil"

@Options({
})
export default class IntroVideoWidget extends Vue {

  videoUrl: string = Constants.introductionVideoUrl

  handleHideClick(){
    SettingsUtil.setIntroVideoSeen()
  }
}
