import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import SchedulingObject from '@/model/SchedulingObject';
import Page from '@/model/Page';
import Event from '@/model/entry/Event';
import { schedulingObjectStore } from '@/store/SchedulingObjectStore';

export default class GeneratedCalendarInboxServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _readInbox(): Promise<SchedulingObject[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('readInbox', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const schedulingObjects: SchedulingObject[] = data.map(schedulingObject => Object.assign(new SchedulingObject(), schedulingObject))
                schedulingObjectStore.replaceSchedulingObjects(schedulingObjects)
                return schedulingObjects
            } else return Promise.reject()
        })
    }


    _handleInvitation(schedulingObject: SchedulingObject, request: Event, status: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleInvitation', rpcParams, false).then((data: any) => {
            const model = Object.assign(new SchedulingObject(), data)
            schedulingObjectStore.removeSchedulingObject(schedulingObject.id)
            schedulingObjectStore.addOrReplaceSchedulingObject(model)
            return model.id
        })
    }

    _handleCancel(schedulingObject: SchedulingObject, request: Event): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleCancel', rpcParams, false).then((data: any) => {
            const model = Object.assign(new SchedulingObject(), data)
            schedulingObjectStore.removeSchedulingObject(schedulingObject.id)
            schedulingObjectStore.addOrReplaceSchedulingObject(model)
            return model.id
        })
    }

    _deleteRequest(id: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteRequest', rpcParams, false).then(() => {
            schedulingObjectStore.removeSchedulingObject(id)
        })
    }

    _handleCounter(schedulingObject: SchedulingObject, accepted: boolean): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleCounter', rpcParams, false).then(() => {
            schedulingObjectStore.removeSchedulingObject(schedulingObject.id)
        })
    }

    readInbox(refresh?: boolean | number): SWR<SchedulingObject[], string[]> {
        //@ts-ignore
        const result: SWR<SchedulingObject[], string[]> = reactive(new SWR<SchedulingObject[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_readInbox' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_readInbox[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const schedulingObjects: SchedulingObject[] = []
                for (const id of data) {
                    const schedulingObject: SchedulingObject | undefined = schedulingObjectStore.state.schedulingObjects.get(id)
                    if (schedulingObject) {
                        schedulingObjects.push(schedulingObject)
                    }
                }
                result.data = schedulingObjects
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._readInbox().then((data: SchedulingObject[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(schedulingObject => schedulingObject.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let schedulingObjects: SchedulingObject[] = [...schedulingObjectStore.state.schedulingObjects.values()]
        
        
        
        result.data = schedulingObjects
        return result
    }

    getSchedulingObject(id: string): SchedulingObject | undefined {
        return schedulingObjectStore.state.schedulingObjects.get(id)
    }
}
