import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Contact from '@/model/entry/Contact';


export default class GeneratedContactStore {

    state = reactive({
        contacts: new Map<string, Contact>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.contacts) this.state.contacts.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceContact(newContact: Contact) {
        if (newContact.originalId) {
            this.state.contacts.set(newContact.originalId, newContact)
            
        }
    }

    addOrReplaceContacts(newContacts: Contact[]) {
        for (const newContact of newContacts) {
            this.addOrReplaceContact(newContact)
        }
    }

    replaceContacts(contacts: Contact[]) {
        const newContacts = new Map<string, Contact>()
        for (const newContact of contacts) {
            if (newContact.originalId) {
                newContacts.set(newContact.originalId, newContact)
                
            }
        }
        this.state.contacts = newContacts
    }

    removeContact(originalId: string | undefined | null) {
        if (originalId !== undefined && originalId !== null) {
            this.state.contacts.delete(originalId)
        }
    }

    removeContacts(originalIds: (string | null)[] | undefined | null) {
        if (originalIds !== undefined && originalIds !== null) {
            for (const originalId of originalIds) {
                this.removeContact(originalId)
            }
        }
    }
}