import Event from '@/model/entry/Event';

export default class SchedulingObject {
  id!: string | null;
  method!: string | null;
  isValid!: boolean | null;
  eventsFromRequest!: Event[] | null;

  constructor() {
    this.id = null;
    this.method = null;
    this.isValid = null;
    this.eventsFromRequest = null;
  }
}
