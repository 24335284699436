
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import EmailUtil from "@/util/EmailUtil"
import {Watch} from "vue-property-decorator"
import {rpcClient} from "@/api/WebsocketClient"
import useToast from "@/util/toasts"
import Avatar from "@/components/common/Avatar.vue"
import ResourceShare from "@/model/common/ResourceShare"
import Group from "@/model/directory/Group"
import {groupServiceApi} from "@/api/GroupServiceApi"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    resource: String,
    type: String,
    accessOptions: {
      type: Array,
      default: null
    },
    onlyAllowSuggestions: {
      type: Boolean,
      default: false
    },
    ignoreSessionUser: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Avatar, Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class ResourceShareUserInput extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  modelValue!: ResourceShare[]
  resource!: string
  type!: string
  accessOptions!: any[] | null
  onlyAllowSuggestions = false
  ignoreSessionUser: boolean = false

  sharedTo: ResourceShare[] = []
  selectableUsers: string[] = []
  newShare = ''
  myUsername = rpcClient.session.user?.userName

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  userNameFor(share: ResourceShare): string {
    return this.users.find(u => u.userName === share.targetId || u.email == share.targetId)?.userName || share.targetId || ''
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  get groups(): Group[] {
    return groupServiceApi.getGroupsForCurrentUser().data || []
  }

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      return user.userName &&
        (user.userName != rpcClient.session.user?.userName || !this.ignoreSessionUser) &&
        user.userName?.toLowerCase()?.indexOf(event.query?.toLowerCase()) !== -1 &&
        !this.sharedTo.find((share: ResourceShare) => share.targetId === user.userName)
    }).map((user: User) => {
      return user.userName || ""
    }).filter((str: string) => {return str !== "" })
  }

  addShare() {
    const email = this.newShare
    if (email === "" || !!this.sharedTo.find((a: ResourceShare) => a.targetId === email)) {
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const user: User | undefined = this.users.find(u => u.userName === email)
      if (user) {
        const share: ResourceShare = new ResourceShare()
        share.resource = this.resource
        share.targetId = user.userName
        share.type = this.type
        share.targetType = 'USER'
        if (this.tAccessOptions && this.tAccessOptions.length > 0) share.permission = this.tAccessOptions[0].id
        this.sharedTo.push(share)
        this.$emit('added', share)
        this.$emit('update:modelValue', this.sharedTo)
        this.newShare = ""
      }
    } else {
      this.toast.error(this.i18n.$gettext("Attendee must be an email address"))
    }
  }

  addShareViaEnter() {
    if (!this.onlyAllowSuggestions) {
      this.addShare()
    }
  }

  updateShare(share: ResourceShare) {
    this.$emit('updated', share)
  }

  removeShare(share: ResourceShare) {
    const removed: number = this.sharedTo.indexOf(share)
    if (removed >= 0) {
      const removedShare: ResourceShare = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedShare)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged() {
    this.sharedTo = this.modelValue
  }

  mounted() {
    this.onValueChanged()
  }
}
