import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import SchedulingObject from '@/model/SchedulingObject';


export default class GeneratedSchedulingObjectStore {

    state: {
        schedulingObjects: Map<string, SchedulingObject>,
        calls: Map<string, string | string[]>
    }
    timer: number | null = null

    constructor() {
        let schedulingObjects: Map<string, SchedulingObject> | null = null
        try {
            const persistedState = localStorage.getItem('SchedulingObjectStore')
            if (persistedState) {
                schedulingObjects = new Map(JSON.parse(persistedState))
            }
        } catch (ignore) {}
        this.state = reactive({
            schedulingObjects: schedulingObjects || new Map<string, SchedulingObject>(),
            calls: new Map<string, string | string[]>()
        })
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    persistState() {
        if (this.timer === null) {
            setTimeout(() => {
                localStorage.setItem('SchedulingObjectStore', JSON.stringify(Array.from(this.state.schedulingObjects)))
                this.timer = null
            }, 1000)
        }
    }

    clearState() {
        if (this.timer) clearTimeout(this.timer)
        if (this.state?.schedulingObjects) this.state.schedulingObjects.clear()
        if (this.state?.calls) this.state.calls.clear()
        localStorage.removeItem('SchedulingObjectStore')
    }

    addOrReplaceSchedulingObject(newSchedulingObject: SchedulingObject, persist: boolean = true): true | undefined {
        if (newSchedulingObject.id) {
            this.state.schedulingObjects.set(newSchedulingObject.id, newSchedulingObject)
            
            if (persist) {
                this.persistState()
            } else {
                return true
            }
        }
    }
    
    addOrReplaceSchedulingObjects(newSchedulingObjects: SchedulingObject[]) {
        let persist = false
        for (const newSchedulingObject of newSchedulingObjects) {
            const changed = !!this.addOrReplaceSchedulingObject(newSchedulingObject, false)
            persist = persist || changed
        }
        if (persist) {
            this.persistState()
        }
    }

    replaceSchedulingObjects(schedulingObjects: SchedulingObject[]) {
        const newSchedulingObjects = new Map<string, SchedulingObject>()
        for (const newSchedulingObject of schedulingObjects) {
            if (newSchedulingObject.id) {
                newSchedulingObjects.set(newSchedulingObject.id, newSchedulingObject)
                
            }
        }
        this.state.schedulingObjects = newSchedulingObjects
        this.persistState()
    }

    removeSchedulingObject(id: string | undefined | null, persist: boolean = true): true | undefined {
        if (id !== undefined && id !== null) {
            if (this.state.schedulingObjects.delete(id)) {
                if (persist) {
                    this.persistState()
                } else {
                    return true
                }
            }
        }
    }

    removeSchedulingObjects(ids: (string | null)[] | undefined | null) {
        let persist = false
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                const changed = !!this.removeSchedulingObject(id, false)
                persist = persist || changed
            }
        }
        if (persist) {
            this.persistState()
        }
    }
}