export default class InternalShareLink {
  name!: string | null;
  smartLink!: string | null;
  smartLinkToken!: string | null;

  constructor() {
    this.name = null;
    this.smartLink = null;
    this.smartLinkToken = null;
  }
}
