
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import {fileServiceApi} from "@/api/FileServiceApi"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import dayjs from 'dayjs'
import LoadingButton from "@/components/common/LoadingButton.vue"
import InputSwitch from "primevue/inputswitch"
import InputNumber from "primevue/inputnumber"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import UploadLink from "@/model/common/UploadLink"
import RpcError from "@/api/RpcError"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import useToast from "@/util/toasts"

@Options({
  components: {
    Dialog, TabView, TabPanel, LoadingButton, InputSwitch, InputNumber, Password, ProgressBar, Button
  },
  //@ts-ignore
  props: {
    inode: INode,
  },
  emits: ["shouldClose"]
})
export default class DownloadLinkForm extends Vue {

  api = fileServiceApi

  inode!: INode

  links: UploadLink[] = []
  loaded = false
  loading = false
  toast = useToast()
  createWithPassword = false
  createWithExpiryDate = false
  expiryDays = 14 //default
  password = ""
  fetchFailed = false
  i18n: Language = useGettext()

  actionIsLoading = false;

  mounted(): void {
    if(this.loaded && !this.fetchFailed) return
    this.loadData().catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Could not get sharing information'))
    })
  }

  loadData(): Promise<void> {
    if(this.inode.path === null) return Promise.reject()
    this.loading = true
    return this.api._listUploadLinks(this.inode.path).then((links: UploadLink[]) => {
      this.links = links
      this.loading = false
      this.loaded = true
    }).catch((error: RpcError) => {
      this.toast.error(error.message)
      this.loading = false
      this.fetchFailed = true
    })
  }

  get hasLink(): boolean {
    if(!this.inode) return false
    return this.links.length > 0
  }

  getLink(): UploadLink | null {
    return (this.links.length > 0 ) ? this.links[0] : null
  }

  get href(): string {
    if(this.getLink() === null) return ""
    return this.links[0].link || ""
  }

  get humanreadableDate(): string {
    if(this.getLink()?.expireDate === null || this.getLink()?.expireDate === "") return this.i18n.$gettext("This link has no expiration date")
    const date: string = this.getLink()?.expireDate || ""
    return this.i18n.$gettext("This link expires on") + ' ' + dayjs(date).format("DD.MM.YYYY")
  }

  deleteLink(): Promise<void> {
    const token: string = this.getLink()?.token || ""
    if(token === "") return Promise.reject()
    this.actionIsLoading = true
    return this.api._deleteUploadLink(token).catch((e: RpcError) => {
      this.toast.info(e.message, this.i18n.$gettext("Upload Link could not be deleted"))
    }).then(() => {
      this.toast.info(this.i18n.$gettext("Upload Link deleted"))
      void this.loadData()
    }).finally(() => { this.actionIsLoading = false })
  }

  createLink(): Promise<void> {
    if(!this.inode.path) return Promise.reject()
    let expireDays: string | null = null
    if(this.createWithExpiryDate) {
      if(this.expiryDays < 1) {
        this.toast.error(this.i18n.$gettext("You need to specify at least one day"))
        return Promise.reject()
      }
      expireDays = dayjs().add(this.expiryDays, 'day').toISOString()
    }
    const password: string | null =  (this.createWithPassword ? this.password : null)
    this.actionIsLoading = true
    return this.api._createUploadLink(this.inode.path, password, expireDays).catch((e: RpcError) => {
      this.toast.info(e.message, this.i18n.$gettext("Upload Link could not be created"))
    }).then(() => {
      this.toast.info(this.i18n.$gettext("Upload Link created"))
      void this.loadData()
    }).finally(() => { this.actionIsLoading = false })
  }

}
